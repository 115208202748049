import React, { useEffect, useState } from 'react';
import playlistCategories from '../../utils/genres';

const AppSettings = ({user={}, userSettings}) => {

   const [prefrences, setPrefrences] = useState(['pop']);
   //  const [theme, setTheme] = useState('dark');
   const [nofollowChecked, setNofollowChecked] = useState(false);
   const [privateChecked, setPrivateChecked] = useState(false);
   const [showPrefOptions, setShowPrefOptions] = useState(false);

    useEffect(()=> {
      if(!user.id){
         if(user.settings && user.settings.prefrences){
            let newprefs = [];
            user.settings.prefrences.split(',').map( (prefItem)=> {
                return newprefs.push(prefItem);
            });
            setPrefrences(newprefs);
            setNofollowChecked(user.settings.nofollow === 'true'? true: false );
            setPrivateChecked(user.settings.private === 'true'? true: false);
        } 
        
      }
    }, [user])

   const setPreferences = (value) => {
      const newPrefs = prefrences.includes(value) ? prefrences.filter((item)=> item !== value) : [...prefrences, value];
      setPrefrences(newPrefs);
      setShowPrefOptions(false);
    }

   const toggleChange = (type) => {
        if(type === 'nofollow'){ setNofollowChecked(!nofollowChecked); }
        if(type === 'private'){ setPrivateChecked(!privateChecked); }
    }

   return(
            <div className={`AppSettings`}  key={`${user.username}_app`} >
                {/* <div className="custom_checkbox">
                    <span>Disable Follow</span>
                    <input type="checkbox" name="nofollow" id="switch_nofollow" value={nofollowChecked} onChange={()=>toggleChange('nofollow')} checked={nofollowChecked } />
                    <label htmlFor="switch_nofollow">Toggle</label>
                </div> */}
                <div className="custom_checkbox">
                    <span>Make Profile Private</span>
                    <input type="checkbox" name="privateacc" id="switch_private" value={privateChecked} onChange={()=>toggleChange('private')} checked={privateChecked } />
                    <label htmlFor="switch_private">Toggle</label>
                </div>
                <div className="select_wrapper">
                    <span>Music Preferences</span>
                    <div className='musicPrefField'>
                        <div className='musicPrefFieldLabel' onClick={()=> setShowPrefOptions(!showPrefOptions) }>
                           {prefrences.length > 0 && prefrences.map((item, index)=> <span key={item+'-'+index}>{item}</span> )}
                        </div>
                        {showPrefOptions && 
                           <div className='musicPrefFieldOptions customScrollbar'>
                              <div>
                                 {playlistCategories.length > 0 && playlistCategories.map((category, index)=> {
                                    return <span key={category+'-'+index} onClick={()=> setPreferences(category.id)} className={prefrences.includes(category.id) ? 'activeOption' : ''}>{category.name}</span>
                                 } )}
                              </div>
                           </div>
                        }
                    </div>
                    <input type="hidden" value={prefrences.join(',')} name={'prefrences'} />
                </div>
            </div>
        );
}

//export default AccountSettings;
export default AppSettings;