import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useSwipeable } from 'react-swipeable';
import { useLongPress } from 'use-long-press';
import albumCover from '../../assets/cover.png';
import SongActions from './SongActions';
import { removeSong, addSong, cutSong } from '../../redux/songSlice';
import { guestRemove } from '../../redux/guestPlaylistSlice';
import { addToQueue, removeFromQueue, repeatSongInQueue, editSongTimeInQueue } from '../../redux/queueSlice';
import { formatDuration, covertToYoutubeTime, youtubeVideoDuration, secondsToTime, throttle } from '../../helpers';
import Icon from '../Icon/Icon';


const Song = (props) => {
   const { mobileSelectedSong, mobileSelectSong, queue, songID, song, playlistsMin, songfbKey, playlistID, uid, playSong, 
      activeSong, draggingSong, order, isMobile, moveSong, onSelectionChange, onDragStart, isSelected, selectedSongs, userIsAuthor, reorder, style={} } = props;

    const dispatch = useDispatch();
    const songRef = useRef(null); 
    const [cover, setCover] = useState('');
    const [showActions, setShowActions] = useState(false);
    const [actionsMenuPos, setActionsMenuPos] = useState(null);
    const [swipeRight, setSwipeRight] = useState(false);
    const [swipeLeft, setSwipeLeft] = useState(false);
    const [press, setPress] = useState(false);
    const [message, setMessage] = useState(null);

    const [{ isDragging }, drag, dragPreview] = useDrag(
      () => ({
         type: 'song',
         // item: { title: song.title, id: song.id, originalIndex: index },
         collect: (monitor) => ({
            isDragging: monitor.isDragging(),
         }),
         item: (dropResult, monitor) => {
            console.log('DragStart');
            draggingSong(queue ? 'queue' : 'playlist');
            const draggedSong = { ...song, cover: song.cover || cover };
            let songs;
            if (selectedSongs.find(song => song.id === songID)) {
               songs = selectedSongs;
            } else {
               songs = [draggedSong];
            }
            const otherSongs = songs.concat();
            otherSongs.splice(songs.findIndex(c => c.id === songID), 1);
            const songsDragStack = [draggedSong, ...otherSongs];
            const dragItem = { 
               songs, 
               songsDragStack, 
               draggedSong, 
               id: songID, 
               song: {...song, cover}, order: order 
            }
            console.log(dragItem, songsDragStack);
            onDragStart(dragItem);
            return dragItem;
         },
         end: (dropResult, monitor) => {
            const { id: droppedId, songs, draggedSong } = monitor.getItem();
            const didDrop = monitor.didDrop();
            console.log('Drag End: ', droppedId, didDrop, songs, title, draggedSong);
            if (didDrop) {
               console.log('Drop Ended!!', queue);
               reorder(queue);
               draggingSong('');
            }else{
               setTimeout(() => {
                  draggingSong('');
               }, 1000);
               return;
            }
         }
      }),
      [],
    );
    // eslint-disable-next-line no-unused-vars
    const [{ isOver, isOverCurrent }, drop] = useDrop(
      () => ({
        accept: 'song',
        hover: throttle((_item, monitor) => {
            if (!songRef.current) { return;  }
            const dragId = monitor.getItem().id;
            const hoverId = props.songID;
            const dragOrder = monitor.getItem().order;
            const hoverOrder = props.order;
      
            if (dragId === hoverId) {
               return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = songRef.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragOrder < hoverOrder && hoverClientY < hoverMiddleY) {
               return
            }
            if (dragOrder > hoverOrder && hoverClientY > hoverMiddleY) {
               return
            }
            console.log('fire Move Song!');
            moveSong(dragId, hoverId);
        }, 100),
        drop(_item, monitor) {
          const didDrop = monitor.didDrop()
          if (didDrop) {
            return
          }
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          isOverCurrent: monitor.isOver({ shallow: true }),
        }),
      }),
    );

   useEffect(() => {
      dragPreview(getEmptyImage(), { captureDraggingState: true })
    }, [dragPreview]);



   useEffect(()=> {
      if(!cover && !queue){
         if(song.cover) {  setCover(song.cover.includes('http') ? song.cover : 'https://lastfm.freetls.fastly.net/i/u/64s/'+song.cover); }
         if(!song.cover && song.youtube){  setCover(`https://i.ytimg.com/vi/${song.youtube}/default.jpg`); }
      }
   }, [queue, song, cover]);

    const copySong = (playlistSelect, queue) => {
        const copySelectedPlaylist = playlistsMin && playlistsMin.filter((plist)=>  plist['id'] === playlistSelect  ); 
        console.log(copySelectedPlaylist, copySelectedPlaylist[0] ? copySelectedPlaylist[0].count : 0);
        if(!queue){
            const newSong = {...song , order: copySelectedPlaylist[0] ? copySelectedPlaylist[0].count : 0}
            dispatch(addSong(uid, playlistSelect, newSong));
        }else{
            dispatch(addToQueue({song, queue, play: false}));
        }
    }

    const updateStartEnd = (start, end) => {
        if(userIsAuthor){
            if( (start && start.match(/(:.*){2}/)) || (end && end.match(/(:.*){2}/)) ){
                let startTime = ''; let endTime = ''; let endSeconds='';
                if(start){
                    startTime = youtubeVideoDuration(covertToYoutubeTime(start));
                    startTime = startTime === 0 ? '' : startTime;
                }
                if(end){
                    endSeconds = youtubeVideoDuration(covertToYoutubeTime(end));
                    endTime = song.duration !==  endSeconds ? endSeconds : '';
                }
                
                const newSong = {...song , start:startTime, end: endTime}
                if(startTime || endTime){
                    if(queue){
                        dispatch(editSongTimeInQueue({song: newSong, queue}));
                    }else{
                        dispatch(cutSong(uid, playlistID, songfbKey, newSong));
                    }
                    
                }
            }
        }
    }


    const removeTheSong = () => {
        if(queue){
            dispatch(removeFromQueue({songID: songfbKey, queue}));
        }else{
            dispatch(removeSong(uid, playlistID, songfbKey));
        }
        
    }
    
      const onSongClick =(e) => {
         //console.log('clicking Song!!', e);
         onSelectionChange(songID, e.metaKey, e.ctrlKey, e.shiftKey);
      }

      const swipeSongRight = () => {
            setSwipeRight(true);
            setTimeout(() => {
               playSong(songfbKey);
               setSwipeRight(false);
            }, 500);
      }

      const swipeSongLeft = () => {
         if(queue){ return }
         setSwipeLeft(true);
         setTimeout(() => {
               setSwipeLeft(false);
               setMessage('Song Added to Queue')
               dispatch(addToQueue({song, queue:'queueOne', play: false}));
               
         }, 500);

         setTimeout(() => {
               setMessage(null);
         }, 1800);
      }


      const swipeHandlers = useSwipeable({
         onSwipedLeft: (eventData) => isMobile && swipeSongLeft(),
         onSwipedRight: (eventData) => isMobile && swipeSongRight(),
         onTouchStartOrOnMouseDown: (eventData) => console.log('touchStart', eventData),
         onTouchEndOrOnMouseUp: (eventData) => console.log('touchEnd', eventData),
      });

      const longPressHandler = useLongPress(() => {
         if(isMobile){
            console.log('Long pressed!');
            setPress(true);
            setTimeout(() => {
                  setPress(false);
                  mobileSelectSong(song);
                  //addToQueue(song, 'queueOne', false);
            }, 500);
         }

       });

      const {title, duration, artist} = song;
      const active = activeSong === songfbKey ? 'active' : '';
      const opacity = isDragging ? 0 : 1;
      const zIndex = isDragging ?  5 : 1;
      const dragClass = !isMobile && isDragging ?  'songDragging' : '';
      
      drag(drop(songRef));

      return (
         <div 
         id={songID} 
         className={`song ${active} ${showActions ? 'selectedSong':''} ${dragClass} ${isSelected || (mobileSelectedSong && mobileSelectedSong.id === songID ) ? 'songSelected' :''} ${swipeRight ? 'swipeAnim':''} ${swipeLeft ? 'swipeAnimLeft':''} ${press ? 'pressAnim':''} `} 
         onClick={onSongClick} 
         onDoubleClick={()=>playSong(songfbKey)} 
         //onContextMenu={showActions}
         onContextMenu={(e)=> { e.preventDefault(); setShowActions(true); }}
         style={{ zIndex, opacity, ...style }}
         ref={songRef}  
         >
            {/* <Hammer onSwipeRight={swipeSongRight} onSwipeLeft={swipeSongLeft} onPress={longPressSong}> */}
            <div className='songPresser' {...swipeHandlers} {...longPressHandler()}>
               <div className="song_inner">
               {message && <span className="songMsg">{message}</span>}
                  {! queue &&
                     <div className="song__album">
                        <img id={`img-${songfbKey}`} src={cover ? cover : albumCover} alt={title} />
                     </div>
                  }
                  
                  
                  <div className={`song__details ${song.repeat > 0 ? 'repeatOn' : ''}`}>
                        {queue && 
                              (mobileSelectedSong && (mobileSelectedSong.id === songID ) ?
                                 <span className="mobileSelectedSongIcon"><Icon icon="md-checkmark" fontSize="14px" color="#ffffff"/></span>
                              :
                                 <button className="song__actions__repeat" aria-label="Keep Repeating this Song" onClick={()=> dispatch(repeatSongInQueue({song: {...song, repeat: song.repeat && song.repeat > 0 ? 0 : 999 }, queue}))}>
                                    <Icon icon="md-refresh" fontSize="14px" color="#ffffff"/>
                                 </button>
                              )
                        }
                        <span className="song__title">{title}</span>
                        {artist && <span className="song__artist">{artist}</span>}
                        {/* <span className="song__album">Album name</span> */}
                  </div>

               </div>
            </div>
            {/* </Hammer> */}

               {!isMobile && 
                  <div className={`songActionsWrapper`}>
                     <button className="song__actions__play" aria-label="Play" onClick={()=> playSong(songfbKey) }>&#9658;</button>
                     <div className="show_actions" onClick={()=>{ setShowActions(!showActions); setActionsMenuPos(null); } }>&#8226; &#8226; &#8226;</div>
                     
                     {showActions &&
                           <SongActions
                           guest={!uid}
                           guestRemove={()=> dispatch(guestRemove(songID))}
                           menuPos={actionsMenuPos}
                           userIsAuthor={userIsAuthor} 
                           playlistsMin={playlistsMin} 
                           start={song.start? secondsToTime(song.start) : '00:00:00'}
                           end={song.end? secondsToTime(song.end) : secondsToTime(song.duration)}
                           repeatCount={song.repeat? song.repeat:0}
                           queue={ queue} 
                           updateTime={updateStartEnd}
                           addSong={copySong}
                           removeSong={removeTheSong} />
                     }
                  </div>
               }
            <div className="song__duration">{formatDuration(duration)}</div>
         </div>
      );

}

export default Song;