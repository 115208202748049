import React from 'react';
import { useDrop } from 'react-dnd';
import { throttle } from '../../helpers';


const PlayListGroupDropZone = ({activateSongDrop}) => {


    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: ['song', 'track'],
      hover: throttle((_item, monitor ) => {
         activateSongDrop();
      }, 100),
      drop: (_item, monitor) => {
         const item = monitor.getItem();
         console.log(item);
         return { moved: true };
      },
      collect: (monitor) => ({
         isOver: monitor.isOver(),
         isOverCurrent: monitor.isOver({ shallow: true }),
         canDrop: monitor.canDrop(),
         itemType: monitor.getItemType()
      }),
   }))

   const isActive = canDrop && isOver;

   return <div ref={drop} className="playListGroupDropZone" style={isActive ? {backgroundColor:'#58347ec7'}: null}><div>Drag Song here to <h4>Add to Existing Playlist</h4></div></div>
    
}

export default PlayListGroupDropZone;
