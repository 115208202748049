import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { minifyPlaylist, arrayMove } from '../../helpers';
import {  draggingSong, selectSong } from '../../redux/userSlice';
import { reorderSongs, addSong } from '../../redux/songSlice';
// import { updatePlaylist, removePlaylist, selectedSong, likePlaylist } from '../../redux/playlistSlice';
import { addToQueue, sortQueue, removeFromQueue } from '../../redux/queueSlice';
import SongWrapper from '../Song/SongWrapper';
import QueueDropZone from './QueueDropZone';
import Modal from '../Modal/Modal';
import Icon from '../Icon/Icon';

const Queue = ({songs, history, playlists, auth, authorID, author}) => {
   const dispatch = useDispatch();
   const queue = useSelector((state) => state.queue);
   const userplaylists = useSelector((state) => state.userplaylists);
   const userplaylistsMin = useSelector((state) =>  minifyPlaylist(state.user?.userData?.playlists || []));
   const userSettings = useSelector((state) => state.user);
   const profile = useSelector((state) => state.user?.userData);
   const userIsAuthor = useSelector((state) => profile && profile.id && authorID && (authorID === profile.id));
   // const playlists = useSelector((state) => profile && authorID === profile.id ? playlists : {} );
   const playlistsMin = useSelector((state) =>  minifyPlaylist( profile && authorID === profile.id ? playlists : {} ));
   const currentPlaylist = userplaylists.selectedPlaylist ? userplaylists.selectedPlaylist : playlistsMin && playlistsMin.length > 0 && playlistsMin[0].id;
 
   const [reorderingSongs, setReorderingSongs] = useState([]);
   const [mobileSelectedSong, setMobileSelectedSong] = useState(null);
   const [selectedAddPlaylist, setSelectedAddPlaylist] = useState(null);
   const [showMobileAddModal, setShowMobileAddModal] = useState(false);
   const [reordering, setReordering] = useState(false);
   // const [playlistHeight, setPlaylistHeight] = useState(500);

   // useEffect(()=> {
   //    const currentPlaylistID = userplaylists.selectedPlaylist ? userplaylists.selectedPlaylist : playlistsMin && playlistsMin.length > 0 && playlistsMin[0].id;
   //    setCurrentPlaylist(currentPlaylistID);
   // }, [userplaylists, playlistsMin, songs, userSettings])

   // useEffect(()=> {
   //    const playlistHeight = document.getElementsByClassName('playlist_songs-mini')[0].offsetHeight;
   //    setPlaylistHeight(playlistHeight);
   // }, [userplaylists, playlistsMin])

   // console.log('queue:', queue);
   const playSong = (songID) => {
    const song = songs.filter((sng)=> sng.id === songID)
    if(song.length > 0 ){
      console.log( 'playSong: ',  song[0], queue.selected );
      dispatch(addToQueue( {song: song[0], queue: queue.selected, play: true }))
    }
  }

   const playMobileSong = () => {
    if(!mobileSelectedSong){ return }
    dispatch(addToQueue( { song: mobileSelectedSong, queue: queue.selected, play: true } ));
    setMobileSelectedSong(null);
  }

   const removeMobileSong = () => {
    dispatch(removeFromQueue({songID : mobileSelectedSong.id, queue: queue.selected}))
    setMobileSelectedSong(null);
  }

   const addMobileSong = () => {
      let queue;
      let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
      if(playlistsMin && playlistsMin.length > 0){ queuePlaylists = [...queuePlaylists, ...playlistsMin]  }
      const copySelectedPlaylist = queuePlaylists.filter((plist)=>  plist['id'] === selectedAddPlaylist  ); 
      if(selectedAddPlaylist === 'queueOne' || selectedAddPlaylist === 'queueTwo' || selectedAddPlaylist === 'queueThree'){
         queue = selectedAddPlaylist;
      }
      console.log(copySelectedPlaylist, copySelectedPlaylist[0] ? copySelectedPlaylist[0].count : 0);
      if(!queue){
         const newSong = {...mobileSelectedSong , order: copySelectedPlaylist[0] ? copySelectedPlaylist[0].count : 0}
         dispatch(addSong(profile.id, selectedAddPlaylist, newSong));
      }else{
         const newSong = {...mobileSelectedSong, order: queue[queue].length}
         dispatch(addToQueue({song: newSong, queue: queue, play: false}));
      }
      setSelectedAddPlaylist(null);
      setMobileSelectedSong(null);
      setShowMobileAddModal(false);
    
  }

   const mobileSongOrder = (direction) => {
      const songsToRerorder = [...reorderingSongs];
      const songCurrentIndex = songsToRerorder.findIndex(x => x.id === mobileSelectedSong.id);
      
      if(direction === 'up' && songCurrentIndex > 0){
          let newSongListUp = arrayMove(songsToRerorder, songCurrentIndex, songCurrentIndex - 1);
          newSongListUp = newSongListUp.map((sng, index) =>  { return {...sng, order: index} });
          setReorderingSongs(newSongListUp);
      }

      if(direction === 'down' && songCurrentIndex <= reorderingSongs.length -1){
        let newSongListDown = arrayMove(songsToRerorder, songCurrentIndex, songCurrentIndex + 1);
        newSongListDown = newSongListDown.map((sng, index) =>  { return {...sng, order: index} });
        setReorderingSongs(newSongListDown);
      }
  }


   const cancelMobileActions = () => {
      setReordering(false);
      setMobileSelectedSong(null);
      setSelectedAddPlaylist(null);
      setShowMobileAddModal(false);
   }

  const renderMobileReorderActions = () => {

      return (
        <div className="mobile_actions">
          <div className="mobile_actions__all">
            <button className="mobile_actions__play" onClick={playMobileSong}><Icon icon="md-play" fontSize="18px" color="#ffffff"/></button>
            <button className="mobile_actions__add" onClick={()=> setShowMobileAddModal(true)}><Icon icon="md-add" fontSize="18px" color="#ffffff"/></button>
            <button className="mobile_actions__remove"onClick={removeMobileSong}><Icon icon="md-trash" fontSize="18px" color="#ffffff"/></button>
            <button className="reorder_btn" onClick={()=> { setReordering(!reordering); setReorderingSongs(reordering ? songs : []) }}>
              <Icon icon="ios-swap" fontSize="18px" color="#ffffff"/>
            </button>
            <button className="mobile_actions__cancel" onClick={cancelMobileActions}><Icon icon="md-close" fontSize="18px" color="#ffffff"/></button>
          </div>
          {reordering && 
            <div className="mobile_actions__reorder">
              <button className="mobile_actions__up" onClick={()=>mobileSongOrder('up')}><Icon icon="ios-arrow-round-up" fontSize="21px" color="#ffffff"/></button>
              <button className="mobile_actions__down" onClick={()=>mobileSongOrder('down')}><Icon icon="ios-arrow-round-down" fontSize="21px" color="#ffffff"/></button>
              <button className="mobile_actions__done" onClick={()=> { setReordering(false); dispatch(sortQueue( {songs: reorderingSongs, queue: queue.selected}))}}><Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/></button>
              <button className="mobile_actions__cancel" onClick={()=> setReordering(false)}><Icon icon="md-close" fontSize="18px" color="#ffffff"/></button>
            </div>
          }
        </div>
        
      )

  }

  const mobileSelectSong = (song) => {
      setMobileSelectedSong(song);
  }


    let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
    if(playlistsMin && playlistsMin.length > 0){ queuePlaylists = [...queuePlaylists, ...playlistsMin]  }


    return(
      <div className={reordering ? `orderingMobileSongs`:''}>

        {userSettings.mobile && mobileSelectedSong && renderMobileReorderActions()}

        <div className="playlistQueue">
          {userSettings.actions.dragging && userSettings.actions.draggingFrom !=='queue' && !userSettings.mobile && 
            <QueueDropZone queue={queue.selected} songs={songs}  />
          }
          <div className="playlist_songs-mini">

                <div>
                  {queue && queue.selected && queue[queue.selected].length > 0 ?
                     <SongWrapper
                        isQueue={true}
                        isMobile={userSettings.mobile}
                        mobileSelectedSong={mobileSelectedSong}
                        mobileSelectSong={mobileSelectSong}
                        queue={queue.selected}
                        selectedSong={userSettings.selectedSong}
                        selectSong={selectSong}
                        songs={reordering ? reorderingSongs :songs} 
                        userIsAuthor={userIsAuthor}
                        reorderSongs={reorderSongs}
                        sortQueue={sortQueue}
                        playlistsMin={userplaylistsMin}
                        playSong={playSong}
                        draggingSong={draggingSong}
                        playlistID={currentPlaylist}
                        activeSong={queue && queue.playing && queue.playing.songId}
                        uid={profile && profile.id}
                        />
                     :
                        <p className="noSongsQueu">No Songs added to this Queue yet</p>
                  }
                </div>

          </div>
        </div>

        <Modal title={`Add Song to`} customClass="mobileAddSongModal" show={showMobileAddModal} maxWidth={400} closeModal={()=> setShowMobileAddModal(false)}>
          <div className="mobile_addSong__buttons__wrap">
              <div className="libPlaylists">
                  <div className="LibSongScroll_wrap customScrollbar">
                      <div>
                          {queuePlaylists && queuePlaylists.map((plist, index)=> {
                              return <button 
                              key={plist.id} 
                              className={selectedAddPlaylist === plist.id ? 'active': ''}
                              onClick={()=> setSelectedAddPlaylist(plist.id)}>
                              {selectedAddPlaylist === plist.id && <Icon icon="md-checkmark" fontSize="16px" color="#fff"/>}
                              {plist.title}
                              </button>
                          })}
                      </div>
                  </div>
              </div>
              <div className="mobile_addSong__buttons">
                  <button className="libConform" onClick={addMobileSong}><Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/></button>
                  <button className="libCancel" onClick={cancelMobileActions}><Icon icon="md-close" fontSize="18px" color="#ffffff"/></button>
              </div>          

          </div>
        </Modal>
      </div>
    );

}

export default Queue;