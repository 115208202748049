import React from 'react';
import SelectField from '../SelectField/SelectField';
import Icon from '../Icon/Icon';

class SongActions extends React.Component {

    constructor(props){
        super(props);

        this.state={
            cover:'',
            showDeleteModal: false,
            showRepeatModal: false,
            showDurationModal: false,
            showAddModal: false,
            playlistSelect: (props.queue && props.queue !=='queueOne') || !props.queue ? 'queueOne' : 'queueTwo',
            start: props.start ? props.start :'00:00:00',
            end: props.end ? props.end :'00:00:00',
            repeatCount:0
        }
    }

    onChange = (value, option) => {
        this.setState({ playlistSelect: value, });
    }

    copySong = () => {
        let queue = '';
        if(this.state.playlistSelect === 'queueOne' || this.state.playlistSelect === 'queueTwo' || this.state.playlistSelect === 'queueThree'){
            queue = this.state.playlistSelect;
        }
        this.props.addSong(this.state.playlistSelect, queue);
        setTimeout(()=> {
            this.setState({showAddModal: false});
        }, 500);

    }
    updateStartEnd = ()=> {
        this.props.updateTime(this.state.start, this.state.end);
        setTimeout(()=> {
            this.setState({showDurationModal: false});
        }, 500);
    }
    

    showModal = (action) => {
        this.setState({  showDeleteModal: false,  showRepeatModal: false, showDurationModal: false, showAddModal: false,})
        this.setState({[action]: true})
    }


    render(){
        const confirmIcon = <Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/>;
        const cancelIcon = <Icon icon="md-close" fontSize="18px" color="#ffffff"/>;
        let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
        if(this.props.playlistsMin && this.props.playlistsMin.length > 0){ queuePlaylists = [...queuePlaylists, ...this.props.playlistsMin]  }
        const copySelectedPlaylist = queuePlaylists.filter((plist)=>  plist['id'] === this.state.playlistSelect  );
        let selectedAction = '';
        if(this.state.showAddModal){ selectedAction = 'song__actions-add'; } if(this.state.showRepeatModal){ selectedAction = 'song__actions-repeat'; }
        if(this.state.showDurationModal){ selectedAction = 'song__actions-duration'; } if(this.state.showDeleteModal){ selectedAction = 'song__actions-delete'; }
        
        return(
            <div className={`song__actions ${selectedAction ?'song__actions-active '+ selectedAction : ''}`} style={this.props.menuPos && {left:this.props.menuPos} }>

                <button className="song__actions__add" onClick={()=> this.showModal('showAddModal')  }><Icon icon="md-copy" fontSize="14px" color="#ffffff"/> Add To</button>
                {this.props.userIsAuthor &&
                    <button className="song__actions__duration" onClick={()=> this.showModal('showDurationModal') }><Icon icon="ios-cut" fontSize="14px" color="#ffffff"/> Edit Time</button>
                }

                {(this.props.userIsAuthor || this.props.queue) &&
                    <button className="song__actions__delete" onClick={()=> this.showModal('showDeleteModal')  }>
                        <Icon icon="md-trash" fontSize="14px" color="#ffffff"/> Remove
                    </button>
                }

                {this.props.guest && !this.props.queue &&
                    <button className="song__actions__delete" onClick={this.props.guestRemove }>
                        <Icon icon="md-trash" fontSize="14px" color="#ffffff"/> Remove
                    </button>
                }
                

                {this.state.showAddModal && 
                    <div className="song__actions__confirm  song__actions__confirm-copy">
                        <span>Add Song to</span>
                        <SelectField
                        value={copySelectedPlaylist[0].id}
                        onChange={this.onChange}
                        placeholder="Select Playlist" 
                        slim={true}
                        showSearch={false}
                        options={queuePlaylists.map((playlist)=> ({label: playlist.title, value: playlist.id }))}
                        />
                        <br />
                        <button className="confirm_yes" onClick={()=> this.copySong() }>{confirmIcon}</button>
                        <button className="confirm_no" onClick={()=> this.setState({showAddModal: false})}>{cancelIcon}</button>
                    </div>
                }

                {this.state.showDurationModal && this.props.userIsAuthor &&
                    <div className="song__actions__confirm  song__actions__confirm-duration">
                        Start from <input type="text" placeholder="00:00:00" value={this.state.start} onChange={(event)=> this.setState({start: event.target.value})} /><br />
                        End at <input type="text" placeholder="00:00:00" value={this.state.end} onChange={(event)=> this.setState({end: event.target.value})}  /><br />
                        <button className="confirm_yes" onClick={ ()=> this.updateStartEnd() }>{confirmIcon}</button>
                        <button className="confirm_no" onClick={()=> this.setState({showDurationModal: false})}>{cancelIcon}</button>
                    </div>
                }

                {/* {this.state.showRepeatModal && this.props.userIsAuthor && !this.props.queue &&
                    <div className="song__actions__confirm song__actions__confirm-repeat">
                        Repeat <input type="text" placeholder="0" value={this.props.repeatCount} onChange={(event)=> this.setState({repeatCount: event.target.value})} /> times<br />
                        <button className="confirm_yes" onClick={()=>this.updateRepeat()}>{confirmIcon}</button>
                        <button className="confirm_no" onClick={()=> this.setState({showRepeatModal: false})}>{cancelIcon}</button>
                    </div>
                } */}

                {this.state.showDeleteModal && (this.props.userIsAuthor || (this.props.guest && this.props.queue) ) &&
                    <div className="song__actions__confirm song__actions__confirm-delete">
                        {this.props.queue ? 'Remove Song ?'  :'Remove Song from this playlist ?' }<br />
                        <button className="confirm_yes" onClick={()=> this.props.removeSong()}>{confirmIcon}</button>
                        <button className="confirm_no" onClick={()=> this.setState({showDeleteModal: false})}>{cancelIcon}</button>
                    </div>
                }

            </div>
        );
    }

}

export default SongActions;