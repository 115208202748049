import { createSlice, current } from "@reduxjs/toolkit";
import { loadState } from "../localStorage";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";

const persistedQueue = loadState('queue');

export const slice = createSlice({
   name: "queue",
   initialState:  {
      queueOne: persistedQueue?.queueOne || [{
          added: 1530450918,
          artist: "Ed Sheeran",
          title: "Shape of You",
          duration: 264,
          order:0,
          id:"JGwWNGJdvx9",
          youtube:"JGwWNGJdvx8",
      },
      {
         added: 1540450918,
         artist: "Linkin Park",
         title: "In The End",
         duration: 218,
         order:1,
         id:"NHwWNGJdvy6",
         youtube:"eVTXPUF4Oz4",
      }],
      queueTwo: persistedQueue?.queueTwo || [],
      queueThree: persistedQueue?.queueThree || [],
      playing: {queue:'queueOne', songId: ''},
      selected: 'queueOne',
   },
   reducers: {
      addToQueue: (state, {payload}) => {
         const {song, queue, play} = payload;
         let songID = song?.id;
         if(!songID && song && song.youtube){
            songID = song.youtube;
         }
         let currentSongs = current(state[queue]);
         console.log(payload, currentSongs);
         
         const newSong = {...song, id: songID, order: currentSongs ? currentSongs.length : 1 }

         if(currentSongs && currentSongs.filter(song => song.id === songID).length === 0){
             state[queue] = [ ...currentSongs, newSong]
             state.playing = play ? {queue: queue, songId: songID} : current(state.playing);
         }else{
             //Song is already in the Queue
             state.playing = play ? {queue: queue, songId: songID} : current(state.playing);
         }
      },
      playAll: (state, {payload}) => {
         const {queue, songs} = payload;
         const currentSongs = current(state[queue]);
         const theSongs = songs.map((sng, idx) => {
            return {...sng, id: sng.id ? sng.id : sng.youtube, order: currentSongs.length + idx}
        });
        const finalSongs = [];
        theSongs.forEach(song => {
            if(currentSongs.findIndex(x => x.id === song.id) === -1){
                finalSongs.push(song);
            }
        })
        state[queue] = [ ...currentSongs, ...finalSongs];
        state.playing = {queue: queue, songId: finalSongs[0].id};

      },
      sortQueue: (state, {payload}) => {
         state[payload.queue] = payload.songs
      },
      selectedQueue: (state, {payload}) => {
         console.log('selectedQueue: ', );
         state.selected = payload.queue; 
      },
      editSongTimeInQueue: (state, {payload}) => {
         let currentSongs = current(state[payload.queue]);
         const editedSongIndex = currentSongs && currentSongs.findIndex(x => x.id && x.id === payload.song.id );
         if(editedSongIndex !== -1){   currentSongs[editedSongIndex] = payload.song;  }
         state[payload.queue] = currentSongs
      },
      repeatSongInQueue: (state, {payload}) => { 
         console.log(payload, payload.queue);
         let currentSongsR = current(state[payload.queue]);
         const updatedSongs = currentSongsR && [...currentSongsR].map((song)=> song.id === payload.song.id ? payload.song : song )
         state[payload.queue] = updatedSongs; 
      },
      removeFromQueue: (state, {payload}) => {
         console.log(payload, JSON.parse(JSON.stringify(state)));
         const currentQueueItems = current(state[payload.queue]);
         let filteredSongs = currentQueueItems.filter( (newSong)=> payload.songID !== newSong.id);
         filteredSongs = filteredSongs.map(( song, index )=>{ return {...song, order: index} });
         console.log(currentQueueItems, filteredSongs);
         // state[payload.queue] = filteredSongs;
         state[payload.queue] = filteredSongs;
      },
      clearQueue: (state, action) => {
         state[state.selected] = [];
      },
   }
});


export const { 
   addToQueue,
   playAll,
   removeFromQueue,
   repeatSongInQueue,
   editSongTimeInQueue,
   sortQueue,
   selectedQueue,
   clearQueue 
} = slice.actions;


export default slice.reducer;