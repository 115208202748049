import { createSlice, current } from "@reduxjs/toolkit";
import { loadState } from "../localStorage";

const persistentPlayer = loadState('player');

export const slice = createSlice({
   name: "player",
   initialState: {
      playing: false,
      playAgain: false,
      clear:false,
      loop: persistentPlayer?.loop || false,
      shuffle: persistentPlayer?.shuffle || false,
      volume: persistentPlayer?.volume || 0.8,
      playDirect: persistentPlayer?.playDirect || false,
      mode: 1,
   },
   reducers: {
      playPausePlayer: (state, {payload}) => {
         console.log('playPausePlayer payload: ', payload);
         state.playing = payload;
         state.clear = false;
         state.playAgain = false;
      },
      playAgain: (state, {payload}) => {
         const currentPlayAgain = current(state.playAgain);
         state.clear = false;
         state.playAgain = !currentPlayAgain;
      },
      repeatPlayer: (state, {payload}) => {
         state.loop = payload;
      },
      shufflePlayer: (state, {payload}) => {
         state.shuffle = payload;
      },
      playDirect: (state, {payload}) => {
         state.playDirect = payload;
      },
      clearPlayer: (state, {payload}) => {
         state.clear = true;
      },
      playerVideoMode: (state, {payload}) => {
         console.log(payload);
         state.mode = payload;
         state.clear = true;
         state.playAgain = false;
      },
      playerVolume: (state, {payload}) => {
         state.volume = payload;
      },
   }
});


export const { 
   playPausePlayer,
   playAgain,
   repeatPlayer,
   shufflePlayer,
   playDirect,
   clearPlayer,
   playerVideoMode,
   playerVolume,
} = slice.actions;


export default slice.reducer;