import { useState } from "react";



const SelectField = ({style={}, options=[], value, onChange, placeholder='', customClass='', showSearch, slim=false}) => {

   const [active, setActive] = useState(false);
   const [searchInput, setSearchInput] = useState('');
   const [filterdOptions, setFilterdOptions] = useState([]);

   const activeItem = options.find(x => x.value === value);

   const filterOptions = (event) => {
      setSearchInput(event.currentTarget.value);
      const filteredItems = [];
      const userVal = event.currentTarget.value.toLowerCase();
      options.forEach((option) => {
         if ( option.label.toLowerCase().includes(userVal) ) {
            filteredItems.push(option);
         }
      });
      setFilterdOptions(filteredItems);
   };

   const closeOptions =  () => {
      setSearchInput(''); 
      setActive(false); 
      setFilterdOptions([]); 
   }

   return (
      <div className={`SelectField ${customClass} ${active ? 'SelectField--show' : ''} ${slim ? 'SelectField--slim' : ''}`} style={style}>
         <div className="SelectField_selected" onClick={() => { !active ? setActive(true) :  closeOptions();}}>
            <span>{activeItem?.label || placeholder}</span><i>{active ? '▲' : '▼'}</i>
         </div>

         {active && 
            <div className="SelectField_options">
               {showSearch && 
                  <div className="SelectField_search">
                     <input type="text" onChange={filterOptions} value={searchInput} placeholder={`Search...`} />
                  </div>
               }
               <ul>
                  {!searchInput && placeholder && <li key={'SelectField_placeholder'}>{placeholder}</li>}
                  {(showSearch && filterdOptions.length > 0 ? filterdOptions : options).map((option)=> {
                     return <li 
                              key={option.value} 
                              className={value === option.value ? 'SelectField_option--active' : ''}
                              onClick={()=> { onChange(option.value); closeOptions(); }}
                              >
                                 {option.label}
                              </li>
                  })}
               </ul>
            </div>
         }
      </div>
   );

}

export default SelectField;