import { createSlice } from "@reduxjs/toolkit";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { collection, query, where} from 'firebase/compat/firestore';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { dataURItoBlob, uploadImage } from "../helpers";
import { getUserPlaylists } from "./playlistSlice";
import { storage } from "..";

// showUserArea, 
// showAddSongModal, 
// mobileTab, 
// showHidePRO, 
// showLibraryModal 
export const slice = createSlice({
  name: "user",
  initialState: {
   userData: null,
   authData: {},
   loadingUser: false,
   showUserArea: false,
   showUsernameSetup: false,
   showCreatePlaylist: false,
   showAddModal: false,
   showLibModal: false,
   addSongModalVars:{tab:'link', string:''},
   showMiniPlaylist: false,
   videoMode: 'left',
   selectedSong: '',
   mobile:false,
   electron: false,
   cordova: false,
   macAddress: '',
   mobileTab:'queue',
   showPro: false,
   isPRO: false,
   station:'',
   stationSongs:{},
   discoverListView: false,
   actions:{
     songAdded:null,
     dragging:false,
     draggingFrom: '',
     draggingYhimbo: false,
     clearSelectedSongs: false
   }
 },
  reducers: {
   setUser: (state, { payload }) => {
      state.userData = { ...state.userData, ...payload.userData };
    },
    setAuthData: (state, { payload }) => {
      state.authData = payload;
    },
    setLoadingUser: (state, { payload }) => {
      console.warn('setLoadingUser:', payload);
      state.loadingUser = payload.loading;
    },
    showUserArea: (state, { payload }) => {
      state.showUserArea = true;
    },
    hideUserArea: (state, { payload }) => {
      state.showUserArea = false;
    },
    showUsernameSetup: (state, { payload }) => {
      state.showUsernameSetup = payload;
    },
    setPRO: (state, { payload }) => {
      state.isPRO = true;
    },
    showAddtoPlaylist: (state, action) => {
      state.showCreatePlaylist = true;
    },
    hideAddtoPlaylist: (state, { payload }) => {
      state.showCreatePlaylist = false;
    },
    showAddSongModal: (state, {payload}) => {
      console.log(payload);
      state.showAddModal =  true;
      state.addSongModalVars =  payload?.tab ? {...payload} : {...state.addSongModalVars};
    },
    hideAddSongModal: (state, action) => {
      state.showAddModal =  false;
      state.addSongModalVars = { tab: state.addSongModalVars.tab, string:'' }
    },
    showLibraryModal: (state, { payload }) => {
      state.showLibModal = true;
    },
    hideLibraryModal: (state, action) => {
      state.showLibModal = false;
    },
    showHidePRO: (state, { payload }) => {
      state.showPro = !state.showPro;
    },
    setElectron: (state, { payload }) => {
      state.electron = true;
    },
    setMacAddress: (state, { payload }) => {
      state.macAddress = payload.macAddress;
    },
    setMobileMode: (state, { payload }) => {
      state.mobile = true;
    },
    mobileTab: (state, {payload}) => {
      console.log(payload);
      state.mobileTab = payload;
    },
    setCordova: (state, { payload }) => {
      state.cordova = true;
    },
    setStation: (state, {payload}) => {
      console.log('setStation: ', payload);
      state.station = payload;
    },
    SET_STATION_SONGS: (state, action) => {
      state.stationSongs = action.stationSongs;
    },
    changeVideoMode: (state, action) => {
      state.videoMode = action.payload;
    },
    completeSongAdd: (state, {payload}) => {
      state.actions = {...state.actions, songAdded: { title: payload.title, artist: payload.artist ? payload.artist : '', playListID: payload.playListID }};
    },
    setSongAddError: (state, { payload }) => {
      state.actions = { songAdded: {error: 'Couldn\'t Add Song' } };
    },
    clearSongAddStatus: (state, { payload }) => {
      state.actions = { songAdded: null };
    },
    TOGGLE_MINI_PLAYLIST: (state, action) => {
      state.showMiniPlaylist = !state.showMiniPlaylist;
    },
    selectSong: (state, action) => {
      state.selectedSong = action.song ? action.song : '';
    },
    draggingSong: (state, action) => {
      console.log(action);
      state.actions = {...state.actions, dragging: action.payload ? true : '' , draggingFrom: action.payload ? action.payload : ''  };
    },
    DRAGGING_YHIMBO: (state, action) => {
      state.actions = {...state.actions, dragging:action.source ? true : '' , draggingYhimbo: action.dragging  };
    },
    CLEAR_SELECTED_SONGS: (state, action) => {
      state.actions = {...state.actions, clearSelectedSongs: action.clear };
    },
    setDiscoverListView: (state, action) => {
      state.discoverListView = action.payload;
      localStorage.setItem('discoverListView', action.payload === true ? true : !state.discoverListView);
    }
  },
});

export const {
   setUser,
   setAuthData,
   setLoadingUser,
   showUserArea,
   hideUserArea,
   setPRO,
   showUsernameSetup,
   showAddtoPlaylist,
   hideAddtoPlaylist,
   showAddSongModal,
   hideAddSongModal,
   showLibraryModal,
   hideLibraryModal,
   showHidePRO,
   setElectron,
   setMacAddress,
   setMobileMode,
   mobileTab,
   setCordova,
   setStation,
   SET_STATION_SONGS,
   changeVideoMode,
   completeSongAdd,
   setSongAddError,
   clearSongAddStatus,
   TOGGLE_MINI_PLAYLIST,
   selectSong,
   draggingSong,
   DRAGGiING_YHIMBO,
   CLEAR_SELECTED_SONGS,
   setDiscoverListView
} = slice.actions;

// The function(s) below is called a thunk and allows us to perform async logic.


// export const login = ({ email, password }) => async (dispatch, state) => {
//   await firebase.auth().signInWithEmailAndPassword(email, password);

//   dispatch(LOGIN({ user: firebase.auth().currentUser.toJSON() }));
//   dispatch(getUser());

//   return state().userAuth.userData;
// };

// /**
//  *
//  */
// export const logOut = () => async (dispatch, state) => {
//   await firebase.auth().signOut();
//   dispatch(LOGOUT());
// };

// /**
//  *
//  */
// export const getUser = () => async (dispatch, state) => {
//   let userData = null;
//   const uid = firebase.auth().currentUser.uid;

//   // query user data
//   const resp = await firebase.firestore().collection("users").doc(uid).get();
//   if (resp.exists) {
//     userData = {
//       id: resp.id,
//       ...resp.data(),
//     };
//   }

//   // update store
//   dispatch(SET_USER_DATA({ userData }));
//   return state().userAuth.userData;
// };

// /**
//  *
//  */
// export const createUser = ({ email, password }) => async (dispatch, state) => {
//   // create auth user
//   const resp = await firebase
//     .auth()
//     .createUserWithEmailAndPassword(email, password);

//   // add user to user collection
//   const uid = firebase.auth().currentUser.uid;
//   await firebase.firestore().collection("users").doc(uid).set({
//     id: uid,
//     email: resp.user.email,
//   });

//   // update store
//   dispatch(CREATE_USER({ user: firebase.auth().currentUser.toJSON() }));
//   const userData = {
//     ...firebase.auth().currentUser.toJSON(),
//     id: uid,
//     email: resp.user.email,
//   };
//   dispatch(SET_USER_DATA({ userData }));
// };

// When App is loaded, Fetch and Set User Profile, Playlists, and PlaylistsMin in Store
export const setAuthListener = () => (dispatch, getState) => {
   console.log(getState());
   // const loadingData = current(state.loadingUser);
   firebase.auth().onAuthStateChanged((user) => {
      console.log('AuthStateChanged: ');
      if(user && user.uid){
         // Get User Profile from User Database & also et the PlaylistsMin.
         dispatch(setAuthData({displayName: user.displayName, email: user.email, uid: user.uid}));
         dispatch(getUser());
         // Get User Playlsits from the Playlist Database
         dispatch(getUserPlaylists());
      }
   });
};

 
//Set Initial Playlist after Signup
export const setUpUser = (userData) =>
  (dispatch, getState) => {
   const db = firebase.firestore();
   const uid = firebase.auth().currentUser.uid;
   if(!uid){ return; }
   const playListsRef = db.collection('Playlists');
   const likesRootRef = db.collection('Likes');
   const UsersRootRef = db.collection('Users');
      console.log('FROM ACTIOn -->>', uid, userData);

   const newPlaylist = {
      title: 'Untitled Playlist',
      status: 'private',
      slug: 'untitled-playlist',
      owner: uid,
      created: parseInt((new Date().getTime() / 1000).toFixed(0), 10),
      category:'mixed',
      order:0,
  }
  const state = getState();
  const guestSongs = state.playlistGuest && state.playlistGuest.playlists && state.playlistGuest.playlists.temp && state.playlistGuest.playlists.temp.songs
  console.log('guestSongs: ', guestSongs);

  
  playListsRef.add({...newPlaylist, songs: guestSongs ? guestSongs : {}})
  .then((ref) => {
      let count = 0;
      if(guestSongs){ count = Object.keys(guestSongs).length}
      console.log('@@New Playlist Created!!!!!!', ref.id);
      UsersRootRef.doc(uid).set({ ...userData, playlists:{[ref.id]: {...newPlaylist, count:count, likes:0, id: ref.id} } }).then(()=> console.log('Update profile Success'));
      likesRootRef.doc(ref.id).set({ owner: uid, users:null} ).then(()=> console.log('Created Likes DB Successfully'));
      setTimeout(()=> { return document.location.href = '/?introduction'}, 2000);
  })
  .catch((error) => {
      console.log('@@ERROR: New Playlist Creation Failed!!!!!!', error);
  });

};


export const getUser = () => (dispatch, state) => {
   let userData = null;
   const uid = firebase.auth().currentUser?.uid;

   console.warn('USER UID: ', uid);
   // query user data
   if(uid){
      dispatch(setLoadingUser({ loading: true }));
      return firebase.firestore().collection("Users").doc(uid).get().then((resp)=> {
         console.log('resp: ', resp.exists);
         if (resp.exists) {
            userData = { id: resp.id, ...resp.data() };
            dispatch(setUser({ userData }));
         }else{
            console.log('Current Path: ', document?.location?.pathname);
            // document.location.href = '/complete-signup';
            dispatch(showUsernameSetup(true))
         }
          
         dispatch(setLoadingUser({ loading: false  }));
      }).catch((err)=> {
         console.log('getUser ERROR: ', err);
         dispatch(setLoadingUser({ loading: false  }));
      }).finally(()=> {
         return state().userAuth.userData;
      })
   }else{
      return false;
   }  
 };

 export const updateUser = (userData) => (dispatch, state) => {
   const uid = firebase.auth().currentUser?.uid;
   if(uid){
      return firebase.firestore().collection("Users").doc(uid).update(userData).then((resp)=> {
         console.log(resp, state);
         if (resp?.exists) {
            userData = { id: resp.id, ...resp.data() };
            console.log('update: ', resp.data());
          }
      }).catch((err)=> {
         console.log('updateUser ERROR: ', err);
      }).finally(()=> {
         dispatch(setUser({ userData }));
         return state().userAuth.userData;
      })
   }else{
      return false;
   }
}

export const updateProfilePhoto = (selectedImage) => (dispatch, state) => {
   const uid = firebase.auth().currentUser?.uid;
   if(uid){
      uploadImage(selectedImage, async (result) =>{
         //console.log('retuedn image', result);
         console.log(selectedImage);

         var small_file = new File([dataURItoBlob(result)], selectedImage.name, {'type':'image/jpeg'});
         console.log('##Small Image: ',small_file);
         const extension = selectedImage.name.split('.').pop();

         //Upload the Profile Picture
         //setLoadingPhoto(true);

         try {
           const fileName = 'avatars/'+uid+'_large.'+extension;
           const storageRef = ref(storage, fileName );
           await uploadBytes(storageRef, selectedImage);
           const imageURL = await getDownloadURL(ref(storage, fileName));
           dispatch(updateUser({photoURL: imageURL}));
           console.log(imageURL);
         } catch (error) {
           console.log('Upload Error', error);
           //setUploadError('Upload Error! Try Again.');
         }

         try {
           const fileNameSmall = 'avatars/'+uid+'_small.'+extension;
           const storageRefSmall = ref(storage, fileNameSmall );
           await uploadBytes(storageRefSmall, small_file);
           const imageURL = await getDownloadURL(ref(storage, fileNameSmall));
           dispatch(updateUser({avatarUrl: imageURL}));
           console.log(imageURL);
         } catch (error) {
           console.log('Upload Error', error);
           //setUploadError('Upload Error! Try Again.');
         }
     });  
   }else{
      return false;
   }
}
// The function below is called a selector and allows us to select a value from
// the state.
export const selectUser = (state) => {
  return state.user.userData;
};

export default slice.reducer;