import React from 'react';
import searchImg from '../../assets/about/about_search2.png';
import discoverImg from '../../assets/about/about_discover.png';
import playlistImg from '../../assets/about/about_playlists.png';
//import audiolnyImg from '../../assets/about/about_audionly.png';
//import localImg from '../../assets/about/about_local.png';

const About  = (props)=> {
    return(
        <div id="about">
            <p className="aboutIntro">Yhimsical is a YouTube music player that lets you play songs from YouTube in an advanced, easy to use drag and drop player. You can also create Playlists, share them and discover new songs.</p>
        
            <div className="about__block">
                <img src={searchImg} alt="Search Yhimsical"/> 
                <div className="about__block__content">
                    <h3>Search from Millions of Youtube Songs</h3>
                    <p>With Yhimsical you are able search songs from youtube by song title or you can directly add a song with youtube video url and search all albums and songs by artist name.</p>
                    {/* <p>With Yhimsical you are able search songs from youtube by song title or you can directly add a song with youtube video url. The PRO version also lets you search all albums and songs by artist name. You can also Import Youtube playlists with the PRO version.</p> */}
                </div>
            </div>

            <div className="about__block">
                <div className="about__block__content">
                    <h3>Discover New Songs</h3>
                    <p>You can discover latest songs, Popular Songs from previous years. You can also find popular artists and all their music videos in the All Artists menu of the Discover section.</p>
                </div>
                <img src={discoverImg} alt="Discover Songs"/> 
            </div>

            <div className="about__block">
                <img src={playlistImg} alt="Create and Share Playlists"/> 
                <div className="about__block__content">
                    <h3>Create & Share Playlists</h3>
                    <p>Creating a sharing a playlists with Yhimsical is a breeze. You can add any songs from YouTube to your playlists and access it anytime online from any device to play them.</p>
                </div>
            </div>

            {/* <div className="about__block">
                <div className="about__block__content">
                    <h3>Play Audio Only <span>PRO</span></h3>
                    <p>With PRO version, you can play only the high quality audio of the YouTube songs which will save up to 95% of your your bandwidth. The player will also able to auto find and play and alternative source of any songs that are restricted in your region.</p>
                </div>
                <img src={audiolnyImg} alt="Play Audio Only"/> 
            </div>

            <div className="about__block">
                <img src={localImg} alt="Play Local Songs"/> 
                <div className="about__block__content">
                    <h3>Play Local Songs <span>PRO</span></h3>
                    <p>Yhimsical PRO also lets you play local songs that you own, alongside the YouTube songs. When you add local songs to Yhimsical Music library you will also be able to access them online without having to upload them anywhere.</p>
                </div>
            </div> */}

        </div>
    );
} 

export default About;