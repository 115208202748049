import React from 'react';

const TrackSkeleton = (props)=>{
    return(
        <div className="trackSkeleton">
            {props.header && 
                <div className="trackSkeleton__header">
                    <div className="TrackSkeleton__header__title"><div/><div></div></div>
                    <div className="trackSkeleton__header__right"><div/></div>
                </div>
            }

            <div className="trackSkeleton__body">
                <div className="trackSkeleton__song"><div/><div/><div/></div>
                <div className="trackSkeleton__song"><div/><div/><div/></div>
                <div className="trackSkeleton__song"><div/><div/><div/></div>
                <div className="trackSkeleton__song"><div/><div/><div/></div>
                <div className="trackSkeleton__song"><div/><div/><div/></div>
                <div className="trackSkeleton__song"><div/><div/><div/></div>
                <div className="trackSkeleton__song"><div/><div/><div/></div>
                <div className="trackSkeleton__song"><div/><div/><div/></div>
                <div className="trackSkeleton__song"><div/><div/><div/></div>
            </div>
        </div>
    );
}

export default TrackSkeleton;