import React, { useMemo, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useSwipeable } from 'react-swipeable';
import TimeAgo from 'react-timeago';
// import Hammer from 'react-hammerjs';
import { viewFormat, formatDuration } from '../../helpers';

const Track = (props) => {
   const {song, queue, station, listView, selectedSong, playSong, selectSong, animateThumbnail, resetThumbnail, playlistSelector, 
         showPlaylists, activePlaylist, searchTrack, addToQueue, addToStation, draggingSong } = props;

   const trackRef = useRef(null); 
   const [swipeRight, setSwipeRight] =  useState(false);
   const [swipeLeft, setSwipeLeft] =  useState(false);
   const [message, setMessage] =  useState(null);


   const relseDate = useMemo(()=> {
      let release_date = null;
      if(song.release_date ) {
         if(song.release_date.toString().length < 12) { 
            release_date = song.release_date *  1000;
         }else{ 
            release_date = song.release_date;
         }
         
      }
      return release_date;
   }, [song.release_date]);
   
   const songID = song.id ? song.id : song.youtube ? song.youtube : '';


   // eslint-disable-next-line no-unused-vars
   const [{ isDragging }, drag] = useDrag(
      () => ({
         type: 'track',
         // item: { title: song.title, id: song.id, originalIndex: index },
         collect: (monitor) => ({
            isDragging: monitor.isDragging(),
         }),
         item: (dropResult, monitor) => {
            console.log('DragStart', dropResult);
            draggingSong('discover');
            const draggedSong = { ...song };
            return { 
               songs:[draggedSong], 
               songsDragStack: draggedSong,
               draggedSong: draggedSong,
               song: draggedSong,
               id: props.song.id ? props.song.id : '',
               order: props.order 
            };
         },
         end: (dropResult, monitor) => {
            // const didDrop = monitor.didDrop();
            console.log('Drag Ended!!');
            draggingSong('');
         }
      }),
      [],
    );
    
   const swipeSongRight = () => {
         setSwipeRight(true);
         setTimeout(() => {
            playSong(song);
            setSwipeRight(false);
         }, 500);
   }

   const swipeSongLeft = () => {
        if(queue){ return }
        setSwipeLeft(true);
        setTimeout(() => {
            setSwipeLeft(false);
            setMessage('Song Added to Queue');
            addToQueue(song, 'queueOne', false);
        }, 500);

        setTimeout(() => {
            setMessage(null);
        }, 1800);
        
   }

   const renderTrackActions = (song, showPlaylists, playSong) => {
        return (
            <div className="track__actions">
                {station ? 
                    <div className="play_buttons"><button className="track__station_button" onClick={()=> addToStation(song)}>+ Add to Station</button></div>
                :
                    <div className="play_buttons">
                        <button className="track__play_button" onClick={()=> playSong(song)}>&#9658;</button>
                        <button className="track__add_button" onClick={()=> showPlaylists(song.youtube)}>+</button>
                    </div>
                }
            </div>

        );
   }

   const swipeHandlers = useSwipeable({
      onSwipedLeft: (eventData) => swipeSongLeft(),
      onSwipedRight: (eventData) => swipeSongRight(),
   });

   drag(trackRef);

   return (
      <div 
      className={`track ${selectedSong === songID ? 'activeSong' : ''} ${swipeRight ? 'swipeAnim':''} ${swipeLeft ? 'swipeAnimLeft':''} ${activePlaylist === song.youtube ? 'track--Playlist_active':''} `}
      key={songID} 
      onDoubleClick={()=> playSong(song)} 
      onMouseEnter={()=> animateThumbnail(song.youtube)} 
      onMouseLeave={()=> resetThumbnail(song.youtube)}
      onClick={()=> selectSong(songID)}
      ref={trackRef}  
      >
            {/* <Hammer onSwipeRight={swipeSongRight} onSwipeLeft={swipeSongLeft} > */}
               <div className="track_inner"  {...swipeHandlers} >
                  {message && <span className="songMsg">{message}</span>}
                  <div className="track_img_wrap" >
                        <img id={`song-${song.youtube}`} src={`https://i.ytimg.com/vi/${song.youtube}/hqdefault.jpg`} alt={`song-${song.title} cover`} />
                        {!listView && renderTrackActions(song, showPlaylists, playSong) }
                        
                        {!listView && song.duration && (searchTrack ? <span className="track__duration">{formatDuration(song.duration)}</span> : <span className="track__duration">{formatDuration(song.duration)}</span>)}
                  </div>
                  <div className="track_content_wrap" title={`${song.title}`}>
                        <h3>{song.artist && song.artist +' -' } {song.title}</h3>
                        <span>{song.views && <i>{viewFormat(song.views)} &#8226;</i>}  { relseDate && <TimeAgo date={relseDate} /> } </span>
                  </div>
                  {listView && renderTrackActions(song, showPlaylists, playSong) }
                  {listView && song.duration && (searchTrack ? <span className="track__duration">{formatDuration(song.duration)}</span> : <span className="track__duration">{formatDuration(song.duration)}</span>)}

                  {activePlaylist === song.youtube && playlistSelector(song)}
               </div>
            {/* </Hammer> */}
      </div>
   );

}

export default Track;