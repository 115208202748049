import React from 'react';
import Icon from '../Icon/Icon';

class PlaylistSelector extends React.PureComponent{

    state={
        showAddModal: false,
        showAddPlaylistModal: false,
        showimportConfirm: false,
        selectedPlaylist:''
    }


    renderPlaylists = ()=> {
        return this.props.playlists && Object.keys(this.props.playlists).map((playlistID)=> {
            return (
                <div className="playlist__selector_pl" key={playlistID}>
                    <h4>{this.props.playlists[playlistID].title} <span>{this.props.playlists[playlistID].songs ? Object.keys(this.props.playlists[playlistID].songs).length : 0}</span></h4>
                    <button onClick={()=> this.setState({ showimportConfirm: true, selectedPlaylist: this.props.playlists[playlistID]})}>Import</button> 
                </div>
            )
        })
    }

    confirmImport = () => {
        this.props.importPlaylist(this.state.selectedPlaylist);
        this.setState({showimportConfirm: false});
        this.props.hidePlaylistSelector();
    }

    cancelImport = () => {
        this.setState({showimportConfirm: false});
        this.props.hidePlaylistSelector();
    }

    render(){
        return(
            <div className="PlaylistSelector">
                <h3>Import Playlist</h3>
                <div className="PlaylistSelector__close"><Icon onClick={()=> this.props.hidePlaylistSelector()} icon="md-close" fontSize="20px" color="#fff"/></div>
                <div className="PlaylistSelector__content"> 
                    <div>{this.renderPlaylists()}</div>
                </div>

                {this.state.showimportConfirm &&
                    <div className="PlaylistSelector__confirm">
                        <p>Are you sure you want to Import {this.state.selectedPlaylist.songs ? Object.keys(this.state.selectedPlaylist.songs).length : 0} Songs from Playlist "{this.state.selectedPlaylist.title}" ?</p>
                        <button onClick={this.confirmImport} className="PlaylistSelector__confirm__confirmbtn">Import</button>
                        <button onClick={this.cancelImport}>Cancel</button>
                    </div>
                }
            </div>
        );
    }
}

export default PlaylistSelector;