import React from 'react';
import { useDispatch } from 'react-redux';
import { removePlaylist } from '../../redux/playlistSlice';
import Modal from '../Modal/Modal';

const PlaylistDelete = ({ playlist, playlistID, show, onClose, playlistsMin }) => {
   const dispatch = useDispatch();
    const onRemove=()=> {
         dispatch(removePlaylist(playlistID, playlistsMin));
         onClose();
    }
    const title = playlist && playlist.title ? playlist.title : '';
    
    return(
        <Modal title={`Remove Playlist - ${title}`} show={show} maxWidth={380} closeModal={()=>onClose()}>
            <div className="removePlaylistAction">
                <p>Are you sure you want to remove Playlist <span>{title}</span> ? All Songs inside the playlist will be also removed.</p>
                <button onClick={onRemove} className="playlistRemoveButton">Remove Playlist</button>
                <button onClick={()=> onClose()}>Cancel</button>
            </div>
        </Modal>
    );
}

export default PlaylistDelete;