import { getDatabase, ref, set, remove, push } from "firebase/database";
import firebase from "firebase/compat/app";

export const SET_STATION_SONGS = 'SET_STATION_SONGS';
// export const SET_STATION_SONG = 'SET_STATION_SONG';
export const SONG_ADDED_COMPLETE = 'SONG_ADDED_COMPLETE';
export const SONG_ADDED_ERROR = 'SONG_ADDED_ERROR';
export const SONG_ADDED_CLEAR = 'SONG_ADDED_CLEAR';

export const setSationSongs = (songs) => {
    return dispatch => {
      dispatch({
        type: SET_STATION_SONGS,
        stationSongs:songs
      })
    }
}

export const startStation = (stationID) => 
    (dispatch, getState) => {
      console.log('FROM ACTIOn -->>', stationID);
      const state = getState();
      const userData = state?.user?.userData;
      const userName = userData?.username;

      if(!userName){ return console.warn('User is Not Logged In', userData); }

      const db = getDatabase();
      const stationRef = ref(db, `stations/${stationID}/`);

      const theMsg = {
         "content": "Station Started!",
         "created": Math.round((new Date()).getTime() / 1000),
         "senderID": "bot",
         "bot": true,
         "senderName": "bot",
      }

      const theUser = {
         username: userData.username, 
         uid:stationID, status:'owner', 
         avatar: userData.avatarUrl, 
         fullname: userData.fullname,
         joined: Math.round((new Date()).getTime() / 1000)
      }

      const dummysongs = {
         "-La66xaa786ts8ga62cR":{ added: 1544963412, addedBy: "jane", addedOn: 1549168375, artist: "Drake", duration:  16, title: "Drake - Nonstop", youtube: "s-cx6tais9A", order: 0 },
         "-LXlcVyRV4LpOy_na5cc":{ added: 1538455744, addedBy: "jane", addedOn: 1549168410, duration: 455, artist: "Rihanna", title: "Rihanna - Work (Explicit) ft. Drake", youtube: "HL1UzIK-flA", order: 1 }
      }
      const stationSavedSongsRaw = localStorage.getItem('station_songs');
      const stationSongs = stationSavedSongsRaw ? JSON.parse(stationSavedSongsRaw) : dummysongs

      const payload = {
         stationInfo:{  live:true, repeat: false, started: Math.round((new Date()).getTime() / 1000) }, 
         users: {[stationID]: theUser},
         messages:{}, 
         songs: stationSongs 
      }

      set(stationRef, payload).then((snapshot) => {
         console.log('Started Station!', snapshot);
         // firebase.database().ref(`stations/${stationID}/messages`).push(theMsg);
         const postsRef = ref(db, `stations/${stationID}/messages`);
         const newPostRef = push(postsRef, theMsg);
         console.log('newPostRef: ', newPostRef);
      }).catch((err)=> console.log('ERROR STARTING Station', err))
}

export const stopStation = (stationID) => 
    (dispatch, getState) => {
        console.log('FROM ACTIOn -->>', stationID);
        const db = getDatabase();
        const stationRef = ref(db, `stations/${stationID}/`);
        set(stationRef, null);
        remove(stationRef).then((snapshot) => {
            console.log('Started Stopped!');
        }).catch((err)=> console.log('ERROR STARTING Station', err))
}


export const joinStation = (stationID) => 
    (dispatch, getState ) => {
      console.log('FROM ACTIOn -->>', stationID);
      const state = getState();
      const db = getDatabase();
      const uid = firebase.auth().currentUser?.uid;
      const userData = state?.user?.userData;

      if(uid && userData){
         const stationRef = ref(db, `stations/${stationID}/users/${uid}`);
         const theUser = {
            username: userData.username, 
            fullname: userData.fullname, 
            uid: userData.id, 
            status:'pending', 
            avatar: userData.avatarUrl ? userData.avatarUrl : '',
            joined: Math.round((new Date()).getTime() / 1000)
         }

         set(stationRef, theUser).then((snapshot) => {
            console.log('Sent Station Request!', snapshot);
         }).catch((err)=> console.log('ERROR Sending Station Join Request', err));
      }

}

export const cancelJoinRequest = (stationID) => 
    (dispatch, getState) => {
        console.log('FROM ACTIOn -->>', stationID);
        const db = getDatabase();
        const uid = firebase.auth().currentUser?.uid;
        const stationRef = ref(db, `stations/${stationID}/userLeft/${uid}`);

        set(stationRef, Math.round((new Date()).getTime() / 1000) ).then((snapshot) => {
            console.log('Canceled Station Request!');
        }).catch((err)=> console.log('ERROR Canceling Station Join Request', err));
}

export const acceptUser = (userID, stationID, username) => 
    (dispatch, getState) => {
        console.log('FROM ACTIOn -->>', userID, stationID);
        const db = getDatabase();

        set(ref(db, `stations/${stationID}/users/${userID}/joined`), Math.round((new Date()).getTime() / 1000));
        set(ref(db, `stations/${stationID}/users/${userID}/status`), 'allowed').then((snapshot) => {
            console.log('Accepted User!', snapshot);
            if(username){
               const theMsg = {
                    "content": `${username} joined Station`,
                    "created": Math.round((new Date()).getTime() / 1000),
                    "senderID": userID,
                    "bot": true,
                    "senderName": username,
               }
               push(ref(db, `stations/${stationID}/messages`), theMsg).then((snapshot) =>  console.log('Message Added! ', snapshot)).catch((err)=> console.log('ERROR Adding Msg', err));
            }
        }).catch((err)=> console.log('ERROR Accepting Station Join Request', err));
}

export const giveFullAccess = (user) => 
    (dispatch, getState) => {
        console.log('FROM ACTIOn -->>', user);
        const db = getDatabase();
        const state = getState();
        const accessMessage = user.status === 'fullAccess' ? `${user.username} Full Access was Revoked` :`${user.username} now has Full Access!`;

        set(ref(db, `stations/${state.user.station}/users/${user.uid}/status`), user.status === 'allowed' ? 'fullAccess' : 'allowed').then((snapshot) => {
            console.log('Full Access Give to', user.username);
            if(user.username){
               const theMsg = {
                    "content": accessMessage,
                    "created": Math.round((new Date()).getTime() / 1000),
                    "senderID": user.uid,
                    "bot": true,
                    "senderName": user.username,
               }
               push(ref(db, `stations/${state.user.station}/messages`), theMsg).then((snapshot) =>  console.log('Message Added! ', snapshot)).catch((err)=> console.log('ERROR Adding Msg', err));
            }
        }).catch((err)=> console.log('ERROR Giving Full Access!', err));
}

export const rejectUser = (userID, stationID, userLeft=false, username='') => 
    (dispatch, getState) => {
        console.log('FROM ACTIOn -->>', userID, stationID);
        const db = getDatabase();

        remove(ref(db, `stations/${stationID}/users/${userID}/`)).then(() => {
            console.log('Rejected User!');
            if(userLeft && username){
               const theMsg = {
                    "content": `${username} left Station`,
                    "created": Math.round((new Date()).getTime() / 1000),
                    "senderID": userID,
                    "bot": true,
                    "senderName": username,
               }
               push(ref(db, `stations/${stationID}/messages`), theMsg).then((snapshot) =>  console.log('Message Added! ', snapshot)).catch((err)=> console.log('ERROR Adding Msg', err));
            }
        }).catch((err)=> console.log('ERROR Rejected Station Join Request', err));
}

export const kickUser = (userID, stationID, username='') => 
    (dispatch, getState) => {
        console.log('FROM ACTIOn -->>', userID, stationID);
        const db = getDatabase();

        set(ref(db, `stations/${stationID}/users/${userID}/status`), 'kicked').then(() => {
            console.log('User Kicked!');
            if(username){
               const theMsg = {
                    "content": `${username} was removed from Station`,
                    "created": Math.round((new Date()).getTime() / 1000),
                    "senderID": userID,
                    "bot": true,
                    "senderName": username,
               }
               push(ref(db, `stations/${stationID}/messages`), theMsg).then((snapshot) =>  console.log('Message Added! ', snapshot)).catch((err)=> console.log('ERROR Adding Msg', err));
            }
            setTimeout(() => {
               remove(ref(db, `stations/${stationID}/users/${userID}/`));
            }, 1000);
        }).catch((err)=> console.log('ERROR Rejected Station Join Request', err));
}

export const completeUserLeft = (userID, stationID, user) => 
    (dispatch, getState) => {
        console.log('FROM ACTIOn -->>', userID, stationID);
        const db = getDatabase();
        const stationRef = ref(db, `stations/${stationID}/users/${userID}/`);
        const userLeftRef = ref(db, `stations/${stationID}/userLeft/${userID}/`);
        
        remove(userLeftRef).then(()=> {
            console.log('Removed User from UserLeft DB!');
        }).catch((err)=> console.log('ERROR Rejected Station Join Request', err));

        remove(stationRef).then(() => {
            console.log('Removed User from the Users DB!');
            if(user){
               const theMsg = {
                    "content": `${user.username} left Station`,
                    "created": Math.round((new Date()).getTime() / 1000),
                    "senderID": userID,
                    "bot": true,
                    "senderName": user.username,
               }
               push(ref(db, `stations/${stationID}/messages`), theMsg).then((snapshot) =>  console.log('Message Added! ', snapshot)).catch((err)=> console.log('ERROR Adding Msg', err));
            }
        }).catch((err)=> console.log('ERROR Rejected Station Join Request', err));
}



export const addSationSong = (song) => 
    (dispatch, getState) => {
        console.log('FROM ACTIOn -->>', song, getState());
        const state = getState();
        const userData = state?.user?.userData;
        console.log('ADD THIS SONG TO STATION: ', song, state.user.station, state.user.stationSongs, userData.username);
        const db = getDatabase();
        const stationSongsRef = ref(db, `stations/${state.user.station}/songs`);
        const {username="", avatarUrl="", fullname=""} = userData;
        const newSong =  {
            ...song, 
            addedOn: Math.round((new Date()).getTime() / 1000), 
            addedBy: username, 
            addedByInfo:{username, avatarUrl, fullname}, 
            artist: '', 
            title: song.artist? `${song.artist} - ${song.title}`: song.title,
            order: state.user.stationSongs ? Object.keys(state.user.stationSongs).length + 1 : 0
        };
        delete newSong.playlist; delete newSong.playlistID; delete newSong.id;
         
        push(stationSongsRef, newSong).then((snapshot) => {

            dispatch({type: SONG_ADDED_COMPLETE, payload: {title: newSong.title, playListID: state.user.station}});
            setTimeout(()=> {
              dispatch({type: SONG_ADDED_CLEAR});
            }, 2500)

            if(username && newSong.title){
               const theMsg = {
                    "content": `${username} added Song "${newSong.title}"`,
                    "created": Math.round((new Date()).getTime() / 1000),
                    "senderID": userData.id,
                    "bot": true,
                    "senderName": username,
               }

               push(ref(db, `stations/${state.user.station}/messages`), theMsg).then((snapshot) =>  console.log('Message Added! ', snapshot)).catch((err)=> console.log('ERROR Adding Msg', err));
            }
            console.log('Added Song to Station!');
        }).catch((err)=> {
            console.log('ERROR Adding Song', err);
            dispatch({type: SONG_ADDED_ERROR})
        })
        
    
}

export const removeSationSong = (songID, song) => 
    (dispatch, getState) => {
         console.log('FROM ACTIOn -->>', songID, getState());
         const db = getDatabase();
         const state = getState();
         const userData = state?.user?.userData;
         console.log('REMOVE THIS SONG FROM STATION: ', songID, state.user.station);
         const stationSongsRef = ref(db, `stations/${state.user.station}/songs/${songID}`);

         remove(stationSongsRef).then((snapshot) => {
            if(userData?.username && song && song.title){
               const theMsg = {
                    "content": `${userData.username} removed Song "${song.title}"`,
                    "created": Math.round((new Date()).getTime() / 1000),
                    "senderID": userData.id,
                    "bot": true,
                    "senderName": userData.username,
               }
               push(ref(db, `stations/${state.user.station}/messages`), theMsg).then((snapshot) =>  console.log('Message Added! ', snapshot)).catch((err)=> console.log('ERROR Adding Msg', err));
            }
            console.log('Removed Song from Station!');
         }).catch((err)=> console.log('ERROR Removing Song', err))
}


export const importPlaylist = (playlist) => 
    (dispatch, getState, getFirebase) => {
        console.log('FROM ACTIOn -->>', playlist);
        const db = getDatabase();
        const state = getState();
        const userData = state?.user?.userData;
        const stationSongsRef = ref(db, `stations/${state.user.station}/songs`);

        if(playlist.songs && userData?.id && state.user.station === userData.id){
            Object.keys(playlist.songs).forEach((songID)=> {
                const song = playlist.songs[songID];
                console.log('RAW SONG: ', song);
                const {username="", avatarUrl="", fullname=""} = userData;
                const newSong = {
                    ...song, 
                    addedOn: Math.round((new Date()).getTime() / 1000), 
                    addedBy: username, 
                    addedByInfo:{username, avatarUrl, fullname}, 
                    artist: '', 
                    title: song.artist? `${song.artist} - ${song.title}`: song.title
                }
                console.log('MODIFIED SONG: ', newSong);
                push(stationSongsRef, newSong).then((snapshot) => {
                    console.log('Added Song to Station!', snapshot);
                }).catch((err)=> console.log('ERROR Adding Song', err))
            })

            //Show Import Message
            if(userData.username){
                setTimeout(() => {
                    const theMsg = {
                        "content": `${userData.username} added a Playlist with ${Object.keys(playlist.songs).length} songs.`,
                        "created": Math.round((new Date()).getTime() / 1000),
                        "senderID": userData.id,
                        "bot": true,
                        "senderName": userData.username,
                    }
                    push(ref(db, `stations/${state.user.station}/messages`), theMsg).then((snapshot) =>  console.log('Message Added! ', snapshot)).catch((err)=> console.log('ERROR Adding Msg', err));
                }, 1000);

            }
        }

    
}

export const clearStation = () => 
    (dispatch, getState) => {
         console.log('FROM ACTIOn -->>', getState());
         const db = getDatabase();
         const state = getState();
         const userData = state?.user?.userData;
         console.log('REMOVE ALL THE SONGS FROM STATION: ',  state.user.station);
         const stationSongsRef = ref(db, `stations/${state.user.station}/songs/`);

         set(stationSongsRef, {}).then((snapshot) => {
            const theMsg = {
                  "content": `${userData.username} cleared all station Songs`,
                  "created": Math.round((new Date()).getTime() / 1000),
                  "senderID": userData.id,
                  "bot": true,
                  "senderName": userData.username,
            }
            push(ref(db, `stations/${state.user.station}/messages`), theMsg).then((snapshot) =>  console.log('Message Added! ', snapshot)).catch((err)=> console.log('ERROR Adding Msg', err));

            console.log('Removed All Songs from Station!');
         }).catch((err)=> console.log('ERROR Removing Songs', err))
}


export const likeStationSong = (songID, song) => 
    (dispatch, getState) => {
         console.log('FROM ACTIOn -->>', song, getState());
         const db = getDatabase();
         const state = getState();
         const userData = state?.user?.userData;
         console.log('LIKE SONG: ', song, state.user.station, state.user.stationSongs, userData.username);
         
      if(userData.username){
         const stationSongsRef = ref(db, `stations/${state.user.station}/songs/${songID}/likes/${userData.username}`);
         set(stationSongsRef, userData.avatarUrl ? userData.avatarUrl: false).then((snapshot) => {
            if(userData?.username && song.title){
               const theMsg = {
                  "content": ` ${userData.username} :heart: "${song.title}"`,
                  "created": Math.round((new Date()).getTime() / 1000),
                  "senderID": userData.id,
                  "bot": true,
                  "senderName": userData.username,
               }
               push(ref(db, `stations/${state.user.station}/messages`), theMsg).then((snapshot) =>  console.log('Message Added! ', snapshot)).catch((err)=> console.log('ERROR Adding Msg', err));
            }
            console.log('Liked Song!');
         }).catch((err)=> {
               console.log('ERROR Liking Song', err);
               dispatch({type: SONG_ADDED_ERROR})
         })
      }
}

//Reorder Song
export const reorderStaionSongs = (songs) =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>', songs);
      const db = getDatabase();
      const state = getState();
      const userData = state?.user?.userData;
      console.log(state.user.station, userData.id, state.user.station && userData.id && (state.user.station === userData.id));

      if(songs){
         const stationSongsRef = ref(db, `stations/${state.user.station}/songs`);
         set(stationSongsRef, songs).then((snapshot) => {
            console.log('@@New Song Reordered!!!!!!');
         }).catch(()=> {
            console.log('@@Song Reorder Failed!!!!!!');
         });
      }
};

//SET Current Song
export const setCurrentSong = (song) =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>', song);
      const state = getState();
      const db = getDatabase();

      const stationSongsRef = ref(db, `stations/${state.user.station}/currentSong/`);

      if(song){
         const newSong = {...song, playing: true, played:0};

         set(ref(db, `stations/${state.user.station}/stationInfo/played`), 0).then(()=> console.log('Set Played time to 0'));
         set(stationSongsRef, newSong).then((snapshot) => {
               console.log('@@Current Song SET!!!!!!');
         }).catch(()=> {
               console.log('@@Current Song SET Failed!!!!!!');
         });
      }else{
         remove(stationSongsRef).then((snapshot) => {
               console.log('@@Playlist Ended! Current Song Removed!');
         }).catch(()=> {
               console.log('@@Playlist Ended! Current Song Removed Failed!');
         });
      }
      remove(ref(db, `stations/${state.user.station}/stationInfo/pauseInfo`)).then((snapshot) => {
         console.log('@@Playlist Ended! Current Song Removed!');
      }).catch(()=> {
            console.log('@@Playlist Ended! Current Song Removed Failed!');
      });
};

//PLAY Current Song
export const playStationSong = () =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>');
      const db = getDatabase();
      const state = getState();
      const stationSongsRef = ref(db, `stations/${state.user.station}/currentSong/playing`);

      set(stationSongsRef, true).then((snapshot) => {
         console.log('@@Play Station Song!!!!!!');
      }).catch(()=> {
         console.log('@@Play Station Song Failed!!!!!!');
      });
      remove(ref(db, `stations/${state.user.station}/stationInfo/pauseInfo`)).then((snapshot) => {
         console.log('@@Removed station Paused Info!');
      }).catch(()=> {
         console.log('@@Remove station Pause Info Failed!');
      });
};

//PAUSE Current Song
export const pauseStationSong = () =>
  (dispatch, getState ) => {
      console.log('FROM ACTIOn -->>');
      const db = getDatabase();
      const state = getState();
      const userData = state?.user?.userData || {};
      const stationSongsRef = ref(db, `stations/${state.user.station}/currentSong/playing`);
      const {username="", avatarUrl="", fullname=""} = userData;

      if(username){
         set(stationSongsRef, false).then((snapshot) => {
         set(ref(db, `stations/${state.user.station}/stationInfo/pauseInfo`), {username, avatarUrl, fullname, pausedOn: Math.round((new Date()).getTime() / 1000) });
            console.log('@@PASUED Station!!!!!!');
         }).catch(()=> {
            console.log('@@PASUED Station Failed!!!!!!');
         });
      }

};

//PAUSE Current Song
export const setPlayedSeconds = (playedSeonds) =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>');
      const db = getDatabase();
      const state = getState();
      const stationSongsRef = ref(db, `stations/${state.user.station}/playedSeconds`);

      set(stationSongsRef, playedSeonds).then((snapshot) => {
         console.log('@@Set Played Seconds');
      }).catch(()=> {
         //console.log('@@Played Seconds Failed!!!!!!');
      });
};



export const setStationRepeat = (currentState) =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>');
      const db = getDatabase();
      const state = getState();
      const stationSongsRef = ref(db, `stations/${state.user.station}/stationInfo/repeat`);

      set(stationSongsRef, !currentState).then((snapshot) => {
         console.log('@@Set Played Seconds');
      }).catch(()=> {
         console.log('@@Played Seconds Failed!!!!!!');
      });
};