import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import albumCover from '../../assets/cover.png';
import { formatDuration, viewFormat } from '../../helpers';
import Icon from '../Icon/Icon';

const ArtistAlbums = ({artist, selectedSong}) => {

   const [currentAlbum, setCurrentAlbum] = useState('');
   const [showPopular, setShowPopuplar] = useState(true);
   const [currentTracks, setCurrentTracks] =  useState({});
   const [currentTrack, setCurrentTrack] = useState('');
   const [error, setError] = useState('');
   const [covers, setCovers] = useState({});
 

   const fetchAlbumCovers = useCallback( async(givenAlbums) => {
      const albums = givenAlbums || [];
      const albumCovers  = {}
      if(albums.length > 0){
         for (const album of albums) {
            if(album.mbid_release_group){
               try {
                  const response =  await axios.get(`http://coverartarchive.org/release-group/${album.mbid_release_group}`) 
                  if(response.data.images.length > 0){
                     const sortedByFront = response.data.images.sort((a, b)=> a.front > b.front );
                     if(sortedByFront[0] && sortedByFront[0].image){
                        albumCovers[album.mbid] = sortedByFront[0].thumbnails && sortedByFront[0].thumbnails.small ? sortedByFront[0].thumbnails.small : sortedByFront[0].image;
                        // console.log( album.mbid, albumCovers[album.mbid]);
                     }
                  }
               } catch (error) {
                  console.log('[ERROR] Album Detail Fetch err', error);
               }
            }
         }
         setCovers(albumCovers);
      }
   }, []);

   useEffect(()=>{
      if(artist && artist.albums && artist.albums.length > 0){
         fetchAlbumCovers(artist.albums);
      }
   }, [artist, fetchAlbumCovers])


   const selectAlbum = (mbid, index) => {
      setError('');

      if(currentAlbum === index){ 
         return setCurrentAlbum('');
      }else{
         setCurrentTrack('');
      }

      setCurrentAlbum(index);

      if(!currentTracks[mbid]){
         axios.get(`https://musicbrainz.org/ws/2/release/${mbid}?inc=recordings+artists+media&fmt=json`) 
         .then((response) => {
            console.log('response: ', response.data.media[0].tracks.map(({title, length})=> ({name: title, duration: Math.round(length / 1000 ) }) ));
            if(response.data.media[0].tracks){
               setCurrentTracks({...currentTracks, [mbid] : response.data.media[0].tracks.map(({title, length})=> ({name: title, duration: Math.round(length / 1000 ) }) )});
            }else{
               setError('No tracks found!');
            }
         }).catch((err)=> {
            console.log('[ERROR] Album Detail Fetch err', );
         })
      }
   }

   const selectTrack = (title, album, index) => {
      console.log(title, album);
      setCurrentTrack(index);
      selectedSong(title, album);
    }


   const { name='', image='', summary='', albums=[], toptracks=[] } = artist ? artist : {};

   const renderPopularTracks = () => {
      return <div className={`albumItem ${showPopular ? 'active' : ''}`}>
               <div className="albumItem__head" onClick={()=> setShowPopuplar(!showPopular)}>★ Popular Tracks <span style={{marginTop: 0}}><Icon icon={showPopular ? "ios-arrow-up" : "ios-arrow-down"} fontSize="18px" color="#ffffff"/></span></div>
               {showPopular && 
                  <div className="album_tracks">
                     <ul>
                        {toptracks.map((track, index)=> {
                              return <li 
                              key={index+'pop'} 
                              className={currentTrack === index ? 'active': ''}
                              onClick={()=> selectTrack(track.title, '', index)}>{index+1}. {track.title} 
                                 <span>{track.playcount && viewFormat(track.playcount)+' Plays' }</span>
                              </li>
                        })}
                     </ul>
                  </div>
               }
            </div>
   }

   return(
      <div id="artistAlbums">
            <div className="artistHeader">
               <div className="artistHeader_left">
                  <img src={image} alt={name}/>
               </div>
               <div className="artistHeader_right">
                  <h4>{name}</h4>
                  <p>{summary ? summary.split('.')[0] : ''}</p>
               </div>
            </div>

            <div className="artistAlbums">
               {renderPopularTracks()}
               {albums && albums.map((album, index)=> {
                  return (
                        <div className={`albumItem ${currentAlbum === index ? 'active' : ''}`} key={index}>
                           <div className="albumItem__head" onClick={()=> selectAlbum(album.mbid, index)}>
                              <div className="album_left"><img src={covers[album.mbid] ? covers[album.mbid] : albumCover} alt={album.name ? album.name : 'Album Cover'} /></div>
                              <div className="album_right">
                                    <div className="albumName">{album.name ? album.name : ''}</div>
                                    <div className="albumArtist">{album.year ? album.year : ''}</div>
                              </div>
                              {currentAlbum === index ?
                                    <span><Icon icon="ios-arrow-up" fontSize="26px" color="#ffffff"/></span>
                              :
                                    <span><Icon icon="ios-arrow-down" fontSize="26px" color="#ffffff"/></span>
                              }
                           </div>

                           {currentAlbum === index && 
                              <div className="album_tracks">
                                    {currentTracks[album.mbid] && currentTracks[album.mbid].length > 0 ?
                                       <ul>
                                          {currentTracks[album.mbid].map((track, index)=> {
                                                return <li 
                                                key={index+'s'} 
                                                className={currentTrack === index ? 'active': ''}
                                                onClick={()=> selectTrack(track.name, album.name, index)}>{index}. {track.name} 
                                                <span>{formatDuration(track.duration)}</span>
                                                </li>
                                          })}
                                       </ul>
                                    :
                                       <p>{error ? error : <Icon icon="ios-sync" rotate={true} fontSize="26px" color="#ffffff"/>}</p>
                                    }
                              </div>
                           }
                        </div>
                  )
               })}


            </div>
      </div>
   );

}

export default ArtistAlbums;