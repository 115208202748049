import React, {useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Icon from '../Icon/Icon';
import fakePP from '../../assets/user_m.png';
// import { showUserArea, showAddSongModal, mobileTab, showHidePRO, showLibraryModal } from '../../actions/index';
import { showUserArea, showAddSongModal, showHidePRO, showLibraryModal } from '../../redux/userSlice';
import { bodyClass } from '../../helpers.js';
import Modal from '../Modal/Modal';
import ToastBox from '../Modal/ToastBox';
import ProCompare from '../PRO/ProCompare';
import About from '../Pages/About';
import { useDispatch } from 'react-redux';
// import { useSigninCheck } from 'reactfire';


const Header = ({userSettings, profile, playlists}) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const [quickLookModal, setQuickLookModal] = useState(false);
    const [showSearchOptions, setShowSearchOptions] = useState(false);
    const [proModal, setProModal] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [selectedSearch, setSelectedSearch] = useState('search');
    //  const [electronMenu, setElectronMenu] = useState(false);

   //  const { status, data:signInCheckResult } = useSigninCheck();
   //  useEffect(()=> {
   //   console.log('signInCheckResult: ', signInCheckResult);
   //  }, [status, signInCheckResult])


    useEffect(() => {
      if(document.querySelector('.install-button')){
         let deferredPrompt;
         //let addBtn = document.querySelector('.install-button');
         
         setTimeout(() => {
             document.querySelector('.install-button').style.display = 'none';
             //console.log('componentDidMount', addBtn, );
             window.addEventListener('beforeinstallprompt', (e) => {
                 //console.log('beforeinstallprompt', addBtn, e);
                 // Prevent Chrome 67 and earlier from automatically showing the prompt
                 e.preventDefault();
                 // Stash the event so it can be triggered later.
                 deferredPrompt = e;
                 // Update UI to notify the user they can add to home screen
                 document.querySelector('.install-button').style.display = 'inline-block';
                 
                 document.querySelector('.install-button').addEventListener('click', (e) => {
                     //console.log(e);
                     // hide our user interface that shows our A2HS button
                     document.querySelector('.install-button').style.display = 'none';
                     // Show the prompt
                     deferredPrompt.prompt();
                     // Wait for the user to respond to the prompt
                     deferredPrompt.userChoice.then((choiceResult) => {
                         if (choiceResult.outcome === 'accepted') {
                             console.log('User accepted the A2HS prompt');
                             window.ga('send', 'event', 'buttons', 'click', 'install-button');
                         } else {
                             console.log('User dismissed the A2HS prompt');
                         }
                         deferredPrompt = null;
                     });
                 });
             });

         }, 600);
         

     }
    }, []);

    useEffect(()=> {
      if(userSettings.showPro){
         setProModal(userSettings.showPro);
      }
    }, [userSettings]);

    const submitSearch = (event) => {
        event.preventDefault();
        console.log(selectedSearch, searchString);
        dispatch(showAddSongModal({tab: selectedSearch, string: searchString}));
        setSearchString('');
    }

    const closeProModal = () => {
        dispatch(showHidePRO());
    }
    const closequickLookModal = () => {
        setQuickLookModal(false)
    }

    const renderSearchForm = () => {
        let placeHolder = '';
        if(selectedSearch === 'link'){  placeHolder = 'Paste Youtube Link'  }
        if(selectedSearch === 'search'){  placeHolder = 'Search Youtube'  }
        if(selectedSearch === 'artist'){  placeHolder = 'Search Artists'  }
        if(selectedSearch === 'library'){  placeHolder = 'Search Your Library'  }
        return(
            <div>
                <form onSubmit={submitSearch}>
                    <input type="text" aria-label={placeHolder} placeholder={placeHolder} onChange={(event)=> setSearchString(event.target.value)} value={searchString} />
                    <button className="searchButton" aria-label="Search" onClick={submitSearch} type="submit">
                        {selectedSearch === 'link' ? <Icon icon="md-add" fontSize="16px" color="#fff" /> : <Icon icon="ios-search" fontSize="16px" color="#fff" />}
                    </button>
                </form>
            </div>
        );
    }


        const songAddedMessage = userSettings.actions.songAdded;
        //const toastBoxMessage =  songAddedMessage && songAddedMessage.title;
        //const toastBoxError = songAddedMessage && songAddedMessage.error;
        let toastBoxType = songAddedMessage && userSettings.actions.songAdded.title ? 'success' : '';
        if(toastBoxType === ''){
          toastBoxType = songAddedMessage && userSettings.actions.songAdded.error ?  'error' : '';
        }
        const checkedQuickHelp = localStorage.getItem('checkedQuickHelp') ? true : false;
        const linkItem = <span onClick={()=> {setSelectedSearch('link'); setShowSearchOptions(false); }}><Icon icon="ios-link" fontSize="16px" color="#fff"/> URL</span>;
        const searchItem = <span onClick={()=> {setSelectedSearch('search'); setShowSearchOptions(false); }}><Icon icon="ios-search" fontSize="16px" color="#fff"/> Search</span>;
        const artistItem = <span onClick={()=> {setSelectedSearch('artist'); setShowSearchOptions(false); }}><Icon icon="ios-person" fontSize="16px" color="#fff"/> Artist</span>;
        //const libItem = <a onClick={()=> this.setState({ selectedSearch: 'library', showSearchOptions: false })}><Icon icon="ios-folder" fontSize="16px" color="#fff"/> Library</a>;

        return (
            <header>
                <div id="searchBar" className={`${showSearchOptions ? 'searchBar-active':''}`} >
                    <div className="searchBarOptions">
                            { selectedSearch === 'link' && <button aria-label="Youtube Link" onClick={()=> setShowSearchOptions(!showSearchOptions)}>{linkItem} <i>&#9660;</i></button> }
                            { selectedSearch === 'search' && <button aria-label="Search Youtube" onClick={()=> setShowSearchOptions(!showSearchOptions)}>{searchItem} <i>&#9660;</i></button> }
                            { selectedSearch === 'artist' && <button aria-label="Search Artists" onClick={()=> setShowSearchOptions(!showSearchOptions)}>{artistItem} <i>&#9660;</i></button> }                        
                        {showSearchOptions &&
                            <ul>
                                { selectedSearch !== 'link' && <li>{linkItem}</li> }
                                { selectedSearch !== 'search' && <li>{searchItem}</li> }
                                { selectedSearch !== 'artist' && <li>{artistItem}</li> }
                            </ul>
                        }

                    </div>
                    {renderSearchForm()}
                    
                </div>

                <ul className={`menu`}>

                {userSettings.mobile && <button aria-label="Help" className={`mobile_help ${checkedQuickHelp ? '' : 'notChecked'}`}  onClick={()=> localStorage.setItem('checkedQuickHelp', 1)}><Link to="/help">i</Link></button> }
                {!userSettings.mobile && <button aria-label="Help" className={`quick_helpBtn ${checkedQuickHelp ? '' : 'notChecked'}`} onClick={()=> { setQuickLookModal(true); localStorage.setItem('checkedQuickHelp', 1); }}>i</button> }
                {/* {profile.isLoaded && profile.id && !userSettings.isPRO && <button aria-label="Upgrade to PRO" className="upgrade_button" onClick={showHidePRO}><Icon icon="ios-flash" fontSize="24px" color="#3f3576"/>{!userSettings.mobile && 'Upgrade'}</button>} */}

                {!userSettings.mobile && 
                    <div className="menu__leftButtons">
                        <button aria-label="Install" className={`install-button`}><Icon icon="md-download" fontSize="16px" color="#fff" /> Install</button>
                        <Link data-tip="Enjoy Music Together with Friends" to={profile.username ? '/station/'+profile.username : '/stations/'  } aria-label="My Station" className={`myStation-button`}>
                            <Icon icon="ios-radio-outline" fontSize="20px" color="#fff" /> Station
                        </Link>
                    </div> 
                }
                {userSettings.mobile && <button aria-label="Install" className={`install-button ${profile && profile.id ? 'install-button--active-user':''}`}><Icon icon="md-download" fontSize="14px" color="#fff" /></button> }

                    {userSettings.mobile && 
                        <li><button className="searchButton" aria-label="Add Song"  onClick={()=> dispatch(showAddSongModal({tab: 'search', string: ''}))}><Icon icon="md-add" fontSize="16px" color="#fff" /></button></li>
                    }
                    {/* <li className={`menu-item--friends ${bodyClass(pathname) === 'page-friends' ? 'active':''}`} onClick={()=> mobileTab('friends')} >
                        <Link to="/friends"><Icon icon="ios-people" fontSize={`${userSettings.mobile ? "30px" : "20px"}`} color="#fff" /> {!userSettings.mobile && 'Freinds'}</Link>
                    </li> */}

                    {!profile.id &&
                        <li className={`menu-item--signup ${bodyClass(pathname) === 'page-login' ? 'active':''}`} >
                            <button onClick={()=> dispatch(showUserArea())} >Create Account</button>
                        </li>
                    }

                    {userSettings.mobile && profile.id &&
                        <li className={`menu-item--library`} >
                            <button onClick={()=> dispatch(showLibraryModal())} ><Icon icon="md-appstore" fontSize="14px" color="#fff" /></button>
                        </li>
                    }

                    <li className={`${bodyClass(pathname) === 'page-friends' ? 'active':''}`} >
                        <button className="menu__account" onClick={()=> dispatch(showUserArea())}><img src={profile.avatarUrl ? profile.avatarUrl :fakePP } alt="My Account" /></button>
                    </li>
                </ul>

               <div className="header__glow"/>
               <div className="menu__glow"><span /></div>

                <Modal title={`Upgrade to PRO`} show={proModal} maxWidth={800} closeModal={closeProModal} size="large" headless={true}>
                    <div className="proModal_wrapper">
                        <div className="proModal__heading">
                            <h3>Upgrade to PRO</h3>
                            <p>Avoid paying hundreds of dollars a year for Music streaming services. Make a one time payment and listen to millions of music from Youtube.</p>
                        </div>
                        <ProCompare mobile={userSettings.mobile} />
                    </div>
                </Modal>

                <Modal title={`Quick Look`} show={quickLookModal} maxWidth={800} closeModal={closequickLookModal} size="large">
                    <div className="quickLook_wrapper">


                        <Tabs forceRenderTabPanel={false}>
                            <div className="quickLook__nav">
                                <TabList>
                                    <Tab>Whats Yhimsical?</Tab>
                                    <Tab>Adding Songs</Tab>
                                    <Tab>Playing Songs</Tab>
                                    <Tab>The Queue</Tab>
                                    <Tab>Creating/Editing Playlists</Tab>
                                </TabList>
                            </div>

                            <div className="quickLook__content">
                                <div className="quickLook__content__wrapper">
                                    <TabPanel>
                                        <div className="customScrollbar helpAboutScroll">
                                            <div><About /></div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <video controls loop={true} autoPlay={true}>
                                            <source src="https://giant.gfycat.com/DarkElaborateIrishwaterspaniel.webm" type="video/webm" />
                                        </video> 
                                    </TabPanel>
                                    <TabPanel>
                                        <video controls loop={true} autoPlay={true}>
                                            <source src="https://giant.gfycat.com/FlashySerpentineDragonfly.webm" type="video/webm" />
                                        </video> 
                                    </TabPanel>
                                    <TabPanel>
                                        <video controls loop={true} autoPlay={true}>
                                            <source src="https://giant.gfycat.com/CandidSillyFlycatcher.webm" type="video/webm" />
                                        </video> 
                                    </TabPanel>
                                    <TabPanel>
                                        <video controls loop={true} autoPlay={true}>
                                            <source src="https://giant.gfycat.com/MeekEachIcterinewarbler.webm" type="video/webm" />
                                        </video> 
                                    </TabPanel>
                                </div>
                                
                            </div>
                        </Tabs>

                    </div>
                </Modal>

                <ToastBox type={toastBoxType}>
                    { songAddedMessage &&  songAddedMessage.title && playlists.userPlaylists && playlists.userPlaylists[songAddedMessage.playListID] &&
                        <div>                                                                                   
                            <Icon icon="md-checkmark-circle-outline" fontSize="24px" color="#fff"/> <strong>{songAddedMessage.title}</strong> added to Playlist <strong>{playlists.userPlaylists[songAddedMessage.playListID].title}</strong>
                        </div>
                    }
                    {songAddedMessage && songAddedMessage.error && 
                        <div>
                            <Icon icon="md-close-circle" fontSize="24px" color="#fff"/> {songAddedMessage.error}
                        </div>
                    }
                </ToastBox>

                {userSettings.station && 
                    <ToastBox type={toastBoxType}>
                        { songAddedMessage &&  songAddedMessage.title &&
                            <div><Icon icon="md-checkmark-circle-outline" fontSize="24px" color="#fff"/> <strong>{songAddedMessage.title}</strong> added to Station.</div>
                        }
                        {songAddedMessage && songAddedMessage.error && 
                            <div><Icon icon="md-close-circle" fontSize="24px" color="#fff"/> {songAddedMessage.error}</div>
                        }
                    </ToastBox>
                }
            </header>
        )
    
}

export default Header;