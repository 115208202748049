import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import Icon from '../Icon/Icon';
import Artists from './Artists';
import genres from '../../utils/genres';
import ArtistSkeleton from '../Loader/ArtistSkeleton';
import OverlayScreen from '../PRO/OverlayScreen';
import DiscoverHeader from '../Discover/DiscoverHeader';


const AllArtists = () => {
   const { genre: genreParam, page: pageParam } = useParams();
   const navigate = useNavigate();

   const userSettings = useSelector((state)=> state.user );
   const uid = userSettings && userSettings.userData && userSettings.userData.id;

   const [loading, setLoading] =  useState(false);
   const [genre, setGenre]  =  useState('');
   const [page, setPage]  =  useState(null);
   const [artists, setArtists] = useState([]);
   const [allArtists, setAllArtists] = useState([]);
   const [allTracksCount, setAllTracksCount] = useState(0);
   const [filteredArtists, setFilteredArtists] = useState(null);
   const [searchString, setSearchString] =  useState('');
   const [showGenreChoices, setShowGenreChoices] = useState(false);

   const count = {};


   const getArtists = useCallback((genre, page) => {
      console.log(`getArtists being called!`, genre, page);
      setLoading(true);
      axios.get(`https://yhimsical.b-cdn.net/allartists.json`).then((results)=> {
          //console.log(results.data);
          if(results.data){
              const fields = results.data;
              const allArtists = Object.keys( fields ).map( p => Object.assign( fields[p], {field:p} ));
              let finalArtists = allArtists.filter(s => s.songs && s.songs > 0).sort( (a, b) =>  a.name.localeCompare(b.name) );
              const refinedArtists = genre === 'all' ? finalArtists  : finalArtists.filter((s)=> s.categories[genre] === true) ;

              let totalTracks = 0
              refinedArtists.forEach(artist => {
                  totalTracks = totalTracks + artist.songs;
              });
              setArtists(refinedArtists.slice( page*15, page*15 + 15 ));
              setAllArtists(finalArtists);
              setAllTracksCount(totalTracks);
          }
      }).catch((err)=> {
          console.warn('[ERROR] getArtists: ', err);
      }).finally(()=> {
          setLoading(false);
      });
   }, [])

   useEffect(()=> {
      console.log(genreParam);
      const currentGenre = genreParam || 'all';
      const currentPage = pageParam ? Number(pageParam) : 0;
      console.log(currentPage);
      setGenre(currentGenre);
      setPage(currentPage);
      getArtists(currentGenre, currentPage);
   }, [genreParam, pageParam, getArtists])


   const handlePageClick = (data) => {
      let page = data.selected;
      setPage(parseInt(page, 10));
      return navigate(`/artists/${genre}/page/${page}/`);
   }

   const onSearch = (event, directValue=false) => {
        let searchValue =''
        if(directValue){  searchValue = searchString;  }else{  searchValue = event.target.value }

        setSearchString(searchValue);
        
        let filteredArtists = filterSongs(searchValue) ? filterSongs(searchValue) : [];
        if(searchValue && filteredArtists.length > 0 ){
            setFilteredArtists(filteredArtists.slice(0, 6));
        }else{
            setFilteredArtists(null);
        }
   }

   const filterSongs = (string) => {
        const allTheArtists = allArtists;
        return allArtists && allTheArtists.filter((x) =>{
            if(x.name ){
                let searchTitle = x.name && x.name.toLowerCase();
                return (searchTitle.indexOf(string.toLowerCase()) !== -1);
            }else{
                return [];
            }
        } );
   }

   const renderHeader = () => {
        let copiedGenres = [...genres]; copiedGenres[0] = {id:'all', name:'All', count: allArtists.length};
        copiedGenres.forEach((item)=>  item.id !== 'all' ? item.count = count && count[item.id] ? count[item.id] : 0 : item.count = allArtists.length );

        const genArrow = showGenreChoices ? <Icon icon="md-arrow-dropup" fontSize="14px" color="#ffffff"/> :  <Icon icon="md-arrow-dropdown" fontSize="14px" color="#ffffff"/>; 
        const artistsCount = count && count[genre] ? `${(page + 1) * 15}/${allArtists.length} ` : ``;
        
        return(
            <div className="tracks_header artists_header">
                <div className="tracks_header__inner">
                    <div className="tracks_header__left">
                        <h2 className="tracks_header__title">All Artists</h2>
                        <div className="tracks_genre_count">{artistsCount}</div>
                    </div>
                    <div className="tracks_header__right">
                        <div className={` genChoices ${showGenreChoices ? 'showGenreChoices' : ''}`}>
                            <button onClick={()=> setShowGenreChoices(!showGenreChoices)}>{genre} {genArrow}</button>
                            <ul>
                              {copiedGenres.map((genr)=> <li key={genr.id}>
                                                            <Link className={genre === genr.id ? `active`:``} to={`/artists/${genr.id}`} onClick={()=> { setShowGenreChoices(false); setGenre(genr.id); }}>
                                                               {genr.name} <span>{genr.count}</span>
                                                            </Link></li>
                              )}
                           </ul>
                        </div>
                        {uid && 
                            <form id="artists__form" onSubmit={(e)=> e.preventDefault() }>
                                <input type="text" name="searchartists" placeholder="Filter Artists.." value={searchString} onChange={onSearch}/>
                            </form>
                        }
                    </div>

                </div>
            </div>
        );
   }


   const renderArtists = () => {
        if(filteredArtists && searchString ){
            return <Artists artists={filteredArtists} />;
        }else if(!filteredArtists && searchString ){
            return <p>No Artists Found!</p>
        }else{
            return loading ? <ArtistSkeleton /> : <Artists artists={artists} />
        }
   }


   const pageCount =  genre !== 'all' ? Math.ceil(allArtists.filter((s)=> s.categories[genre] === true).length / 15) : Math.ceil(allArtists.length / 15);

   return(
      <div id="discover">
            {userSettings.mobile && <DiscoverHeader /> }
            <div id="discover_content">
               <div className="discover_content__wrap discover_content__wrap-all discover_artists">
                  <div className="tracks_wrap">
                        {renderHeader()}
                        <div className="trackScroll_wrap trackScroll_wrap--pagination customScrollbar">
                           <div>{renderArtists()}</div>
                        </div>
                  </div>
                  {!uid ?
                           <OverlayScreen 
                           className="allArtist_block" 
                           title="Please Login" 
                           message={`login/Create an Account to access all ${allArtists.length} Artists and their ${allTracksCount} Popular Songs.`}
                           action="login"
                           button="Login"
                           />
                  :
                        (pageCount > 0 && <div className="tracks__pagination">
                           <ReactPaginate previousLabel={<Icon icon="ios-arrow-back" fontSize="20px" color="#ffffff"/>}
                           nextLabel={<Icon icon="ios-arrow-forward" fontSize="20px" color="#ffffff"/>}
                           breakLabel={'...'}
                           forcePage={page}
                           breakClassName={"break-me"}
                           pageCount={pageCount}
                           marginPagesDisplayed={2}
                           pageRangeDisplayed={userSettings.mobile ? 1 : 5 }
                           onPageChange={handlePageClick}
                           containerClassName={"pagination"}
                           subContainerClassName={"pages pagination"}
                           activeClassName={"active"} />
                        </div>)
                  }
            </div>
      </div>
      </div>
   );

}

export default AllArtists;