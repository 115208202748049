import { useDispatch, useSelector } from "react-redux";
import DiscoverHeader from "../components/Discover/DiscoverHeader";
import TracksHome from "../components/Discover/TracksHome";
import { setDiscoverListView } from "../redux/userSlice";
import Icon from "../components/Icon/Icon";

const DiscoverHome = () => {
   const dispatch = useDispatch();
   const userSettings = useSelector((state) => state.user);
   const listView = userSettings.discoverListView;
   
   return (
      <div id="discover" className="discover--latest">
         {userSettings.mobile && <DiscoverHeader showListUI={true} /> }
         {!userSettings.mobile && 
            <button className={`listViewBtn ${listView ? 'listViewBtn--active':'' }`} data-tip="List View" onClick={()=> dispatch(setDiscoverListView(!listView))}>
               <Icon icon="md-list" fontSize="16px" color="#ffffff"/>
            </button>
         }
         <div id="discover_content">
            <TracksHome />
         </div>
    </div>
   );
};

export default DiscoverHome;