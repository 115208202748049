import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../Modal/Modal';
import { updatePlaylist } from '../../redux/playlistSlice';
import playlistCategories from '../../utils/genres';
import { createSlug } from '../../helpers';
import SelectField from '../SelectField/SelectField';

const PlaylistEdit = (props) => {
   const { width, show:showModal, onClose, playlist, playlistsMin, playlistID } = props;
   const dispatch = useDispatch();
   const [title, setTitle] = useState('');
   const [playlistStatus, setPlaylistStatus] = useState('public');
   const [category, setCategory] = useState('mixed');
   const [show, setShow] = useState(false) 
   const itemsWidth = width || 380 ;
   const selectedCat = category ? playlistCategories.find((plist)=>  plist.id === category ) : {id:'mixed',name:'Mixed'} ;

   useEffect(()=> {
      if (playlist && (playlist && playlist.title)) {
         setTitle(playlist.title);
         setPlaylistStatus(playlist.status);
         setCategory(playlist.category);
         setShow(showModal);
      }
      if (show!== showModal) {
         setShow(showModal);
      }
   }, [show, showModal, playlist])

   const submitUpdatedPlaylist = (event)=> {
        event.preventDefault();
        const songsCount = playlist.songs && Object.keys(playlist.songs).length > 0? Object.keys(playlist.songs).length  : 0
        let updatedPlaylist = {...playlist, count: songsCount, id: playlistID, title: title, status: playlistStatus, category: category}
        
        if(playlist.title !== title){
            const slug = createSlug(title, playlistsMin);
            updatedPlaylist.slug = slug
        }

        delete updatedPlaylist.songs;
        if(updatedPlaylist){
            dispatch(updatePlaylist(playlistID, updatedPlaylist));
            setShow(false)
            onClose();
        }
        
   }

   const onTitleChange = (event) => setTitle(event.target.value)
   const onModalClose = () => { setShow(false); onClose(); }

   return(
      <Modal title={`Edit Playlist - ${title}`} show={show} maxWidth={itemsWidth} closeModal={onModalClose}>
            <form onSubmit={submitUpdatedPlaylist} className="playlist_edit_form">
               <label>
                  <span>Title</span>
                  <input type="text" placeholder="Playlist Title" value={title} onChange={onTitleChange} />
               </label>
               
               <label>
                  <span>Visibility</span>
                  <SelectField 
                  style={{ width: itemsWidth - 50 }} 
                  options={[{ label: 'Public', value: 'public' }, { label: 'Private', value: 'private' }]}
                  value={playlistStatus}
                  onChange={(value)=> setPlaylistStatus(value)}
                  showSearch={false}
                  />
               </label>

               <label>
                  <span>Category</span>
                  <SelectField 
                  style={{ width: itemsWidth - 50 }} 
                  options={playlistCategories.map((category)=> ({ label: category.name, value: category.id }) )}
                  value={selectedCat?.id}
                  onChange={(value)=> setCategory(value)}
                  showSearch={false}
                  />
               </label>
               <button>Update Playlist</button>
            </form>
      </Modal>
   );

}

export default PlaylistEdit;
