import React, {useState, useEffect, useRef} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link  } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useDispatch } from 'react-redux';
import AccountSettings from './AccountSettings';
import AppSettings from './AppSettings';
import {uploadImage, dataURItoBlob} from '../../helpers';
import { checkRequiredFields, checkUniqueEmail } from '../../utils/validation';
import user_m from '../../assets/user_m.png';
import { storage } from '../..';
import {updateUser} from '../../redux/userSlice'
import Icon from '../Icon/Icon';

//import user_f from '../../assets/user_f.png';


const UserSettings = ({user, push, firebase, userSettings}) => {
    const settingsForm = useRef(null);
    const dispatch  = useDispatch();
    const [uploadError, setUploadError] = useState('');
    const [loadingPhoto, setLoadingPhoto] = useState(false);
    const [error, setError] = useState([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
      if(!user){
         push('/login');
      }
    }, [user, push])

   const onSaveSettings = (event) => {
        event.preventDefault();
        let totalErrors = [];

        const formData = new FormData(settingsForm.current);
        const fullname = formData.get('fullname');
        const nofollow = formData.get('nofollow');
        const email = formData.get('email');
        const theme = formData.get('theme');
        const bio = formData.get('bio');
        const privateacc = formData.get('privateacc');
        const prefrences = formData.get('prefrences');

        const allfields = [ {label : 'fullname', value: fullname}, {label: 'email', value: email} ]
        checkRequiredFields(allfields).then((fieldsEmpty)=> {
            if(fieldsEmpty.length > 0){
                return totalErrors = [...totalErrors , 'Please Fill Up the Required Fields'];
            }
        }).then((fieldsEmpty)=> {
            if(fieldsEmpty > 0){
                return setError(totalErrors);
            }else{
                if(user.email !== email){
                    return checkUniqueEmail(email.trim()).then((notUniqueEmail)=> {
                        return notUniqueEmail ? totalErrors = [...totalErrors , notUniqueEmail] :'' ;
                    });
                }else{
                    return
                }
            }
        }).then(()=> {
            if(totalErrors.length > 0){
               setError(totalErrors);
                console.log('Found Error!', error, totalErrors);
            }else{
                console.log('No Error! Success!');
                setError([]);
                setSaving(true);

                if(user.email !== email){
                  const user = firebase.auth().currentUser;
                  user.updateEmail(email).then(()=> {
                     console.log('Changed Email!') 
                  }).catch((error)=> console.log(error) );
                }
                firebase.firestore().collection("Users").doc(user.id).update({ 
                     fullname: fullname,
                     bio: bio,
                     settings:{
                        nofollow: nofollow && nofollow ? nofollow : false,
                        private: privateacc && privateacc ? privateacc : false,
                        prefrences: prefrences && prefrences ? prefrences : 'pop',
                        theme: theme && theme ? theme : 'dark'
                     }
                }).then((data)=> {
                    console.log('##Data Saved!', data);
                    setSaving(false);
                }).catch((err)=>{
                     console.log('##Data Save Error: ', err);
                });
            }

        });


        // let requiredError = false;
        // let totalErrors = [];
        // if(this.checkRequired('fullname', fullname) === true) requiredError = true;
        // if(this.checkRequired('email', email) === true) requiredError = true;
        // if(requiredError){ totalErrors.push('Please Fill Up the Required Fields');}

    }


   // const checkRequired = (fieldName, value) => {
   //      if(!value){  
   //          document.querySelector('.label_'+fieldName).classList.add('error'); 
   //          return true;
   //      }else{
   //          document.querySelector('.label_'+fieldName).classList.remove('error');
   //          return false;
   //      }
   //  }

   const handleImageUpload = () => {
        var selectedImage = document.getElementById('uploadPhoto').files[0];
        
        if(selectedImage.type.match(/image.*/)) {
            uploadImage(selectedImage, async (result) =>{
                //console.log('retuedn image', result);
                console.log(selectedImage);

                var small_file = new File([dataURItoBlob(result)], selectedImage.name, {'type':'image/jpeg'});
                console.log('##Small Image: ',small_file);
                const extension = selectedImage.name.split('.').pop();
                const UID =  firebase.auth().currentUser.uid;

                //Upload the Profile Picture
                setLoadingPhoto(true);

                try {
                  const fileName = 'avatars/'+UID+'_large.'+extension;
                  const storageRef = ref(storage, fileName );
                  await uploadBytes(storageRef, selectedImage);
                  const imageURL = await getDownloadURL(ref(storage, fileName));
                  dispatch(updateUser({photoURL: imageURL}));
                  console.log(imageURL);
                } catch (error) {
                  console.log('Upload Error', error);
                  setUploadError('Upload Error! Try Again.');
                }

                try {
                  const fileNameSmall = 'avatars/'+UID+'_small.'+extension;
                  const storageRefSmall = ref(storage, fileNameSmall );
                  await uploadBytes(storageRefSmall, small_file);
                  const imageURL = await getDownloadURL(ref(storage, fileNameSmall));
                  dispatch(updateUser({avatarUrl: imageURL}));
                  console.log(imageURL);
                } catch (error) {
                  console.log('Upload Error', error);
                  setUploadError('Upload Error! Try Again.');
                }

                setLoadingPhoto(false);

            });   
        }else{
            console.log('Not an Image File. Please select a png, jpg or gif file');
        }
    }
    
   return(
      <div id="UserSettings" className={saving ? 'UserSettings__saving' : ''}>

            <div className="UserSettings__header">
               <div className="profile_photo">
               
                  {loadingPhoto && <div className="loading__file"><Icon icon="ios-sync" rotate={true} fontSize="24px" color="#ffffff"/></div>}
                  {
                        user.avatarUrl ? <img className="pp" src={user.avatarUrl} alt="My Profile" />
                  :
                        <img className="pp" src={user_m} alt="My Profile" />
                  }

                  <label className="fileContainer">
                        <Icon icon="md-create" fontSize="16px" color="#fff"/>
                        <input onChange={()=> handleImageUpload()} id="uploadPhoto" type="file" name="profile" accept="image/*" />
                  </label>
                  <label className="publicProfileLink" data-tip="View Your Public Profile">
                        {user && user.username && 
                           <Link to={`/user/${user.username }/`}>
                              <Icon icon="md-person" fontSize="12px" color="#d9d9df"/>
                           </Link>
                        }
                  </label>
                  {uploadError && <div className="userSettings__form__error">
                     <p className="errorMsg">{uploadError}</p>
                  </div>}
               </div>
               {user.fullname && <h4><Link to={`/user/${user.username }/`}>{user.fullname}</Link></h4>}
            </div>

               <Tabs forceRenderTabPanel={true}>
                  <TabList>
                        <Tab>Settings</Tab>
                        <Tab>Account</Tab>
                  </TabList>

            
                  <form id="userSettings__form" onSubmit={onSaveSettings} ref={settingsForm} >
                  {saving && <div className="saving"><Icon icon="ios-sync" rotate={true} fontSize="38px" color="#ffffff"/></div>}
                     
                        <div className="userSettings__form__error">
                           {error.length > 0 && error.map( (error, idx)=> <p key={idx} className="errorMsg">{error}</p> )}
                        </div>
                        
                        <TabPanel>
                           <div className="tab_settings">
                              <AppSettings user={user} userSettings={userSettings} />
                           </div>
                        </TabPanel>
                        <TabPanel>
                           <div className="tab_account">
                              <div className="userScroll_wrap customScrollbar">
                                    <div><AccountSettings user={user} full={true} /></div>
                              </div>
                           </div>
                        </TabPanel>
                        
                        <div className="submit__wrap">
                           <button className="profile_update_button" aria-label="Save" type="submit">Save Settings</button>
                        </div>
                        
                  </form>

               </Tabs>
            
      </div>
      
   );
}

export default UserSettings;