import React from 'react';
import ReactPlayer from 'react-player';
import isEqual from 'lodash.isequal';
import axios from 'axios';
import StationControl from './StationControl';
import SoundCloudCover from '../Player/SoundCloudCover';
import UserMalePic from '../../assets/user_m.png';
import { soundcloudApis } from '../../config';
import Icon from '../Icon/Icon';


class StationPlayer extends React.PureComponent{


    //Auto play next song in the playlist
    //If repeat is on, start from beginning


    constructor(props){
        super(props);
          this.state = {
            url: '',
            played:0,
            index:0,
            playing: false,
            volume: props.volume ? props.volume :  0.8,
            muted: false,
            currentSong:{},
            currentUser:{},
            maxScreen: false,
            mobilehideVideo: true,
        }
    
    }

    componentWillReceiveProps(nextProps){
        // if(nextProps.stationSongs){

        // }
        if(isEqual(this.props.currentSong, nextProps.currentSong) && isEqual(this.props.stationInfo, nextProps.stationInfo) && isEqual(this.props.users, nextProps.users)){
            //console.log(this.props.currentSong, nextProps.currentSong);
            return;
        }
        if(nextProps.currentSong && nextProps.stationSongs && (nextProps.currentSong.youtube || nextProps.currentSong.soundcloud)){

            // if(!this.props.currentSong && nextProps.currentSong){
            //     console.log('@@@@NO CURRENT SONG!! SHOW LOADING ICON');
            // }
            let url= '';
            if(nextProps.currentSong.youtube){ url= 'https://www.youtube.com/watch?v='+nextProps.currentSong.youtube; }

            if((this.props.currentSong && (nextProps.currentSong.id !== this.props.currentSong.id)) || (!this.props.currentSong && nextProps.currentSong) ){
                console.log('@@@@NEW SONG!! SHOW LOADING ICON', nextProps.currentSong);
                this.setState({playing: false, loading: true});
                if(nextProps.currentSong.soundcloud && nextProps.currentSong.soundcloudID){
                    const sdcApi = soundcloudApis.split(',');
                    const randomNum = Math.floor(Math.random() * 39) + 0;
                    const sdcApikey = sdcApi[randomNum];
                    //console.log('@@@GETCHING SOUNDCLOUD SONG URL!!!');
                    axios.get(`https://api.soundcloud.com/tracks/${nextProps.currentSong.soundcloudID}/streams?client_id=${sdcApikey}`).then((results)=>{
                        //console.log('@@@@@SOUNDCLOUD SONG URLS: ', results.data.http_mp3_128_url);
                        url= results.data.http_mp3_128_url;
                    })
                    
                }
            }
            if((this.props.currentSong && this.props.currentSong.soundcloudID && (nextProps.currentSong.id === this.props.currentSong.id)) && this.state.url ){
                console.log('@@@NEW SONG AND CURRENT SONG IS SAME SOUNDCLOUD SONG, SET TO CURRENT STATE URL!!!');
                url = this.state.url;
            }
            
            setTimeout(() => {
                const index = nextProps.stationSongs.findIndex(x => x.id === nextProps.currentSong.id);

                this.setState({
                    currentSong: nextProps.currentSong, 
                    url:  url, 
                    index: index,
                    playing: nextProps.currentSong.playing ? true: false,
                    loading: false
                });

                console.log('@@@@SET CURRENT SONG IN PLAYER!!!!', nextProps.currentSong, index);
            }, 1600);


        }

        if(nextProps.currentSong && nextProps.currentSong.playing && this.state.currentSong){
            console.log('CurrentSong is set to PLAYING!!, PLAYING SONG');
            this.setState({playing: true});
        }
        if(nextProps.currentSong && nextProps.currentSong.playing === false && this.state.currentSong){
            console.log('CurrentSong is set to PAUSE!!, PAUSING SONG');
            this.setState({playing: false});
        }


        //When Song is paused and then played, seek to current time of the host to maintain precision
        if(this.props.currentSong && nextProps.currentSong && this.props.currentSong.playing === false && nextProps.currentSong.playing === true){
            this.stationPlayer.seekTo(this.props.getCurrentTime());
        }

        if(this.props.currentSong && !nextProps.currentSong){
            console.log('Playlist Ended! Clear Current Song in Stationplayer.');
            //this.setState({currentSong:{}, playing: false, url:'', played:0, index:0});

            setTimeout(() => {
                if(!nextProps.currentSong){
                    this.setState({currentSong:{}, playing: false, url:'', played:0, index:0});
                }
            }, 2000);
        }
        
    }

    ref = player => {
        this.stationPlayer = player
    }

    onPlay = () => {
        console.log('On Play!');
        // if(this.state.currentSong.soundcloud && this.state.currentSong.soundcloudID && !this.state.currentSong.url){
        //     this.setState({url:`https://api.soundcloud.com/i1/tracks/${this.state.currentSong.soundcloudID}/streams?client_id=CoeTA81rlM4PNaXs33YeRXZZAixneGwv`})
        // }
    }

    onStart = () => {
        console.log('onStart', this.props.getCurrentTime());
        if(this.props.isOwner){ return }

        if(!this.props.isOwner && this.props.getCurrentTime()){
            setTimeout(() => {
                console.log('@@@onStart 2', this.props.getCurrentTime());
                this.stationPlayer.seekTo(this.props.getCurrentTime() + 1.6);
            }, 1000);
            
        }
        
    }

    onPause = () => {
        console.log('onPause', this.state.playing)
        //IF IN MOBILE, When the screen is locked, the music stops. This plays the audio after a second
          if(this.state.playing && this.props.mobile && !this.props.stationInfo.pauseInfo){
            this.setState({ playing: false });
            setTimeout(() => {
              this.setState({ playing: true })
            }, 200);
          }
      }

    onProgress = (state) => {
        if(this.state.playing){
            this.setState({played: state.playedSeconds});
        }
        
        if(this.props.isOwner && this.props.trueOwner && this.state.playing){
            this.props.setPlayedSeconds(state.playedSeconds) 
        }

        if(this.state.currentSong.duration - 2 <= state.playedSeconds ){
            //console.log('#onProgress: SONG ENDED!');
            if(this.props.isOwner && this.props.trueOwner){
                const nextIndex = this.state.index + 1;
                this.props.setPlayedSeconds(0);
                if(this.props.stationSongs[nextIndex]){
                    console.log('Playing Next Song!', this.props.stationSongs[nextIndex], nextIndex);
                    this.props.setCurrentSong(this.props.stationSongs[nextIndex]);
                }else{
                    if(this.props.stationInfo && this.props.stationInfo.repeat){
                        console.log('Player is set to Repeat! Start from the beginning of the Playlist!')
                        this.setState({playing: false, played:0})
                        this.props.setCurrentSong(this.props.stationSongs[0]);
                        
                    }else{
                        this.props.setCurrentSong(null);
                        this.setState({playing: false, played:0})
                    }

                }
            }
        }

    }

    onEnded = () => {
        console.log('onEnded', this.state.index);


    }

    playPause = ()=> {
        if(!this.props.isOwner){ return }
        if(!this.props.currentSong && this.props.stationSongs.length > 0){
            this.props.setCurrentSong(this.props.stationSongs[0]);
            this.props.playSong();
            this.setState({playing: true});
        }

        if(this.state.playing){
            console.log('#################### CLICKED PAUSE!!!');
            this.setState({playing: false});
            this.props.pauseSong();
        }else{
            this.setState({playing: false});
            this.props.playSong();
        }
        
    }
    
    onError = (e)=> {
        console.log('onError', e);
        //this.searchYoutube();
    }

    setVolume = e => {
        const vol = parseFloat(e.target.value);
        if(vol === 0){
            this.setState({ muted: true, volume: vol }, ()=> this.props.playerVolume(vol) )
        }else{
            this.setState({ muted: false, volume: vol }, ()=> this.props.playerVolume(vol) );
        }
    }

    onClickMaxScreen = () => {
        this.setState({maxScreen: !this.state.maxScreen})
    }

    onClickFullscreen = () => {
        const stationPlayer = document.getElementById('react-player__station');
        stationPlayer.requestFullscreen();
    }

    loveSong = () => {
        if(this.state.currentSong.likes && this.state.currentSong.likes[this.props.currentUser]){
            console.log('User already liked song');
            
        }else if (this.state.currentSong.addedBy === this.props.currentUser){
            console.log('You cannot like your own song');
            this.setState({error: 'You cannot like your own song.'})
        }else{
            console.log('Like the song');
            this.props.likeStationSong(this.state.currentSong.id, this.state.currentSong);
        }
    }

    

    render(){
        const { volume, muted } = this.state;
        const currentSongwithLikes = this.state.currentSong && this.props.stationSongs ? this.props.stationSongs.filter(song => song.id === this.state.currentSong.id) : [];

        return(
            <div className={`station__player ${this.state.maxScreen ? 'station__player--max' : ''}`}>

                <div className={`station__player__wrap ${this.state.mobilehideVideo ? 'station__player__wrap--hidden':''}`}>
                    <div className="station__player__inner">
                        <div className="station__player__block">
                            {this.props.isOwner && this.state.currentSong &&
                                <div className="station__player__block__play" onClick={this.playPause}>
                                    {this.state.playing &&<div className="station__player__block__pauseIcon"><span /><span /></div>}
                                </div>
                            }
                        </div>
                        {!this.state.playing && this.state.currentSong && this.props.stationInfo && this.props.stationInfo.pauseInfo &&
                            <div className="station__player__block__paused">
                                <p>Paused by <img src={this.props.stationInfo.pauseInfo.avatarUrl ? this.props.stationInfo.pauseInfo.avatarUrl : UserMalePic } alt={this.props.stationInfo.pauseInfo.username} /> {this.props.stationInfo.pauseInfo.username}</p>
                                {this.state.currentSong.youtube && <img src={`https://i.ytimg.com/vi/${this.state.currentSong.youtube}/default.jpg`} alt="Station paused" />}
                                {this.state.currentSong.soundcloud && <img src={this.state.currentSong.cover} alt="Station paused" />}
                                {this.state.currentSong.soundcloud && !this.state.currentSong.cover && <SoundCloudCover title={this.state.currentSong.title} playing={this.state.playing} blur={true} />}
                                {this.props.isOwner && <div className="station__player__block__playIcon" onClick={this.playPause}>&#9658;</div>}
                            </div>
                        }
                        {this.state.loading && <div className="station__player__loading"><Icon icon="ios-refresh" fontSize="40px" color="#8f95a5" rotate={true} /></div>}
                        
                        {this.state.currentSong.soundcloud && this.state.currentSong.cover && 
                            <div className="soundcloud__cover">
                                <span><Icon icon="ios-cloud-circle" fontSize="14px" color="#ffffff"/> SoundCloud</span>
                                <img src={this.state.currentSong.cover} alt={this.state.currentSong.soundcloud.title} />
                                <img className="soundcloud__cover__bg" src={this.state.currentSong.cover} alt={this.state.currentSong.soundcloud.title} />
                            </div>
                        }
                        {this.state.currentSong.soundcloud && !this.state.currentSong.cover && <SoundCloudCover title={this.state.currentSong.title} playing={this.state.playing} />}

                        <ReactPlayer 
                        ref={this.ref}
                        url={this.state.url}
                        playing={this.state.playing}
                        className='react-player'
                        id="react-player__station"
                        config={{ youtube: { preload: true, playerVars: { rel: 0 } },  file: {  attributes: { poster: this.state.currentSong && this.state.currentSong.youtube && `https://i.ytimg.com/vi/${this.state.currentSong.youtube}/hqdefault.jpg`  } }  }}
                        volume={volume}
                        muted={muted}
                        //onStateChange={(state) => console.log('State: ',state)}
                        //onReady={this.onReady}
                        onStart={this.onStart}
                        onPlay={this.onPlay}
                        onPause={this.onPause}
                        onBuffer={() => console.log('onBuffer')}
                        onSeek={e => console.log('onSeek', e)}
                        onEnded={this.onEnded}
                        onError={this.onError}
                        onProgress={this.onProgress}
                        // onDuration={this.onDuration}
                        width='100%'
                        height='100%'
                        rel={0}
                        />
                        
                        <button className="channelMaxScreen__btn" onClick={this.onClickMaxScreen}><Icon icon="md-resize" fontSize="16px" color="#ffffff"/></button>
                        <button className="channelFullScreen__btn" onClick={this.onClickFullscreen}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M16 16h-8v-8h8v8zm-10-7.172v-2.828h2.828l-3.414-3.414 2.586-2.586h-8v8l2.586-2.586 3.414 3.414zm2.828 9.172h-2.828v-2.828l-3.414 3.414-2.586-2.586v8h8l-2.586-2.586 3.414-3.414zm9.172-2.828v2.828h-2.828l3.414 3.414-2.586 2.586h8v-8l-2.586 2.586-3.414-3.414zm-2-15.172l2.586 2.586-3.414 3.414h2.828v2.828l3.414-3.414 2.586 2.586v-8h-8z"/></svg>
                        </button>
                    </div>

                    <div className="player__nowplaying">
                        {this.state.currentSong.id &&
                            <div className="player__nowplaying__songInfo">
                                <div className="player__nowplaying__songInfo__details">
                                    <span className="player__songInfo__count">[{this.state.index + 1}/{ this.props.stationSongs ? this.props.stationSongs.length : 1}]</span>
                                    <span className="player__songInfo__title">
                                        {this.state.currentSong.title} 
                                    </span>
                                    {this.state.currentSong.addedBy &&
                                        <div className="player__songInfo__artist">
                                            <div className="player__songInfo__artist__avatar">
                                                <img src={this.state.currentSong.addedByInfo && this.state.currentSong.addedByInfo.avatarUrl ? this.state.currentSong.addedByInfo.avatarUrl : UserMalePic } alt={this.state.currentSong.addedBy} />
                                            </div>
                                            {this.state.currentSong.addedBy}
                                        </div>
                                    }                                  
                                </div>
                                {this.state.maxScreen && currentSongwithLikes[0] &&
                                    <div className="player__nowplaying__songInfo__actions">
                                        <div className="song__actions__love__wrap">
                                            <button className="song__actions__love" onClick={this.loveSong}>
                                                <Icon icon="ios-heart" fontSize="20px" color="#ffffff"/> {currentSongwithLikes[0].likes ? Object.keys(currentSongwithLikes[0].likes).length : 0}
                                            </button>
                                            {currentSongwithLikes[0].likes &&
                                                <div className="song__actions__love__likes">
                                                    {Object.keys(currentSongwithLikes[0].likes).map(user => <div key={`${user}__liked`}><img src={currentSongwithLikes[0].likes[user] ? currentSongwithLikes[0].likes[user]: UserMalePic } alt={user} /> {user}</div>)}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                </div>

                {!this.state.playing && this.state.currentSong && this.props.stationInfo && this.props.stationInfo.pauseInfo && this.props.mobile &&
                    <div className="stationPuseInfo__mobile">
                        <p>Paused by {this.props.stationInfo.pauseInfo.username}</p>
                    </div>
                }

                <StationControl 
                showLibraryModal={this.props.showLibraryModal} 
                showAddSongModal={this.props.showAddSongModal} 
                playlists={this.props.playlists} 
                isOwner={this.props.isOwner} 
                importPlaylist={this.props.importPlaylist}
                currentSong={this.props.currentSong}
                volume={this.state.volume}
                setVolume={this.setVolume}
                playPause={this.playPause}
                playing={this.state.playing}
                played={this.state.played}
                setStationRepeat={this.props.setStationRepeat}
                repeat={this.props.stationInfo && this.props.stationInfo.repeat ? this.props.stationInfo.repeat : false}
                muted={this.state.muted}
                setMuted={()=> this.setState({muted: !this.state.muted})}
                addSationSong={this.props.addSationSong}
                mobile={this.props.mobile}
                hideVideo={(bool)=> this.setState({mobilehideVideo: bool})}
                />

        </div>
        );
    }
}

export default StationPlayer;