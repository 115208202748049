import AppNew from "./components/appn/index";
import { useFirebaseApp, AuthProvider } from "reactfire";
// import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";


const ProviderLayer = () => {
    //reactfire useapp hook
    const app = useFirebaseApp();

    //firebase SDK setup
   //  const db = getDatabase (app);
    const auth = getAuth(app);
    
    
    return(
    <AuthProvider sdk={auth}>
         <AppNew />
    </AuthProvider>
     
    )
}

export default ProviderLayer