import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useSwipeable } from 'react-swipeable';
import { useLongPress } from 'use-long-press';
import UserMalePic from '../../assets/user_m.png';
import { formatDuration, throttle } from '../../helpers';
import SelectField from '../SelectField/SelectField';
import Icon from '../Icon/Icon';

const StationSong = (props) =>{
   const { isOwner, songPlaying, currentUser } = props;

   const {addSong, likeStationSong, pauseSong,  removeSationSong } = props;

   const { selectedSongs, reorder, mobileSelectedSong, mobileSelectSong, queue, songID, song, playlistsMin, playSong,  activeSong, draggingSong, order, isMobile, moveSong, onDragStart, style={} } = props;

   //  const dispatch = useDispatch();
    const songRef = useRef(null); 
   //  const [cover, setCover] = useState('');
   //  const [error, setError] = useState('');
    const [playlistSelect, setPlaylistSelect] = useState('');
    const [swipeRight, setSwipeRight] = useState(false);
    const [swipeLeft, setSwipeLeft] = useState(false);
    const [press, setPress] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    
   //  componentDidMount = () => {
   //      this.props.connectDragPreview(getEmptyImage(), {
	// 		captureDraggingState: true,
	// 	})
   //  }

   //  componentWillReceiveProps(nextProps){
   //      if (!isDragging && nextProps.isDragging) {
   //          console.log(nextProps);
   //          this.props.onDragStart(nextProps.item);
   //      }
   //  }

   
   const [{ isDragging }, drag, dragPreview] = useDrag(
      () => ({
         type: 'song',
         // item: { title: song.title, id: song.id, originalIndex: index },
         collect: (monitor) => ({
            isDragging: monitor.isDragging(),
         }),
         item: (dropResult, monitor) => {
            console.log('DragStart');
            draggingSong(queue ? 'queue' : 'playlist');
            const draggedSong = { ...song };
            let songs;
            if (selectedSongs.find(song => song.id === songID)) {
               songs = selectedSongs;
            } else {
               songs = [draggedSong];
            }
            const otherSongs = songs.concat();
            otherSongs.splice(songs.findIndex(c => c.id === songID), 1);
            const songsDragStack = [draggedSong, ...otherSongs];
            const dragItem = { 
               songs, 
               songsDragStack, 
               draggedSong, 
               id: songID, 
               song: {...song}, order: order 
            }
            console.log(dragItem, songsDragStack);
            onDragStart(dragItem);
            return dragItem;
         },
         end: (dropResult, monitor) => {
            const { id: droppedId, songs, draggedSong } = monitor.getItem();
            const didDrop = monitor.didDrop();
            console.log('Drag End: ', droppedId, didDrop, songs, draggedSong);
            if (didDrop) {
               console.log('Drop Ended!!', queue);
               reorder(queue);
               draggingSong('');
            }else{
               setTimeout(() => {
                  draggingSong('');
               }, 1000);
               return;
            }
         }
      }),
      [],
    );
   // eslint-disable-next-line no-unused-vars
    const [{ isOver, isOverCurrent }, drop] = useDrop(
      () => ({
        accept: 'song',
        hover: throttle((_item, monitor) => {
            if (!songRef.current) { return;  }
            const dragId = monitor.getItem().id;
            const hoverId = props.songID;
            const dragOrder = monitor.getItem().order;
            const hoverOrder = props.order;
      
            if (dragId === hoverId) {
               return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = songRef.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragOrder < hoverOrder && hoverClientY < hoverMiddleY) {
               return
            }
            if (dragOrder > hoverOrder && hoverClientY > hoverMiddleY) {
               return
            }
            console.log('fire Move Song!');
            moveSong(dragId, hoverId);
        }, 100),
        drop(_item, monitor) {
          const didDrop = monitor.didDrop()
          if (didDrop) {
            return
          }
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          isOverCurrent: monitor.isOver({ shallow: true }),
        }),
      }),
    );

   useEffect(() => {
      dragPreview(getEmptyImage(), { captureDraggingState: true })
    }, [dragPreview]);


   const copySong = () => {
        addSong(playlistSelect, song);
        setTimeout(()=> {
            setShowAddModal(false);
        }, 500);

    }

   const loveSong = () => {
        if(song.likes && song.likes[currentUser]){
            console.log('User already liked song');
            
        }else if (song.addedBy === currentUser){
            console.log('You cannot like your own song');
            // setError('You cannot like your own song.');
        }else{
            console.log('Like the song');
            likeStationSong(songID, song);
        }
    }

   const playTheSong = ()=>{
        if(!isOwner){ return }
        playSong(song)
    }

   const swipeSongRight = () => {
        if(!isOwner){ return }
        setSwipeRight(true);
        setTimeout(() => {
            playSong(song);
            setSwipeRight(false);
        }, 500);
    }
    const swipeSongLeft = () => {
      if(!isOwner){ return }
      if(queue){ return }
      setSwipeLeft(true);
      setTimeout(() => {
            setSwipeLeft(false);
            // setMessage('Song Added to Queue')
            // dispatch(addToQueue({song, queue:'queueOne', play: false}));
            
      }, 500);

      // setTimeout(() => {
      //       setMessage(null);
      // }, 1800);
   }

   const swipeHandlers = useSwipeable({
      onSwipedLeft: (eventData) => isMobile && swipeSongLeft(),
      onSwipedRight: (eventData) => isMobile && swipeSongRight(),
   });

   const longPressHandler = useLongPress(() => {
      if(!isOwner){ return }
      if(isMobile){
         console.log('Long pressed!');
         setPress(true);
         setTimeout(() => {
               setPress(false);
               mobileSelectSong(song);
               //addToQueue(song, 'queueOne', false);
         }, 500);
      }

   });

   const removeSong = () => { removeSationSong(songID, song); setShowRemoveModal(false);  setShowMore(false); }
   const showSongAddModal = () => { setShowAddModal(true); setShowRemoveModal(false); }
   const hideAddModal = () => { setShowAddModal(false); setShowMore(false); }
   const showTheRemoveModal = () => { setShowAddModal(false); setShowRemoveModal(true); }
   const hideRemoveModal = () =>  setShowRemoveModal(false);

   drag(drop(songRef));
   
   const confirmIcon = <Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/>;
   const cancelIcon = <Icon icon="md-close" fontSize="18px" color="#ffffff"/>;
   let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
   if(playlistsMin && playlistsMin.length > 0){ queuePlaylists = [...queuePlaylists, ...playlistsMin]  }
   const copySelectedPlaylist = queuePlaylists.filter((plist)=>  plist['id'] === playlistSelect  );
   let selectedAction = '';
   if(showAddModal){ selectedAction = 'song__actions-add'; }
   if(showRemoveModal){ selectedAction = 'song__actions-delete'; }
   const opacity = isDragging ? 0 : 1;
   const zIndex = isDragging ?  5 : 1;
   const dragClass = !isMobile && isDragging ?  'songDragging' : '';
   const songLiked = song.likes && song.likes[currentUser] ? 'song--liked': '';
   const active = activeSong ? 'song--active': '';

   const mobileSelected = mobileSelectedSong && mobileSelectedSong.id === songID ? 'song--mobileSelected' :'';
   const mobileSelectedIcon = mobileSelectedSong && mobileSelectedSong.id === songID && <div className="song__selected__icon"><Icon icon="md-checkmark" fontSize="16px" color="#ffffff"/></div>;
   //const songSelected = this.props.selectedSong === songID ?  'songSelected': '';

   return(
      <div 
      id={song.youtube} 
      ref={songRef} 
      className={`song ${showMore ? 'selectedSong':''} ${mobileSelected} ${swipeRight ? 'swipeAnim':''} ${swipeLeft ? 'swipeAnimLeft':''} ${press ? 'pressAnim':''} ${songLiked} ${dragClass} ${active} ${swipeRight ? 'swipeAnim':''}`} 
      style={{ zIndex, opacity,position:'absolute', ...style }} 
      onDoubleClick={playTheSong} >
         <div className='songPresser' {...swipeHandlers} {...longPressHandler()}>
            <div className="song_inner">

                  <div className="song__album">
                     {mobileSelectedIcon}
                     {/* <img id={`img-${song.youtube}`} src={`https://i.ytimg.com/vi/${song.youtube}/default.jpg`} alt={song.title} /> */}
                     <img src={song.addedByInfo && song.addedByInfo.avatarUrl ? song.addedByInfo.avatarUrl: UserMalePic} alt={song.addedBy} />
                  </div>

                  <div className={`song__details`}>
                     <span className="song__title">{song.title}</span>
                     {/* {song.artist  && <span className="song__artist">{song.artist}</span>} */}
                     {song.addedBy  && <span className="song__artist">{song.addedBy}</span>}
                  </div>

                  <div className={`song__actions ${selectedAction ?'song__actions-active '+ selectedAction : ''}`}>

                     {isOwner && !activeSong && <button className="song__actions__play" aria-label="Play" onClick={playTheSong}>&#9658;</button>}
                     
                     {isOwner && songPlaying && activeSong && <button className="song__actions__play song__actions__pause" aria-label="Pause" onClick={()=> pauseSong() }><span /><span /></button> }
                     {isOwner && !songPlaying && activeSong && <button className="song__actions__play" aria-label="Play" onClick={playTheSong}>&#9658;</button>}


                     <div className="song__actions__love__wrap">
                        <button className="song__actions__love" onClick={loveSong}>
                              <Icon icon="ios-heart" fontSize="16px" color="#ffffff"/> {song.likes ? Object.keys(song.likes).length : 0}
                        </button>
                        {song.likes &&
                              <div className="song__actions__love__likes">
                                 {Object.keys(song.likes).map(user => <div key={`${user}__liked`}><img src={song.likes[user] ? song.likes[user]: UserMalePic } alt={user} /> {user}</div>)}
                              </div>
                        }
                     </div>
                     
                     <button className="song__actions__more" onClick={()=> setShowMore(!showMore)}>&#8226;&#8226;&#8226;</button>
                     

                     {showMore &&
                        <div className="song__actions__all">
                              <button className="song__actions__add" onClick={showSongAddModal}><Icon icon="md-add" fontSize="14px" color="#ffffff"/> Copy Song</button>
                              {isOwner && <button className="song__actions__delete" onClick={showTheRemoveModal}><Icon icon="md-trash" fontSize="14px" color="#ffffff"/> Remove Song</button>}
                              {!isOwner && currentUser === song.addedBy &&  <button className="song__actions__delete" onClick={showTheRemoveModal}><Icon icon="md-trash" fontSize="14px" color="#ffffff"/> Remove Song</button>}

                              
                              {showAddModal && 
                                 <div className="song__actions__confirm  song__actions__confirm-copy">
                                    <span>Add Song to</span>
                                    <SelectField
                                    value={copySelectedPlaylist && copySelectedPlaylist[0] && copySelectedPlaylist[0].id}
                                    onChange={(value)=> setPlaylistSelect(value)}
                                    placeholder="Select Playlist" 
                                    slim={true}
                                    showSearch={false}
                                    options={queuePlaylists.map((playlist)=> ({label: playlist.title, value: playlist.id }))}
                                    />
                                    <br />
                                    <button className="confirm_yes" onClick={copySong}>{confirmIcon}</button>
                                    <button className="confirm_no" onClick={hideAddModal}>{cancelIcon}</button>
                                 </div>
                              }

                              {showRemoveModal && 
                                 <div className="song__actions__confirm  song__actions__confirm-remove">
                                    <span>Remove this Song?</span>
                                    <button className="confirm_yes" onClick={removeSong}>{confirmIcon}</button>
                                    <button className="confirm_no" onClick={hideRemoveModal}>{cancelIcon}</button>
                                 </div>
                              }
                        </div>
                     }
                     


                  </div>
                  
            </div>
         </div>
      <div className="song__duration">{formatDuration(song.duration)}</div>
   </div>
   );

}

export default StationSong;
