import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchYoutube from './SearchYoutube';
import SearchArtist from './SearchArtist';
import FetchUrl from './FetchUrl';
// import Import from './Import';
import { addSong } from '../../redux/songSlice';
import { addSationSong } from '../../redux/stationSlice';
import { hideAddSongModal } from '../../redux/userSlice';
// import { selectedPlaylist } from '../../redux/playlistSlice';
import { guestAdd } from '../../redux/guestPlaylistSlice';
import {  minifyPlaylist } from '../../helpers';
import Icon from '../Icon/Icon';

const AddSong  = (props) =>  {
   const { playlists, uid } = props;
   const dispatch = useDispatch();
   const userSettings = useSelector((state)=> state.user );
   const queue = useSelector((state)=> state.queue );
   const userplaylists = useSelector((state)=> state.userplaylists );
   const userplaylistsMin = userplaylists && userplaylists.userPlaylists ? minifyPlaylist(userplaylists.userPlaylists) : [];
   const guestPlaylists = useSelector((state)=> state.playlistGuest );

   const [current, setCurrent] = useState(userSettings.addSongModalVars.string ? userSettings.addSongModalVars.tab : 'search');

   const addTheSong = (uid, playListID, selectedSong, play, queueHasSong) => {
      console.log('addTheSong: ', uid, playListID, selectedSong, play, queueHasSong);
      dispatch(addSong(uid, playListID, selectedSong, play, queueHasSong));
   }

   const addGuestSong = (song) => {
      console.log('addGuestSong: ', song);
      dispatch(guestAdd(song));
   }
   const addTheStationSong = (song) => {
      console.log('addSationSong: ', song);
      dispatch(addSationSong(song)); 
   }

   return (
      <div id="addSong">
            <div className="addSongWrap">
               <div className="addSong__header">
                  <ul>
                        <li onClick={()=> setCurrent('search')} className={current === 'search' ? 'active' : ''}>Songs</li>
                        <li onClick={()=> setCurrent('artist')} className={current === 'artist' ? 'active' : ''}>Artists</li>
                        <li onClick={()=> setCurrent('link')} className={current === 'link' ? 'active' : ''}>URL</li>
                        {/* <li onClick={()=> setCurrent('library')} className={current === 'library' ? 'active' : ''}>My Library</li> */}
                        {userSettings.isPRO && <li onClick={()=> setCurrent('import')} className={current === 'import' ? 'active' : ''}>Import</li>}
                  </ul>
                  <Icon onClick={()=> dispatch(hideAddSongModal())} icon="md-close" fontSize="24px" color="#fff"/>
               </div>
               {current === 'search' && 
                  <SearchYoutube 
                  queue={queue}
                  playlists={playlists ? playlists : {}}
                  userplaylistsMin={userplaylistsMin}
                  currentPlaylist={userplaylists.selectedPlaylist && userplaylists.selectedPlaylist}
                  uid={uid}
                  guestPlaylists={guestPlaylists}
                  search={userSettings.addSongModalVars}
                  clear={userSettings.actions.songAdded ? false : true}
                  station={userSettings.station}
                  addSationSong={addTheStationSong}
                  addSong={addTheSong}
                  guestAdd={addGuestSong}
                  />
               }
               {current === 'artist' && 
                  <SearchArtist 
                  mobile={userSettings.mobile}
                  queue={queue}
                  playlists={playlists ? playlists : {}}
                  userplaylistsMin={userplaylistsMin}
                  currentPlaylist={userplaylists.selectedPlaylist && userplaylists.selectedPlaylist}
                  uid={uid}
                  pro={uid && userSettings.isPRO}
                  guestPlaylists={guestPlaylists}
                  search={userSettings.addSongModalVars}
                  clear={userSettings.actions.songAdded ? false : true}
                  station={userSettings.station}
                  addSationSong={addTheStationSong}
                  addSong={addTheSong}
                  guestAdd={addGuestSong}
                  />
               }
               {current === 'link' && 
                  <FetchUrl 
                  queue={queue}
                  userplaylistsMin={userplaylistsMin}
                  currentPlaylist={userplaylists.selectedPlaylist && userplaylists.selectedPlaylist}
                  uid={uid}
                  guestPlaylists={guestPlaylists}
                  search={userSettings.addSongModalVars}
                  clear={userSettings.actions.songAdded ? false : true}
                  station={userSettings.station}
                  addSationSong={addTheStationSong}
                  addSong={addTheSong}
                  guestAdd={addGuestSong}
                  />
               }
               {/* {current === 'import' && userSettings.isPRO && 
                  <Import 
                  queue={queue}
                  userplaylistsMin={userplaylistsMin}
                  playlists={playlists ? playlists : {}}
                  currentPlaylist={userplaylists.selectedPlaylist && userplaylists.selectedPlaylist}
                  uid={uid}
                  pro={this.props.auth.isLoaded && userSettings.isPRO}
                  addSong={addTheSong}
                  guestAdd={this.props.guestAdd}
                  guestPlaylists={guestPlaylists}
                  updateSong={this.props.updateSong}
                  search={userSettings.addSongModalVars}
                  clear={userSettings.actions.songAdded ? false : true}
                  showAddtoPlaylist={this.props.showAddtoPlaylist}
                  importSongs={this.props.importSongs}
                  selectedPlaylist={this.props.selectedPlaylist}
                  hideAddSongModal={this.props.hideAddSongModal}
                     />
               } */}
            </div>
            
      </div>
   );
    
}

export default AddSong;