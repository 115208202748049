import React from 'react';
import { arrayMove } from '../../helpers';
import SelectField from '../SelectField/SelectField';
import Icon from '../Icon/Icon';

class StationMobileActions extends React.PureComponent{

    state={
        reordering: false,  
        songs:[],   
        reorderingSongs:[],
        playlistSelect:'queueOne',
        selectedAddPlaylist: null, 
        showMobileAddModal: false
    }


    mobileSongOrder = (direction) => {
        const songsToRerorder = [...this.state.reorderingSongs];
        const songCurrentIndex = songsToRerorder.findIndex(x => x.id === this.props.mobileSelectedSong.id);
        console.log(direction, songsToRerorder, songCurrentIndex);
        if(direction === 'up' && songCurrentIndex > 0){
            let newSongListUp = arrayMove(songsToRerorder, songCurrentIndex, songCurrentIndex - 1);
            newSongListUp = newSongListUp.map((sng, index) =>  { return {...sng, order: index} });
            console.log('newSongListUp: ',newSongListUp);
            this.setState({ reorderingSongs: newSongListUp });
            
            let newSongsObject = {};
            newSongListUp.forEach((song)=> newSongsObject[song.id] = song )
            this.props.reorderSongs(newSongsObject);
        }

        if(direction === 'down' && songCurrentIndex <= this.state.reorderingSongs.length -1){
            let newSongListDown = arrayMove(songsToRerorder, songCurrentIndex, songCurrentIndex + 1);
            newSongListDown = newSongListDown.map((sng, index) =>  { return {...sng, order: index} });
            console.log('newSongListDown: ',newSongListDown);
            this.setState({ reorderingSongs: newSongListDown });
            
            let newSongsObject = {};
            newSongListDown.forEach((song)=> newSongsObject[song.id] = song )
            this.props.reorderSongs(newSongsObject);
        }
    }

    cancelMobileActions = () => {
        this.setState({reordering: false, selectedAddPlaylist: null, showMobileAddModal: false, reorderingSongs:[] });
        this.props.mobileSelectSongClear();
    }

    copySong = () => {
        this.props.addSong(this.state.playlistSelect, this.props.mobileSelectedSong);
        setTimeout(()=> {
            this.setState({showMobileAddModal: false});
        }, 500);

    }

    playSong = ()=>{
        console.log('PLAY SONG!!');
        this.props.playSong(this.props.mobileSelectedSong)
    
    }

    removeSong = () => {this.props.removeSationSong(this.props.mobileSelectedSong.id, this.props.mobileSelectedSong); this.props.mobileSelectSongClear(); };


    render(){
        const confirmIcon = <Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/>;
        const cancelIcon = <Icon icon="md-close" fontSize="18px" color="#ffffff"/>;
        let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
        if(this.props.playlistsMin && this.props.playlistsMin.length > 0){ queuePlaylists = [...queuePlaylists, ...this.props.playlistsMin]  }
        const copySelectedPlaylist = queuePlaylists.filter((plist)=>  plist['id'] === this.state.playlistSelect  );

        return(
            <div className="mobile_actions">
                <div className="mobile_actions__all">
                    <button className="mobile_actions__play" onClick={this.playSong}><Icon icon="md-play" fontSize="18px" color="#ffffff"/></button>
                    <button className="mobile_actions__add" onClick={()=> this.setState({showMobileAddModal: true})}><Icon icon="md-add" fontSize="18px" color="#ffffff"/></button>
                    <button className="mobile_actions__remove"onClick={this.removeSong}><Icon icon="md-trash" fontSize="18px" color="#ffffff"/></button>
                    <button className="reorder_btn" onClick={()=> this.setState({reordering: !this.state.reordering}, ()=> this.setState({reorderingSongs: this.state.reordering ? this.props.songs : []}))}>
                    <Icon icon="ios-swap" fontSize="18px" color="#ffffff"/>
                    </button>
                    <button className="mobile_actions__cancel" onClick={this.cancelMobileActions}><Icon icon="md-close" fontSize="18px" color="#ffffff"/></button>
                </div>
                {this.state.reordering && 
                    <div className="mobile_actions__reorder">
                    <button className="mobile_actions__up" onClick={()=>this.mobileSongOrder('up')}><Icon icon="ios-arrow-round-up" fontSize="21px" color="#ffffff"/></button>
                    <button className="mobile_actions__down" onClick={()=>this.mobileSongOrder('down')}><Icon icon="ios-arrow-round-down" fontSize="21px" color="#ffffff"/></button>
                    {/* <button className="mobile_actions__done" onClick={()=> { this.setState({reordering: false}); this.props.sortQueue( this.state.reorderingSongs, this.props.queue.selected)}}><Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/></button> */}
                    <button className="mobile_actions__cancel" onClick={()=> this.setState({reordering: false})}><Icon icon="md-close" fontSize="18px" color="#ffffff"/></button>
                    </div>
                }

                {this.state.showMobileAddModal && 
                    <div className="song__actions__confirm  song__actions__confirm-copy">
                        <span>Add Song to</span>
                        <SelectField
                        value={copySelectedPlaylist && copySelectedPlaylist[0] && copySelectedPlaylist[0].id}
                        onChange={(value)=> this.setState({ playlistSelect: value })}
                        placeholder="Select Playlist" 
                        slim={true}
                        showSearch={false}
                        options={queuePlaylists.map((playlist)=> ({label: playlist.title, value: playlist.id }))}
                        />
                        <br />
                        <button className="confirm_yes" onClick={this.copySong}>{confirmIcon}</button>
                        <button className="confirm_no" onClick={()=> this.setState({showMobileAddModal: false})}>{cancelIcon}</button>
                    </div>
                }
            </div>
        );
    }
}

export default StationMobileActions;