import getSlug from 'speakingurl';

export const youtube_regex = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\\&vi?=)([^#\\&\\?]*).*/
//export const youtube_regex = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\&vi?=)([^#\&\?]*).*/


export const debounce = (func, delay) => {
    let inDebounce
    return function() {
      const context = this
      const args = arguments
      clearTimeout(inDebounce)
      inDebounce = setTimeout(() => func.apply(context, args), delay)
    }
  }

  export const throttle = (func, limit) => {
    let inThrottle
    return function() {
      const args = arguments
      const context = this
      if (!inThrottle) {
        func.apply(context, args)
        inThrottle = true
        setTimeout(() => inThrottle = false, limit)
      }
    }
  }

  export const uniqByProp = (arr, predicate) => {
    const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];
    
    return [...arr.reduce((map, item) => {
      const key = cb(item);
      
      map.has(key) || map.set(key, item);
      
      return map;
    }, new Map()).values()];
  };

  export const splitArray =(array, chunkSize) => {
    let chunkCount = Math.ceil(array.length / chunkSize);
    let chunks = new Array(chunkCount);
    for(let i = 0, j = 0, k = chunkSize; i < chunkCount; ++i) {
        chunks[i] = array.slice(j, k);
        j = k;
        k += chunkSize;
    }
    return chunks;
}

export function uploadImage (file, callback) {
    
    // Ensure it's an image
    if(file.type.match(/image.*/)) {
        console.log('An image has been loaded');

        // Load the image
        var reader = new FileReader();
        reader.onload = function (readerEvent) {
            var image = new Image();
            image.onload = function (imageEvent) {

                // Resize the image
                var canvas = document.createElement('canvas'),
                    max_size = 160,
                    width = image.width,
                    height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                var dataUrl = canvas.toDataURL('image/jpeg');
                //console.log(dataUrl);
                
                callback(dataUrl);


            }
            image.src = readerEvent.target.result;
        }
        reader.readAsDataURL(file);
        
    }
            
}


export function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
}

export function cleanYoutubeTitle(songName){
    let clean = songName;

    clean = clean.replaceAll(/(\(|\[)(Video)(\)|\])/gmi, "");
    clean = clean.replaceAll(/(\(|\[)(HD)(\)|\])/gmi, "");
    clean = clean.replaceAll(/(\(|\[)(Official Audio)(\)|\])/gmi, "");
    clean = clean.replaceAll(/(\(|\[)(Official Video)(\)|\])/gmi, "");
    clean = clean.replaceAll(/(\(|\[)(Official Music Video)(\)|\])/gmi, "");
    clean = clean.replaceAll(/(\(|\[)(Lyrics)(\)|\])/gmi, "");
    clean = clean.replaceAll(/(\(|\[)(Lyric)(\)|\])/gmi, "");
    clean = clean.replaceAll(/(\(|\[)(Lyric Video)(\)|\])/gmi, "");
    clean = clean.replaceAll(/(\(|\[)(Official Lyric Video)(\)|\])/gmi, "");
    clean = clean.replaceAll(/(\(|\[)(Official HD Music Video)(\)|\])/gmi, "");
    clean = clean.replaceAll(/(\(|\[)(Visualizer)(\)|\])/gmi, "");
    clean = clean.replaceAll(/(\(|\[)(Video Oficial)(\)|\])/gmi, "");
    
    return clean ? clean.trim() : clean;
}

export function youtubeVideoDuration(duration){
    var a = duration.match(/\d+/g);
    if (duration.indexOf('M') >= 0 && duration.indexOf('H') === -1 && duration.indexOf('S') === -1) {
        a = [0, a[0], 0];
    }
    if (duration.indexOf('H') >= 0 && duration.indexOf('M') === -1) {
        a = [a[0], 0, a[1]];
    }
    if (duration.indexOf('H') >= 0 && duration.indexOf('M') === -1 && duration.indexOf('S') === -1) {
        a = [a[0], 0, 0];
    }
    duration = 0;
    if (a.length === 3) {
        duration = duration + parseInt(a[0], 10) * 3600;
        duration = duration + parseInt(a[1], 10) * 60;
        duration = duration + parseInt(a[2], 10);
    }
    if (a.length === 2) {
        duration = duration + parseInt(a[0], 10) * 60;
        duration = duration + parseInt(a[1], 10);
    }
    if (a.length === 1) {
        duration = duration + parseInt(a[0], 10);
    }
    return duration
}

export function formatDuration(duration){
    
    const hours = Math.floor(duration / 3600);
    const time =  duration - hours * 3600;
    const minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${hours > 0 ? hours+':':''}${minutes > 0 ? minutes :0 }:${seconds > 0 ? seconds:0}`;
}

export function covertToYoutubeTime(time="00:00:00"){
    if(time.match(/(:.*){2}/)){
        const timeArray = time.split(':');
        
        const hh = parseInt(timeArray[0], 10) === 0 ? '' : parseInt(timeArray[0], 10)+'H';
        const mm = parseInt(timeArray[1], 10) === 0 ? '' : parseInt(timeArray[1], 10)+'M';
        const ss = parseInt(timeArray[2], 10) === 0 ? '' : parseInt(timeArray[2], 10)+'S';
        
        const newTime = 'PT'+hh+mm+ss;
        return newTime === 'PT' ? 'PT0S': newTime;
    }
}

export function secondsToTime(seconds){
    if(seconds){
        var date = new Date(null);
        date.setSeconds(seconds);
        var time = leadZero(date.getUTCHours().toString()) + ':' + leadZero(date.getUTCMinutes().toString()) + ':' +  leadZero(date.getUTCSeconds().toString());
        return time;
    }
}

export function stringTimetonumber(string){
    //Converts string to seconds -> 02:33 to 173
    return string.split(':').reduce((acc,time) => (60 * acc) + +time);
}

export function leadZero(number){
    if(number){
        return parseInt(number, 10) < 10 ? 0+number.toString() : number.toString()
    }
}

export function minifyPlaylist(data, array=true, ){
    let newPlaylistsArray = [];
    let newPlaylistsObject = {};
    if(data && array){
        //Return an Array excluding the songss of each playlist
        Object.keys(data).forEach( (key, index) => {
            let count = 0;
            if(data[key]){
                if(data[key].songs && Object.keys(data[key].songs).length > 0){

                    count = Object.keys(data[key].songs).length;
                }else if(data[key].count){
                    count = data[key].count;
                }
    
                return  newPlaylistsArray.push({ 
                    id: key,
                    title:data[key].title ? data[key].title : '',
                    status:data[key].status ? data[key].status : '',
                    order:data[key].order ? data[key].order : '',
                    slug: data[key].slug ? data[key].slug : '', 
                    category: data[key].category ? data[key].category : '', 
                    likes: data[key].likes ? data[key].likes : 0, 
                    count: count
                });
            }
            
        })
        return newPlaylistsArray.sort((a, b) => a.order - b.order);

    }else if(data && array === false){
        //Return an Object excluding the songs of each playlist
        Object.keys(data).forEach( (key, index) => {
            if(data[key]){
                return newPlaylistsObject[key] = { 
                    id: key,
                    title:data[key].title ? data[key].title : '',
                    status:data[key].status ? data[key].status : '',
                    order:data[key].order ? data[key].order : '',
                    slug: data[key].slug ? data[key].slug : '', 
                    count: data[key].songs ? Object.keys(data[key].songs).length: 0
                }
            }
        }) 
        return newPlaylistsObject;
    }
}

export function getSongs(playlists, currentPlaylist){
    let songsArray =[]
    let selectedPL = currentPlaylist ? currentPlaylist : playlists && minifyPlaylist(playlists)[0].id;
    //console.log(playlists, selectedPL );

    if(playlists && selectedPL){
        const selectedPlaylist =  playlists[selectedPL];
        const {songs} = selectedPlaylist ? selectedPlaylist : {};
        if(songs){
            songsArray = Object.keys(songs).map(function (key) { return {...songs[key], id: key}; });
            songsArray.sort((a, b) => a.order - b.order);
        }
    }
    return songsArray;

}

export function getAllSongs(playlists){
    let songsArray =[]
    if(playlists){
        Object.keys(playlists).map((key, index)=> {
            let selectedPlaylist =  playlists[key];
            let {songs, title=''} = selectedPlaylist;
            if(songs){
                return Object.keys(songs).map( (songKey) => {
                     return songsArray.push({...songs[songKey], playlist:title, id:songKey, playlistID: key }); 
                });
            }else{
                return null;
            }
        });
    }
    return songsArray;

}

export function setPlayerSongs(songs, currentSong){
    console.log(songs, currentSong);
    let songsArray =[]

    if(songs && currentSong){
        songs.forEach((song)=>{
            if(song.id === currentSong){
                return songsArray.push({...song, playing:true}); 
            }else{
                return songsArray.push({...song, playing:false}); 
            }
        })
    }
    return songsArray;

}

export function createSlug(title, playlistsMin){
    //console.log(slug(title.toLowerCase()), playlistsMin);
    let existingSlugs = []
    let theSlug = getSlug(title.toLowerCase());
    Object.keys(playlistsMin).forEach((key)=> { 
        if(playlistsMin[key].slug){ 
            return existingSlugs.push(playlistsMin[key].slug) 
        }  
    })
    
    let possibleSlugs = [];
    let possibleSlugIndex = null;
    for(var i=0; i <= 20 ;i++){
        //loop through possible theSlug-[i] and match with all the existingSlugs to see if the slug
        //has previously added as slug-11...if found, set the index of found item to possibleSlugIndex
        //so we can below create a slug with slug-12 and not slug-11-1 
        if(existingSlugs.length > 0 && existingSlugs.indexOf(theSlug+'-'+i) !== -1){
            possibleSlugs.push(theSlug+'-'+i);
            possibleSlugIndex = i;
        }
    }
    
    const slugOne = theSlug+'-'+(existingSlugs.indexOf(theSlug)+1).toString();
    //console.log(slugOne, existingSlugs.indexOf(slugOne), possibleSlugs.length > 0);
    if(existingSlugs.length > 0 && existingSlugs.indexOf(theSlug) !== -1 && existingSlugs.indexOf(slugOne) === -1 ){
        return slugOne
    }else if(existingSlugs.length > 0 && possibleSlugs.length > 0){
        return theSlug+'-'+(possibleSlugIndex+1).toString();
    }else{
        return theSlug;
    }
}

export function objectToArray(object, withKey =false) {
    if(object){
        if(withKey){
            return Object.keys(object).map((key)=> {
                    return { ...object[key], id: key }
            })
        }else{
            return Object.keys(object).map((key)=> {
                return object[key]
            })
        }
    }else{
        return [];
    }
}

export function convertNotifications(object, withKey =false) {
    if(object){
        let newArray = []
        const newObj = {...object}
        delete newObj['lastseen'];

        Object.keys(newObj).forEach((key)=> {
            newArray.push({ id: key, ...newObj[key] });
        })
        return newArray
    }else{
        return [];
    }
}

export function newNotificationCount(array=[], lastseen) {
    //console.log(array, lastseen);
    if(lastseen){
        return array.filter((item)=> {
            return item.sent > lastseen;
        }).length;
    }else{
        return array.length
    }
}

export function viewFormat(labelValue) 
  {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

       ? roundNumber(Math.abs(Number(labelValue)) / 1.0e+9) + "B"
       // Six Zeroes for Millions
       : Math.abs(Number(labelValue)) >= 1.0e+6

       ? roundNumber(Math.abs(Number(labelValue)) / 1.0e+6) + "M"
       // Three Zeroes for Thousands
       : Math.abs(Number(labelValue)) >= 1.0e+3

       ? roundNumber(Math.abs(Number(labelValue)) / 1.0e+3) + "K"

       : roundNumber(Math.abs(Number(labelValue)));

   }

export function roundNumber(number, precision=2) {
    var shift = function (number, precision, reverseShift) {
      if (reverseShift) {
        precision = -precision;
      }  
      const numArray = ("" + number).split("e");
      return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
    };
    return shift(Math.round(shift(number, precision, false)), precision, true);
  }

export function bodyClass(path=''){
    if(path === '/'){
        return 'page-home';
    }
    if(path === '/friends' || path === '/friends/'){
        return 'page-friends';
    }
    if(path.includes("/channel") ){
        return 'page-channel';
    }
    if(path.includes("/station/") ){
        return 'page-station';
    }
    if(path === '/music' || path.includes("/music/") || path === '/artists' || path.includes("/artists/") || path.includes("/artist/") ){
        return 'page-discover';
    }
    return '';
}

export function getLastSongOrder(playlists, playlistID){
    let orderValue = 0;

    if(Array.isArray(playlists) === false && playlists && playlists[playlistID].songs && Object.keys(playlists[playlistID].songs).length > 0 ){
        const currentPlaylistSongs = playlists[playlistID].songs;
        const allSongsofPlaylist =  objectToArray(currentPlaylistSongs, false);
        let lastSong = allSongsofPlaylist.sort((a, b) => a.order - b.order);
        lastSong = lastSong[lastSong.length - 1];
        orderValue = lastSong.order ? lastSong.order + 1 :  Object.keys(currentPlaylistSongs).length;
    }else if(Array.isArray(playlists) && playlists && playlists.length > 0){
        const selectedPlaylist = playlists.length > 0 ? playlists.filter(plist => plist.id === playlistID) : [];
        orderValue = selectedPlaylist.length > 0 ?  selectedPlaylist[0].count + 1: 0
    }

    return orderValue;
}


export function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

export function arrayMove(arr, previousIndex, newIndex) {
    const array = arr.slice(0);
    if (newIndex >= array.length) {
      let k = newIndex - array.length;
      while (k-- + 1) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
    return array;
  }

//Prevent Console log from outputting in Production Mode
export function noop() {}

export function processChannelSongs(songsObj){
    if(!songsObj){
        return;
    }
    
    const d = new Date();
    const n = d.getTimezoneOffset();
    const timediff = n * 60;
    console.log('Process Channel Songs Function Called!!', timediff);
    const currentDate = new Date(); 
    const currentYear = currentDate.getFullYear(); 
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const songsArray = Object.keys(songsObj.songs).map((songID, index)=> {
         let theSong = songsObj.songs[songID];

         const songTodayDate = new Date(theSong.playAt *1000);
         songTodayDate.setFullYear(currentYear);
         songTodayDate.setMonth(currentMonth);
         songTodayDate.setDate(currentDay);

         theSong.playAt = songTodayDate.getTime() / 1000;

         if(timediff  < 0) { 
               theSong.playAt = theSong.playAt - timediff;
         }else{ 
               theSong.playAt = theSong.playAt + timediff;
         }

         return {...theSong, id: songID};
    });

    return songsArray.sort((x, y) => y.playAt - x.playAt);
}


export function getCurrentUserTime(){
    var datetime    = new Date();
    const offsetmins = datetime.getTimezoneOffset();
    if(offsetmins > 0){
        datetime.setMinutes(datetime.getMinutes()+offsetmins); 
    }else{
        datetime.setMinutes(datetime.getMinutes()-offsetmins); 
    }
    
    return  Math.round(datetime.getTime() / 1000);
}

export function compareArray(array1, array2){
    const array1String = JSON.stringify(array1);
    const array2String = JSON.stringify(array2);
    if(array1String === array2String){
        return true;
    }else{
        return false;
    }
}

export function filterChatMessage(string){
    let cleanstring = string;

    cleanstring = cleanstring.replace('asshole', '*$#&@!!*');
    cleanstring = cleanstring.replace('bitch', '*$#&@!!*');
    cleanstring = cleanstring.replace('dick', '*$#&@!!*');
    cleanstring = cleanstring.replace('cunt', '*$#&@!!*');
    cleanstring = cleanstring.replace('fuck', '*$#&@!!*');
    cleanstring = cleanstring.replace('nigger', '*$#&@!!*');
    cleanstring = cleanstring.replace('negro', '*$#&@!!*');
    cleanstring = cleanstring.replace('nigga', '*$#&@!!*');
    cleanstring = cleanstring.replace('pussy', '*$#&@!!*');
    cleanstring = cleanstring.replace('cunt', '*$#&@!!*');
    cleanstring = cleanstring.replace('fag', '*$#&@!!*');
    cleanstring = cleanstring.replace(/<a\b[^>]*>(.*?)<\/a>/i,"~Links Not Allowed!~");
    cleanstring = cleanstring.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '~Links Not Allowed!~');

    return cleanstring;
}


export const getArtistAlbums = async (artistMBID) => {
   let finalAlbums = [];
   try {
      const response = await fetch(`https://musicbrainz.org/ws/2/release-group/?query=arid:${artistMBID}+AND+primarytype%3A(%22Album%22%20)NOT%20secondarytype:(%22Live%22OR%22Compilation%22OR%22Soundtrack%22)%20AND%20status:official%20&limit=100&fmt=json`).then((res)=> res.json() );

      if(response['release-groups'] && response['release-groups'].length){
         const groups = response['release-groups'];

         groups.forEach((group)=> {
            const allOfficialAlbums = group.releases && group.releases.filter((release)=> release.status === 'Official' || release.status === 'official' )

            if(group.title && allOfficialAlbums && allOfficialAlbums[0] && allOfficialAlbums[0].id){
               finalAlbums.push({
                  name: group.title, 
                  year: group['first-release-date'] ? new Date(group['first-release-date']).getFullYear(): null,
                  mbid: allOfficialAlbums && allOfficialAlbums[0] && allOfficialAlbums[0].id,
                  mbid_release_group: group.id
               });
            }
         })
      }
   } catch (error) {
      console.log('getArtistAlbums: ' , error);
   }

   return {albums: finalAlbums.sort((a,b)=> (a.year && b.year) && b.year - a.year), totalPages: 1};
}

// export const YT_SEARCH_URL = 'http://localhost:8080/api/search?q=Linkin+Park';
export const YT_SEARCH_URL = 'https://api.yhimsical.com/searchyt?q=';

// export const CLOUD_FUNC_URL = 'http://127.0.0.1:5001/yhimsical/us-central1';
export const CLOUD_FUNC_URL = 'https://us-central1-yhimsical.cloudfunctions.net';

