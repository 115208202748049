import React, { useState, useEffect, useRef } from 'react';
//import * as EmailValidator from 'email-validator';
import AccountSettings from '../../User/AccountSettings';
// import { setUpUser } from '../../../actions/index';
import { checkRequiredFields, checkUniqueUsername, checkUniqueEmail, stringValidation, usernameValidation, isEmailValid } from '../../../utils/validation';
import { setUpUser } from '../../../redux/userSlice';
import { useDispatch } from 'react-redux';
import Icon from '../../Icon/Icon';


const SignUp = ({user, setTab, firebase}) => {
   const dispatch = useDispatch();
    const signupForm = useRef(null);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(()=> {
      if(user && user.isEmpty === false){
         console.log('User:', user);
         setTab('myaccount');
     }
    }, [user, setTab])

   const createNewUser = ({ email, password, username, fullname,bio="" }) => {
        
      firebase.auth().createUserWithEmailAndPassword( email, password )
        .then((user)=> { 
            setLoading(true);
            const appSettings = {nofollow:"false",prefrences: "pop", private:"false", theme: "dark" }
            const UID = firebase.auth().currentUser.uid;
            const userData = {email, username, bio, fullname, displayName: fullname, settings: appSettings};
            console.log('UID: ', UID);
            dispatch(setUpUser(userData));
            //setTimeout(()=> { return document.location.href = '/?introduction'}, 1800);
        })
        .catch(err => {
         console.log(err);
         setError([err.message]);
         setLoading(true);
      })
    }


   const onSignUpSubmit = (event) => {
        event.preventDefault();
        if(loading) { return console.log('Already Perfoming Submission!'); }
        let totalErrors = [];

        setUsername('');
        setEmail('');

        const formData = new FormData(signupForm.current);
        const fullname = formData.get('fullname');
        const username = formData.get('username');
        const email = formData.get('email');
        const pass = formData.get('pass');
        const passagain = formData.get('passagain');
        const allfields = [ 
            {label : 'fullname', value: formData.get('fullname')}, 
            {label: 'username', value: formData.get('username')}, 
            {label: 'email', value: formData.get('email')},
            {label: 'pass', value: formData.get('pass')}, 
            {label: 'passagain', value: formData.get('passagain')},
        ]
        
        //Password validation. atleast 6 characters and has to match the passagain
        if(pass && pass.length < 6){   totalErrors.push('Password Should be at-least 6 chracters'); }
        if(pass && passagain && (pass !== passagain)){   totalErrors.push('Password Doesnt Match'); }
        if(isEmailValid(email) === false){  totalErrors.push('Invalid Email');  }
        // Validate Username
         const usernameErrors = usernameValidation(username);  
         console.log(usernameErrors);
         if(usernameErrors && usernameErrors.length > 0){
            usernameErrors.forEach((uError)=> {
               totalErrors.push(uError);
            })
         }
         // If validation Fails, do not Signup
         if(totalErrors.length > 0){
            console.log(allfields, totalErrors);
            return setError(totalErrors);
         }

        //Check if the Fields are FilledUP
        checkRequiredFields(allfields).then((fieldsEmpty)=> {
            
            if(fieldsEmpty.length > 0){
                console.log(fieldsEmpty);
                totalErrors = [...totalErrors , 'Please Fill Up the Required Fields'];
                return setError(totalErrors);
            }else{
                const invalidStrings = stringValidation([fullname, username, email, pass, passagain])
                invalidStrings.length > 0 && totalErrors.push(invalidStrings.join());
                setLoading(true);
                //CHECK if username is unique
                checkUniqueUsername(username.trim())
                .then((notUniqueUsername)=> {  return notUniqueUsername ? totalErrors = [...totalErrors , notUniqueUsername] : setUsername(username.trim()); })
                .then(()=> {
                    //Check if email is unique and valid
                    return checkUniqueEmail(email.trim()).then((notUniqueEmail)=> {
                        return notUniqueEmail ? totalErrors = [...totalErrors , notUniqueEmail] : setEmail(email.trim()) ;
                    });

                })
                .then(()=> {
                     setLoading(false);
                     if(totalErrors.length > 0){
                        console.log('Found Error!', totalErrors);
                        setError(totalErrors);
                     }else{
                        setError([]);
                        console.log('No Found Error!', totalErrors);
                        setError([]);
                        createNewUser({ 
                            email:email, 
                            password: pass, 
                            username: username,
                            fullname: fullname,
                        });
                        console.log('No Error! Success!');
                    }
                }).catch((err)=> {
                  console.log('checkUniqueUsername Error!', err);
                  setLoading(false);
                })

            }
        })

    }

   //  const checkRequired = (fieldName, value) => {
   //      if(!value){  
   //          document.querySelector('.label_'+fieldName).classList.add('error'); 
   //          return true;
   //      }else{
   //          document.querySelector('.label_'+fieldName).classList.remove('error');
   //          return false;
   //      }
   //  }
    

   return(
      <div id="signup" className={loading ? 'loading_data':''}>
            <h3>Sign Up</h3>
            <p className="signup_msg">Singup free to Access Your Playlists from Anywhere</p>
            {loading && <div className="loading"><Icon icon="ios-sync" rotate={true} fontSize="38px" color="#ffffff"/></div>}
            <form id="signup_form" onSubmit={onSignUpSubmit} ref={signupForm}>
            <AccountSettings 
               full={false} 
               usernameUnique={username ? true : false } 
               emailUnique={email ? true : false }  />
               
               {error.length > 0 && error.map( (error, idx)=> <p key={idx} className="errorMsg">{error}</p> )}
               <button aria-label="Sign Up" disabled={loading ? true : undefined} type="submit">Sign Up</button>
            </form>
            <p className="signuploginroutes"><button onClick={()=> setTab('login')}>Already Have an Account? Login {'>'}</button></p>
      </div>
   );
    
}

export default SignUp;