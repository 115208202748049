import React from 'react';

const ArtistSkeleton = (props)=>{
    return(
        <div className="artistSkeleton">
            {props.header && 
                <div className="artistSkeleton__header">
                    <div className="artistSkeleton__header__title"><div/><div></div></div>
                    <div className="artistSkeleton__header__right"><div/></div>
                </div>
            }

            <div className="artistSkeleton__body">
                <div className="artistSkeleton__artist"><div className="artistSkeleton__artist_left"/><div className="artistSkeleton__artist_right"><div/><div/><div/></div></div>
                <div className="artistSkeleton__artist"><div className="artistSkeleton__artist_left"/><div className="artistSkeleton__artist_right"><div/><div/><div/></div></div>
                <div className="artistSkeleton__artist"><div className="artistSkeleton__artist_left"/><div className="artistSkeleton__artist_right"><div/><div/><div/></div></div>
                <div className="artistSkeleton__artist"><div className="artistSkeleton__artist_left"/><div className="artistSkeleton__artist_right"><div/><div/><div/></div></div>
                <div className="artistSkeleton__artist"><div className="artistSkeleton__artist_left"/><div className="artistSkeleton__artist_right"><div/><div/><div/></div></div>
                <div className="artistSkeleton__artist"><div className="artistSkeleton__artist_left"/><div className="artistSkeleton__artist_right"><div/><div/><div/></div></div>
                <div className="artistSkeleton__artist"><div className="artistSkeleton__artist_left"/><div className="artistSkeleton__artist_right"><div/><div/><div/></div></div>
                <div className="artistSkeleton__artist"><div className="artistSkeleton__artist_left"/><div className="artistSkeleton__artist_right"><div/><div/><div/></div></div>
                <div className="artistSkeleton__artist"><div className="artistSkeleton__artist_left"/><div className="artistSkeleton__artist_right"><div/><div/><div/></div></div>

            </div>
        </div>
    );
}

export default ArtistSkeleton;