/* eslint-disable jsx-a11y/anchor-is-valid */
import React , {useRef }from 'react';
// import { DragSource, DropTarget } from 'react-dnd';
import { useDrag, useDrop } from 'react-dnd';
import { throttle } from '../../helpers';

const playing = <div className="playingIcon"><svg className="lds-equalizer" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><g transform="rotate(180 50 50)" className=""><rect ng-attr-x="{{16.666666666666668 - config.width/2}}" y="37.75" ng-attr-width="{{config.width}}" height="9.1151" fill="#93dbe9" x="13.166666666666668" width="7" className="">
    <animate attributeName="height" calcMode="spline" values="17.5;26.25;3.5;17.5" times="0;0.33;0.66;1" ng-attr-dur="{{config.speed}}" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.2s" dur="1" className="">
  </animate></rect><rect ng-attr-x="{{33.333333333333336 - config.width/2}}" y="37.75" ng-attr-width="{{config.width}}" height="17.5301" fill="#689cc5" x="29.833333333333336" width="7" className="">
    <animate attributeName="height" calcMode="spline" values="17.5;26.25;3.5;17.5" times="0;0.33;0.66;1" ng-attr-dur="{{config.speed}}" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.4s" dur="1" className="">
  </animate></rect><rect ng-attr-x="{{50 - config.width/2}}" y="37.75" ng-attr-width="{{config.width}}" height="24.2683" fill="#5e6fa3" x="46.5" width="7" className="">
    <animate attributeName="height" calcMode="spline" values="17.5;26.25;3.5;17.5" times="0;0.33;0.66;1" ng-attr-dur="{{config.speed}}" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.6s" dur="1" className="">
  </animate></rect><rect ng-attr-x="{{66.66666666666667 - config.width/2}}" y="37.75" ng-attr-width="{{config.width}}" height="4.26616" fill="#3b4368" x="63.16666666666667" width="7" className="">
    <animate attributeName="height" calcMode="spline" values="17.5;26.25;3.5;17.5" times="0;0.33;0.66;1" ng-attr-dur="{{config.speed}}" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="0s" dur="1" className="">
  </animate></rect><rect ng-attr-x="{{83.33333333333333 - config.width/2}}" y="37.75" ng-attr-width="{{config.width}}" height="23.884" fill="#191d3a" x="79.83333333333333" width="7" className="">
    <animate attributeName="height" calcMode="spline" values="17.5;26.25;3.5;17.5" times="0;0.33;0.66;1" ng-attr-dur="{{config.speed}}" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.8s" dur="1" className="">
  </animate></rect></g></svg></div>;


const PlayListGroupItem = (props) => {
      const { playlist, playlistOrder, style, currentPlaylist, selectedPlaylist, currentlyPlaying, hovered, hideActivePlaylist, hideAddSongModal, reorder, movePlaylist } = props;
      const playlistRef = useRef(null);
      const disabled = (currentPlaylist === playlist.id) && hideActivePlaylist ? 'disabled': '';

      const [{ isDragging, }, drag] = useDrag(
         () => ({
            type: 'playlistMenuItem',
            collect: (monitor) => ({
               isDragging: monitor.isDragging(),
            }),
            item: (dropResult, monitor) => {
               return { id: playlist.id, playlist: {...playlist},  order: playlistOrder }
            },
            end: (dropResult, monitor) => {
               if (!monitor.didDrop()) {  return; }
               setTimeout(() => {
                  console.log('Drag Ended! ', dropResult?.playlist?.title);
                  reorder();
               }, 200);
            }
         }),
         [],
       );

       const [{ handlerId }, drop] = useDrop(
         () => ({
           accept: ['song', 'track', 'playlistMenuItem'],
           canDrop: (item, monitor) => (currentPlaylist === playlist.id) && hideActivePlaylist ? false : true,
           hover: throttle((_item, monitor) => {
               if (!playlistRef.current) { return;  }
               const item = monitor.getItem();
               const dragId = item.id;
               const dragOrder = item.order;
               const hoverId = playlist.id;
               const hoverOrder = playlistOrder;
          
               if (dragId === hoverId) {
                 return;
               }
              
               // Determine rectangle on screen
               const hoverBoundingRect = playlistRef.current?.getBoundingClientRect()
               const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
               const clientOffset = monitor.getClientOffset();
               const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            
               if(monitor.getItemType() === 'playlistMenuItem'){
                  if (dragOrder < hoverOrder && hoverClientY < hoverMiddleY) {   return;  }
                  if (dragOrder > hoverOrder && hoverClientY > hoverMiddleY) {   return;  }
                  // console.log( item, monitor.getItemType());
                  movePlaylist(dragId, hoverId);
               }
               if(monitor.getItemType() === 'song' || monitor.getItemType() === 'track' ){
                  //console.log( item, monitor.getItemType());
                  if(monitor.isOver()) {
                     props.setHoveredItem(hoverId, item.source === 'playlist');
                  }
               }
            }, 100),
            drop(_item, monitor) {
               const itemType = monitor.getItemType();
               const item  = monitor.getItem();
               const hoverId = playlist.id;
               console.log('DROP End!!!!', hoverId, item);
               if(itemType  === 'song' || itemType === 'track' ){
               //   console.log( 'Song Droped ',item, hoverId );
                 if(item.songs && item.songs.length > 0 && hoverId){
                   item.songs.map((song)=> {
                     return props.addSong(hoverId, song);
                   })
                 }
               }
            },
            collect: (monitor) => ({
               isOver: monitor.isOver(),
               isOverCurrent: monitor.isOver({ shallow: true }),
               handlerId: monitor.getHandlerId(),
            }),
         }),
       );


       const opacity = isDragging ? 0 : 1;
       const zIndex = isDragging ?  5 : 1;
       drag(drop(playlistRef));
      //  console.log(isOver, isOverCurrent);
       //transform: isOver ? 'translate3d(0, ' + 30 + 'px, 0)' : null
      return <li 
               ref={playlistRef} 
               data-handler-id={handlerId}
               style={{ zIndex, opacity, ...style}}
               className={`playlistsItem ${currentPlaylist === playlist.id ? 'active': ''} ${hovered && !disabled ? 'hovered' : ''} ${disabled}`}
               onClick={()=> {selectedPlaylist(playlist.id, playlist.slug ? playlist.slug : ''); hideAddSongModal()}}>
               <a>{playlist.title}<span>{playlist.count? playlist.count : 0}</span> {currentlyPlaying ===  playlist.id && playing }</a>
               </li>
    
}
export default PlayListGroupItem;
