import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import firebase from "firebase/compat/app";
// import '@firebase/messaging';
// import "firebase/compat/messaging";
import { getToken } from "firebase/messaging";
import { getDatabase, push, ref } from 'firebase/database';
import { playerVolume, playPausePlayer } from '../../redux/playerSlice';
import { setSationSongs, removeSationSong, startStation, stopStation, joinStation, acceptUser, rejectUser, completeUserLeft, kickUser, cancelJoinRequest,importPlaylist, likeStationSong, reorderStaionSongs, setCurrentSong, playStationSong, pauseStationSong, setPlayedSeconds, setStationRepeat, giveFullAccess, addSationSong, clearStation } from '../../redux/stationSlice';
import { setStation, showLibraryModal, showAddSongModal, showUserArea, draggingSong, showAddtoPlaylist } from '../../redux/userSlice';
import { sendPushNotification } from '../../actions/pushnotifications';
import { addSong } from '../../redux/songSlice';
import { addToQueue } from '../../redux/queueSlice';
import StationSkeleton from '../Loader/StationSkeleton';
import StationOwner from './StationOwner';
import StationGuest from './StationGuest';
import { messaging } from '../..';
import Modal from '../Modal/Modal';
import { publicURL } from '../../config';




const Station  = () => {

   const [owner, setOwner] = useState(null);
   const [stationID, setStationID] = useState('');
   const [stationOwner, setStationOwner] =  useState(false);
   const [userFCMToken, setUserFCMToken] = useState('');
   const [showInvite, setShowInvite] =  useState(false);
   

   const { user: stationName } = useParams();
   const dispatch = useDispatch();
   const queue = useSelector(state => state.queue);
   const player = useSelector(state => state.player);
   const userSettings = useSelector(state => state.user);
   const playlists = useSelector((state) => state.userplaylists);
   const playlistsMin = playlists?.userPlaylistsMin;
   const profile = userSettings?.userData || {};
   const uid = profile?.id;

   const fetchUser = useCallback((stationOwner, uid) => {
      if(owner || !stationOwner ){ return }
      console.log('### FETCH USER', stationOwner);

      const db = firebase.firestore();
      const UserRootRef = db.collection('Users').where("username", "==", stationOwner).limit(1);
      UserRootRef.get().then((docSnap)=> {
         //  console.log('### New CurrentUsername: ', docSnap);
          docSnap.forEach((doc)=> {
              const userObj = doc.data();
              delete userObj.email; delete userObj.playlists; delete userObj.settings;
              let {avatarUrl='', bio='', country='', username='', fullname='', gender='', photoURL=''} = userObj;
              let filteredProfile = { avatarUrl, bio, country, username, fullname, gender, photoURL, id: doc.id }
            //   console.log('$$$$$$ Profile Query Complete!', uid === filteredProfile.id, filteredProfile);
              setOwner(filteredProfile);
              setStationOwner(uid && (uid === filteredProfile.id) ? true: false);
              setStationID(filteredProfile.id);
          });
          
      }).catch(err => console.log('ERROR FETCHING USER: ', err) )
  },[owner])

   const askFCmPermission = async () => {
      try {
      //   const messaging = getMessaging();
      //   await messaging.requestPermission();
        const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FB_MSG });
        console.log('User Token:', token);
        setUserFCMToken(token);
        return token;
      } catch (error) {
        console.error(error);
      }
   }

   useEffect(()=> {
      document.title = `${stationName}'s Station - Yhimsical`;
      fetchUser(stationName, uid);
      // askFCmPermission();
      return ()=> { document.title = `Yhimsical`;}
   },[stationName, uid, fetchUser]);

   const copySong = (playlistSelect, song) => {
        let queue = '';
        if(playlistSelect === 'queueOne' || playlistSelect === 'queueTwo' || playlistSelect === 'queueThree'){
            queue = playlistSelect;
        }

        const copySelectedPlaylist = playlistsMin && playlistsMin.filter((plist)=>  plist['id'] === playlistSelect  ); 
        console.log(copySelectedPlaylist, copySelectedPlaylist[0] ? copySelectedPlaylist[0].count : 0);
        if(!queue){
            const newSong = {...song , order: copySelectedPlaylist[0] ? copySelectedPlaylist[0].count : 0}
            dispatch(addSong(uid, playlistSelect, newSong));
        }else{
            dispatch(addToQueue(song, queue, false));
        }
        
    }
    
   const postChatMessage = (station,msg) => {
        if(!uid || !profile.username){
            return console.log('Auth Id or username Missing!');
        }
        const theMsg = {
            "content": msg,
            "created": Math.round((new Date()).getTime() / 1000),
            "senderID": uid,
            "senderName": profile.username,
        }
        console.log(station, msg, `stations/${station}/messages`);

        const db = getDatabase();
        const postsRef = ref(db, `stations/${station}/messages`);
        push(postsRef, theMsg).then(() => {
         console.log('Message Sent!');
        });
    }



    const compProps = {
      stationName,
      queue,
      player,
      userSettings,
      profile,
      auth: { uid: uid },
      userplaylistsMin: playlistsMin,
      playlists: playlists?.userPlaylists,
      addSong: (uid, playlistID, song, play=false, playAgainn=false)=> dispatch(addSong(uid, playlistID, song, play=false, playAgainn=false)),
      addToQueue: (payload)=> dispatch(addToQueue(payload)),
      playerVolume: (payload)=> dispatch(playerVolume(payload)),
      setStation: (payload) => dispatch(setStation(payload)),
      setSationSongs: (songs) => dispatch(setSationSongs(songs)),
      removeSationSong: (songID, song) => dispatch(removeSationSong(songID, song)),
      startStation: (stationID) => { dispatch(playPausePlayer(false)); dispatch(startStation(stationID)); },
      stopStation: (stationID) => dispatch(stopStation(stationID)),
      joinStation: (stationID) => dispatch(joinStation(stationID)),
      acceptUser: (userID, stationID, username) => dispatch(acceptUser(userID, stationID, username) ),
      rejectUser: (userID, stationID, userLeft=false, username='') => dispatch(rejectUser(userID, stationID, userLeft=false, username='')),
      completeUserLeft: (userID, stationID, user) => dispatch(completeUserLeft(userID, stationID, user)) ,
      kickUser:(userID, stationID, username='') => dispatch(kickUser(userID, stationID, username='')),
      cancelJoinRequest: (stationID) => dispatch(cancelJoinRequest(stationID)),
      importPlaylist: (playlist) => dispatch(importPlaylist(playlist)),
      showLibraryModal: () => dispatch(showLibraryModal()),
      showAddSongModal: () => dispatch(showAddSongModal()),
      showUserArea: () => dispatch(showUserArea()),
      likeStationSong: (songID, song) => dispatch(likeStationSong(songID, song)),
      draggingSong: (payload) => dispatch(draggingSong(payload)),
      reorderStaionSongs: (songs) => dispatch(reorderStaionSongs(songs)),
      setCurrentSong: (song) => dispatch(setCurrentSong(song)),
      playStationSong: () => dispatch(playStationSong()),
      pauseStationSong: () => dispatch(pauseStationSong()),
      setPlayedSeconds: (playedSeonds) => dispatch(setPlayedSeconds(playedSeonds)), 
      setStationRepeat: (currentState) => dispatch(setStationRepeat(currentState)), 
      giveFullAccess: (user) => dispatch(giveFullAccess(user)), 
      addSationSong: (song) => dispatch(addSationSong(song)), 
      sendPushNotification: (data) => dispatch(sendPushNotification(data)),
      createPlaylist: ()=> dispatch(showAddtoPlaylist()),
   }

   return(
      <div className="station">
            {!stationID && <StationSkeleton stationName={stationName} />}
            {stationOwner && stationID && 
               <StationOwner {...compProps} 
               userFCMToken={userFCMToken} 
               copySong={copySong} 
               askFCmPermission={askFCmPermission} 
               owner={owner} 
               stationID={stationID} 
               firebase={firebase}
               postChatMessage={postChatMessage}
               showInviteModal={()=> setShowInvite(true)}
               clearStation={()=> dispatch(clearStation())}
                /> 
            }
            {!stationOwner && stationID && 
               <StationGuest {...compProps}
               userFCMToken={userFCMToken} 
               copySong={copySong} 
               askFCmPermission={askFCmPermission} 
               owner={owner} 
               stationID={stationID} 
               firebase={firebase}
               postChatMessage={postChatMessage} 
               showInviteModal={()=> setShowInvite(true)}
               />
            }
            <div className='playlistActionModals '>
               <Modal title={`Invite Friends to Join ${stationName}'s Station`} show={showInvite} maxWidth={380} closeModal={()=> setShowInvite(false)}>
                  <div className="shareBox">
                     <label>Invite your friends to join the Station using this URL: </label>
                     <input type="text" defaultValue={`${publicURL}station/${stationName}/`} readOnly  />   
                  </div>
               </Modal>
            </div>
      </div>
   );

}

export default Station;
