import React, { useState } from 'react';
import Queue from '../Queue/Queue';
// import { createPlaylist } from '../../redux/queueSlice';
import { selectedQueue as selectQueue, clearQueue } from '../../redux/queueSlice';
import { showAddtoPlaylist } from '../../redux/userSlice';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import Icon from '../Icon/Icon';

const RightSidebar = ({onCreatePlaylist, history, userSettings, queue, playlists, profile, authorID, author}) => {

   const dispatch = useDispatch();
   //  const [showNotification, setShowNotification] = useState(false);
    const [selectedQueue, setSelectedQueue] = useState(1);

    const changeQueue = (type='') =>{
        const currentQ = selectedQueue; 
        let selectedQ = '';
        console.log(currentQ);

        if(type === 'next') {  
            const newQ = currentQ !== 3 ? currentQ + 1 : 1
            setSelectedQueue(newQ);
            if(newQ === 1) { selectedQ = 'queueOne'; }else if(newQ === 2){ selectedQ = 'queueTwo' }else if(newQ === 3){ selectedQ = 'queueThree' }
            dispatch(selectQueue({queue: selectedQ }));
        }
        if(type === 'prev') {  
            const newQ = currentQ > 1 ? currentQ - 1 : 3;
            setSelectedQueue(newQ);
            if(newQ === 1) { selectedQ = 'queueOne'; }else if(currentQ === 2){ selectedQ = 'queueTwo' }else if(newQ === 3){ selectedQ = 'queueThree' }
            dispatch(selectQueue({queue: selectedQ }));
        }
        
    }

    const createPlaylistfromQ = ()=> {
        onCreatePlaylist();
        dispatch(showAddtoPlaylist());
    }

   return(
      <div id="RightSidebar">
            <div id="playlist_mini" className={selectedQueue ? `playlist_mini-${selectedQueue}`: ''}>
               <div id="queueMenu">
                  <div className="queueHeader">Queue ({selectedQueue}/3)                             
                        <div className="queue_tools">
                           <button id="sidebarNewPlButton" aria-label="Create New Playlist" onClick={createPlaylistfromQ} data-tooltip-content="Create New Playlist"><span>+</span><Icon icon="md-list" fontSize="18px" color="#ffffff"/></button>
                           <button id="sidebarClearButton" aria-label="Clear Queue" onClick={()=> dispatch(clearQueue())} data-tooltip-content="Clear Queue"><Icon icon="ios-backspace" fontSize="18px" color="#ffffff"/></button>
                        </div>
                  </div>
                  <div className="queueNav">
                        <div className="handles">
                           <button aria-label="Previous Queue" onClick={()=> changeQueue('prev')}><Icon icon="ios-arrow-back" fontSize="18px" color="#ffffff"/></button>
                           <button aria-label="Next Queue" onClick={()=> changeQueue('next')}><Icon icon="ios-arrow-forward" fontSize="18px" color="#ffffff"/></button>
                        </div>
                  </div>
               </div>

               <div className={`queueTab queueOne ${selectedQueue === 1 ? 'queueTab-active': ''}`}>
                  <Queue 
                  queueId={'queueOne'}
                  songs={queue.queueOne}
                  history={history}
                  playlists={playlists}
                  authorID={authorID}
                  author={profile && profile.username ? profile.username : ''}
                  />
               </div>
               <div className={`queueTab queueTwo ${selectedQueue === 2 ? 'queueTab-active': ''}`}>
                  <Queue 
                  queue={'queueTwo'}
                  songs={queue.queueTwo}
                  history={history}
                  playlists={playlists}
                  authorID={authorID}
                  author={profile && profile.username ? profile.username : ''}
                  />
               </div>
               <div className={`queueTab queueThree ${selectedQueue === 3 ? 'queueTab-active': ''}`}>
                  <Queue 
                  queue={'queueThree'}
                  songs={queue.queueThree}
                  history={history}
                  playlists={playlists}
                  authorID={authorID}
                  author={profile && profile.username ? profile.username : ''}
                  />
               </div>
            </div>
            
            <Tooltip anchorId="sidebarNewPlButton" />
            <Tooltip anchorId="sidebarClearButton" />
      </div>
   );

}

export default RightSidebar;