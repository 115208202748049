import { Routes, Route } from "react-router-dom";
import DiscoverHome from "../../routes/DiscoverHome";
import Home from "../../routes/Home";
import Channels from "../channels/Channels";
import AllArtists from "../Discover/AllArtists";
import AllTracks from "../Discover/AllTracks";
import Artist from "../Discover/Artist";
import PopularTracks from "../Discover/PopularTracks";
import PublicProfile from "../Profile/PublicProfile";
import Station from "../Station/Station";


const AppRoutes = () => {
   return (
      <Routes>
         <Route path={'/'} element={<Home />} />
         {/* <Route path="about" element={<About />} />
         <Route path="dashboard" element={<Dashboard />} /> */}

         {/* Using path="*"" means "match anything", so this route
               acts like a catch-all for URLs that we don't have explicit
               routes for. */}
         {/* <Route path="*" element={<NoMatch />} /> */}
         <Route path="/music" element={<DiscoverHome />} />
         <Route exact path="/popular" element={<PopularTracks />} />
         <Route exact path="/music/:genre" element={<AllTracks />} />
         <Route exact path="/music/:genre/page/:page" element={<AllTracks />} />
         <Route exact path="/music/:genre/:sort" element={<AllTracks />} />
         <Route exact path="/music/:genre/:sort/page/:page" element={<AllTracks />} />
         
         <Route exact path="/user/:username/" element={<PublicProfile />} />
         <Route exact path="/user/:username/:playlistSlug" element={<PublicProfile />}  />
         <Route exact path="/complete-signup" element={<Home />} />

         <Route exact path="/channel/:channel" element={<Channels />} />
         <Route exact path="/station/:user" element={<Station />} />

         {/* <Route exact path="/about" render={()=><Home auth={this.props.auth && this.props.auth} playlists={this.props.playlists} />} />
         <Route exact path="/download" render={()=><Home auth={this.props.auth && this.props.auth} playlists={this.props.playlists} />} />
         <Route exact path="/contact" render={()=><Home auth={this.props.auth && this.props.auth} playlists={this.props.playlists} />} />
         <Route exact path="/help" render={()=><Home auth={this.props.auth && this.props.auth} playlists={this.props.playlists} />} />
         <Route exact path="/privacy" render={()=><Home auth={this.props.auth && this.props.auth} playlists={this.props.playlists} />} />
         <Route exact path="/tos" render={()=><Home auth={this.props.auth && this.props.auth} playlists={this.props.playlists} />} /> */}

         <Route exact path="/artists/" element={<AllArtists />} />
         <Route exact path="/artists/:genre" element={<AllArtists  />} />
         <Route exact path="/artists/:genre/page/:page" element={<AllArtists />} />
         <Route exact path="/artist/:slug" element={<Artist />} />

      </Routes>
   );
}

export default AppRoutes;