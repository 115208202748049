import React, { useEffect, useRef, useState } from 'react';
import AccountSettings from '../../User/AccountSettings';
import { checkRequiredFields, checkUniqueUsername, checkUniqueEmail, isEmailValid, usernameValidation } from '../../../utils/validation';
import { useDispatch } from 'react-redux';
import { setUpUser } from '../../../redux/userSlice';
import Icon from '../../Icon/Icon';


const CompleteSignup = (props) => {
   const { user, setTab, authData } = props;

   const completeForm = useRef(null);
   const dispatch = useDispatch();

   const [username, setUsername] =  useState('');
   const [email, setEmail] =  useState('');
   const [error, setError] =  useState([]);
   const [loading, setLoading] =  useState(false);

   useEffect(()=> {
      document.body.classList.add('complete-registration');
      setTab('myaccount');
   }, [setTab])

   const onSignUpComplete = (event) => {
        event.preventDefault();
        if(loading) { return console.log('Already Perfoming Submission!'); }
            let totalErrors = [];
            setEmail('');
            setUsername('');
            setError([]);

            const formData = new FormData(completeForm.current);
            const fullname = formData.get('fullname');
            const username = formData.get('username');
            const email = formData.get('email');
            const allfields = [ 
                {label : 'fullname', value: formData.get('fullname')}, 
                {label: 'username', value: formData.get('username')}, 
                {label: 'email', value: formData.get('email')},
            ]
            if(!fullname){  totalErrors.push('Name is Required!'); }
            if(isEmailValid(email) === false){  totalErrors.push('Invalid Email');  }
            // Validate Username
             const usernameErrors = usernameValidation(username);  
             console.log(usernameErrors);
             if(usernameErrors && usernameErrors.length > 0){
                usernameErrors.forEach((uError)=> {
                   totalErrors.push(uError);
                })
             }

             // If validation Fails, do not Signup
             if(totalErrors.length > 0){
                console.log(allfields, totalErrors);
                return setError(totalErrors);
             }

            checkRequiredFields(allfields).then((fieldsEmpty)=> {
                if(fieldsEmpty.length > 0){
                    return totalErrors = [...totalErrors , 'Please Fill Up the Required Fields'];
                }
            }).then((fieldsEmpty)=> {
                if(fieldsEmpty > 0){
                    setError(totalErrors);
                }else{
                    //CHECK if username is unique
                    setLoading(true);
                    checkUniqueUsername(username.trim())
                    .then((notUniqueUsername)=> {  return notUniqueUsername ? totalErrors = [...totalErrors , notUniqueUsername] : setUsername(username.trim()) })
                    .then(()=> {
                        //Check if email is unique and valid
                        if(email !== authData.email){
                            return checkUniqueEmail(email.trim()).then((notUniqueEmail)=> {
                                return notUniqueEmail ? totalErrors = [...totalErrors , notUniqueEmail] : setEmail(email.trim()) ;
                            });
                        }else{
                            return;
                        }
                    })
                    .then(()=> {
                        if(totalErrors.length > 0){
                            console.log('Found Error!', totalErrors, fullname, username );
                            setError(totalErrors);
                        }else{
                            setError([]);
                            console.log('No Found Error!', totalErrors);
                            updateUser({ email: email || authData.email, username: username, fullname: fullname })
                        }
            
                    }).finally(()=>  setLoading(false) )
                }

            })

    }


   const updateUser = ({fullname, username, email}) => {
      console.log('updateUser!!!!');
      setTimeout(() => { setLoading(true); }, 100);
      const appSettings = {nofollow:"false",prefrences: "pop", private:"false", theme: "dark" }
      const userData = {email, username, fullname, settings: appSettings};
      //console.log(userData, user);
      if(user && !user.playlists){
         //If the temp playlist was not created initially. Create it now.
         dispatch(setUpUser(userData))
      }
   }




   return(
      <div id="signup" className={`${loading ? 'loading_data':''}`}>

            <div>
               <h3>Complete Registration</h3>
               {loading && <div className="loading"><Icon icon="ios-sync" rotate={true} fontSize="38px" color="#ffffff"/></div>}
               <form id="signup_form" className={`${!user.email ? 'signup_form-complete-email':'signup_form-complete'}`} onSubmit={onSignUpComplete} ref={completeForm}>
                     <AccountSettings 
                     full={false} 
                     usernameUnique={username ? true : false } 
                     emailUnique={email ? true : false }
                     user={{...authData, fullname: authData.displayName}}
                     />
                     
                     {error.length > 0 && error.map( (error, idx)=> <p key={idx} className="errorMsg">{error}</p> )}
                     <button type="submit">Complete</button>
               </form>
            </div>

      </div>
   );

}

export default CompleteSignup;