import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
//import isEqual from 'lodash.isequal';
import { useDispatch } from 'react-redux';
import { selectPlaylist } from '../../redux/playlistSlice';
import { showAddtoPlaylist, mobileTab, showLibraryModal, showUserArea  } from '../../redux/userSlice';
import Modal from '../Modal/Modal';

import PlayListGroupWrapper from './PlayListGroupWrapper';
// import PlayListGroupDropZone from './PlayListGroupDropZone';

import logo from '../../assets/logo_white.png';
import logoM from '../../assets/logo_mobile.png';
import PlayListGroupDropZone from './PlayListGroupDropZone';
import Icon from '../Icon/Icon';


const PlayListGroup = ({ playlistsMin, currentUserID, userSettings, userplaylists, playlistGuestMin, authorID, savedPlaylists }) =>{
   const { pathname, } = useLocation();
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const userIsAuthor = authorID === currentUserID;

   const [playlists, setPlaylists] = useState([]);
   // const [playlistsHeight, setPlaylistsHeight] = useState(300);
   const [selectedMenu, setSelectedMenu] = useState('mine');
   // const [showPlaylistMenu, setShowPlaylistMenu] = useState(false);
   const [activateSongDrop, setActivateSongDrop] = useState(true);
   const [signupModal, setSignupModal] = useState(false);

   const setMobileTab = useCallback((newTab) => dispatch(mobileTab(newTab)), [dispatch]);

   useEffect(()=> {
      let playlists = {}
      if( currentUserID && playlistsMin){
          playlists = playlistsMin ? playlistsMin  : []
      }else if(!currentUserID){
          playlists = playlistGuestMin ? playlistGuestMin  : []
      }
      setPlaylists(playlists);

   }, [pathname, currentUserID, playlistGuestMin, playlistsMin]);

   useEffect(()=> {
      if(!userSettings.actions.dragging){
         setActivateSongDrop(true);
     }
   }, [userSettings]);

   useEffect(()=> {
        //Set Active Menu
        if(pathname.includes('/music') && userSettings.mobileTab !== 'discover'){  setSelectedMenu('discover_latest'); setMobileTab('discover'); }
        if(pathname.includes('/artists') && userSettings.mobileTab !== 'discover'){  setSelectedMenu('discover_artists'); setMobileTab('discover'); }
        if(pathname.includes('/music/all') && userSettings.mobileTab !== 'discover'){  setSelectedMenu('discover_all'); setMobileTab('discover'); }
        if(pathname.includes('/popular') && userSettings.mobileTab !== 'discover'){  setSelectedMenu('discover_popular'); setMobileTab('discover'); }
        if(pathname.includes('/user/') && userSettings.mobileTab === 'queue'){  
         setSelectedMenu('');
         setMobileTab('other');
     }
   }, [pathname, userSettings, setMobileTab]);

   useEffect(()=> {
      if(!userplaylists.selectedPlaylist && playlistsMin && playlistsMin[0]){
         dispatch(selectPlaylist({ playlistID: playlistsMin[0].id }));
     }
   }, [userplaylists, playlistsMin, dispatch])


   const setCurrentChannel = useCallback((buttonClicked) => {
      if(buttonClicked){
          setSelectedMenu('channels');
      }
      if(pathname.includes('/channel/pop') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_pop'); setMobileTab('channels');  }
      if(pathname.includes('/channel/hiphop') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_hiphop'); setMobileTab('channels');  }
      if(pathname.includes('/channel/rnb') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_rnb'); setMobileTab('channels');  }
      if(pathname.includes('/channel/rock') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_rock'); setMobileTab('channels');  }
      if(pathname.includes('/channel/metal') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_metal'); setMobileTab('channels');  }
      if(pathname.includes('/channel/indie') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_indie'); setMobileTab('channels');  }
      if(pathname.includes('/channel/electronic') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_electronic'); setMobileTab('channels');  }
      if(pathname.includes('/channel/dance') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_dance');  setMobileTab('channels'); }
      if(pathname.includes('/channel/instrumental') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_instrumental');  setMobileTab('channels'); }
      if(pathname.includes('/channel/country') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_country');  setMobileTab('channels'); }
      if(pathname.includes('/channel/blues') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_blues');  setMobileTab('channels'); }
      if(pathname.includes('/channel/folk') && userSettings.mobileTab !== 'channels'){  setSelectedMenu('channels_folk');  setMobileTab('channels'); }
      
  }, [pathname, userSettings, setMobileTab]);

    useEffect(()=> {
      setCurrentChannel();
   }, [setCurrentChannel]);

   //  componentDidMount(){
   //      const playlistsHeight = document.getElementById('playlists').offsetHeight;
   //      this.setState({playlistsHeight: playlistsHeight});
   //      // if(userplaylists.currentPlaylist && userplaylists.currentSong ){
   //      //     dispatch(selectPlaylist({playlistID: userplaylists.currentPlaylist}));
   //      // }
        
   //  }

   const selectThePlaylist = (playlistID, playlistSlug='') => {
        dispatch(selectPlaylist({playlistID}));
        navigate(`/`);
        //If its a public profile, Change the route to /user/:username/:playlistSlug
        // if(playlistSlug && this.props.author ){
        //     navigate(`/user/${this.props.author}/${playlistSlug}/`);
        // }
        
    } 

   const showCreatePlaylist = () => {
        if(currentUserID){
            dispatch(showAddtoPlaylist());
        }else{
            setSignupModal(true);
        }
        
    }

   const showSignupArea = () => {
        setSignupModal(false);
        dispatch(showUserArea());
    }

   const renderPlayLists = () => {
          //console.log('renderPlayLists:',this.props);
          const allPlaylistsMin =  currentUserID && playlistsMin ? playlistsMin : playlistGuestMin;
          const currentPlaylist = userplaylists.selectedPlaylist ? userplaylists.selectedPlaylist : allPlaylistsMin && allPlaylistsMin.length > 0 && allPlaylistsMin[0].id
          const currentlyPlaying = userplaylists && userplaylists.currentSong ? userplaylists.currentPlaylist :'';
          

            return(
                <div className="myPlaylists">
                    {userSettings.actions.dragging && activateSongDrop && 
                        <PlayListGroupDropZone activateSongDrop={()=> setActivateSongDrop(false)} />
                    }
                    <div className="playlistGroupScroll_wrap customScrollbar">

                            <div className="playlists__Button" onClick={showCreatePlaylist}>
                              <button><span>+</span> New Playlist</button>
                           </div>
                            {userIsAuthor ?
                                <PlayListGroupWrapper
                                dragging={userSettings.actions.dragging}
                                draggingFrom={userSettings.actions.draggingFrom}
                                playlists={playlists}
                                currentlyPlaying={currentlyPlaying}
                                currentPlaylist={currentPlaylist}
                                selectedPlaylist={selectThePlaylist}
                                uid={currentUserID}
                                />
                            :
                                playlists && 
                                <ul>
                                    {playlists.map((playlist, index) => {
                                        return (
                                            <li 
                                            key={playlist.id}
                                            className={`playlistsItem ${currentPlaylist === playlist.id ? 'active': ''}`}
                                            onClick={()=> selectThePlaylist(playlist.id, playlist.slug ? playlist.slug : '')}>
                                                <button>{playlist.title}<span>{playlist.count? playlist.count : 0}</span></button>
                                            </li>
                                            )
                                        })
                                    }
                                </ul>
                            }

                    </div>
                    
                </div>
              );

         
      }

      const renderSavedPlaylists = () => {
        if(userIsAuthor && savedPlaylists && savedPlaylists.length > 0){
            return (
                <div className="savedPlaylists">
                    <div className="playlistGroupScroll_wrap customScrollbar">
                        <div>
                            {savedPlaylists.map((savedPl)=> {
                                return(
                                    <li key={`${savedPl.author}-${savedPl.slug}`} className={`playlistsItem`} >
                                        <Link to={`/user/${savedPl.author}/${savedPl.slug}/`}>{savedPl.title}</Link>
                                    </li>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )
        }else{
             return <p className="no_saved">{currentUserID ? "No Saved Playlists" : "Please Login to access your Saved Playlists"}</p>
        }
      }



      return(
         <div>
               <div className={`playlists_wrapper ${userIsAuthor ? 'playlists_wrapper-owner' : 'playlists_wrapper-public'}`}>
                  <div className="logo">
                     {userSettings.mobile ? <img src={logoM} alt="Yhimsical" /> : <img src={logo} alt="Yhimsical" />}
                  </div>

                  <div id="playlists">
                     
                     {userSettings.mobile ?
                              <div className={`playlists__menu  playlists__menu-${selectedMenu} playlists__menu--menu`}>
                                 <button aria-label="Queue" onClick={()=> { navigate(`/`); setMobileTab('queue'); } } className={`mobile-tab ${userSettings.mobileTab === 'queue' ? 'mobile-tab--active' : ''}`}>
                                       <Icon icon="ios-play" fontSize="14px" color="#fff" /> Queue
                                 </button>
                                 <button aria-label="Playlists" onClick={()=> { setMobileTab('playlists'); navigate(`/`); } } className={`mobile-tab ${userSettings.mobileTab === 'playlists' ? 'mobile-tab--active' : ''}`}>
                                       <Icon icon="md-list" fontSize="14px" color="#fff" /> Playlists
                                 </button>
                                 
                                 <button aria-label="Discover" onClick={()=> {setMobileTab('discover'); navigate(`/music/`); } } className={`mobile-tab ${userSettings.mobileTab === 'discover' ? 'mobile-tab--active' : ''}`}>
                                       <Icon icon="md-musical-notes" fontSize="14px" color="#fff" /> Discover
                                 </button>

                                 <button aria-label="Channels" onClick={()=> {setMobileTab('channels');navigate(`/channel/hiphop`);}  } className={`mobile-tab mobile-tab--channels ${userSettings.mobileTab === 'channels' ? 'mobile-tab--active' : ''}`}>
                                       <Icon icon="md-desktop" fontSize="14px" color="#fff" /> Channels 
                                 </button>

                              </div>
                           :
                              <div className={`playlists__menu  playlists__menu-${selectedMenu}`}>

                              <div className={`playlists__menu__item playlists__menu__discover ${selectedMenu.includes("discover") ? 'active_menu' : ''}`}>
                                 <button aria-label="Discover" onClick={()=> setSelectedMenu('discover')}>
                                       <Icon icon="md-musical-notes" fontSize="14px" color="#fff" /> Discover {selectedMenu.includes("discover") ? '-' : '+'}
                                 </button>
                                 {selectedMenu.includes("discover")  &&
                                       <ul>
                                          <li className={selectedMenu === "discover_latest" ? 'active' : ''} onClick={()=> setSelectedMenu('discover_latest')}><Link to="/music">Latest Tracks</Link></li>
                                          <li className={selectedMenu === "discover_all" ? 'active' : ''} onClick={()=> setSelectedMenu('discover_all')}><Link to="/music/all">All Tracks ({new Date().getFullYear()})</Link></li>
                                          <li className={selectedMenu === "discover_popular" ? 'active' : ''} onClick={()=> setSelectedMenu('discover_popular')}><Link to="/popular">Popular Tracks</Link></li>
                                          <li className={selectedMenu === "discover_artists" ? 'active' : ''} onClick={()=> setSelectedMenu('discover_artists')}><Link to="/artists">All Artists</Link></li>
                                       </ul>
                                 }
                              </div>

                              <div className={`playlists__menu__item playlists__menu__channels ${selectedMenu.includes("channels") ? 'active_menu' : ''}`}>
                                 <button aria-label="Channels" onClick={()=> setCurrentChannel(true)}>
                                       <Icon icon="md-desktop" fontSize="14px" color="#fff" /> Channels <span>NEW</span> {selectedMenu.includes("channels") ? '-' : '+'}
                                 </button>
                                 {selectedMenu.includes("channels")  &&
                                       <div className="playlistGroupScroll_wrap customScrollbar">
                                          <ul>
                                             <li className={selectedMenu === "channels_pop" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_pop')}><Link to="/channel/pop">Pop</Link></li>
                                             <li className={selectedMenu === "channels_hiphop" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_hiphop')}><Link to="/channel/hiphop">Hiphop</Link></li>
                                             <li className={selectedMenu === "channels_rnb" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_rnb')}><Link to="/channel/rnb">RnB</Link></li>
                                             <li className={selectedMenu === "channels_rock" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_rock')}><Link to="/channel/rock">Rock</Link></li>
                                             <li className={selectedMenu === "channels_metal" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_metal')}><Link to="/channel/metal">Metal</Link></li>
                                             <li className={selectedMenu === "channels_indie" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_indie')}><Link to="/channel/indie">Indie</Link></li>
                                             <li className={selectedMenu === "channels_electronic" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_electronic')}><Link to="/channel/electronic">Electronic</Link></li>
                                             <li className={selectedMenu === "channels_dance" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_dance')}><Link to="/channel/dance">Dance</Link></li>
                                             <li className={selectedMenu === "channels_instrumental" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_instrumental')}><Link to="/channel/instrumental">Instrumental</Link></li>
                                             <li className={selectedMenu === "channels_country" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_country')}><Link to="/channel/country">Country</Link></li>
                                             <li className={selectedMenu === "channels_blues" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_blues')}><Link to="/channel/blues">Blues</Link></li>
                                             <li className={selectedMenu === "channels_folk" ? 'active' : ''} onClick={()=> setSelectedMenu('channels_folk')}><Link to="/channel/folk">Folk</Link></li>
                                          </ul>
                                       </div>
                                 }
                              </div>

                              <div className={`playlists__menu__item playlists__menu__myPlaylist ${selectedMenu === 'mine' ? 'active_menu' : ''}`}>
                                 <button aria-label="Playlists" onClick={()=> setSelectedMenu('mine')}>
                                       <Icon icon="md-list" fontSize="14px" color="#fff" /> My Playlists ({currentUserID && playlistsMin ? playlistsMin.length : 1}) {selectedMenu === 'mine'? '-' : '+'}
                                 </button>
                                 { selectedMenu === 'mine' && renderPlayLists() }
                              </div>

                              <div className={`playlists__menu__item playlists__menu__savedPlaylist  ${selectedMenu === 'saved' ? 'active_menu' : ''}`}>
                                 <button aria-label="Saved" onClick={()=> setSelectedMenu('saved')}>
                                       <Icon icon="md-heart" fontSize="14px" color="#fff" />  Saved Playlists ({currentUserID && savedPlaylists ? savedPlaylists.length : 0}) {selectedMenu === 'saved'? '-' : '+'}
                                 </button>
                                 { userIsAuthor && selectedMenu === 'saved' && renderSavedPlaylists() }
                              </div>

                              <div className={`playlists__menu__item playlists__menu__library  ${userSettings.showLibModal ? 'active_menu' : ''}`}>
                                 <button aria-label="Library" onClick={()=> dispatch(showLibraryModal())}>
                                       <Icon icon="md-appstore" fontSize="14px" color="#fff" /> Library
                                 </button>
                              </div>

                              </div>
                     }

                     {userSettings.mobile && userSettings.mobileTab === 'saved' && <div id="mobile-tab-saved">{renderSavedPlaylists()}</div>}
                  </div>
                  {userSettings.mobile &&
                     <div className="siteBG">
                           <div className="siteBG_color"/>
                           <div className="siteBG_fade"/>
                     </div>
                  }
               </div>

               <Modal title={`Login to Create a Playlist`} show={signupModal} maxWidth={400} closeModal={()=> setSignupModal(false)}>
                  <div className="signupModal_wrapper">
                     <p>Please login or Signup to Create New Playlists</p>
                     <button onClick={showSignupArea}>Login</button>
                  </div>
               </Modal>
         </div>  
      )
    
    
}

export default PlayListGroup;