import React from 'react';
import Icon from '../Icon/Icon';
import SelectField from '../SelectField/SelectField';

class AddChannelSong extends React.PureComponent{

    state = {
        playlistSelect:'queueOne'
    }

    addSong = () => {
        this.props.addSong(this.state.playlistSelect);
    }

    
    render(){
        const confirmIcon = <Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/>;
        const cancelIcon = <Icon icon="md-close" fontSize="18px" color="#ffffff"/>;
        let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
        if(this.props.playlistsMin && this.props.playlistsMin.length > 0){ queuePlaylists = [...queuePlaylists, ...this.props.playlistsMin]  }
        const copySelectedPlaylist = queuePlaylists.filter((plist)=>  plist['id'] === this.state.playlistSelect  );

        return(
            <div className="song__actions__confirm  song__actions__confirm-copy">
                <span>Add Song to</span>
                <SelectField
                  value={copySelectedPlaylist && copySelectedPlaylist[0] && copySelectedPlaylist[0].id}
                  onChange={(value)=> this.setState({ playlistSelect: value })}
                  placeholder="Select Playlist" 
                  slim={true}
                  showSearch={false}
                  options={queuePlaylists.map((playlist)=> ({label: playlist.title, value: playlist.id }))}
                  />
                <button className="confirm_yes" onClick={this.addSong}>{confirmIcon}</button>
                <button className="confirm_no" onClick={this.props.hideAddModal}>{cancelIcon}</button>
            </div>
        );
    }
}

export default AddChannelSong;