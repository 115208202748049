import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import { useLongPress } from 'use-long-press';
import { addToQueue } from '../../redux/queueSlice';
import Icon from '../Icon/Icon';

const LibrarySong = (props) =>{

   const {song, station, style, active, playlists, setSelected, selectSong, updateSong, playSong, addSong, addSationSong, hasSelected, isMobile} = props;

   const  [playListID, setPlayListID] =  useState('queueOne');
   const  [libSongTitle, setLibSongTitle] =  useState(song && song.title ? song.title : '') ;
   const  [libSongArtist, setLibSongArtist] =  useState(song && song.artist ? song.artist : '');
   const  [libSongAlbum, setLibSongAlbum] =  useState(song && song.album ? song.album : '');
   const  [showMoreOptions, setShowMoreOptions] =  useState(false);
   const  [showAddOptions, setShowAddOptions] =  useState(false);
   const  [showEditOptions, setShowEditOptions] = useState(false);
   const  [swipeRight, setSwipeRight] =  useState(false);
   const  [swipeLeft, setSwipeLeft] =  useState(false);
   const [press, setPress] = useState(false);
   const  [message, setMessage] =  useState(null);

   const dispatch = useDispatch();
   const changeTitle =(event) => {  setLibSongTitle(event.target.value);  }
   const changeArtist =(event) => {  setLibSongArtist(event.target.value)  }
   const changeAlbum =(event) => {  setLibSongAlbum(event.target.value)  }

   const showMore = () => {
        selectSong(song); 
        setSelected(); 
        console.log(showMoreOptions);
        setShowMoreOptions(!showMoreOptions);
        setShowAddOptions(false);
        setShowEditOptions(false);
   }

   const canceloptions = (deselectSong=true) => {
      setShowAddOptions(false); 
      setShowMoreOptions( false); 
      setShowEditOptions( false);
      setLibSongTitle( song && song.title ? song.title : '' );
      setLibSongArtist( song && song.artist ? song.artist : '');
      setLibSongAlbum( song && song.album ? song.album : '');
      setSelected(); 
      if(deselectSong){
         selectSong(song); 
      }
    }

   const updateTheSong = () => {
        updateSong({...song, title:libSongTitle, artist: libSongArtist, album: libSongAlbum }); 
        canceloptions();
    }

   const playTheSong = () => {
        return playSong(song)
    }


   const swipeSongRight = () => {
      setSwipeRight(true);
      setTimeout(() => {
         playSong(song);
         setSwipeRight(false);
      }, 500);
   }

   const swipeSongLeft = () => {
      setSwipeLeft(true);
      setTimeout(() => {
            setSwipeLeft(false);
            setMessage('Song Added to Queue')
            dispatch(addToQueue({song, queue:'queueOne', play: false}));
            
      }, 500);

      setTimeout(() => {
            setMessage(null);
      }, 1800);
   }


   const swipeHandlers = useSwipeable({
      onSwipedLeft: (eventData) => isMobile && swipeSongLeft(),
      onSwipedRight: (eventData) => isMobile && swipeSongRight(),
      onTouchStartOrOnMouseDown: (eventData) => console.log('touchStart', eventData),
      onTouchEndOrOnMouseUp: (eventData) => console.log('touchEnd', eventData),
   });

   const longPressHandler = useLongPress(() => {
      if(isMobile){
         console.log('Long pressed!');
         setPress(true);
         setTimeout(() => {
               setPress(false);
               showMore();
               //mobileSelectSong(song);
               //addToQueue(song, 'queueOne', false);
         }, 500);
      }

    });

   const confirmIcon = <Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/>;
   const cancelIcon = <Icon icon="md-close" fontSize="18px" color="#ffffff"/>;
   let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
   if(playlists && playlists.length > 0){ queuePlaylists = [...queuePlaylists, ...playlists]  }

   return(
      // <Hammer onSwipeRight={this.swipeSongRight} onSwipeLeft={this.swipeSongLeft}>
            <div style={style} className={`libSong ${active? 'active':'' }  ${press ? 'pressAnim':''} ${swipeRight ? 'swipeAnim':''} ${swipeLeft ? 'swipeAnimLeft':''} ${hasSelected && !active ? 'obsecureLibSong':''}`} onDoubleClick={()=> playSong(song)}>
               <div className='songPresser' {...swipeHandlers} {...longPressHandler()}>
               {song.local  && <span className={`localSong`} title={song.local}></span>}
               {message && <span className="songMsg">{message}</span>}

               <div className="libSong__title">{song.title}</div>
               <div className="libSong__artist">{song.artist ? song.artist : '-'}</div>
               <div className="libSong__album">{song.album ? song.album : '-'}</div>

               

               {/* <td>{song.playlist ? song.playlist : '-'} */}
               <div className="libsongActionsWrap" data-showmore={showMoreOptions}>
                  {station && <button className="libaddToStation" onClick={()=> addSationSong(song)}><Icon icon="md-add" fontSize="16px" color="#ffffff"/> Add to Station</button>}
                  {!station && <button className="libPlaySong" onClick={playTheSong}>&#9658;</button>}
                  {!station && <button className="libOptionsSong" onClick={showMore}>&#8226; &#8226; &#8226;</button> }
                  
                  {showMoreOptions && 
                     <div className="libsongActions">
                     {!station &&
                        <ul>
                              <li onClick={()=> { setShowAddOptions(true); setShowEditOptions(false); }}><Icon icon="md-add" fontSize="16px" color="#ffffff"/> Add to Playlist</li>
                              <li onClick={()=> { setShowAddOptions(false); setShowEditOptions(true); }}><Icon icon="md-create" fontSize="16px" color="#ffffff"/> Edit Song</li> 
                        </ul>
                     }

                        {showAddOptions && 
                              <div className="libAddSong">
                                 <div className="playlistInfo__wrap">
                                    <div className="libPlaylists">
                                          <div className="LibSongScroll_wrap customScrollbar">
                                             <div>
                                                {queuePlaylists && queuePlaylists.map((plist, index)=> {
                                                      return <button 
                                                      key={plist.id} 
                                                      className={playListID === plist.id ? 'active': ''}
                                                      onClick={()=> setPlayListID(plist.id)}>
                                                      {playListID === plist.id && <Icon icon="md-checkmark" fontSize="16px" color="#fff"/>}
                                                      {plist.title}
                                                      </button>
                                                })}
                                             </div>
                                          </div>
                                    </div>
                                    <div className="libsongActions__buttons">
                                          <button className="libConform" onClick={()=> { addSong(song, playListID); canceloptions(false); }}>{confirmIcon}</button>
                                          <button className="libCancel" onClick={canceloptions}>{cancelIcon}</button>
                                    </div>          

                                 </div>

                              </div>
                        }
                        {showEditOptions && 
                              <div className="libEditSong">
                                 <div className="libEditSong__form">
                                    <input type="text" name="libSongTitle" value={libSongTitle} onChange={changeTitle} placeholder="Title" />
                                    <input type="text" name="libSongArtist" value={libSongArtist} onChange={changeArtist} placeholder="Artist" />
                                    <input type="text" name="libSongAlbum" value={libSongAlbum} onChange={changeAlbum} placeholder="Album" />
                                 </div>  
                                 <div className="libsongActions__buttons">
                                    <button className="libConform"onClick={updateTheSong}>{confirmIcon}</button>
                                    <button className="libCancel" onClick={canceloptions}>{cancelIcon}</button>
                                 </div>  
                              </div>
                        }

                     </div>
                  }
               </div>
               </div>
            </div>
      // </Hammer>
   )
    
}

export default LibrarySong;
