import React, { useCallback, useEffect, useState, useRef } from 'react';
import axios from 'axios'
import Icon from '../Icon/Icon';
import {  cleanYoutubeTitle, getArtistAlbums, getLastSongOrder  } from '../../helpers';
import Tracks from '../../components/Discover/Tracks';
import ArtistAlbums from './ArtistAlbums';
import TrackSkeleton from '../Loader/TrackSkeleton';
import Loader from '../Loader/Loader';
import OverlayScreen from '../PRO/OverlayScreen';

const SearchArtist = (props) =>  {
   const { queue, userplaylistsMin, uid, guestPlaylists, search, station, addSationSong, addSong, guestAdd, mobile } = props;
   const searchArtistForm = useRef(null);
   const [songs, setSongs]  = useState(null);
   const [artist, setArtist]  = useState(null);
   const [error, setError]  =  useState('');
   const [loading, setLoading]  =  useState(false);
   const [searching, setSearching]  =  useState(false);
   const [importSongInfo, setImportSongInfo]  =  useState(true);
   const [searchString, setSearchString]  =  useState(search.tab === 'artist' && search.string ?  search.string : '');
   const [showSongsTab, setShowSongsTab]  =  useState(false);
   const [totalAlbumPages, setTotalAlbumPages]  =  useState(0);
   const [currentSearchPageNum, setCurrentSearchPageNum]  =  useState(0);
    
   const searchSubmit = useCallback(async(event) => {
        if(event){  event.preventDefault();    }

        if(searchString){
         setSongs(null);
         setArtist(null);
         setError('');
         setLoading(true);
            if(window.ga){
               window.ga('send', 'pageview', `search?q=${searchString}&c="artist"`);
            }
            //Get Artist Search Correction
            try {
               const artistResp = await axios.get(`https://api.yhimsical.com/getartist?artist=${searchString}`);
               if(artistResp?.data?.artist){
                  console.log('Found Artist: ', artistResp.data.artist);
                  setArtist({...artistResp.data.artist, albums:[]});
                  if(artistResp?.data?.artist?.mbid){
                     const {albums:foundAlbums=[], totalPages=0} = await getArtistAlbums(artistResp.data.artist.mbid);
                     console.warn('foundAlbums: ', foundAlbums , totalPages);
                     setArtist({...artistResp.data.artist, albums: foundAlbums});
                     setTotalAlbumPages(totalPages);
                  }
               }
            } catch (error) {
               console.warn('[ERROR] Search ERROR: ', error);
               setError('Error Making Search');
            }
            setLoading(false);
        }
    }, [searchString])

   useEffect(()=> {
      if(search.string && !songs){
         searchSubmit();
      }
   }, [search.string, songs, searchSubmit])

   const searchYoutube = (title) => {
        if(!uid){ return;  }
        setSearching(true);
        const searchString = `${artist.name} ${title}`;
        axios.get(`https://api.yhimsical.com/searchyt?q=${searchString}`).then((res)=> {
         console.log('[SUCCESS] searchYoutube result: ', res?.data?.result);
         if(res?.data?.result){
            const updatedSongs = res.data.result.map(({title, ID, duration, release_date, views=null})=> ({ id: ID, youtube: ID, title, duration, release_date, views }));
            setSongs(updatedSongs);
         }
         }).catch((err)=> {
            console.warn('[ERROR] searchYoutube: ', err);
         }).finally(()=> {
            setSearching(false);
         })
    }

   const selectedArtistSong = (title, album) => {
      setShowSongsTab(true);
      return searchYoutube(title);
   }

   const loadMore = async ()=> {
      const newSearchPageNum = currentSearchPageNum+1;

      setLoading(true);
      const {albums:foundAlbums=[], totalPages=0} = await getArtistAlbums(artist.mbid, newSearchPageNum);
      console.warn('foundAlbums: ', foundAlbums , totalPages);
      if(foundAlbums.length > 0){
         setCurrentSearchPageNum(newSearchPageNum);
         setArtist({...artist, albums: [...artist.albums, ...foundAlbums]});
      }
      setLoading(false);
   }

   const getSelectedSong  = (song, playlistID, play=false) => {
      console.log(song, playlistID);
      if(!importSongInfo){ return addtoPlaylist(play); }
      setLoading(true);
       let updatedSong = {...song}
      //Fetch Track Name and Artist Name
      axios.get(`https://api.yhimsical.com/gettrackdetails?title=${cleanYoutubeTitle(song.title)}&youtube=${song.youtube}`).then((result)=> {
          console.log('[SUCCESS] result: ', result.data);
          if(result?.data?.trackDetails?.artist){
             updatedSong.artist = result.data.trackDetails.artist;
             updatedSong.title = result.data.trackDetails.title;
             if(result?.data?.trackDetails?.cover){  updatedSong.cover = result.data.trackDetails.cover;  }
             if(result?.data?.trackDetails?.album){  updatedSong.album = result.data.trackDetails.album;  }
          }
      }).catch((err)=> {
          console.log('[ERROR] getSelectedSong: ', err);
      }).finally(()=> {
          addtoPlaylist(updatedSong, playlistID, play);
      })
   } 

   const playSong = (song) => {
      console.log(song, queue.selected);
      if(song.id === queue.playing.songId){
         addtoPlaylist(song, queue.selected, true);
      }else{
         getSelectedSong(song, queue.selected, true);
      }
   }

   const addtoPlaylist = (selectedSong, playListID, play=false) => {
      // console.log(selectedSong, playListID);
      if(selectedSong && selectedSong.title && selectedSong.youtube && playListID){
         const queueSelected = playListID === 'queueOne' || playListID === 'queueTwo' || playListID === 'queueThree';
         const selectedQ = queueSelected ? queue[playListID] : null;
         const queueHasSong = queue[queue.selected].filter(sng => sng.id === selectedSong.id).length > 0 ? true: false;

         const orderValue = queueSelected ? queue[queue.selected].length : getLastSongOrder(userplaylistsMin, playListID);
         const updatedSelectedSong = { ...selectedSong,  added: parseInt((new Date().getTime() / 1000).toFixed(0), 10), order: orderValue }

         // console.log(selectedQ, queueHasSong, updatedSelectedSong);

         if(!uid && !selectedQ){
            console.log('add to Untitled Playlist');
            guestAdd({...updatedSelectedSong, id: updatedSelectedSong.youtube });
         }else{
            addSong(uid, playListID, updatedSelectedSong, play, queueHasSong);
         }

         // //Then Clear the currently selected Song
         setTimeout(()=> {
            setLoading(false);
         }, queueSelected ? 200 : 2500);
      }
   }


   return (
      <div id="SearchArtists">

            <div className="searchbar">
               <form id="artists__form" onSubmit={searchSubmit} ref={searchArtistForm} >
                  <input type="text" name="searchartists" placeholder="Search Artist.." aria-label="Search Artist.." onChange={(event)=> setSearchString(event.target.value) } value={searchString} />
                  <button type="submit"><Icon icon="md-search" fontSize="24px" color="#ffffff"/></button>
               </form>
               <div className="importSongInfoCheck custom_checkbox">
                  <input 
                        type="checkbox" 
                        id="importSongInfo_switch"
                        name="importSongInfo" 
                        value={importSongInfo} 
                        onChange={()=> setImportSongInfo(!importSongInfo )} 
                        checked={importSongInfo }
                     />
                  <label htmlFor="importSongInfo_switch">Toggle</label>
                  <span>Auto Import Song info on Add/Play</span>
               </div>
            </div>

            <div className={`searchresults ${loading ? 'loading' : '' }`}>
               <div className="searchresults__iner">
                  
                  
                  <div className="searchresults__left">
                        <div className="searchresults__left__head"><span>1</span> Select Song</div>
                        <div className="searchresults__left__content">
                        {artist ? 
                           <div className="addSongScroll_wrap customScrollbar">
                              <div>
                                    <ArtistAlbums artist={artist} selectedSong={selectedArtistSong} />
                                    {totalAlbumPages > 1 && totalAlbumPages !== currentSearchPageNum && (
                                       <div className='artistSearch__loadMore' onClick={()=> loadMore() }>Load More ({currentSearchPageNum+1}/{totalAlbumPages})</div>
                                    )}
                              </div>
                           </div>
                        :
                           <p>Search for an Artist</p>
                        }
                        </div>
                  </div>

                  <div className={`searchresults__middle ${showSongsTab ? 'showSongsTab':''}`}>
                        <div className="searchresults__middle__head"><span>2</span> Search Youtube</div>
                        {mobile && <button onClick={()=> setShowSongsTab(false)}><Icon icon="ios-arrow-round-back" fontSize="28px" color="#ffffff"/></button>}
                        <div className="searchresults__middle__content">
                        
                        {error && <p>{error}</p>}

                        {(!uid) ?
                           <OverlayScreen 
                           className="searchArtist_block" 
                           title="Login" 
                           message="Login to search all the Songs of this Artist."
                           action="login"
                           button="Login"
                           />
                        :
                           !songs && !searching ? 
                              <p>Select a Song to Search Youtube</p>
                           :
                              <div className="addSongScroll_wrap customScrollbar">
                                    <div>
                                       {searching ? 
                                          <TrackSkeleton />
                                       :
                                          <Tracks 
                                          searchTrack={true}
                                          guest={!uid}
                                          guestPlaylists={guestPlaylists}
                                          songs={songs} 
                                          addToPlaylist={getSelectedSong} 
                                          station={station}
                                          addToStation={song =>  addSationSong(song)}
                                          playSong={playSong} 
                                          allPlaylists={userplaylistsMin} 
                                          slider={false} />
                                       }
                                    </div>
                              </div>
                        }




                        {/* {(!uid || !props.pro) ?
                           <OverlayScreen 
                           className="searchArtist_block" 
                           title="Upgrade to PRO" 
                           message="Upgrade to PRO to search all the Songs of this Artist."
                           action="pro"
                           button="Upgrade"
                           />
                        :
                           !songs && !searching ? 
                              <p>Select a Song to Search Youtube</p>
                           :
                              <div className="addSongScroll_wrap customScrollbar">
                                    <div>
                                       {searching ? 
                                          <TrackSkeleton />
                                       :
                                          <Tracks 
                                          searchTrack={true}
                                          guest={!uid}
                                          guestPlaylists={guestPlaylists}
                                          songs={songs} 
                                          addToPlaylist={getSelectedSong} 
                                          playSong={playSong} 
                                          allPlaylists={userplaylistsMin} 
                                          slider={false} />
                                       }
                                    </div>
                              </div>
                        } */}

                        </div>
                  </div>

               </div>
               {loading && <div className="addSongLoader"><Loader /></div>}
            </div>
            
      </div>
   );

}

export default SearchArtist;