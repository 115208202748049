import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as EmailValidator from 'email-validator';

const verbs = {
    username:'Username Already Taken',
    emailInvalid: 'Invalid Email',
    emailTaken:'Email is already in use'
}


export const checkUniqueUsername = (username) => {
    return new Promise((resolve, reject) => { 
        if(!username) resolve();
        const db = firebase.firestore();
        const usersRootRef = db.collection('Users');
        const usernameQuery = usersRootRef.where('username', '==', username).get();

        return usernameQuery.then((snapShot) => {
            if(snapShot.size > 0) {
                resolve(verbs.username);
            } else {
                resolve();
            }
        }).catch((error) => console.log('username error:',error.message));
     });
}

export const checkUniqueEmail = (email) => {
    return new Promise((resolve, reject) => {  
        if(!email) resolve();
        const db = firebase.firestore();
        const usersRootRef = db.collection('Users');
        const emailQuery = usersRootRef.where('email', '==', email).get();

        if(EmailValidator.validate(email) === false) { 
            resolve(verbs.emailInvalid);
        }else{
            return emailQuery.then((snapShot) => {
                if(snapShot.size > 0) {
                    resolve(verbs.emailTaken);
                } else {
                    resolve();
                }
            }).catch((error) => console.log('email error:',error.message));
        }
    });
} 

export const checkRequiredFields = (fieldNames={}) => {
    return new Promise((resolve, reject) => { 
        let requiredEmpty = [];
        Object.keys(fieldNames).map((key)=> {
            if(!fieldNames[key].value){
                document.querySelector('.label_'+fieldNames[key].label).classList.add('error');
                return requiredEmpty.push(fieldNames[key].label+' Empty');
            }else{
                return document.querySelector('.label_'+fieldNames[key].label).classList.remove('error');
            }
        })
        setTimeout(()=> resolve(requiredEmpty), 100);
    })
}

export const usernameValidation = (username='') => {
    if(username){
        let errors = [];
        typeof username !== 'string' && errors.push('No HTML or special characters allowed in username.');
        username.length < 4 && errors.push('username should be more than 4 characters');
        username.length > 20 && errors.push('username should not be more than 20 characters');
        (/^[a-z0-9_.]+$/).test(username) === false && errors.push('No Capital Letter or special characters/space allowed in username');
        username === 'admin' && errors.push('Username cannot be admin');
        return errors;
    }else{
      return ['Username is Required!']
    }

}

export const stringValidation = (string=[], limitChar=50) => {
    if(string && string.length > 0){
        console.log(string);
        let errors = [];
        string.forEach((str)=> {
            if(typeof str !== 'string' || str.match(/(<([^>]+)>)/ig,"") !== null ){
                console.log(typeof str !== 'string', str.match(/(<([^>]+)>)/ig,""));
                if(limitChar && (str.length > limitChar)){
                    errors.push(`No HTML or special characters allowed and no fields should have more than 50 characters.`);
                }else{
                    errors.push(`No HTML or special characters allowed.`);
                }
            }
        })
        return errors;
    }

}

export const isEmailValid = (email) => {
   return EmailValidator.validate(email) === false ? false : true
}