import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as EmailValidator from 'email-validator';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import Loader from '../../Loader/Loader'
import { updateProfilePhoto } from '../../../redux/userSlice';
import Icon from '../../Icon/Icon';



const Login = ({user, userSettings, setTab, firebase}) => {
   const dispatch = useDispatch();
   // const [username, setUsername] = useState('');
   const emailForm = useRef(null);
   const [error, setError] = useState('');
   const [showLogin, setShowLogin] = useState(false);
   const [loading, setLoading] = useState(false);

   useEffect(()=> {
      if(user && user.isEmpty === false){
         //console.log('User:', nextProps.user);
         setTab('myaccount');
     }
   }, [user, setTab])

    const onEmailLogin = (event) => {
        event.preventDefault();
        const formData = new FormData(emailForm.current);
        const email = formData.get('email');
        const password = formData.get('password');
        if(EmailValidator.validate(email) === false){  
            setError('Invalid Email'); 
        }else{
            setLoading(true); 
            firebase.auth().signInWithEmailAndPassword(email, password)
            .then( (loginData)=>{
                setLoading(false); 
                console.log('Login Data: ',loginData);
            }).catch( (err)=> {
                console.log(err);
                let msg = 'Login Error! Please try Again.';
                if(err.code === "auth/user-not-found"){ msg = 'User Not Found!'}
                if(err.code === "auth/wrong-password"){ msg = 'Invalid Email or Password'}
                setError(msg);
                setLoading(true);
            });
        }
    }

    const onSocialLogin = (provider) => {
      const auth = getAuth();
      console.log(provider, 'login clicked!', window.Windows ? 'Windows RT detected, set to redirect!' : 'Windows RT not found, set to popup', auth);
      let theProvider = null; let providerInstance = null;
      if(provider === 'google'){
         theProvider = new GoogleAuthProvider();
         providerInstance = GoogleAuthProvider;
      }
      if(provider === 'facebook'){
         theProvider = new FacebookAuthProvider();
         providerInstance = FacebookAuthProvider;
      }
      signInWithPopup(auth, theProvider).then((result) => {
         // This gives you a Google Access Token. You can use it to access the Google API.
         const credential = providerInstance.credentialFromResult(result);
         const token = credential.accessToken;
         // The signed-in user info.
         const user = result.user;
         console.log(token, user, result?.user?.providerData[0]?.uid);

         if(result?.user?.providerData[0]?.uid && result?.user?.providerData[0]?.providerId === "facebook.com" && token){
            const fbid = result?.user?.providerData[0]?.uid;
            fetch(`https://graph.facebook.com/${fbid}/picture?type=large&access_token=${token}`).then(res => res.blob())
            .then(blob => {
               let profileImage = new File([blob], 'avatar.jpg', {'type': "image/jpeg"});
               //console.log(profileImage);
               dispatch(updateProfilePhoto(profileImage));
            });
         }
      }).catch((error) => {
         // Handle Errors here.
         const errorCode = error.code;
         const errorMessage = error.message;
         // The email of the user's account used.
         const email = error.customData.email;
         // The AuthCredential type that was used.
         const credential = GoogleAuthProvider.credentialFromError(error);
         console.log(errorCode, errorMessage, email, credential);
         console.log(error);
      });

    }
    

      return(
         <div id="login">
               <h3>Login</h3>
               <p className="signup_msg">Singup free to Access Your Playlists from Anywhere</p>
               {loading && <div className="user_loader"><Loader /></div>}

               <div className={`login_wrapper ${loading ? ' loading_data':''}`}>
                  <div className="social_login">
                     <button className="facebook_login" onClick={()=> onSocialLogin('facebook')}>
                        <Icon icon="logo-facebook" fontSize="18px" color="#fff"/> Login With Facebook
                     </button>
                     {/* <button className="twitter_login" onClick={()=> onSocialLogin('twitter')}>
                        <Icon icon="logo-twitter" fontSize="18px" color="#fff"/> Login With Twitter
                     </button> */}
                     <button className="google_login" onClick={()=> onSocialLogin('google')}>
                        <Icon icon="logo-googleplus" fontSize="18px" color="#fff"/> Login With Google
                     </button>
                  </div>
                  <div className="email_login_wrapper">
                     <h4 className="email_login" onClick={()=> setShowLogin(!showLogin)}><Icon icon="ios-at-outline" fontSize="18px" color="#fff"/> Login With Email</h4>
                     
                     
                     {showLogin &&
                           <form id="signup_form" onSubmit={onEmailLogin} ref={emailForm}>
                              <input type="email" placeholder="Email" name="email" autoComplete="email" />
                              <input type="password" placeholder="Your Password" name="password" autoComplete="password" />
                              {error && <p className="errorMsg loginError">{error}</p>}

                              <button aria-label="Login" type="submit">Login</button>
                           </form>
                     }
                  </div>

                  <div className="signuploginroutes">
                     <p className="signuploginroutes"><button onClick={()=>setTab('signup')}>Sign Up with Email {'>'}</button></p>
                     <p className="signuploginroutes"><button onClick={()=>setTab('resetpassword')}>Forgot Password ?</button></p>
                  </div>

               </div>
               
         </div>
      );
    
}

export default Login;
