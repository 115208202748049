import React, { useState, useEffect, useRef } from 'react';
import update from 'immutability-helper';
import { useDispatch } from 'react-redux';
import { guestReorder } from '../../redux/guestPlaylistSlice';
import { reorderSongs } from '../../redux/songSlice';
// import VirtualList from 'react-tiny-virtual-list';
// import { Motion, spring } from 'react-motion';
import Song from './Song';


const getNodeClientBounds = node => {
    const el = node.nodeType === 1 ? node : node.parentElement;
    if (!el) {
      return null;
    }
    return el.getBoundingClientRect();
};

const SongWrapper = (props) =>  {
   const { isMobile, isQueue, mobileSelectedSong, mobileSelectSong, queue, selectedSong, selectSong, songs=[], 
   userIsAuthor, sortQueue, playlistsMin, playSong, draggingSong, playlistID, activeSong, uid} = props;
   const songHeight = isQueue ? 37 : 65;
   const dispatch = useDispatch();
   const container = useRef(null);
   const [selectedSongs, setSelectedSongs] = useState([]);
   const [selectedSongsIds, setSelectedSongsIds] = useState([]);
   const [activeSongId, setActiveSongId] = useState(null);
   //  const [draggedSongsIds, setDraggedSongsIds] = useState([]);
   //  const [insertIndex, setInsertIndex] = useState(-1);
   const [theSongs, setTheSongs] = useState(songs);
   const reorederdSongs = useRef([]);

   //  const [dragSongOrder, setDragSongOrder] = useState(0);
   //  const [hoverSongOrder, setHoverSongOrder] = useState(0);
   //  const [height, setHeight] = useState(400);



    useEffect(()=> {
      const sortedSongs = [...songs].sort(( a, b )=>  a.order - b.order);
      setTheSongs(sortedSongs);
      //   if(userActions && userActions.clearSelectedSongs){
      //       setSelectedSongs([]);
      //       setSelectedSongsIds([]);
      //       setDraggedSongsIds([]);
      //   }
    }, [songs]);

   //  useEffect(()=> {
   //    console.warn('theSongs Changed!!!', theSongs);
   //  }, [theSongs])

   //  componentDidMount(){
   //      songs && this.setState({songs: songs.sort(( a, b )=>  a.order - b.order), ordered: songs.sort(( a, b )=>  a.order - b.order)});

   //      let height = 400;

   //      if(queue){
   //          height = document.getElementsByClassName('playlistQueue')[0].offsetHeight;
   //      }else{
   //          height = document.getElementsByClassName('Playlist')[0].offsetHeight - 110;
   //      }

   //      this.setState({height: height});
   //  }


   const onSongDragStart = (dragItem) => {
        if(isMobile){ return }
        console.log(dragItem);
        const bounds = {};
        Array.from(container.current.childNodes).forEach((child, i) => {
            bounds[i] = getNodeClientBounds(child);
        });
        //   const updatedSongs = [...theSongs].map((song, index)=> ({...song, bounds: bounds[index] || {} }));
        //   console.warn('updatedSongs: ', theSongs, updatedSongs, songs);
        // setTheSongs(updatedSongs);
        setSelectedSongs(dragItem.songs);
        setSelectedSongsIds(dragItem.songs.map(c => c.id));
   }


	const moveSong = (dragId, hoverId, item) => {
         if(isMobile){ return }

         const dragIndex = theSongs.findIndex((el) => el.id === dragId);
         const hoverIndex = theSongs.findIndex((el) => el.id === hoverId);
       
         if( dragIndex === -1 ){  return console.log(hoverId)  }

         const updatedSongs = update(theSongs, {
            $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, theSongs[dragIndex]],
            ],
         });

         setTheSongs(updatedSongs);
         reorederdSongs.current = updatedSongs;
    }

    const reorder = (queue) => {
      if(isMobile){ return }
      // console.log(queue, reorederdSongs.current);
      if(reorederdSongs.current && reorederdSongs.current.length > 0){
         const orderedSongs = [...reorederdSongs.current].map((song, index)=> { console.log(song); return {...song, order: index} });
         setTheSongs(orderedSongs);
         reorederdSongs.current = [];

         if(queue && sortQueue){
            dispatch(sortQueue( {songs: orderedSongs, queue}))
         }else{
            if(!uid){
                console.log('Not User! ', orderedSongs);
                dispatch(guestReorder(orderedSongs));
            }else{
                let updateSongs = {};
                const clonedSongs = [...orderedSongs];
                clonedSongs.map( (song)=> {
                    const songID = song.id; 
                  //   delete song.id; 
                    delete song.bounds; 
                    return updateSongs[songID] = song;
                });
               dispatch(reorderSongs(uid, playlistID, updateSongs));
            }
         }
      }
    }

    const onSongSelectionChange =(songId, cmdKeyActive, ctrlKeyActive, shiftKeyActive) => {
        let slctedSongsIds = [];
        let activSongId;
    
        const songs = theSongs.slice();
        let previousSelectedSongsIds = selectedSongsIds.slice();
        let previousActiveSongId = activeSongId;
    
        if (cmdKeyActive || ctrlKeyActive) {
          if (previousSelectedSongsIds.indexOf(songId) > -1 && songId !== previousActiveSongId) {
            slctedSongsIds = previousSelectedSongsIds.filter(id => id !== songId);
          } else {
            slctedSongsIds = [...previousSelectedSongsIds, songId];
          }
          console.log('ctrlKey', slctedSongsIds);
        } else if (shiftKeyActive && songId !== previousActiveSongId) {
          const activeSongIndex = songs.findIndex(c => c.id === previousActiveSongId);
          const songIndex = songs.findIndex(c => c.id === songId);
          const lowerIndex = Math.min(activeSongIndex, songIndex);
          const upperIndex = Math.max(activeSongIndex, songIndex);
          slctedSongsIds = songs.slice(lowerIndex, upperIndex + 1).map(c => c.id);
          console.log('shiftKey', slctedSongsIds);
        } else {
          slctedSongsIds = [songId];
          activSongId = songId;
          if(selectedSong === songId){
            selectSong('');
          }else{
            selectSong(songId);
          }
          
        }
    
        const selectdSongs = songs.filter(c => slctedSongsIds.includes(c.id));
        setSelectedSongs(selectdSongs);
        setSelectedSongsIds(slctedSongsIds);
        if (activSongId) {
          setActiveSongId(activSongId);
        }

      }

      return(
         <div className="songWrapper customScrollbar" ref={container}>

            {theSongs && theSongs.length > 0 && theSongs.map((sng, index)=> {
               return <Song 
                        key={theSongs[index].id}
                        index={index}
                        order={index}
                        selectedSong={selectedSong}
                        mobileSelectedSong={mobileSelectedSong}
                        mobileSelectSong={mobileSelectSong}
                        queue={queue}
                        songID={theSongs[index].id} 
                        song={theSongs[index]} 
                        playlistsMin={playlistsMin} 
                        songfbKey={theSongs[index].id} 
                        playlistID={playlistID} 
                        uid={uid} 
                        playSong={playSong}
                        activeSong={activeSong} 
                        draggingSong={(value)=> dispatch(draggingSong(value))}
                        disabled={!userIsAuthor} 
                        moveSong={moveSong}
                        onSelectionChange={onSongSelectionChange}
                        onDragStart={onSongDragStart}
                        isSelected={!isMobile ? selectedSongsIds.includes(theSongs[index].id): selectedSong === theSongs[index].id}
                        isMobile={isMobile}
                        selectedSongs={selectedSongs}
                        userIsAuthor={userIsAuthor}
                        reorder={reorder}
                        style={{position: 'absolute', transform: 'translate3d(0, ' + (songHeight * index) + 'px, 0)', }}
                        />
            })}

         </div>

      );
   
}

export default SongWrapper;