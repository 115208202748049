import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-tooltip/dist/react-tooltip.css';
import 'sanitize.css';
import './styles/style.scss';
// import 'rc-select/assets/index.css';
// import App from './App';
import { Provider } from 'react-redux';
import { TooltipProvider } from 'react-tooltip';
// import { HistoryRouter as Router } from "redux-first-history/rr6";
import { BrowserRouter } from "react-router-dom";
import { TourProvider } from '@reactour/tour'
import firebase from "firebase/compat/app";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging/sw";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FirebaseAppProvider } from "reactfire";
// import {config} from './config'
import reportWebVitals from './reportWebVitals';
import store, { history } from './store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ProviderLayer from './ProviderLayer';
import tourSteps from './utils/tourSteps';

// replace console.log to disable log on production
if (process.env.NODE_ENV === 'production') {
   console.log = () => {}
}

// const config = {
//    apiKey: "AIzaSyDb7_vkMhQ5vLk6OQLE36UCxGrPvkDWwJY",
//    authDomain: "yhimsical-n.firebaseapp.com",
//    projectId: "yhimsical-n",
//    storageBucket: "yhimsical-n.appspot.com",
//    messagingSenderId: "334393112501",
//    appId: "1:334393112501:web:22b252a8bc1710169b8bfc"
// };

// const config = {
//    apiKey: `AIzaSyCBsVMQ1KQXMx3XRfweWmDSIgnsI1maKE8`,
//    authDomain: `yhimsical.firebaseapp.com`,
//    databaseURL: `https://yhimsical.firebaseio.com`,
//    projectId: `yhimsical`,
//    storageBucket: `yhimsical.appspot.com`,
//    messagingSenderId: 253787493649
// };

const config = {
   apiKey: "AIzaSyCE7z8M4nwWZKybWyudcnKpJ1PzQR06wSQ",
   authDomain: "yhimsical.firebaseapp.com",
   databaseURL: "https://yhimsical.firebaseio.com",
   projectId: "yhimsical",
   storageBucket: "yhimsical.appspot.com",
   messagingSenderId: "253787493649",
   appId: "1:253787493649:web:a21d5232791b864ee6e8b0"
 };

export const app = firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true, merge: true });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Provider store={store}>
         <BrowserRouter history={history}>
            <FirebaseAppProvider firebaseConfig={config}>
               <DndProvider backend={HTML5Backend}>
                  <TooltipProvider>
                     <TourProvider steps={tourSteps}>
                        <ProviderLayer />
                     </TourProvider>
                  </TooltipProvider>
               </DndProvider>
            </FirebaseAppProvider>
         </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export const storage = getStorage(app);
export const messaging = getMessaging(app);
