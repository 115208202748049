import React from 'react';
import UserMalePic from '../../assets/user_m.png';
import Loader from './Loader';

const StationSkeleton = (props)=>{
    return(
        <div className="stationSkeleton">
            <div className="station__inner">

                <div className="station__left">
                    <h4><span><img alt={props.stationName} src={UserMalePic} /></span> {`${props.stationName}'s Station`}</h4>
                    <div className="station__playlist"><Loader /></div>
                </div>
                
                <div className="station__right">
                    <div className="station__chat">
                        <div id="chatbox" className={`chatbox`}>
                            <h4>Chat</h4>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    );
}

export default StationSkeleton;