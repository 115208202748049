import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import * as EmailValidator from 'email-validator';
import { showUserArea } from '../../../redux/userSlice';
import { useDispatch } from 'react-redux';
import Icon from '../../Icon/Icon';

const ResetPassword = ({user, push, setTab, history}) =>  {
   const dispatch = useDispatch();
   const resetform = useRef(null);
   const [error, setError] = useState([]);
   const [loading, setLoading] = useState(false);
   const [complete, setComplete] = useState(false);
   const [changed, setChanged] = useState(false);
   const [code, setCode] = useState('');

    useEffect(()=> {
         document.body.classList.add('complete-registration');
         dispatch(showUserArea());
         if(history.location.search){
            let url = history.location.search;
            url = url.split('Code=');
            const filteredUrl = url[1].split('&');
            console.log(filteredUrl.length > 0 ? filteredUrl[0]: 'Code not Found!');
            if(filteredUrl.length > 0 ){
               firebase.auth().verifyPasswordResetCode(filteredUrl[0])
               .then((email) => {
                     //console.log('Code verified:', email);
                     setCode(filteredUrl[0]); 
               })
               .catch(() => {
                     setError( ['Invalid Reset Link or Link Expired']);
               })
               
            }else{
               setError( ['Invalid Reset Link or Link Expired']);
            }
         }
         return () => {
            document.body.classList.remove('complete-registration');
         }
    }, [dispatch, history.location])

    
    const onPassReset = (event) => {
        event.preventDefault();
        setError([]);

        const formData = new FormData(resetform.current);
        const resetemail = formData.get('resetemail');
        const pass = formData.get('pass');
        const newpass = formData.get('newpass');
        const newpassagain = formData.get('newpassagain');

        if(user && user.email && !code){
            changePassword(pass, newpass, newpassagain);
        }else if(code){
            changePasswordFromEmailLink(newpass, newpassagain);
        }else{
            resetPassword(resetemail);
        }
        
    }

    const changePassword = (pass, newpass, newpassagain) => {
        //Password Change for logged in user
        
        if(pass && newpass && newpassagain){
            console.log(pass, newpass, newpassagain);
            let totalErrors = []
            if(pass === newpass){   totalErrors.push('New Password should be different')  }
            if(newpass && newpass.length < 6){  totalErrors.push('Password Should be at-least 6 chracters')   }
            if(newpass && newpassagain && (newpass !== newpassagain)){  totalErrors.push('New Passwords Dont Match')  }
            if(totalErrors.length > 0){
                //console.log('Errors Found');
                setError(totalErrors);
            }else{
                //console.log('Success');
                setError([]);
                setLoading(true);
                //Change the Password
                const user = firebase.auth().currentUser;
                const credentials = firebase.auth.EmailAuthProvider.credential( user.email , pass );
                user.reauthenticateWithCredential(credentials).then( () => {
                    user.updatePassword(newpass).then(() => {
                        //console.log('Success');
                        setChanged(true);
                        setTimeout(()=> {
                            history.push('/');
                        }, 4000)
                    }).catch((error) => {
                        console.log('Error: ', error);
                        setError(['Unknown Error! Please try again.']);
                    });
                }).catch((err)=> {
                    //console.log(err);
                    setError(['You did not enter the correct current password']);
                }).finally(()=> {
                  setLoading(false);
                })

            }
        }else{
            //console.log('Passwords not provided!');
            setError(['Please fill up the Requied fields']);
        }
        
    }

    
    const changePasswordFromEmailLink = (newpass, newpassagain) => {
        console.log(newpass, newpassagain);
        let totalErrors = []
        if(newpass && newpass.length < 6){  totalErrors.push('Password Should be at-least 6 chracters')   }
        if(newpass && newpassagain && (newpass !== newpassagain)){  totalErrors.push('New Passwords Dont Match')  }
        if(totalErrors.length > 0){
            //console.log('Errors Found');
            setError(totalErrors);
        }else{
            console.log('Success! No Errors Found');
            setLoading(true);
            firebase.auth().confirmPasswordReset(code, newpass)
            .then(() => {
                // Success
                setChanged(true);
                setTimeout(()=> {
                    history.push('/');
                }, 4000)
            })
            .catch(() => {
                // Invalid code
                console.log('Errors Found');
                setError(['Invalid Reset Link or Link Expired']);
            }).finally(()=> {
               setLoading(false);
            })
        }
    }


    const resetPassword = (resetemail) => {
        //Password Reset for non logged in users
        setComplete(false);
        setLoading(true);
        if(resetemail){
            if(EmailValidator.validate(resetemail) === true) { 
                firebase.auth().fetchProvidersForEmail(resetemail).then((providers)=> {
                    //console.log('Providers: ',providers);
                    if(providers && providers.includes('password')){
                        //console.log('Hold on while we reset your password');
                        firebase.auth().sendPasswordResetEmail(resetemail).then(() =>{
                            console.log('Email Sent!');
                            setComplete(true)
                          }).catch((error) => {
                            console.log('Email Not Sent!', error);
                            setError(['Unknown Error! Please try again.']);
                            // An error happened.
                          });
                        
                    }else{
                        setError([`You signed-up with a ${providers[0].replace('.com', '')} account, hence you dont have a password. Please try logging in with ${providers[0].replace('.com', '')}.`])
                    }
                }).finally(()=> setLoading(false));
            }else{
                setError(['Invalid Email'])
            }
        }else{
            setError(['Please provide your email address'])
        }
    }

    const renderResetForm = () => {
        return (<div>
                {!code &&
                    <label className="label_pass">
                        <span>Old Password*</span>
                        <span className="error_msg">Required</span>
                        <input type="password" id="reg_password" placeholder="Old Password" name="pass" />
                    </label>
                }
                <label className="label_pass">
                    <span>New Password*</span>
                    <span className="error_msg">Required</span>
                    <input type="password" id="reg_password" placeholder="New Password" name="newpass"  />
                </label>
                <label className="label_passagain">
                    <span>New Password Again*</span>
                    <span className="error_msg">Required</span>
                    <input type="password" id="reg_passwordagain" placeholder="New Password Again" name="newpassagain" />
                </label>
            </div>)
    }


      return(
         <div id="reset" className={`${loading ? 'loading_data':''}`}>
               
                  <div>
                     <h3>Reset Password</h3>
                     {loading && <div className="loading"><Icon icon="ios-sync" rotate={true} fontSize="38px" color="#ffffff"/></div>}
                     <form id="signup_form" onSubmit={onPassReset} ref={resetform}>
                           
                           {(user && user.email) || (code) ?
                              renderResetForm()
                           :
                              <label><input type="email" name="resetemail" placeholder="Email" /></label>
                           }

                           {error.length > 0 && error.map( (error, idx)=> <p key={idx} className="errorMsg">{error}</p> )}
                           {complete && <p className="successMsg">Please check your Email to reset your Password.</p> }
                           {changed && <p className="successMsg">Password Changed Successfully.{code ? ' Please try loggin in with your new Password now.' : 'Going back in 2 seconds.'}</p> }
                           <button aria-label="Reset Password" type="submit">Reset Password</button>
                           <p className="signuploginroutes"><Link to={`/`} onClick={()=>setTab((user && user.email) ? 'myaccount' : 'login')}> {`< Go Back `}</Link></p>
                     </form>
                  </div>

         </div>
      );

}

export default ResetPassword;