import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios'
import Icon from '../Icon/Icon';
import Tracks from '../../components/Discover/Tracks';
import { cleanYoutubeTitle, getLastSongOrder, youtube_regex, stringTimetonumber } from '../../helpers';
import TrackSkeleton from '../Loader/TrackSkeleton';
import Loader from '../Loader/Loader';

const SearchYoutube = (props) =>{
  const { queue, userplaylistsMin, uid, guestPlaylists, search, station, addSationSong, addSong, guestAdd } = props;

  const searchForm = useRef(null);
  const [songs, setSongs] = useState(null);
  const [error, setError] =  useState('');
  const [loading, setLoading] =  useState(false);
  const [searching, setSearching] =  useState(search.tab === 'search' && search.string ? true : false);
  const [importSongInfo, setImportSongInfo] =  useState(true);
  const [searchString, setSearchString] =  useState(search.tab === 'search' && search.string ?  search.string : '');


   const searchSubmit = useCallback((event) => {
      if(event){  event.preventDefault(); }
      if(searchString){
         if(window.isElectron){
            window.sendToElectron('searchRequest', searchString);
            window.ipcRenderer.on('searchResult', (event, data) => {
               if(data){
                  let newSongs = [];
                  //console.log(data.results);
                  if(data.results.length > 0){
                        newSongs = data.results.map((song)=> {
                           const parsed = song.video.url.match(youtube_regex);
                           const videoID = parsed && parsed[2] ? parsed[2]:null;
                           return {
                              title: song.video.title ? song.video.title : '',
                              id: videoID,
                              youtube: videoID,
                              release_date: song.video.upload_date ? song.video.upload_date : '',
                              views: song.video.views ?  Number(song.video.views.replace(/,/g , "")) : '',
                              duration: song.video.duration ? stringTimetonumber(song.video.duration) : ''
                           }
                        });
                        //console.log('NEW SONGS!',newSongs);
                  }
                  setSongs(newSongs);
               }
               setSearching(false);
            });
         }else{
            setSongs(null);
            setSearching(true);
            setError('');

            axios.get(`https://api.yhimsical.com/searchyt?q=${searchString}`).then((res)=> {
               console.log('[SUCCESS] result: ', res?.data?.result);
               if(res?.data?.result){
                  const updatedSongs = res.data.result.map(({title, ID, duration, release_date})=> ({ id: ID, youtube: ID, title, duration, release_date }));
                  setSongs(updatedSongs);
               }
            }).catch((err)=> {
               console.warn('[ERROR] Search ERROR: ', err);
               setError('Error Making Search');
            }).finally(()=> {
               setSearching(false);
            })
         }
      }
   }, [searchString])

   useEffect(()=> {
      if(search.string && !songs){
         searchSubmit();
      }
   }, [search.string, songs, searchSubmit])

   const getSelectedSong  = (song, playlistID, play=false) => {
        console.log(song, playlistID);
        if(!importSongInfo){ return addtoPlaylist(play); }
        setLoading(true);
         let updatedSong = {...song}
        //Fetch Track Name and Artist Name
        axios.get(`https://api.yhimsical.com/gettrackdetails?title=${cleanYoutubeTitle(song.title)}&youtube=${song.youtube}`).then((result)=> {
            console.log('[SUCCESS] result: ', result.data);
            if(result?.data?.trackDetails?.artist){
               updatedSong.artist = result.data.trackDetails.artist;
               updatedSong.title = result.data.trackDetails.title;
               if(result?.data?.trackDetails?.cover){  updatedSong.cover = result.data.trackDetails.cover;  }
               if(result?.data?.trackDetails?.album){  updatedSong.album = result.data.trackDetails.album;  }
            }
        }).catch((err)=> {
            console.log('[ERROR] getSelectedSong: ', err);
        }).finally(()=> {
            addtoPlaylist(updatedSong, playlistID, play);
        })
    } 

   const playSong = (song) => {
      console.log(song, queue.selected);
      if(song.id === queue.playing.songId){
         addtoPlaylist(song, queue.selected, true);
      }else{
         getSelectedSong(song, queue.selected, true);
      }
   }


   const addtoPlaylist = (selectedSong, playListID, play=false) => {
      // console.log(selectedSong, playListID);
      if(selectedSong && selectedSong.title && selectedSong.youtube && playListID){
         const queueSelected = playListID === 'queueOne' || playListID === 'queueTwo' || playListID === 'queueThree';
         const selectedQ = queueSelected ? queue[playListID] : null;
         const queueHasSong = queue[queue.selected].filter(sng => sng.id === selectedSong.id).length > 0 ? true: false;

         const orderValue = queueSelected ? queue[queue.selected].length : getLastSongOrder(userplaylistsMin, playListID);
         const updatedSelectedSong = { ...selectedSong,  added: parseInt((new Date().getTime() / 1000).toFixed(0), 10), order: orderValue }

         // console.log(selectedQ, queueHasSong, updatedSelectedSong);

         if(!uid && !selectedQ){
            console.log('add to Untitled Playlist');
            guestAdd({...updatedSelectedSong, id: updatedSelectedSong.youtube });
         }else{
            addSong(uid, playListID, updatedSelectedSong, play, queueHasSong);
         }

         // //Then Clear the currently selected Song
         setTimeout(()=> {
            setLoading(false);
         }, queueSelected ? 200 : 2500);
      }
   }

   const renderTracks = () => {
        if(searching){
            return <TrackSkeleton />
        }else{
            return <Tracks 
            searchTrack={true}
            guest={!uid}
            guestPlaylists={guestPlaylists}
            songs={songs} 
            addToPlaylist={getSelectedSong}
            station={station}
            addToStation={song => addSationSong(song)}
            playSong={playSong} 
            allPlaylists={userplaylistsMin} 
            slider={false} />
        }
   }

   return (
      <div>
         <div className="searchbar">
            <form id="youtube__form" onSubmit={searchSubmit} ref={searchForm} >
               <input type="text" name="searchyt" placeholder="Search Youtube.." aria-label="Search Youtube.." onChange={(event)=> setSearchString(event.target.value) } value={searchString}/>
               <button type="submit"><Icon icon="md-search" fontSize="24px" color="#ffffff"/></button>
            </form>
            <div className="importSongInfoCheck custom_checkbox">
               <input 
                     type="checkbox" 
                     id="importSongInfo_switch"
                     name="importSongInfo" 
                     value={importSongInfo} 
                     onChange={()=> setImportSongInfo(!importSongInfo)} 
                     checked={importSongInfo }
                  />
               <label htmlFor="importSongInfo_switch">Toggle</label>
               <span>Auto Import Song info on Add/Play</span>
            </div>
         </div>

         <div className={`searchresults ${loading ? 'loading' : '' }`}>
            <div className="searchresults__iner">
               <div className="searchresults__left">
                     {/* <div className="searchresults__left__head"><span>1</span> Select Song</div> */}
                     <div className="searchresults__left__content">
                        {!songs && !searching ? 
                           <p>Search for a Song</p>
                        :
                           <div className="addSongScroll_wrap customScrollbar">
                              <div>
                                    {renderTracks()}
                              </div>
                           </div>
                        }
                        {error && <p>{error}</p>}
                     </div>
               </div>

            </div>
            {loading && <div className="addSongLoader"><Loader /></div>}
         </div> 
      </div>
   );

}

export default SearchYoutube;