import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { nanoid } from "nanoid";
import { getLastSongOrder } from "../helpers";
import { playPausePlayer, playAgain } from "./playerSlice";
import { optimisticPlaylistChange } from "./playlistSlice";
import { addToQueue } from "./queueSlice";
import { completeSongAdd, setSongAddError, clearSongAddStatus } from "./userSlice";



export const SONG_ADDED_COMPLETE = 'SONG_ADDED_COMPLETE';
export const SONG_ADDED_ERROR = 'SONG_ADDED_ERROR';
export const SONG_ADDED_CLEAR = 'SONG_ADDED_CLEAR';
export const GUEST_PLAYLIST_ADD = 'GUEST_PLAYLIST_ADD';
export const PLAY_AGAIN = 'PLAY_AGAIN';


//Add Song
export const addSong = (uid, playlistID, song, play=false, playAgainn=false) =>
  (dispatch, getState) => {
      console.log('FROM ACTION -->>', playlistID, uid, song, play, playAgainn);

      if(playlistID === 'queueOne' || playlistID === 'queueTwo' || playlistID === 'queueThree'){
         console.log('ADD to queue', playlistID);
         dispatch(addToQueue({song, queue: playlistID, play}));
         if(play){   dispatch(playPausePlayer(true));  }
         if(playAgainn){   dispatch(playAgain());  }
         dispatch(completeSongAdd({title: song.title, artist:song.artist? song.artist : '', playListID: playlistID}));
         dispatch(clearSongAddStatus());
      }else{
        if(uid){
          const db = firebase.firestore();
          const playListsRef = db.collection('Playlists');
          const usersRef = db.collection('Users');
          const songID = nanoid();
          const theState = getState();
          const playlistSongsCount = theState?.userplaylists?.userPlaylists[playlistID]?.songs ? Object.keys(theState.userplaylists.userPlaylists[playlistID].songs).length : 0;
  
          const theLastSongOrder = theState?.userplaylists?.userPlaylists[playlistID]?.songs ? getLastSongOrder(theState.userplaylists.userPlaylists, playlistID) : 0;

          if(song.id){ delete song.id; } if(song.bounds){ delete song.bounds; }

          const currentPlaylists = theState.userplaylists.userPlaylists;
          const currentPlaylistsMin = theState.userplaylists.userPlaylistsMin;
          dispatch(optimisticPlaylistChange('add_song', {currentPlaylists, currentPlaylistsMin , playListID: playlistID, song:{...song, id: songID} }));

  
          playListsRef.doc(playlistID).update( { ['songs.' + songID ]: {...song, order: theLastSongOrder} } )
          .then((ref) => {
  
              usersRef.doc(uid).update( { ['playlists.' + playlistID + '.count']: playlistSongsCount + 1 });
              console.log('####Start Dispatch from Action!!!!!', song);
              dispatch(completeSongAdd({title: song.title, artist:song.artist? song.artist : '', playListID: playlistID}));
              setTimeout(()=> {
                dispatch(clearSongAddStatus());
              }, 2500)
          }).catch(()=> {
                dispatch(setSongAddError())
          });
        }else{
          const songID = nanoid();
          //If user is not logged in and tries to add to playlist, add it to temp playlist
          dispatch({type: GUEST_PLAYLIST_ADD, song:{...song, id: songID} })
        }

      }

};

//Import Song
export const importSongs = (uid, playlistID, songs) =>
  (dispatch, getState) => {
      console.log('FROM ACTION -->>', uid, playlistID, songs, getState());
      const db = firebase.firestore();
      const playListsRef = db.collection('Playlists');
      const currentState = getState();
      const userPlaylists = currentState.firestore && currentState.firestore.data && currentState.firestore.data.plist
      const existingSongs = userPlaylists && userPlaylists[playlistID] && userPlaylists[playlistID].songs && userPlaylists[playlistID].songs
      const newSongs = {...existingSongs, ...songs[0]};
      //console.log('@@@@ existingSongs: ', newSongs);

      const currentPlaylists = currentState.userplaylists.userPlaylists;
      const currentPlaylistsMin = currentState.userplaylists.userPlaylistsMin;
      dispatch(optimisticPlaylistChange('import_song', {currentPlaylists, currentPlaylistsMin , playListID: playlistID, songs: newSongs }));


      playListsRef.doc(playlistID).update( { songs: newSongs } )
      .then((ref) => {
        console.log('Songs successfully added to Playlist');
      }).catch((err)=> {
            console.log('', err);
      });

};

// //Reorder Song
export const reorderSongs = (uid, playlistID, orderedPlaylist) =>
  (dispatch, getState) => {
    console.log('FROM ACTIOn -->>', playlistID, uid, orderedPlaylist);
    //const firebase = getFirebase();
    if(orderedPlaylist){
      //console.log('orderedPlaylist -->>',orderedPlaylist);
      const db = firebase.firestore();
      const playListsRef = db.collection('Playlists');

      const currentState = getState();
      const currentPlaylists = currentState.userplaylists.userPlaylists;
      const currentPlaylistsMin = currentState.userplaylists.userPlaylistsMin;
      dispatch(optimisticPlaylistChange('reorder_songs', {currentPlaylists, currentPlaylistsMin , playListID: playlistID, songs: orderedPlaylist }));

      playListsRef.doc(playlistID).update( { songs : orderedPlaylist } )
      .then((ref) => {
          console.log('@@New Song Reordered!!!!!!');
      }).catch(()=> {
          console.log('@@Song Reorder Failed!!!!!!');
      });
    }

};

// //Remove Song
export const removeSong = (uid, playlistID, songID) =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>', playlistID, uid, songID);
      const db = firebase.firestore();
      const playListsRef = db.collection('Playlists');
      const usersRef = db.collection('Users');
      const theState = getState();
      const playlistSongsCount = theState?.userplaylists?.userPlaylists[playlistID]?.songs ? Object.keys(theState.userplaylists.userPlaylists[playlistID].songs).length : 0;
      
      const currentState = getState();
      const currentPlaylists = currentState.userplaylists.userPlaylists;
      const currentPlaylistsMin = currentState.userplaylists.userPlaylistsMin;
      dispatch(optimisticPlaylistChange('remove_song', {currentPlaylists, currentPlaylistsMin , playListID: playlistID, songID }));

      playListsRef.doc(playlistID).update( { ['songs.' + songID ]: firebase.firestore.FieldValue.delete() } )
      .then((ref) => {
        usersRef.doc(uid).update( { ['playlists.' + playlistID + '.count']: playlistSongsCount > 0 ? playlistSongsCount - 1 :0 });
          console.log('@@Song Deleted!!!!!!');
      }).catch(()=> {
          console.log('@@Song Delete Failed!!!!!!');
      });

};


// //Cut Song
export const cutSong = (uid, playlistID, songID, song) =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>', playlistID, uid);
      const db = firebase.firestore();
      const playListsRef = db.collection('Playlists');

      const currentState = getState();
      const currentPlaylists = currentState.userplaylists.userPlaylists;
      const currentPlaylistsMin = currentState.userplaylists.userPlaylistsMin;
      dispatch(optimisticPlaylistChange('cut_song', {currentPlaylists, currentPlaylistsMin , playListID: playlistID, song: {...song, id: songID} }));

      playListsRef.doc(playlistID).update( { ['songs.' + songID ]: song } )
      .then((ref) => {
          console.log('@@Song Time Updated!!!!!!');
      }).catch(()=> {
          console.log('@@Song Time Update Failed!!!!!!');
      });
};

export const updateSong = (uid, playlistID, songID, song) =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>', playlistID, uid, song);
      const db = firebase.firestore();
      const playListsRef = db.collection('Playlists');

      const currentState = getState();
      const currentPlaylists = currentState.userplaylists.userPlaylists;
      const currentPlaylistsMin = currentState.userplaylists.userPlaylistsMin;
      dispatch(optimisticPlaylistChange('update_song', {currentPlaylists, currentPlaylistsMin , playListID: playlistID, song: {...song, id: songID} }));

      playListsRef.doc(playlistID).update( { ['songs.' + songID ]: song } )
      .then((ref) => {
          console.log('@@Song Time Updated!!!!!!');
      }).catch(()=> {
          console.log('@@Song Time Update Failed!!!!!!');
      });
};