import React, {  useState } from 'react';
import { useDispatch } from 'react-redux';
import { reorderSongs, removeSong, addSong } from '../../redux/songSlice';
import { guestReorder, guestRemove, guestAdd } from '../../redux/guestPlaylistSlice';
import { addToQueue } from '../../redux/queueSlice';
import { arrayMove } from '../../helpers';
import Modal from '../Modal/Modal';
import Icon from '../Icon/Icon';


const PlaylistMobileActions  = (props) => {
   const { uid, queue, playlistsMin, publicPlaylist=false, authorID, songs } = props;
   const {setReorderingSongs, reorderingSongs, setReordering, reordering, mobileSelectedSong, setMobileSelectedSong, currentPlaylist} = props;

   const dispatch = useDispatch();
   const [showMobileAddModal, setShowMobileAddModal] = useState( false);
   const [selectedAddPlaylist, setSelectedAddPlaylist] = useState(null);

   const playMobileSong = () => {
      if(!mobileSelectedSong){ return }
      dispatch(addToQueue({ song: mobileSelectedSong, queue: queue.selected} ));
      setMobileSelectedSong(null);
  }

   const removeMobileSong = () => {
      if(!uid && !publicPlaylist){
         dispatch(guestRemove(mobileSelectedSong.id))
      }else{
         dispatch(removeSong({uid: authorID, playlistID: currentPlaylist, songID: mobileSelectedSong.id}))
      }
      setMobileSelectedSong(null);
  }

   const addMobileSong = () => {
      let queue;
      let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
      if(playlistsMin && !publicPlaylist && playlistsMin.length > 0){ queuePlaylists = [...queuePlaylists, ...playlistsMin]  }
      const copySelectedPlaylist = queuePlaylists.filter((plist)=>  plist['id'] === selectedAddPlaylist  ); 
      if(selectedAddPlaylist === 'queueOne' || selectedAddPlaylist === 'queueTwo' || selectedAddPlaylist === 'queueThree'){
         queue = selectedAddPlaylist;
      }
      console.log(copySelectedPlaylist, queue, copySelectedPlaylist[0] ? copySelectedPlaylist[0].count : 0);

      if(!queue){
         const newSong = {...mobileSelectedSong, order: copySelectedPlaylist[0] ? copySelectedPlaylist[0].count : 0}
         if(uid){
            dispatch(addSong({uid, playlist: selectedAddPlaylist, song: newSong}));
         }else{
            dispatch(guestAdd(newSong));
         }
         
      }else{
         const newSong = {...mobileSelectedSong, order: queue[queue].length}
         dispatch(addToQueue({song: newSong, queue, play:false}));
      }

      setSelectedAddPlaylist(null);
      setMobileSelectedSong(null);    
  }

   const mobileSongOrder = (direction) => {
      if(publicPlaylist){ return }
      const songsToRerorder = [...reorderingSongs];
      const songCurrentIndex = songsToRerorder.findIndex(x => x.id === mobileSelectedSong.id);
      
      if(direction === 'up' && songCurrentIndex > 0){
          let newSongListUp = arrayMove(songsToRerorder, songCurrentIndex, songCurrentIndex - 1);
          newSongListUp = newSongListUp.map((sng, index) =>  { return {...sng, order: index} });
          setReorderingSongs(newSongListUp);
      }

      if(direction === 'down' && songCurrentIndex <= reorderingSongs.length -1){
        let newSongListDown = arrayMove(songsToRerorder, songCurrentIndex, songCurrentIndex + 1);
        newSongListDown = newSongListDown.map((sng, index) =>  { return {...sng, order: index} });
        setReorderingSongs(newSongListDown);
      }
  }

   const reorderMobileSongsDone = () => { 
      if(!uid){
         dispatch(guestReorder(reorderingSongs));
      }else{
         dispatch(reorderSongs( {uid, playlistID: currentPlaylist, orderedPlaylist: reorderingSongs}));
      }
      setReordering(false);
   }

   const cancelMobileActions = () => {
      setReordering(false);
      setMobileSelectedSong(null);
      setSelectedAddPlaylist(null);
      setShowMobileAddModal(false);
   }

   const onMobileReorderButtonClick = () => {
      const newReorderingStatus = reordering ? true : false;
      setReordering(newReorderingStatus);
      setReorderingSongs(newReorderingStatus ? songs : []);
   }

   let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
   if(playlistsMin && !publicPlaylist && playlistsMin.length > 0){ queuePlaylists = [...queuePlaylists, ...playlistsMin]  }

   return (
      <div className="mobile_actions">
         <div className="mobile_actions__all">
         <button className="mobile_actions__play" onClick={playMobileSong}><Icon icon="md-play" fontSize="18px" color="#ffffff"/></button>
         <button className="mobile_actions__add" onClick={()=> setShowMobileAddModal(true)}><Icon icon="md-add" fontSize="18px" color="#ffffff"/></button>
         {!publicPlaylist && <button className="mobile_actions__remove"onClick={removeMobileSong}><Icon icon="md-trash" fontSize="18px" color="#ffffff"/></button>}
         {!publicPlaylist && 
            <button className="reorder_btn" onClick={()=> onMobileReorderButtonClick()}>
               <Icon icon="ios-swap" fontSize="18px" color="#ffffff"/>
            </button>
         }
         <button className="mobile_actions__cancel" onClick={cancelMobileActions}><Icon icon="md-close" fontSize="18px" color="#ffffff"/></button>
         </div>
         {reordering && !publicPlaylist && 
         <div className="mobile_actions__reorder">
            <button className="mobile_actions__up" onClick={()=> mobileSongOrder('up')}><Icon icon="ios-arrow-round-up" fontSize="21px" color="#ffffff"/></button>
            <button className="mobile_actions__down" onClick={()=> mobileSongOrder('down')}><Icon icon="ios-arrow-round-down" fontSize="21px" color="#ffffff"/></button>
            <button className="mobile_actions__done" onClick={()=> reorderMobileSongsDone()}><Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/></button>
            <button className="mobile_actions__cancel" onClick={()=> setReordering(false)}><Icon icon="md-close" fontSize="18px" color="#ffffff"/></button>
         </div>
         }

         <Modal title={`Add Song To`} customClass="mobileAddSongModal" show={showMobileAddModal} maxWidth={400} closeModal={()=> setShowMobileAddModal(false)}>
          <div className="mobile_addSong__buttons__wrap">
              <div className="libPlaylists">
                  <div className="LibSongScroll_wrap customScrollbar">
                      <div>
                          {queuePlaylists && queuePlaylists.map((plist, index)=> {
                              return <button 
                              key={plist.id} 
                              className={selectedAddPlaylist === plist.id ? 'active': ''}
                              onClick={()=> setSelectedAddPlaylist(plist.id)}>
                                 {selectedAddPlaylist === plist.id && <Icon icon="md-checkmark" fontSize="16px" color="#fff"/>}
                                 {plist.title}
                              </button>
                          })}
                      </div>
                  </div>
              </div>
              <div className="mobile_addSong__buttons">
                  <button className="libConform" onClick={addMobileSong}><Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/></button>
                  <button className="libCancel" onClick={cancelMobileActions}><Icon icon="md-close" fontSize="18px" color="#ffffff"/></button>
              </div>
          </div>
        </Modal>
      </div>
      
   )


}
export default PlaylistMobileActions;