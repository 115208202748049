import { useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import PlayList from "../components/PlayList/PlayList";


const Home = () => {
   const userProfile = useSelector((state) => state.user.userData);
   const userplaylists = useSelector((state) => state.userplaylists.userPlaylists);
    
   return (
      <div id="now_playing" className={`routeHasPlaylist`}>
         <PlayList 
         playlists={userplaylists}
         auth={firebase.auth()} 
         authorID={userProfile && userProfile.id}
         author={userProfile?.username || ''}
         />
    </div>
   );
};

export default Home;