import React from 'react';
// import {useState} from 'react';
import { Link } from 'react-router-dom';


const AccountSettings = ({user={}, full, usernameUnique, emailUnique}) => {

   //  const [country, setCountry] = useState('');
   //  const [countries, setCountries] = useState([]);
        
   return(
      <div className={`AccountSettings ${full ? 'hasVals' : ''}`}  key={user.username} >
            <label className="label_fullname">
               <span>Name*</span>
               <span className="error_msg">Required</span>
               <input type="text" placeholder="Full Name*" name="fullname" defaultValue={user.fullname && user.fullname} autoComplete="name" />
            </label>
            <label className="label_username">
               <span>Username*</span>
               {usernameUnique ? <dt>&#10004;</dt> : ''}
               <span className="error_msg">Required</span>
               <input type="text" placeholder="username*" name="username" defaultValue={user.username} disabled ={full ? true: false}/>
            </label>
            <label className="label_email">
               <span>Email*</span>
               {emailUnique ? <dt>&#10004;</dt> : ''}
               <span className="error_msg">Required</span>
               <input type="email" placeholder="Email*" name="email" defaultValue={user.email} autoComplete="email" />
            </label>

            {!full &&
               <div>
                  <label className="label_pass">
                        <span>Password*</span>
                        <span className="error_msg">Required</span>
                        <input type="password" id="reg_password" placeholder="Password" name="pass" />
                  </label>
                  <label className="label_passagain">
                        <span>Password Again*</span>
                        <span className="error_msg">Required</span>
                        <input type="password" id="reg_passwordagain" placeholder="Password Again" name="passagain" />
                  </label>
               </div>
            }


            {/* 
            
            <div className="signup_gender customRadio">
               <label className="control control--radio">Male
               {full ?
                  <input type="radio" name="gender" value="male" defaultChecked={user.gender === 'male' ? true : false } />
                  :
                  <input type="radio" name="gender" value="male" defaultChecked={true} />
               }
                  <div className="control__indicator"></div>
               </label>

               <label className="control control--radio">Female
                  <input type="radio" name="gender" value="female" defaultChecked={user.gender === 'female' ? true : false } />
                  <div className="control__indicator"></div>
               </label>

               <label className="control control--radio">Other
                  <input type="radio" name="gender" value="other" defaultChecked={user.gender === 'other' ? true : false } />
                  <div className="control__indicator"></div>
               </label>
            </div> */}
            {full &&
               <label className="signup_bio">
                  <textarea placeholder="Write a Short Bio (100 words max)" name="bio" defaultValue={user.bio}></textarea>
               </label>
            }
            {/* {full &&
               <div className="signup_social">
                  <label className="label_facebook">
                        <span>Facebook</span>
                        <input type="text" placeholder="Facebook Account Url" name="facebook" defaultValue={user.facebook} />
                  </label>
                  <label className="label_twitter">
                        <span>Twitter</span>
                        <input type="text" placeholder="Twitter Account Url" name="twitter" defaultValue={user.twitter} />
                  </label>
                  <label className="label_soundcloud">
                        <span>SoundCloud</span>
                        <input type="text" placeholder="Soundcloud Account Url" name="soundcloud" defaultValue={user.soundcloud} />
                  </label>
                  <label className="label_youtube">
                        <span>Youtube</span>
                        <input type="text" placeholder="Youtube Channel Url" name="youtube" defaultValue={user.youtube} />
                  </label>
                  <label className="label_lastfm">
                        <span>LastFM</span>
                        <input type="text" placeholder="lastfm Account Url" name="lastfm" defaultValue={user.lastfm} />
                  </label>
               </div>
            } */}
            {full && user.providerData && user.providerData.map((prvdr)=> prvdr.providerId ==='password').length > 0 &&
               <p className="signuploginroutes"><Link to={`/resetpassword`}> {`Reset Password`}</Link></p>
            }
      </div>
   );
}

export default AccountSettings;