import React, { useEffect, useRef, useState } from 'react';
import TimeAgo from 'react-timeago';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getStorage, ref ,getDownloadURL } from "firebase/storage";
import 'firebase/firestore';
import Modal from '../Modal/Modal';
import fakePP from '../../assets/user_m.png';
import Icon from '../Icon/Icon';

const PlaylistLikers  = (props) => {
   const imagesLoaded = useRef(false);
   const {show, onClose, playlistID, uid} = props;
   const [users, setUsers] = useState([]);
   const [images, setImages] = useState(null);
   const [loading, setLoading] = useState(true);

   useEffect(()=> {
      if(playlistID && uid && !imagesLoaded.current){
            let likers = [];
            console.log('playlistLiker First Pass!');
            const db = firebase.firestore();
            const LikesRef = db.collection('Likes').doc(playlistID);
            LikesRef.get().then((doc)=> {
               console.log('DOC: ', doc.exists, doc.data());
               if(doc.exists){
                  const docData = doc.data();
                  if(docData.users){
                        Object.keys(docData.users).map((key)=> {
                           let user = docData.users[key];
                           const storage = getStorage();
                           const storageRef = ref(storage, `gs://yhimsical.appspot.com/avatars/${key}_small.jpg`);
                           // var pathReference = storage.refFromURL(`gs://yhimsical.appspot.com/avatars/${key}_small.jpg`);
                           getDownloadURL(storageRef).then((data)=> {
                                    return setImages({ ...images, [user.username] : data })
                              }).catch((error) => {
                                    return likers.push(user);
                              });
                           return likers.push(user);

                        })
                        setUsers(likers);
                        setLoading(false);
                  }else{
                     setLoading(false);
                  }
               }else{
                  setLoading(false);
               }
            });
            imagesLoaded.current= true;
      }
   }, [playlistID, uid, images])

   return(
      <Modal title={`Playlist Likes`} show={show} maxWidth={400} closeModal={onClose}>
      {loading ? <div className="loading"><Icon icon="ios-sync" rotate={true} fontSize="38px" color="#ffffff"/></div>
      :
            <div className="playlistlikers">

               {users.length > 0 && <div className="playlistlikers__count">Liked by {users.length} users.</div>}
               <ul>
                  {users.length > 0 && users.map((usr, index)=> {
                        return <li key={usr.username}>
                                    <a href={`/user/${usr.username}`} target="_blank" rel='noreferrer'>
                                       <img src={images && images[usr.username] ? images[usr.username] : fakePP} alt={usr.username ? usr.username : "Profile Photo" } />
                                       <span>{usr.username}</span>
                                       {usr.liked && <span><TimeAgo date={new Date(usr.liked *  1000)} /></span>}
                                    </a>
                              </li>
                  })}
                  {users.length === 0 && <p>No one Liked this Playlist yet!</p>}
               </ul>
            </div>
      }
      </Modal>
   )
}

export default PlaylistLikers;