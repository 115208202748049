import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setDiscoverListView } from '../../redux/userSlice';
import Icon from '../Icon/Icon';

const DiscoverHeader = ({ showListUI:showListUIProp}) => {
   const dispatch = useDispatch();
   const listView = useSelector((state)=> state.user.discoverListView )
   const { pathname } = useLocation();
   const [showMenu, setShowMenu] =  useState(false);
   const [showListUI, setShowListUI] =  useState(false);

   useEffect(()=> {
      if(window.innerWidth > 370){
         setShowListUI(true);
      }
   }, [])

   return(
      <div id="discover_mobile_header" className={showListUI ? 'showListUI' : ''}>
            {showListUIProp && showListUI && (
               <button className={`listViewBtn ${listView ? 'listViewBtn--active':'' }`} onClick={()=> dispatch(setDiscoverListView(!listView))}>
                  <Icon icon="md-list" fontSize="16px" color="#ffffff"/>
               </button>
            )}
            <div className="discover_mobile_header__trigger" onClick={()=> setShowMenu(!showMenu)}>
               {showMenu ? <Icon icon="ios-arrow-up" fontSize="18px" color="#ffffff"/> : <Icon icon="ios-arrow-down" fontSize="18px" color="#ffffff"/>}
            </div>
            {showMenu && 
            <div className="discover_mobile_header__menu">
               <ul>
                  <li className={pathname ==='/music' ? 'activeDiscover' : ''}><Link to="/music">Latest Tracks</Link></li>
                  <li className={pathname ==='/popular' ? 'activeDiscover' : ''}><Link to="/popular">Popular Tracks</Link></li>
                  <li className={pathname ==='/music/all' ? 'activeDiscover' : ''}><Link to="/music/all">All Tracks</Link></li>
                  <li className={pathname ==='/artists' ? 'activeDiscover' : ''}><Link to="/artists">All Artists</Link></li>
               </ul> 
            </div>
            }
      </div>
   );

}

export default DiscoverHeader;