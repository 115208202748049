export const loadState = (itemKey=null)=> {

    try{
        let serializedState = {};
        if(itemKey === 'playlistGuest' || itemKey === null){
            serializedState.playlistGuest = JSON.parse(localStorage.getItem('playlistGuest'));
        }
        if(itemKey === 'last_synced' || itemKey === null){
            serializedState.last_synced = JSON.parse(localStorage.getItem('last_synced'));
        }
        if(itemKey === 'queue' || itemKey === null){
            serializedState.queue = JSON.parse(localStorage.getItem('queue'));
        }
        if(itemKey === 'player' || itemKey === null){
            serializedState.player = JSON.parse(localStorage.getItem('player'));
        }

        if(serializedState === {}){
            return undefined;
        }
        
        return itemKey && serializedState[itemKey] ? serializedState[itemKey] : serializedState;

    }catch(err){
        return undefined;
    }
}

export const saveState = (state) => {
    try{
        
        //console.log(state.last_synced);
        if(state && state.playlistGuest){
            localStorage.setItem('playlistGuest', JSON.stringify(state.playlistGuest));
        }

        if(state && state.last_synced){
            localStorage.setItem('last_synced', JSON.stringify(state.last_synced));
        }

        if(state && state.queue){
            localStorage.setItem('queue', JSON.stringify(state.queue));
        }

        if(state && state.player){
            localStorage.setItem('player', JSON.stringify(state.player));
        }

    }catch(err){

    }
}