import React from 'react';
import Icon from '../Icon/Icon';
import { formatDuration } from '../../helpers';
import SelectField from '../SelectField/SelectField';

class ChannelSong extends React.Component {

    state = {
        showActions: false,
        showAddModal: false,
        playlistSelect: 'queueOne',
    }


    onChange = (value, option) => {
        this.setState({ playlistSelect: value });
    }
    

    copySong = () => {
        this.props.addSong(this.state.playlistSelect, this.props.song);
        setTimeout(()=> {
            this.setState({showAddModal: false});
        }, 500);

    }

    render(){
        const confirmIcon = <Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/>;
        const cancelIcon = <Icon icon="md-close" fontSize="18px" color="#ffffff"/>;
        let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
        if(this.props.playlistsMin && this.props.playlistsMin.length > 0){ queuePlaylists = [...queuePlaylists, ...this.props.playlistsMin]  }

        let selectedAction = '';
        if(this.state.showAddModal){ selectedAction = 'song__actions-add'; }

        return(
            <div id={this.props.song.youtube} className={`song ${this.state.showActions ? 'selectedSong':''} ${this.props.active || this.props.pastSong === false ? 'futureSong':''}`}>
              <div className="song_inner">

                    <div className="song__album">
                        <img id={`img-${this.props.song.youtube}`} src={`https://i.ytimg.com/vi/${this.props.song.youtube}/default.jpg`} alt={this.props.song.title} />
                    </div>

                    <div className={`song__details`}>
                        <span className="song__title">{this.props.song.title}</span>
                        {this.props.song.artist  && <span className="song__artist">{this.props.song.artist}</span>}
                    </div>

                    <div className={`song__actions ${selectedAction ?'song__actions-active '+ selectedAction : ''}`}>

                        <button className="song__actions__add" onClick={()=> this.setState({showAddModal: true})}><Icon icon="md-add" fontSize="14px" color="#ffffff"/></button>
                        {this.state.showAddModal && 
                            <div className="song__actions__confirm  song__actions__confirm-copy">
                                <span>Add Song to</span>
                                <br />
                                <SelectField 
                                 style={{ width: 200 }} 
                                 options={queuePlaylists.map((category)=> ({ label: category.title, value: category.id }) )}
                                 value={this.state.playlistSelect}
                                 onChange={this.onChange}
                                 placeholder={'Select Category'}
                                 showSearch={false}
                                 />
                                <button className="confirm_yes" onClick={()=> this.copySong() }>{confirmIcon}</button>
                                <button className="confirm_no" onClick={()=> this.setState({showAddModal: false})}>{cancelIcon}</button>
                            </div>
                        }
                        </div>

              </div>
              <div className="song__duration">{formatDuration(this.props.song.duration)}</div>
            </div>

        );
    };
}

export default ChannelSong;