/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useMemo, useState } from 'react';
import Icon from '../Icon/Icon';
import LibrarySong from './LibrarySong';
import uniqBy from 'lodash.uniqby';
// import FolderSelector from './FolderSelector';
import { getLastSongOrder } from '../../helpers';
import { useDispatch } from 'react-redux';
import { hideLibraryModal } from '../../redux/userSlice';
import { addSong, updateSong } from '../../redux/songSlice';
import { addSationSong } from '../../redux/stationSlice';

const Library = (props) => {
   const { search, userplaylistsMin, queue, uid, userSettings, station } = props;

   const dispatch = useDispatch();
   
   // const theSongsInt = [...props.songs];
   const allSongsInt = useMemo(()=> uniqBy([...props.songs], 'youtube'), [props.songs]);
   const allArtistsInt = useMemo(()=> [...new Set(allSongsInt.map(item => item.artist).filter((item)=> item !== undefined))], [allSongsInt]);
   const allAlbumsInt = useMemo(()=> [...new Set(allSongsInt.map(item => item.album).filter((item)=> item !== undefined))], [allSongsInt]);
   const artists = allArtistsInt.sort((x, y) => x && x.localeCompare(y));
   const albums = allAlbumsInt.sort((x, y) => x && x.localeCompare(y));


   const [songs, setSongs] = useState(allSongsInt.sort((x, y) => y.added - x.added));
   const [results, setResults] = useState(null);
   const [filter, setFilter] = useState(null);
   // const [libHeight, setLibHeight] = useState(300);
   const [selectedSong, setSelectedSong] = useState(null);
   const [hasSelected, setHasSelected] = useState(false);
   const [searchString, setSearchString] = useState(search.tab === 'library' && search.string ? search.string : '');
   const [active, setActive] = useState('all');
   const [activeItem, setActiveItem] = useState(''); 
   const [mobileActiveTab, setMobileActiveTab] = useState('right');
   const [loading, setLoading] = useState(false);

   
   // useEffect(()=> {
   //    const newSongs = uniqBy(props.songs, 'id');

   //    if(newSongs.length !== songs.length){
   //       const theSongs = [...songs, ...props.songs];
   //       const allSongs = uniqBy(theSongs, 'id');
   //       const allArtists = [...new Set(allSongs.map(item => item.artist))];
   //       const allAlbums = [...new Set(allSongs.map(item => item.album))];

   //       setSongs(allSongs.sort((x, y) => y.added - x.added));
   //       setArtists(allArtists.sort((x, y) => x && x.localeCompare(y)));
   //       setAlbums(allAlbums.sort((x, y) => x && x.localeCompare(y)));
   //    }

   // }, [props.songs, songs]);

   // useEffect(()=> {
   //    const libHeight = document.getElementsByClassName('SearchLibrary__wrap')[0].offsetHeight;
   //    setLibHeight(libHeight);
   // }, [])


   const onSearch = (event, directValue=false) => {
        if(active !== 'all'){
            setActive('all');
            setActiveItem('');
        }
        let searchValue =''
        if(directValue){  searchValue = searchString;  }else{  searchValue = event.target.value }

        setSearchString(searchValue)

        
        let obj = filterSongs(searchValue) ? filterSongs(searchValue) : [];
        if(searchValue && obj.length > 0 ){
            setResults(obj);
        }else{
            setResults(null);
        }
    }

   const filterSongs = (string) => {
        const allSongs = songs;
        return songs && allSongs.filter((x) =>{
            if(x.title || x.artist){

                let searchTitle = x.title && x.title.toLowerCase();
                let searchArtist = x.artist && x.artist.toLowerCase();

               return (searchTitle.indexOf(string.toLowerCase()) !== -1) || (searchArtist && searchArtist.indexOf(string.toLowerCase()) !== -1);
            }else{
                return [];
            }
        } );
    }


   const playSong = (song) => {
      console.log(song, queue.selected);
      setSelectedSong(song);
      addtoPlaylist(song, queue.selected, true);
   }

   const addTheSong = (song, playListID) => {
      console.log(song, playListID);
      setSelectedSong(song);
      addtoPlaylist(song, playListID);
   }

   const updateTheSong = (song) => {
        let newSong = {...song};
        const plId = song.playlistID;
        const songId = song.id;
        delete newSong.id; delete newSong.playlist; delete newSong.playlistID;
        //console.log(uid, plId, songId, newSong);
        dispatch(updateSong(uid, plId, songId, newSong));
   }

   const filterSong = (type) => {
        setFilter(type);
        let filteredSongs=[];
        if(songs && songs.length > 0){
            if(filter === type){
                filteredSongs = songs.slice(0).sort((a, b) => b[type] && b[type].localeCompare(a[type]));
            }else{
                filteredSongs = songs.slice(0).sort((a, b) => a[type] && a[type].localeCompare(b[type]));
            }
            //console.log(filteredSongs);
            setSongs(filteredSongs);
        } 
   }

   const addtoPlaylist = (selectedSong, playListID, play=false) => {
        console.log(uid, playListID, selectedSong);

        if(selectedSong && selectedSong.title && (selectedSong.youtube || selectedSong.local) && playListID){
            const queueSelected = playListID === 'queueOne' || playListID === 'queueTwo' || playListID === 'queueThree';
            const orderValue = getLastSongOrder(userplaylistsMin, playListID);
            console.warn('orderValue: ', orderValue, playListID, userplaylistsMin);
            const queueHasSong = queue[queue.selected].filter(sng => sng.id === selectedSong.id).length > 0 ? true: false;
            const updatedSelectedSong = { ...selectedSong, added:parseInt((new Date().getTime() / 1000).toFixed(0), 10), order: orderValue - 1 }
            setSelectedSong(updatedSelectedSong);
            dispatch(addSong(uid, playListID, updatedSelectedSong, play, queueHasSong));
            setSelectedSong(null);
            setTimeout(()=> setLoading(false), queueSelected ? 200 : 2500);
           
        }else{
            console.warn('Error Adding Song to Playlist.')
        }
   }

   const setTheActiveItem = (type, item) => {
        let filteredItems;
        if(type === 'artists'){
            filteredItems = songs.filter((song)=> song.artist && song.artist === item );
        }
        if(type === 'albums'){
            filteredItems = songs.filter((song)=> song.album && song.album === item );
        }

        setResults(filteredItems);
        setActiveItem(item);
        setMobileActiveTab('right');
    }

   const setActiveMenu = (active)=> {
      setActive(active !== 'all' ? active : 'all' );
      setResults(null);
      setActiveItem('');
    }

    const renderSongs = () => {
      if(results && results.length > 0){
          return getTheSongs(results);
      }else if(songs && songs.length > 0){
          return getTheSongs(songs);
      }else{
          return <p>No Songs Found</p>;
      }
   }

   const LibSong = (song, index, style) => {
      return <LibrarySong 
               key={song.id}
               style={style}
               song={song} 
               //overscanCount={40}
               active={selectedSong && (selectedSong.id === song.id ) ? true : false} 
               playlists={userplaylistsMin}
               station={station}
               addSationSong={(payload)=> dispatch(addSationSong(payload))}
               addSong={addTheSong}
               playSong={playSong} 
               updateSong={updateTheSong} 
               hasSelected={hasSelected}
               selectSong={()=> setSelectedSong(selectedSong && (song.id ===  selectedSong.id) ? null :  song)}
               setSelected={()=> setHasSelected( !hasSelected )}
               isMobile={userSettings.mobile}
               />
   }

   // const getTheSongs = (allSongs) => {
   //      return <List height={libHeight - 150} itemCount={allSongs ? allSongs.length : 0} itemSize={38} width={'100%'}>{({index, style})=> LibSong(allSongs[index], index, style)}</List>     
   // }

   const getTheSongs = (allSongs) => {
      return allSongs.map((item, index)=>  LibSong(item, index, {}) )   
   }


   return(
      <div id="SearchLibrary">
            <div className="SearchLibrary__wrap">
               <div className="searchbar">
                  <form id="artists__form" onSubmit={(e)=> e.preventDefault() }>
                        <input type="text" name="searchartists" placeholder="Search Library.." aria-label="Search Library.." value={searchString} onChange={onSearch}/>
                        <button aria-label="Search" type="submit"><Icon icon="md-search" fontSize="24px" color="#ffffff"/></button>
                  </form>

                  <button aria-label="Close" className="closeLibrary" onClick={()=> dispatch(hideLibraryModal())} ><Icon icon="md-close" fontSize="24px" color="#fff"/></button>
               </div>

               <div className={`searchresults ${loading ? 'loading' : '' }`}>
                  <div className="searchresults__iner">
                        
                  <div className={`searchresults__left ${userSettings.mobile && mobileActiveTab === 'right' ? 'activeMobileLibTab' : ''}`}>
                        {userSettings.mobile && mobileActiveTab === 'left' && <div onClick={()=> setMobileActiveTab('right')} className="libMobileOverlay" /> }
                        <div className="searchresults__left__content">
                        {songs && songs.length > 0 ? 
                           <div>
                                    <table>
                                       <thead>
                                          <tr>
                                                <th><a onClick={()=> filterSong('title')}>Title</a></th>
                                                <th><a onClick={()=> filterSong('artist')}>Artist</a></th>
                                                <th><a onClick={()=> filterSong('album')}>Album</a></th>
                                                {/* <th><a onClick={()=> filterSong('playlist')}>Playlist</a></th> */}
                                                {/* <th>Added</th> */}
                                          </tr>
                                       </thead>

                                       <tbody>
                                          
                                       </tbody>
                                    </table>
                                    
                                    <div className="virtualList customScrollbar customScrollbar--alwaysShow">{renderSongs()}</div>
                              </div>

                        :
                           <p className="lib_nosongs">No Songs in Library</p>
                        }
                        </div>
                  </div>

                        <div className={`searchresults__right ${userSettings.mobile && mobileActiveTab === 'left' ? 'activeMobileLibTab' : ''}`}>
                           {userSettings.mobile && mobileActiveTab === 'right' && <div onClick={()=> setMobileActiveTab('left')} className="libMobileOverlay" /> }

                              <div className="searchresults__right__content">
                                    <ul>
                                       <li className={active === 'all' ? 'active' : ''}>
                                          <a onClick={()=> setActiveMenu('all')}>All ({songs ? songs.length : 0})</a>
                                       </li>
                                       <li className={active === 'artists' ? 'active' : ''}>
                                       {active === 'artists' ? '-' : '+'}<a onClick={()=> setActiveMenu('artists')}> Artists ({artists ? artists.length : 0})</a>
                                          {active === 'artists' && artists && artists.length > 0 && 
                                                <ul className="virtualList customScrollbar">
                                                   {artists.length > 0 && artists.map((item, index)=> {
                                                      return   <li 
                                                               key={`libAlbums-${index}`} 
                                                               className={activeItem === artists[index] ? 'active' : ''} 
                                                               onClick={()=> setTheActiveItem('artists', artists[index])}>
                                                                  {artists[index]}
                                                               </li>
                                                   })}
                                                </ul>
                                          }
                                       </li>
                                       <li className={active === 'albums' ? 'active' : ''}>
                                       {active === 'albums' ? '-' : '+'}<a  onClick={()=> setActiveMenu('albums')}> Albums ({albums ? albums.length : 0})</a>
                                          {active === 'albums' && artists && artists.length > 0 && 
                                                <ul className="virtualList customScrollbar">
                                                   {albums.length > 0 && albums.map((item, index)=> {
                                                      return   <li 
                                                               key={`libAlbums-${index}`} 
                                                               className={activeItem === albums[index] ? 'active' : ''} 
                                                               onClick={()=> setTheActiveItem('albums', albums[index])}>
                                                                  {albums[index]}
                                                               </li>
                                                   })}

                                                </ul>
                                          }
                                       </li>
                                    </ul>
                              </div>
                        </div>
                        

                  </div>
               </div>

            </div>
            
            <div className="SearchLibrary__bg"></div>
            
      </div>
   );

}

export default Library;
