import icons from './icons.js';

const Icon = ({icon, style, color, fontSize, onClick, classNames}) => {

   const getPathByIconName = () => {
      let theIcon = icons.find(icn => icn.tags[0] === icon)
      if (theIcon) return theIcon.paths.join(' ')
      return ''
    }

   return (
      <svg
        style={style}
        className={classNames}
        fill={color}
        width={fontSize}
        height={fontSize}
        viewBox="0 0 1024 1024"
        onClick={onClick}
      >
        <path d={getPathByIconName()}></path>
      </svg>
   )
}

export default Icon