import React from 'react';
import Icon from '../Icon/Icon';

class ProCompare extends React.PureComponent{
    state={

    }

    renderMobileCompare = () => {
        return(
            <div className="compare_pro">
                <div className="compare_pro__inner">
                    <div className="pricing_table__body">
                        <p>Play Audio Only and save 95% Bandwidth <i>*</i></p>
                        <p>Play Local Files <i>*</i></p>
                        <p>Search Artists and all their Songs</p>
                        <p>Play your Local files from anywhere</p>
                        <p>Import playlists from youtube</p>
                        <span><i>*</i> Requires the Desktop Client.</span>
                        <button aria-label="Get Started">Get Started</button>
                    </div>
                </div>
            </div>
        )
    }

    renderDesktopCompare = () => {
        return(
            <div className="compare_pro">
                <div className="compare_pro__inner">

                    <div className="pricing_table__features">
                        <h4>Features</h4>
                        <div className="pricing_table__body">
                            <p>Search Millions of Youtube songs</p>
                            <p>Create Unlimited Playlists</p>
                            <p>Share playlists with anyone</p>
                            <p>Play from Any device</p>
                            <p>Play Audio Only and save 95% Bandwidth <i>*</i></p>
                            <p>Play Local Files <i>*</i></p>
                            <p>Search Artists and all their Songs</p>
                            <p>Play your Local files from anywhere</p>
                            <p>Import playlists from youtube</p>
                            <span><i>*</i> Requires the Desktop Client.</span>
                        </div>
                    </div>

                    <div className="pricing_table pricing_table_one">
                        <div className="pricing_table__header">
                            <span>Basic</span>
                            <h3>Free</h3>
                        </div>
                        <div className="pricing_table__body">
                            <p>&#x2714;</p>
                            <p>&#x2714;</p>
                            <p>&#x2714;</p>
                            <p>&#x2714;</p>
                            <p className="pricing_no">x</p>
                            <p className="pricing_no">x</p>
                            <p className="pricing_no">x</p>
                            <p className="pricing_no">x</p>
                            <p className="pricing_no">x</p>
                            <button aria-label="Get Started">Get Started</button>
                        </div>
                    </div>

                    <div className="pricing_table pricing_table_two">
                        <div className="pricing_table__header">
                            <span>PRO</span>
                            <h3>$49</h3>
                        </div>
                        <div className="pricing_table__body">
                            <p>&#x2714;</p>
                            <p>&#x2714;</p>
                            <p>&#x2714;</p>
                            <p>&#x2714;</p>
                            <p>&#x2714;</p>
                            <p>&#x2714;</p>
                            <p>&#x2714;</p>
                            <p>&#x2714;</p>
                            <p>&#x2714;</p>
                            <button aria-label="Get Started">Get Started</button>
                        </div>
                        <div className="pricing_table_pro_overlay"><Icon icon="ios-flash" fontSize="224px" color="#fff"/></div>
                    </div>


                </div>
            </div>
        );

    }

    render(){
        return this.props.mobile ? this.renderMobileCompare() : this.renderDesktopCompare();
    }
}

export default ProCompare;