import { useDispatch } from 'react-redux';
import mobileSwipe from '../../assets/mobileSwipe.png';
import { updateUser } from '../../redux/userSlice';

const WelcomePopup = ({isMobile, userLoggedIn, closePopup, showTour}) => {

   const dispatch = useDispatch();
  

   const markWelcomeTourDone = (startTour=false) => {
      console.log('startTour: ', startTour);
      // setShowTour(startTour? startTour : false);
      if(startTour) { showTour(); }
      if(userLoggedIn){
         dispatch(updateUser({welcomeTourDone: true }));
      }else{
         localStorage.setItem('welcomeTourDone', true);
      }
      closePopup();
   }

   return (
      <div>
         {!isMobile ?
            <div className="welcomeTour">
               <button aria-label="Close" className="closeTour" onClick={()=> markWelcomeTourDone(false)}>x</button>
               <div className="welcomeTour__inner">
                  <h4>Welcome to Yhimsical</h4>
                  <p>Yhimsical is a music player that lets you search and listen to millions of youtube songs and create/share playlists absolutely free.</p>
                  <button aria-label="Start Welcome Tour" onClick={()=> markWelcomeTourDone(true)}>Take the Tour</button>
               </div>
            </div>
         :
            <div className="welcomeTour">
               <button aria-label="Close" className="closeTour" onClick={()=> markWelcomeTourDone(false)}>x</button>
               <div className="welcomeTour__inner">
                  <div className="mobile_welcome_slide">
                     {/* <h4>Welcome to Yhimsical</h4> */}
                     <p>Welcome to Yhimsical! Swipe Right to play each Song and Swipe left to add the song to Queue</p>
                     <img alt="How to Play Songs" className="mobileSwipe" src={mobileSwipe}/>
                  </div>
               </div>
            </div>
         }
      </div>
   );
}

export default WelcomePopup;