import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import Tracks from './Tracks';
import { getSongs } from '../../helpers';
import { addSong } from '../../redux/songSlice';
import genres from '../../utils/genres';
import TrackSkeleton from '../Loader/TrackSkeleton';
import DiscoverHeader from '../Discover/DiscoverHeader';
import { setDiscoverListView } from '../../redux/userSlice';
import Icon from '../Icon/Icon';


const PopularTracks = () =>{
   const dispatch = useDispatch();
   const queue = useSelector((state)=> state.queue );
   const userSettings = useSelector((state)=> state.user );
   const userplaylists = useSelector((state) => state.userplaylists);
   const userplaylistsMin =  useSelector((state) => state.userplaylistsMin);
   const songs = getSongs(userplaylists, 'temp' );
   const uid = userSettings && userSettings.user && userSettings.user.id;
   const listView = userSettings.discoverListView;

   const [genre, setGenre] =  useState('all');
   const [page, setPage ]=  useState(null);
   const [years, setYears] = useState([]);
   const [currentYear, setCurrentYear] =  useState();
   const [genreTracks, setGenreTracks] = useState([]);
   const [currentGenCount, setCurrentGenCount] =  useState(0);
   const [tracks, setTracks] = useState([]); 
   const [englishOnly, setEnglishOnly] =  useState(false);
   const [showYearSelector, setShowYearSelector] = useState(false);
   const [showGenreChoices, setShowGenreChoices] = useState(false);
   const [poplarTracks, setPopularTracks] = useState({});


   const filterGenreSongs = useCallback((allSongs) => {
      console.log('filterGenreSongs being Called!!');

      let currentSongCount = allSongs ? allSongs.length : 0
      if(englishOnly){
          currentSongCount = allSongs.filter(x => x.language === 'english').length;
      }
      console.log('currentSongCount: ', currentSongCount);
      let copiedGenres = [...genres]; 
      copiedGenres[0] = {id:'all', name:'All', count: currentSongCount};

      copiedGenres.forEach((item)=> {            
          
          if(item.id !== 'all' && allSongs && allSongs.length > 0){
              const genreSongs = allSongs.filter(x => {
                  if(englishOnly){
                     return x.genres && x.genres[item.id] === true && x.language === 'english'
                  }else{
                      return x.genres && x.genres[item.id] === true 
                  }
              });
              item.count = genreSongs && genreSongs.length > 0 ? genreSongs.length : 0;
              item.songs = genreSongs ? genreSongs  : [];
          }
      });
      setGenreTracks(copiedGenres);

  }, [englishOnly])


   const getTracks = useCallback((givenyear) => { 

      console.log('Popular Tracks!! getTracks Being Called: ');

      const theYear = givenyear ? givenyear : new Date().getFullYear();

      const setYearTracks = (selectedTracks) => {
         if(englishOnly){
            selectedTracks = selectedTracks.filter(x => x.language === 'english');
         }
         setTracks(selectedTracks ? selectedTracks.sort((a, b) => b.views - a.views ).slice(0, 100) : []);
         setPage(0);
         setGenre('all');
         console.log(selectedTracks.length);
         setCurrentGenCount(selectedTracks.length);
         filterGenreSongs(selectedTracks);
      }

      if(poplarTracks[theYear]){ 
         setYearTracks(poplarTracks[theYear]);
         return console.log('Popular Tracks for Given Year Already Fetched! '); 
      }

      setCurrentYear(theYear);

      axios.get(`https://yhimsical.b-cdn.net/popular/${theYear}.json`)
      .then((results)=> {
         if(results.data && results.data.length > 0){
            setPopularTracks({...poplarTracks, [theYear]: results.data.map((item)=> ({...item, year: new Date(item.release_date).getFullYear }) ) });
            console.log(theYear);
            setYearTracks(results.data);
         }
      })
      .catch(err => console.log(err));

   }, [poplarTracks, englishOnly, filterGenreSongs]);
   
;
   useEffect(()=> {
      if(Object.keys(poplarTracks).length === 0){
         getTracks();
      }
   }, [poplarTracks, getTracks]);

   useEffect(()=> {
      let totalYears = [];
      for (let index = 2000; index <= (new Date().getFullYear()); index++) {
         totalYears.push(index);
      }
      setYears(totalYears);
   }, [])


   const handlePageClick = (data) => {
        var popularDiv = document.querySelectorAll('.trackScroll_wrap--pagination > div')[0];
        popularDiv.scrollTop = 0;

        let page = data.selected;
        let pageTracks = [];
        if(genre === 'all' ){
            pageTracks = poplarTracks[currentYear].slice(page*100, page*100 + 100 )
        }else{
            const currentGenTracks = genreTracks.filter(x => x.id === genre );
            pageTracks = currentGenTracks[0] && currentGenTracks[0].songs ? currentGenTracks[0].songs.slice(page*100, page*100 + 100 ) : [];
        }

        if(englishOnly){
            pageTracks = pageTracks.filter(x => x.language === 'english');
        }
        setTracks(pageTracks);
        setPage(page);        
    }

 
   // const setYear = (year) => {
   //      let selectedTracks = poplarTracks ? poplarTracks[year] : [];
   //      if(englishOnly){
   //          selectedTracks = poplarTracks && poplarTracks[year].filter(x => x.language === 'english');
   //      }
   //      setCurrentYear(year);
   //      setPage(0);
   //      setShowYearSelector(false);
   //      setGenre('all');
   //      setCurrentGenCount(selectedTracks.length);
   //      setTracks(selectedTracks.sort((a, b) => b.views - a.views ).slice(0, 100));
   //      filterGenreSongs(poplarTracks && poplarTracks[year]);
   // }

   const setEnglishLangOnly = (englishOnly) => {
        let selectedTracks = poplarTracks ? poplarTracks[currentYear] : [];
        if(englishOnly){
            selectedTracks = poplarTracks && poplarTracks[currentYear].filter(x => x.language === 'english');
        }
        setEnglishOnly(englishOnly);
        setTracks(selectedTracks.sort((a, b) => b.views - a.views ).slice(0, 100));
        setPage(0);
        setGenre('all');
        filterGenreSongs(poplarTracks && poplarTracks[currentYear]);
    }

   const selectGenre = (genr)=> {
      setShowGenreChoices(false);
      setGenre(genr.id);
      setCurrentGenCount(genr.count);
      setPage(0);
      setTracks(genr.id === 'all'? poplarTracks && poplarTracks[currentYear].slice(0, 100) : genr.songs.slice(0, 100));
   }

   const renderHeader = () => {
        //console.log('copiedGenres', copiedGenres);
        const genArrow = showGenreChoices ? <Icon icon="md-arrow-dropup" fontSize="14px" color="#ffffff"/> :  <Icon icon="md-arrow-dropdown" fontSize="14px" color="#ffffff"/>; 
        
        return(
            <div className="tracks_header">
                <div className="tracks_header__inner">

                    <div className="tracks_header__left">
                        <h2 className="tracks_header__title">Popular Tracks <button onClick={()=> setShowYearSelector(!showYearSelector)}>{currentYear} <Icon icon="md-arrow-dropdown" fontSize="14px" color="#ffffff"/></button></h2>
                        {showYearSelector &&
                            <ul className="yearSelector">
                                {years.map(x => <li key={`year-${x}`}onClick={()=>  { setShowYearSelector(false); getTracks(x); } }>{x}</li>)}
                            </ul>
                        }
                    </div>

                    <div className="tracks_header__right">
                        <div className={` genChoices ${showGenreChoices ? 'showGenreChoices' : ''}`}>
                            <button onClick={()=> setShowGenreChoices(!showGenreChoices)}>{genre} ({currentGenCount}) {genArrow}</button>
                            <ul>{genreTracks.map((genr)=> {
                                return genr.count > 0 && 
                                    <li key={genr.id} onClick={()=> selectGenre(genr)}>
                                        {genr.name} <span>{genr.count}</span>
                                    </li>
                                })}
                            </ul>
                        </div>
                        <div onClick={()=> setEnglishLangOnly(!englishOnly)} className={`englishOnlyButton ${englishOnly ? 'englishOnlyButton--on': '' }`}>
                            <span>EN</span>
                        </div>    
                    </div>

                </div>
            </div>
        );
    }


   const playSong = (song) => {
        const newSong = { 
            artist: song.artist, 
            duration:song.duration, 
            title: song.title, 
            youtube: song.youtube, 
            order: songs[songs.length - 1] ? Number(songs[songs.length - 1].order) + 1 : 0,
            added: Number((new Date().getTime() / 1000).toFixed(0))
        };
        const queueHasSong = queue[queue.selected].filter(sng => sng.id === newSong.id).length > 0 ? true: false;
        dispatch(addSong(uid, queue.selected, newSong, true, queueHasSong));
  
    }

   const addToPlaylist = (song, playlistID) => {
        const selectedPlaylist = userplaylistsMin.length > 0 ? userplaylistsMin.filter(plist => plist.id === playlistID) : [];
        const newSong = { 
            artist: song.artist, 
            duration:song.duration, 
            title: song.title, 
            youtube: song.youtube, 
            order: selectedPlaylist.length > 0 ?  selectedPlaylist[0].count + 1: 0,
            added: Number((new Date().getTime() / 1000).toFixed(0)) 
        };

        return dispatch(addSong(uid, playlistID, newSong));
    }




   let pageCount = 1;
   if(genreTracks.length > 0 ) {
      const currentGenSongsCount = genreTracks.filter(x => x.id === genre);
      pageCount = Math.ceil(currentGenSongsCount[0].count/100) ;
   }

   
   return(
      <div id="discover" className="discover--popular">
      
            {userSettings.mobile && <DiscoverHeader showListUI={true}  /> }
            {!userSettings.mobile && 
               <button className={`listViewBtn ${listView ? 'listViewBtn--active':'' }`} data-tip="List View" onClick={()=> dispatch(setDiscoverListView(!listView))}>
                  <Icon icon="md-list" fontSize="16px" color="#ffffff"/>
               </button>
            }

            <div id="discover_content">
               <div className="discover_content__wrap discover_popular discover_content__wrap-all">
                  <div className="tracks_wrap">
                        {renderHeader()}
                        <div className="trackScroll_wrap trackScroll_wrap--pagination customScrollbar">
                           <div>
                              {poplarTracks ? 
                                    <Tracks 
                                    listView={listView}
                                    songs={tracks} 
                                    addToPlaylist={addToPlaylist} 
                                    playSong={playSong} 
                                    allPlaylists={userplaylistsMin} 
                                    slider={false}
                                    guest={!uid} 
                                    />
                              :
                                    <TrackSkeleton />
                              }
                           </div>
                        </div>
                  </div>
                  <div className="tracks__pagination">
                        <ReactPaginate previousLabel={<Icon icon="ios-arrow-back" fontSize="20px" color="#ffffff"/>}
                        nextLabel={<Icon icon="ios-arrow-forward" fontSize="20px" color="#ffffff"/>}
                        breakLabel={'...'}
                        forcePage={page}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={userSettings.mobile ? 1 : 5 }
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                  </div>
            </div>
      </div>
      </div>
   );

}

export default PopularTracks;