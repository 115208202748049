import React from 'react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import UserMalePic from '../../assets/user_m.png';

const formatter = buildFormatter({
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: '',
    suffixFromNow: 'from now',
    seconds: 'just now',
    minute: '1 min',
    minutes: '%d mins',
    hour: '1 hr',
    hours: '%d hrs',
    day: '1 day',
    days: '%d days',
    month: '1 month',
    months: '%d months',
    year: '1 year',
    years: '%d years',
    wordSeparator: ' ',
    numbers: []
})

class StationUsers extends React.PureComponent{
    state = {
        users:[],
        showUsers: false
    }

    renderUsersMini = () => {
        return Object.keys(this.props.users).slice(0, 3).map((userID)=>
        <div className="stationUser" key={userID}>
            <img src={this.props.users[userID].avatar ? this.props.users[userID].avatar : UserMalePic } alt={this.props.users[userID].username} />
        </div>)
    }

    giveFullAccess = (user)=> {
        console.log('Give Full Access!');
        this.props.giveFullAccess(user);
    }

    renderAllUsers = () => {
        return Object.keys(this.props.users).slice(0, 3).map((userID)=> {
            return (
                <div className={`stationUsers__all__user ${this.props.isOwner ? 'stationUsers__all__user--owner': 'stationUsers__all__user--guest'}`} key={userID}>
                    <div className="stationUsers__all__user__left">
                        <div className="stationUsers__all__user__avatar">
                            {this.props.users[userID].status === 'fullAccess' &&<span className="stationUsers__all__user__fullAccess">&#9733;</span>}
                            <img src={this.props.users[userID].avatar ? this.props.users[userID].avatar : UserMalePic } alt={this.props.users[userID].username} />
                        </div>
                        {this.props.users[userID].status === 'pending' && <span className="stationUsers__all__user__pending">Pending</span>}
                        {this.props.users[userID].status === 'owner' && <span className="stationUsers__all__user__owner">Owner</span>}
                        {(this.props.users[userID].status === 'allowed' || this.props.users[userID].status === 'fullAccess') && <span className="stationUsers__all__user__time"><TimeAgo date={new Date(this.props.users[userID].joined * 1000)} formatter={formatter} /></span>}
                    </div>
                    <div className="stationUsers__all__user__right">
                        <h4>
                            {this.props.users[userID].fullname ? this.props.users[userID].fullname: this.props.users[userID].username }
                            <span>@{this.props.users[userID].username}</span>
                        </h4>
                        {this.props.users[userID].status === 'pending' && 
                                <p>
                                    <button onClick={()=> this.props.acceptUser(this.props.users[userID].uid, this.props.stationID, this.props.users[userID].username)}>Accept</button>
                                    <button onClick={()=> this.props.rejectUser(this.props.users[userID].uid, this.props.stationID)}>Reject</button>
                                </p> 
                        } 
                        {(this.props.users[userID].status === 'allowed' || this.props.users[userID].status === 'fullAccess') && this.props.isOwner &&
                            <p>
                                <button onClick={()=> this.props.kickUser(this.props.users[userID].uid, this.props.stationID, this.props.users[userID].username)}>Remove</button>
                                <button data-tip="Let user Play/Puase, Re-order and Remove Songs" onClick={()=>this.giveFullAccess(this.props.users[userID])}>{this.props.users[userID].status === 'fullAccess' ? 'Revoke Full Access' :'Give Full Access'}</button>
                            </p>
                        }
                    </div>
                </div>
            );
        });
    }

    render(){
        return(
            <div className="stationUsers">
                <div className={`usersMini ${this.state.showUsers ? 'usersMini--active' : ''} `}onClick={()=> this.setState({showUsers: !this.state.showUsers})}>
                    <div className="userMini__thumbs">{this.renderUsersMini()}</div>
                    <div className="userMini__counts">{this.props.mobile ? Object.keys(this.props.users).length :Object.keys(this.props.users).length+ ' Online'}</div>
                </div> 
                {this.state.showUsers && 
                    <div className="stationUsers__all">
                        {this.renderAllUsers()}
                    </div>
                }
            </div>
        );
    }
}

export default StationUsers;