import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../Modal/Modal';
import playlistCategories from '../../utils/genres';
import { createPlaylist } from '../../redux/playlistSlice';
import { hideAddtoPlaylist } from '../../redux/userSlice';
import { createSlug, minifyPlaylist } from '../../helpers';
import SelectField from '../SelectField/SelectField';


const CreatePlaylist = ({playlists, userSettings, uid, clearPlayListfromQ, createPlayListfromQ, currentQueue}) => {

   const createPlaylistForm = useRef(null);
   const dispatch = useDispatch();

   const [category, setCategory] = useState('mixed');
   const [error, setError] = useState('');

   const onCatChange = (selectedOption) => { 
        console.log(selectedOption);
        setCategory(selectedOption);
    }

   const onCreatePlaylist = (event) => {
        event.preventDefault();
        const formData = new FormData(createPlaylistForm.current);
        const createplaylistTitle = formData.get('createplaylistTitle');
        const createplayliststatus = formData.get('createplayliststatus');

        console.log('PlayList Title and Category', createplaylistTitle , createplayliststatus);
        let error = false;
        if(!createplaylistTitle){
            error = true;
            setError('Please give the playlist a Name');
        }
        if(createplaylistTitle.length > 100){
            error = true;
            setError('Name Should not have more than 100 Characters');
        }

        if(!error){
            console.log('PlayList Creation Success!', playlists, minifyPlaylist(playlists));
            setError('');
            const orderValue = playlists ? Object.keys(playlists).length : 0;
            const slug = createSlug(createplaylistTitle, minifyPlaylist(playlists));

            const newPlaylist = {
                title: createplaylistTitle,
                status: createplayliststatus,
                slug: slug,
                created: Number((new Date().getTime() / 1000).toFixed(0)),
                category:category,
                order:orderValue,
                owner: uid,
            }
            if(createPlayListfromQ || window.stationSongs){
               const playlistSongs = window.stationSongs ? window.stationSongs : currentQueue;
               dispatch(createPlaylist(newPlaylist, uid, playlistSongs));
               clearPlayListfromQ();
            }else{
               dispatch(createPlaylist(newPlaylist, uid));
            }
            
            dispatch(hideAddtoPlaylist());
        }
    }

   const onCloseModal = () => {
         window.stationSongs = null;
         clearPlayListfromQ();
         dispatch(hideAddtoPlaylist());
    }

   
   return(
      <div id="createPlaylistModal" className={userSettings.showCreatePlaylist ? 'show' : 'hide'}>
            <Modal title={`Create New Playlist`} show={userSettings.showCreatePlaylist} maxWidth={400} closeModal={onCloseModal}>
               <div className="createPlaylistModal_wrapper">

                  <form id="userSettings__form" onSubmit={onCreatePlaylist} ref={createPlaylistForm} >
                        <span className="playlistFromQ">{(createPlayListfromQ || window.stationSongs) ? 'Creating Playlist from '+(window.stationSongs ? 'this station' : 'Queue'):''}</span>
                        <div className="input_wrapper">
                           <span>Name*</span>
                           <input type="text" name="createplaylistTitle" placeholder="Playlist Name"/>
                        </div>
                        
                        <div className="select_wrapper">
                           <span>Category*</span>
                           <SelectField 
                           style={{ width: 340 }} 
                           options={playlistCategories.map((category)=> ({ label: category.name, value: category.id }) )}
                           value={category}
                           onChange={onCatChange}
                           placeholder={'Select Category'}
                           showSearch={true}
                           />
                        </div>

                        <div className="playlist_status customRadio">
                           <label className="control control--radio">Public
                              <input type="radio" name="createplayliststatus" value="public" defaultChecked={true} />
                              <div className="control__indicator"></div>
                           </label>

                           <label className="control control--radio">Private
                              <input type="radio" name="createplayliststatus" value="private" />
                              <div className="control__indicator"></div>
                           </label>

                        </div>

                        {error && <p className="errorMsg">{error}</p>}

                        <button type="submit">Create Playlist</button>
                  </form>
               </div>
            </Modal>
      </div>
   );
    

}

// export default compose(
//     connect(
//       (state, props) => ({
//         queue: state.queue,
//         auth: state.firebase.auth,
//         userSettings: state.user,
//         profile: state.firebase.profile // load profile
//       })
//       , 
//       {createPlaylist, hideAddtoPlaylist}
//     )
//   )(CreatePlaylist);

  export default CreatePlaylist;