import React from 'react';
import Icon from '../Icon/Icon';


class PlaylistLike extends React.PureComponent{

    onLike = () => {
        console.log('liked!');
        if(this.props.userIsAuthor){
            this.props.showLikers();
        }else{
            this.props.likePlaylist();
        }
    }

    render(){
        return(
            <div className={`playlist__likes ${this.props.userAlreadyLiked ? 'liked' : ''}`} onClick={this.onLike}>
              <Icon icon="ios-heart" fontSize="20px" color="#ffffff"/> {this.props.likes}
            </div>
        )
    }
}

export default PlaylistLike;
