import React from 'react';
import isequal from 'lodash.isequal';
import { getDatabase, ref, onValue, onDisconnect, off, get, child } from "firebase/database";
import UserMalePic from '../../assets/user_m.png';
import { objectToArray } from '../../helpers';
import StationPlayer from './StationPlayer';
import ChatBox from '../channels/ChatBox';
import StationUsers from './StationUsers';
import StationSongWrapper from './StationSongWrapper';
import StationMobileActions from './StationMobileActions';
import Icon from '../Icon/Icon';

class StationGuest extends React.PureComponent{
    constructor(props){
        super(props);
          this.state = {
            playing: false,
            station:{},
            stationOnline: null,
            stationActive:false,
            connectionLost: false,
            stationMessage:'',
            sentRequest: false,
            kicked: false,
            requestStatus: '',
            requestTimer: {},
            activeUsers:{},
            pendingUsers:[],
            channelSongs:[],
            currentSong:{},
            showAddModal: false,
            shouldLock: false,
            mobileTab:'queue',
            newChatMessage:0,
            mobileSelectedSong:null,
            stationRef:{},
            showStationMenu: false
        }
    
    }
    
    componentDidMount = () => {
        if(!this.props.userSettings.mobile) {
            document.addEventListener('visibilitychange', () =>{
                if(this.state.newChatMessage > 0){
                    if(document.hidden){
                    }else{
                        setTimeout(() => {
                            this.setState({newChatMessage: 0  });
                            document.title = `${this.props.stationName}'s Station - Yhimsical`;
                        }, 600);

                    }
                }
            })
        }
        if(this.props.stationID){
            const db = getDatabase();
            const theStationRef = ref(db, `stations/${this.props.stationID}`);
            console.log('######## theStationRef: ', theStationRef);
            this.setState({stationRef: theStationRef });
            this.checkStationStatus(this.props.stationID);
        }
    }

    componentWillUnmount(){
         if(this.state.stationRef && Object.keys(this.state.stationRef).length > 0){
            console.warn('this.state.stationRef: ', this.state.stationRef);
            // off(this.state.stationRef);
         }
         this.props.setStation('');
    }

    listenToDB = (isActive) => {
        if(!this.props.stationID) { return this.setState({error: 'Station ID not Found!'})}
        if(isActive){ return off(this.state.stationRef)}
        
         onValue(this.state.stationRef,  (snapshot) => {

            //console.log(snapshot.val());
            let station = snapshot.val();

                    //If user request is rejected by owner, disconnect from the DB
                    if(station && this.props.auth && this.props.auth.uid && this.state.pendingUsers.filter(user => user.uid === this.props.auth.uid).length > 0 && !station.users[this.props.auth.uid]){
                        console.log('Your Request has been rejected!');
                        this.setState({requestStatus:'rejected', sentRequest:false,requestTimer:{}}, ()=> {
                            off(this.state.stationRef);
                            clearInterval(this.theInterval);
                            setTimeout(() => {
                                this.setState({requestStatus:'', sentRequest:false,requestTimer:{}});
                            }, 4000);
                        });
                    }
                    // if(this.props.auth && this.props.auth.uid && this.state.pendingUsers.filter(user => user.uid === this.props.auth.uid).length === 0 && station.users[this.props.auth.uid]){
                    //     console.log('Your Request has been Approved!');
                    //     clearInterval(this.theInterval);
                    // }

                    if(station && station.users && this.props.auth?.uid && station.users[this.props.auth.uid] && (station.users[this.props.auth.uid].status ==='pending')){
                        console.log('SET Pending Users for Requested User!');
                        let pendingUsers = []; 
                        Object.keys(station.users).forEach(userID=> {
                            if(station.users[userID].status === 'pending'){
                                pendingUsers.push(station.users[userID]);
                            }
                        });
                        this.setState({ pendingUsers: pendingUsers });
                    }
                    //IF user is kicked, disconnect from the DB
                    if(station && station.users && station.users[this.props.auth.uid] && station.users[this.props.auth.uid].status === 'kicked'){
                        this.setState({kicked: true, stationActive: false, station: {}, stationOnline: false});
                        this.props.setStation('');
                        off(this.state.stationRef);
                    }

            if(station && this.props.auth.uid && station.users && station.users[this.props.auth.uid] && (station.users[this.props.auth.uid].status ==='allowed' || station.users[this.props.auth.uid].status ==='owner' || station.users[this.props.auth.uid].status ==='fullAccess')){
                //console.log('USER ALLOWED!');
                this.setState({ stationActive: true, sentRequest: false});
                if(station && station.songs){
                    this.props.setSationSongs(station.songs);
                }

                if(!isequal(this.state.station.users, station.users)){
                    let pendingUsers = []; let activeUsers = {}; 
                    Object.keys(station.users).forEach(userID=> {
                        if(station.users[userID].status === 'pending'){
                            pendingUsers.push(station.users[userID]);
                        }
                        if(station.users[userID].status === 'allowed' || station.users[userID].status === 'owner' || station.users[userID].status === 'fullAccess' ){
                            activeUsers[userID] = station.users[userID];
                        }
                    });
                    this.setState({pendingUsers: pendingUsers, activeUsers: activeUsers});
                }
    
                this.setState({station: station ? station : {}});
            } 

            //If user leaves, add him to userleft db so that owner can remove them
            onDisconnect(this.state.stationRef).update({
                ...station,
                    userLeft: station && station.userLeft ? {...station.userLeft, [this.props.auth.uid]: Math.round((new Date()).getTime() / 1000) } :{[this.props.auth.uid]: Math.round((new Date()).getTime() / 1000)}
            });



            if(!snapshot.val()){
                if(this.props.auth && this.props.auth.uid &&  (this.state.activeUsers[this.props.auth.uid] || this.state.pendingUsers[this.props.auth.uid]) ){
                    //If user connection is lost show users that they have been disconnected
                    this.setState({stationActive: false, station: {}, connectionLost: true, stationOnline: false});
                    this.props.setStation('');
                    off(this.state.stationRef);
                }
            }
            if(snapshot.val() && !station.users[this.props.auth.uid]){
                //If user is not found in the users list, disconnect the user
                this.setState({stationActive: false, station: {}, connectionLost: true, stationOnline: false});
                this.props.setStation('');
                off(this.state.stationRef);
            }

        });


    }

    checkStationStatus = (stationID) => {
        console.log(stationID);
        const dbRef = ref(getDatabase());
        get(child(dbRef, `/stations/${stationID}`)).then((snapshot) => {
            if(snapshot.val() && snapshot.val().stationInfo){
                const stationData = snapshot.val();
                console.log('$$$$$$ STATION ONLINE');
                this.setState({ stationOnline: true, connectionLost: false, shouldLock: stationData.users && Object.keys(stationData.users).length >= 5 ? true: false });
            }else{
                console.log('$$$$$$ STATION OFFLINE!');
                this.setState({stationOnline: false, connectionLost: false});
            }
        });
    }


    removeSong = (songID, song) => {
        const hasFullAccess = this.props.auth.uid && this.state.activeUsers && this.state.activeUsers[this.props.auth.uid] && this.state.activeUsers[this.props.auth.uid].status ==='fullAccess' ? true: false;
        if(this.state.station.songs[songID].addedBy === this.props.profile.username || hasFullAccess){
            this.props.removeSationSong(songID, song);
        }
    }

    newChatMessage = () => {
        if(this.props.userSettings.mobile && this.props.mobileTab === 'queue'){
            this.setState({newChatMessage: this.state.newChatMessage + 1})
        }
        //console.log('@@@@@document.hasFocus', document.hasFocus());
        if(!this.props.userSettings.mobile && !document.hasFocus()){
           
            this.setState({newChatMessage: this.state.newChatMessage + 1}, ()=> {
                if(this.state.newChatMessage > 0){
                    document.title = `(${this.state.newChatMessage})${this.props.stationName}'s Station - Yhimsical`;
                }
            })
            
        }
    }

    renderPlaylist = (hasFullAccess) => {
        return <StationSongWrapper 
                //selectedSong={this.props.userSettings.selectedSong}
                //selectSong={this.props.selectSong}
                // mobileSelectedSong={this.state.mobileSelectedSong}
                // mobileSelectSong={this.mobileSelectSong}
                //uid={this.props.auth  && this.props.auth.uid }
                reorderSongs={this.props.reorderStaionSongs}
                songs={objectToArray(this.state.station.songs, true)} 
                isMobile={this.props.userSettings.mobile}
                queue={this.props.queue}
                playlistsMin={this.props.userplaylistsMin}
                draggingSong={this.props.draggingSong}
                currentSong={this.state.station.currentSong && this.state.station.currentSong.id}
                userActions={this.props.userSettings.actions}
                addSong={this.props.copySong}
                removeSationSong={this.removeSong}
                likeStationSong={this.props.likeStationSong}
                isOwner={hasFullAccess}
                trueOwner={true}
                currentUser={this.props.profile.username}
                playSong={this.props.setCurrentSong}
                pauseSong={this.props.pauseStationSong}
                mobileSelectedSong={this.state.mobileSelectedSong}
                mobileSelectSong={(sng)=> this.setState({mobileSelectedSong: sng}) }
                />
    }

    joinStation = () => {
        this.props.joinStation(this.props.stationID);
        this.props.setStation(this.props.stationID);
        this.setState({sentRequest: true});
        this.listenToDB();
        this.requestTimer();
        //If after 7 minutes the owner does not accept the request, disconnect from the Database.
        setTimeout(() => {
            if(!this.state.stationActive){
                this.listenToDB(true);
                clearInterval(this.theInterval);
            }
        }, 420000);

    }

    requestTimer = () =>{
        var countDownDate = new Date().getTime() + 420000;
        // Update the count down every 1 second
        this.theInterval = setInterval(() =>  {
            var now = new Date().getTime();
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            //var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            //var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            if(this.state.sentRequest){
                this.setState({sentRequest: true, requestTimer: { minutes: minutes, seconds: seconds} })
                // If the count down is finished, write some text 
                if (distance < 0) {
                    clearInterval(this.theInterval);
                    this.cancelJoinRequest();
                }
            }

        }, 1000);
    } 

    cancelJoinRequest = () => {
        this.props.cancelJoinRequest(this.props.stationID);
        this.setState({sentRequest: false, requestTimer: {} });
        this.props.setStation('');
        clearInterval(this.theInterval);
    }

    leaveStation = () => {
        this.props.cancelJoinRequest(this.props.stationID);
        this.props.setStation('');
        this.setState({stationActive: false, station: {}, connectionLost: true, stationOnline: false, requestStatus:'', sentRequest:false,requestTimer:{}});
        off(this.state.stationRef);
    }

    stationMessage = () => {
        let stationMessage = "";
        if(!this.props.profile.username){
            stationMessage = this.state.stationOnline ? <p>Station is Online. Please login to join the Station <button className="stationLoginbtn" onClick={ ()=>this.props.showUserArea()}>Login</button></p>: <p>This Station is not Online! Please try again later.. <button className="checkStationStatus" onClick={ ()=>this.checkStationStatus(this.props.stationID)}>Try Again</button></p>;
        }
        if(!this.state.stationOnline && this.props.profile.username && (this.props.profile.username !== this.props.stationName) && !this.state.shouldLock){
            stationMessage = <p>This Station is not Online! Please try again later.. <button className="checkStationStatus" onClick={ ()=>this.checkStationStatus(this.props.stationID)}>Try Again</button></p>;
        }
        if(this.state.stationOnline && this.props.profile.username && (this.props.profile.username !== this.props.stationName) && !this.state.shouldLock){
            stationMessage = this.state.sentRequest && this.state.requestTimer.minutes ? <p>Waiting For Station Owner to accept your Reuqest. <button className="cancelJoinRequestBtn" onClick={this.cancelJoinRequest}>Cancel</button> <span className="stationCounter">{this.state.requestTimer.minutes}:{this.state.requestTimer.seconds}</span></p>:<p><i className="stationMsgOnline" />{this.props.stationName}'s Station is online! Join Station to listen/Watch Together <button className="checkStationStatus" onClick={this.joinStation}>Join Station</button></p>;
        }
        if(this.state.stationOnline && this.props.profile.username && (this.props.profile.username !== this.props.stationName) && this.state.shouldLock){
            stationMessage = <p>This Station is Full! Please try again later.. <button className="checkStationStatus" onClick={ ()=>this.checkStationStatus(this.props.stationID)}>Try Again</button></p>;
        }
        if(this.state.stationOnline && this.props.profile.username && (this.props.profile.username !== this.props.stationName) && this.state.requestStatus === 'rejected'){
            stationMessage = <p>Your Request has been Rejected!! Please try again later...</p>;
        }
        if(this.state.stationOnline && this.state.stationActive && this.props.auth.uid && this.state.activeUsers[this.props.auth.uid] && (this.state.activeUsers[this.props.auth.uid].status === 'allowed' || this.state.activeUsers[this.props.auth.uid].status === 'fullAccess')){
            stationMessage = '';
        }
        //console.log(stationMessage);
        return stationMessage;
    }

    render(){
        let stationMessage = this.stationMessage();
        const hasFullAccess = this.props.auth.uid && this.state.activeUsers && this.state.activeUsers[this.props.auth.uid] && this.state.activeUsers[this.props.auth.uid].status ==='fullAccess' ? true: false;
        return(
            <div className="station__guest">
        

                <div className={`station__inner station__inner--mobile__${this.state.mobileTab}`}>

                    <div className="station__left">
                        <div className='playlist__header'>
                            <h4>
                                <span><img alt={this.props.stationName} src={this.props.owner && this.props.owner.avatarUrl ? this.props.owner.avatarUrl :UserMalePic} /></span>
                                {`${this.props.stationName}'s Station`}
                                {this.state.stationOnline ? <i className="stationActiveIcon" /> : <i className="stationInactiveIcon" /> }
                            </h4>
                            <div className='playlist__header_right'>
                                 {this.state.stationActive && 
                                    <div className="station__leave" onClick={this.leaveStation}><Icon icon="md-log-out" fontSize="16px" color="#ffffff"/> Leave Station</div>
                                 }
                                 {this.state.stationActive && 
                                    <div className={`playlist__edit ${this.state.showStationMenu ? 'active': ''}`} onClick={()=> this.setState({showStationMenu: !this.state.showStationMenu})}>
                                       <div className="playlist__edit__icon"><Icon icon="ios-more" fontSize="22px" color="#ffffff"/></div>
                                       <ul>
                                          <li onClick={()=> { window.stationSongs = objectToArray(this.state.station.songs, true); this.props.createPlaylist(); }}>Save as Playlist</li>
                                          <li onClick={()=> this.props.showInviteModal()}>Invite Friends</li>
                                       </ul>
                                    </div>
                                 }
                              </div>
                        </div>
                            {stationMessage && <div className="stationMessage">{stationMessage}</div>}

                            {this.state.stationActive &&
                                <div className="station__playlist">
                                    {this.state.station.songs && <div className="station_playlist_wrap customScrollbar"><div>{this.renderPlaylist(hasFullAccess)}</div></div>}
                                    {!this.state.station.songs && <div className="stationMessage"><p>No Songs added yet! Add Songs to get Started.</p></div>}
                                </div>
                            }
                    </div>
                    <div className="station__right">
                        
                        <div className="station__chat">
                            {this.state.stationActive && this.state.station && this.state.activeUsers &&<StationUsers users={this.state.activeUsers} />}
                            {this.state.stationActive ? 
                                <ChatBox 
                                uid={this.props.auth && this.props.auth.uid}
                                messages={this.state.station && this.state.station.messages} 
                                onlineUsers={this.state.activeUsers} 
                                channel={this.props.stationID}
                                station={this.props.stationID}
                                channelName={this.props.stationName}
                                postChatMessage={this.props.postChatMessage}
                                firebase={this.props.firebase}
                                addSong={this.props.copySong}
                                playlistsMin={this.props.userplaylistsMin} 
                                queue={this.props.queue} 
                                mobile={this.props.userSettings.mobile}
                                newChatMessage={this.newChatMessage}
                                />
                                :
                                <div id="chatbox" className={`chatbox`}>
                                    <h4>Chat</h4>
                                </div>
                            }
                        </div>

                    </div>

                    {this.props.userSettings.mobile && this.state.mobileSelectedSong &&
                        <StationMobileActions 
                        mobileSelectSong={(sng)=> this.setState({mobileSelectedSong: sng}) }
                        mobileSelectSongClear={()=> this.setState({mobileSelectedSong: null}) }
                        addSong={this.props.copySong}
                        removeSationSong={this.props.removeSationSong}
                        playlistsMin={this.props.playlistsMin}
                        playSong={this.props.setCurrentSong}
                        reorderSongs={this.props.reorderStaionSongs}
                        queue={this.props.queue}
                        mobileSelectedSong={this.state.mobileSelectedSong}
                        songs={this.state.station.songs && objectToArray(this.state.station.songs, true).length > 0 && objectToArray(this.state.station.songs, true).sort(( a, b )=>  a.order - b.order)}
                        />
                    }
                
                </div>

                {this.props.userSettings && this.props.userSettings.mobile && this.state.stationActive &&
                    <div className={`station__mobile__tabs station__mobile__tabs__${this.state.mobileTab}`}>
                        <button className="station__mobile__tabs__queue" onClick={()=> this.setState({mobileTab: 'queue'})}>Queue</button>
                        <button className="station__mobile__tabs__chat" onClick={()=> this.setState({mobileTab: 'chat'})}>Chat</button>
                    </div>
                }

                {this.state.stationActive && 
                    <StationPlayer 
                    showLibraryModal={this.props.showLibraryModal} 
                    showAddSongModal={this.props.showAddSongModal} 
                    playlists={this.props.playlists} 
                    isOwner={hasFullAccess} 
                    trueOwner={true}
                    mobile={this.props.userSettings && this.props.userSettings.mobile}
                    importPlaylist={this.props.importPlaylist}
                    stationSongs={this.state.station.songs && objectToArray(this.state.station.songs, true).length > 0 && objectToArray(this.state.station.songs, true).sort(( a, b )=>  a.order - b.order)}
                    currentSong={this.state.station.currentSong}
                    playerVolume={this.props.playerVolume}
                    setCurrentSong={this.props.setCurrentSong}
                    playSong={this.props.playStationSong}
                    pauseSong={this.props.pauseStationSong}
                    setPlayedSeconds={this.props.setPlayedSeconds}
                    volume={this.props.player.volume}
                    getCurrentTime={()=> this.state.station.playedSeconds ? this.state.station.playedSeconds : 0}
                    stationInfo={this.state.station.stationInfo}
                    setStationRepeat={this.props.setStationRepeat}
                    currentUser={this.props.profile.username}
                    likeStationSong={this.props.likeStationSong}
                    />
                }                
                
                {this.state.connectionLost && <div className="station__connectionLost"><p>You are disconnected from the Station. </p><button onClick={()=> this.setState({connectionLost: false})}>Close</button></div>}
                {this.state.kicked && <div className="station__connectionLost"><p>You were removed from the Station by the Station Owner. </p><button onClick={()=> this.setState({kicked: false})}>Close</button></div>}

            </div>
        );
    }
}

export default StationGuest;