import { createSlice } from "@reduxjs/toolkit";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { collection, where, query, getDocs } from "firebase/firestore";
import { nanoid } from "nanoid";
import { minifyPlaylist } from "../helpers";
//import { sendNotification } from './notifications';
export const SEELECTED_PLAYLIST = 'SEELECTED_PLAYLIST';

export const slice = createSlice({
   name: "userplaylists",
   initialState: {
      updating: false,
      reordering: false,
      userPlaylists: {},
      userPlaylistsMin: [],
      selectedPlaylist: null,
      currentPlaylist:'temp',
      currentSong: '',
      currentSongs: [],
      curentSongAdded:'',
      currentPlaylistDetail: null
    },
   reducers: {
      selectPlaylist: (state, { payload }) => {
         state.selectedPlaylist = payload.playlistID;
      },
      setUserPlaylists: (state, {payload}) => {
         state.userPlaylists = payload.playlists;
      },
      setUserPlaylistsMin: (state, {payload}) => {
         state.userPlaylistsMin = payload.playlistsMin;
      },
      selectPlaylistSong: (state, action) => {
         state.currentSongs = action.songs; 
         state.currentSong = action.payload;  
         state.curentSongAdded = action.added; 
         state.currentPlaylist = action.playlistID;
         state.currentPlaylistDetail = action.playlistDetail;
      },
      setPlaylistUpdating: (state, { payload }) => {
         console.log('setPlaylistUpdating: ', payload);
         state.updating = payload;
      },
      setPlaylistReordering: (state, { payload }) => {
         state.reordering = payload;
      },
   }
});

export const { selectPlaylist, selectPlaylistSong, setUserPlaylists, setUserPlaylistsMin, setPlaylistUpdating, setPlaylistReordering } = slice.actions;

//Fetch Playlist
export const getUserPlaylists = () => (dispatch, state) => {
   let playlists = {};
   const uid = firebase.auth().currentUser?.uid;
   console.log('getUserPlaylists: ', uid);
   // query user data
   if(uid){
      const db = firebase.firestore();
      const PlaylistRef = collection(db, 'Playlists');
      const userPlaylistsQuery = query(PlaylistRef, where("owner", "==", uid));

      getDocs(userPlaylistsQuery).then((docs)=> {
         docs.forEach((doc)=> {
            const playlist = doc.data();
            playlists[doc.id] = {...playlist, id: doc.id};
         });
      }).catch((err)=> {
         console.log('getUser ERROR: ', err);
      }).finally(()=> {
         dispatch(setUserPlaylists({ playlists }));
         dispatch(setUserPlaylistsMin({ playlistsMin: minifyPlaylist(playlists) }))
         dispatch(setPlaylistUpdating(false));
         return state().userAuth.userData;
      })
   }else{
      return false;
   }  
 };


//Create Playlist
export const createPlaylist = (playlist, uid, songs) =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>', playlist, uid, songs);
    
    let newPlaylist = {...playlist, owner: uid};
    let playlistMini = {...playlist, count:0}
    
    if(songs){
      const newSongs = [...songs]
      let songsObject = {};
      newSongs.forEach((song, index) => {
        const songID = nanoid();
        const newSong = {
          artist: song.artist ? song.artist : '', 
          duration: song.duration ? song.duration : '', 
          title: song.title ? song.title : '', 
          youtube: song.youtube ? song.youtube : '', 
          order: index,
          added: parseInt((new Date().getTime() / 1000).toFixed(0), 10) 
        }
        songsObject[songID] = newSong;
      });
      newPlaylist = {...playlist, songs:songsObject};
      playlistMini = {...playlist, count: songs.length}
    }
    const db = firebase.firestore();
    const playListsRef = db.collection('Playlists');
    const likesRootRef = db.collection('Likes');
    const userRef = db.collection("Users").doc(uid);
    const curentState = getState();
    const currentPlaylists = curentState.userplaylists.userPlaylists || {};
    const currentPlaylistsMin = {};
    Object.keys(currentPlaylists).forEach((playlistID)=> {
      currentPlaylistsMin[playlistID] = {...currentPlaylists[playlistID], songs: []}
    })
    dispatch(setPlaylistUpdating(true));
    playListsRef.add(newPlaylist).then((ref) => {
         console.log('@@New Playlist Created!!!!!!', ref.id);
         likesRootRef.doc(ref.id).set({ owner: uid, users:null} );
         currentPlaylistsMin[ref.id] = {...playlistMini, id: ref.id};
         userRef.update({ playlists: currentPlaylistsMin }).then((resp)=> {
            console.log('createPlaylist update: ', resp);
         // dispatch(setUser({ userData }));
         }).catch((err)=> {
            console.log('createPlaylist ERROR: ', err);
         });

         dispatch(setUserPlaylists({playlists: {...currentPlaylists, [ref.id]: {...playlist, id: ref.id} }}));
         dispatch(setUserPlaylistsMin({playlistsMin: minifyPlaylist({...currentPlaylistsMin, [ref.id]: {...playlistMini, id: ref.id} }) }));
    })
    .catch((error) => {
      console.log('@@ERROR: New Playlist Creation Failed!!!!!!', error);
    }).finally(()=> {
      dispatch(setPlaylistUpdating(false));
    });  
};

//Set Initial Playlist after Signup
export const setInitialPlaylist = (playlist, uid) =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>', playlist, uid);
      const db = firebase.firestore();
      const userRef = db.collection("Users").doc(uid);
      firebase.set(`playlists/${uid}`, {'temp':playlist}).then((data) => {
         console.log('All Done!',data);
         userRef.update({ playlists:{'temp':playlist} }).then((resp)=> {
            console.log('setInitialPlaylist update: ', resp.data());
            // dispatch(setUser({ userData }));
            }).catch((err)=> {
               console.log('setInitialPlaylist ERROR: ', err);
            });
            //dispatch({type: 'CREATE_PLAYLIST', payload: playlist})
      })
};


export const optimisticPlaylistChange = (action, payload) => (dispatch, getState) => {
   console.log('optimisticPlaylistChange: ', action, payload);
   const {currentPlaylists={}, currentPlaylistsMin=[]} = payload;

   let updatedPlaylists = {...currentPlaylists}; let updatedPlaylistsMin = [...currentPlaylistsMin]; 
   if(action === 'update' && payload.updatedPlaylist){
      const {id, title, status, category } = payload.updatedPlaylist;
      console.log('update', payload.updatedPlaylist);
      if(updatedPlaylists[id]){
         updatedPlaylists[id] = {...updatedPlaylists[id], title, status, category};
         updatedPlaylistsMin = updatedPlaylistsMin.map((item) => item.id === id ? {...item, title, status, category} : item );
      }
      console.log('updated', updatedPlaylists, updatedPlaylistsMin);
   }
   if(action === 'remove' && payload.playListID && updatedPlaylists[payload.playListID]){
      delete updatedPlaylists[payload.playListID];
      updatedPlaylistsMin = currentPlaylistsMin.filter((item)=> item.id !== payload.playListID);
   }
   if(action === 'reorder' && Array.isArray(payload.updatedPlaylists) && payload.updatedPlaylists.length > 0){
      const newOrders = {};
      payload.updatedPlaylists.forEach((playlist)=> {  newOrders[playlist.id] = playlist.order;  });
      Object.keys(updatedPlaylists).forEach((plKey)=> {
         if(newOrders[plKey] && Number.isInteger(newOrders[plKey].order)) {
            updatedPlaylists[plKey] = newOrders[plKey].order;
         }
      })
      updatedPlaylistsMin = currentPlaylistsMin.map((item)=> ({...item, order: Number.isInteger(newOrders[item.id]) ? newOrders[item.id] : item.order })).sort(( a, b )=>  a.order - b.order);
   }

   if(action === 'add_song' && payload.playListID && payload.song){
      const existingSongs = updatedPlaylists[payload.playListID].songs;
      const newSongs = { ...existingSongs, [payload.song.id]: payload.song }
      console.log(updatedPlaylists[payload.playListID], newSongs);
      updatedPlaylists[payload.playListID] = {...updatedPlaylists[payload.playListID], songs: newSongs };
      updatedPlaylistsMin = updatedPlaylistsMin.map((item) => item.id === payload.playListID ? {...item, count: Object.keys(newSongs).length} : item );
   }

   if(action === 'remove_song' && payload.playListID && payload.songID){
      const existingSongs = updatedPlaylists[payload.playListID].songs;
      const newSongs = { ...existingSongs }
      delete newSongs[payload.songID];
      console.log(updatedPlaylists[payload.playListID], newSongs);
      updatedPlaylists[payload.playListID] = {...updatedPlaylists[payload.playListID], songs: newSongs };
      updatedPlaylistsMin = updatedPlaylistsMin.map((item) => item.id === payload.playListID ? {...item, count: Object.keys(newSongs).length} : item );
   }

   if((action === 'cut_song' || action === 'update_song') && payload.playListID && payload.song){
      const existingSongs = updatedPlaylists[payload.playListID].songs;
      const newSongs = { ...existingSongs, [payload.song.id]: payload.song }
      console.log(updatedPlaylists[payload.playListID], newSongs);
      updatedPlaylists[payload.playListID] = {...updatedPlaylists[payload.playListID], songs: newSongs };
   }

   if((action === 'reorder_songs') && payload.playListID && payload.songs){
      const newSongs = payload.songs
      console.log(updatedPlaylists[payload.playListID], newSongs);
      updatedPlaylists[payload.playListID] = {...updatedPlaylists[payload.playListID], songs: newSongs };
   }
   

   dispatch(setUserPlaylists({ playlists: updatedPlaylists }));
   dispatch(setUserPlaylistsMin({ playlistsMin: updatedPlaylistsMin }));
}

//Update playlist
export const updatePlaylist = (playListID, updatedPlaylist) =>
  (dispatch, getState) => {
    console.log('FROM ACTIOn -->>', playListID, updatedPlaylist, getState());
    const db = firebase.firestore();
    const uid = firebase.auth().currentUser?.uid;
    const playListsRef = db.collection('Playlists');
    const usersRef = db.collection('Users');

    const currentState = getState();
    const currentPlaylists = currentState.userplaylists.userPlaylists;
    const currentPlaylistsMin = currentState.userplaylists.userPlaylistsMin;

    dispatch(optimisticPlaylistChange('update', {updatedPlaylist, currentPlaylists, currentPlaylistsMin}));
    dispatch(setPlaylistUpdating(true));

    playListsRef.doc(playListID).update(updatedPlaylist)
    .then((ref) => {
      console.log('@@Playlist Updated!!!!!!', updatedPlaylist);
      usersRef.doc(uid).update( { ['playlists.' + playListID+'' ]: updatedPlaylist }).catch((error)=> {
         console.log('@@ERROR: Playlist update in Profile Failed!!!!!!', error);
         dispatch(setUserPlaylistsMin({ playlistsMin: currentPlaylistsMin }));
      });
    })
    .catch((error) => {
      console.log('@@ERROR: Playlist update Failed!!!!!!', error);
      dispatch(setUserPlaylists({ playlists: currentPlaylists }));
    }).finally(()=> {
      dispatch(setPlaylistUpdating(false));
    });  
};


//Delete Playlist
export const removePlaylist = (playListID, playlistsMin) =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>', playListID, playlistsMin);
      const db = firebase.firestore();
      const uid = firebase.auth().currentUser?.uid;
      const playListsRef = db.collection('Playlists');
      const likesRootRef = db.collection('Likes');
      const userRef = db.collection("Users").doc(uid);

      const currentState = getState();
      const currentPlaylists = currentState.userplaylists.userPlaylists;
      const currentPlaylistsMin = currentState.userplaylists.userPlaylistsMin;
      
      const playlistsAfterRemoval = playlistsMin.length > 0 ? playlistsMin.filter(plst => plst.id !== playListID) : [];
      const playlistNext = playlistsAfterRemoval[0] ? playlistsAfterRemoval[0].id : 'temp';
      
      dispatch(optimisticPlaylistChange('remove', { playListID, currentPlaylists, currentPlaylistsMin }));
      dispatch(selectPlaylist({ playlistID: playlistNext }));
      dispatch(setPlaylistUpdating(true));

      playListsRef.doc(playListID).delete()
      .then((ref) => {
         console.log('@@Playlist Deleted!!!!!!', playlistsAfterRemoval, playlistNext);
         likesRootRef.doc(playListID).delete();
         userRef.update({ [`playlists.${playListID}`]: firebase.firestore.FieldValue.delete() }).then((resp)=> {
            console.log('@@removePlaylist Profile update: ', resp);
            // dispatch(setUser({ userData }));
          }).catch((err)=> {
             console.log('@@removePlaylist Profile Update ERROR: ', err);
             dispatch(setUserPlaylistsMin({ playlistsMin: currentPlaylistsMin }));
          });
      })
      .catch((error) => {
         console.log('@@ERROR: Playlist update Failed!!!!!!', error);
         dispatch(setUserPlaylists({ playlists: currentPlaylists }));
      }).finally(()=> {
         dispatch(setPlaylistUpdating(false));
      })

};


//Sort Playlist
export const reorderPlaylists = (orderedPlaylists) =>
  (dispatch, getState) => {
   const uid = firebase.auth().currentUser?.uid;
   console.log('FROM ACTIOn -->>', uid, orderedPlaylists);
   const db = firebase.firestore();
   const batch = db.batch();
   const playListsRef = db.collection('Playlists');
   const usersRef = db.collection('Users');
   const profilePayload = {};

   const currentState = getState();
   const currentPlaylists = currentState.userplaylists.userPlaylists;
   const currentPlaylistsMin = currentState.userplaylists.userPlaylistsMin;
   // const playlistIDs = currentPlaylistsMin.map((pl)=> pl.id );
   // const newPlaylistIDs = orderedPlaylists.map((pl)=> pl.id );
   // console.warn(playlistIDs, newPlaylistIDs, JSON.stringify(newPlaylistIDs) !== JSON.stringify(playlistIDs));

   if(orderedPlaylists){
      dispatch(optimisticPlaylistChange('reorder', {updatedPlaylists: orderedPlaylists, currentPlaylists, currentPlaylistsMin}));
      dispatch(setPlaylistReordering(true));
      for (const playlist of orderedPlaylists) {
         batch.update(playListsRef.doc(playlist.id), {order: playlist.order});
         profilePayload['playlists.' + playlist.id+ '.order' ] = playlist.order;
      }
      batch.commit().then((task)=> {
         console.log('@@Updated Playlists Order Successfully', task);
      }).catch((err) => {
         console.error('@@Error Updating Playlists Order ', err);
         dispatch(setUserPlaylists({ playlists: currentPlaylists }));
      });
      usersRef.doc(uid).update( profilePayload)
      .then((prof)=> {
         console.log('@@Profile Updated: ', prof);
      })
      .catch((err)=> {
         console.error('@@Error Updating Profile Playlist ', err);
         dispatch(setUserPlaylistsMin({ playlistsMin: currentPlaylistsMin }));
      }).finally(()=> {
         dispatch(setPlaylistReordering(false));
      });
   }
};


//Update Playlists data in Firestore Profile
export const updateProfilePlaylists = (updatedPlaylistMin) =>
  (dispatch, getState) => {
    //console.log('FROM ACTIOn -->>', updatedPlaylistMin);
    const uid = firebase.auth().currentUser?.uid;
    if(uid){
      const db = firebase.firestore();
      db.collection("Users").doc(uid).update({ playlists:{...updatedPlaylistMin} }).then((resp)=> {
         console.log('updateProfilePlaylists update: ', resp.data());
         // dispatch(setUser({ userData }));
       }).catch((err)=> {
          console.log('updateProfilePlaylists ERROR: ', err);
       })
    }
};

//LIKE Playlist
export const likePlaylist = (uid, playListID, playlistDetail, likedBy, likeCount, notificationSender) =>
  (dispatch, getState) => {
      console.log('FROM ACTIOn -->>', playListID, uid, likedBy, likeCount,  Object.keys(likedBy)[0]);
      const db = firebase.firestore();
      const userRef = db.collection("Users").doc(uid);
      const userID = Object.keys(likedBy).length > 0 && Object.keys(likedBy)[0];

      const likesRootRef = db.collection('Likes');
      likesRootRef.doc(playListID).get().then((doc)=> {
         
         if(doc.exists){
            const plikeData = doc.data();

            if(plikeData.users){
                  console.log('plikeData.users: ', plikeData.users[userID]);
                  if(plikeData.users[userID]){
                     //User Already Liked
                     likesRootRef.doc(playListID).update({ ['users.' + userID ]: firebase.firestore.FieldValue.delete()  })
                     .then(() => {
                        console.log('User Already Liked this Playlist!, remove it!');
                        if(uid){
                           userRef.update({ liked:{ [playListID]: firebase.firestore.FieldValue.delete() } }).then((resp)=> {
                              console.log('likePlaylist: ', resp.data());
                              // dispatch(setUser({ userData }));
                           }).catch((err)=> {
                              console.log('likePlaylist ERROR: ', err);
                           })
                        }
                     })
                  }else{
                  likesRootRef.doc(playListID).update({ users: likedBy })
                  .then(() => {
                     console.log('Like Action Complete!!');
                     if(uid){
                        userRef.update({ liked:{ [playListID]: playlistDetail } }).then((resp)=> {
                           console.log('likePlaylist: ', resp.data());
                           // dispatch(setUser({ userData }));
                        }).catch((err)=> {
                           console.log('likePlaylist ERROR: ', err);
                        })
                     }
                     // if(userID && friendID && notificationSender && liedkPlaylist){
                     //   //Dispatch Notification to uid (playlist Owner)
                     //   console.log('Send Like Notification');
                     //   dispatch(sendNotification('like', userID, friendID, notificationSender, liedkPlaylist));
                     // }
                  })
                  }

            }else{
               likesRootRef.doc(playListID).update({ users: likedBy })
               .then(() => {
                  console.log('Like Action Complete!!');               
                  if(uid){
                     userRef.update({ liked:{ [playListID]: playlistDetail } }).then((resp)=> {
                        console.log('likePlaylist: ', resp.data());
                        // dispatch(setUser({ userData }));
                     }).catch((err)=> {
                        console.log('likePlaylist ERROR: ', err);
                     })
                  }
                     // if(userID && friendID && notificationSender && liedkPlaylist){
                     //   //Dispatch Notification to uid (playlist Owner)
                     //   console.log('Send Like Notification');
                     //   dispatch(sendNotification('like', userID, friendID, notificationSender, liedkPlaylist));
                     // }
               })
            }

         }
      })

};

export const removeLikedPlaylist = (playListID) =>
  (dispatch, getState) => {
    const uid = firebase.auth().currentUser?.uid;
    if(uid){
      const db = firebase.firestore();
      db.collection("Users").doc(uid).update({ liked:{ [playListID]: firebase.firestore.FieldValue.delete() } }).then((resp)=> {
         console.log('removeLikedPlaylist: ', resp.data());
         // dispatch(setUser({ userData }));
       }).catch((err)=> {
          console.log('removeLikedPlaylist ERROR: ', err);
       })
    }
  };

export default slice.reducer;