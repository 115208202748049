import { combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit';
import userAuthReducer from './testslice';
import userReducer from './redux/userSlice';
import queueReducer from './redux/queueSlice';
import playlistReducer from './redux/playlistSlice';
import playlistGuestReducer from './redux/guestPlaylistSlice';
import playerReducer from './redux/playerSlice';
// React History
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from "redux-first-history";
import { saveState } from "./localStorage";

const { createReduxHistory, routerReducer } = createReduxHistoryContext({ 
   history: createBrowserHistory(),
   //other options if needed 
 });

const store = configureStore({
   reducer: combineReducers({
      userAuth: userAuthReducer,
      user: userReducer,
      router: routerReducer,
      queue: queueReducer,
      userplaylists: playlistReducer,
      playlistGuest: playlistGuestReducer, 
      player: playerReducer
    }),
   //   middleware: getDefaultMiddleware =>
   //    getDefaultMiddleware({
   //       thunk: {
   //          extraArgument: 'myCustomApiService'
   //       }
   //    })
 });

   store.subscribe((data)=> {
      const stateData = store.getState();
      const dataToSave = { 
         playlistGuest: stateData.playlistGuest, 
         // last_synced: stateData.localSongs.last_synced,
         queue: stateData.queue, 
         player: stateData.player, 
       }
      saveState(dataToSave);
      // console.log('STORE SUBS DATA: ', data, dataToSave);
   });
export const history = createReduxHistory(store);

export default store;