import axios from 'axios';
import { publicURL, FBServerkey} from '../config';

//User Requested
export const sendPushNotification = (data) =>
  (dispatch, getState, getFirebase) => {
    console.log('FROM ACTIOn -->>', data);

    const notifData = {body: data.message, to: data.userToken, url: data.path? publicURL+data.path : publicURL} 
    const request = buildFCmRequest(notifData);
    axios(request).then((results)=> {
        console.log('Push Notification sent!', results );
    })
    .catch(err=> console.log('error: ', err));
};


const buildFCmRequest = (data) => {
    return {
        url: 'https://fcm.googleapis.com/fcm/send',
        method: 'post',
        headers: {
          "Content-Type":"application/json",
          "Authorization":`key=${FBServerkey}`
        },
        data: {
            "notification": {
                "title": "Yhimsical",
                "body": data.body,
                "click_action": data.url,
                "icon": "https://i.imgur.com/PMzYqsf.png"
            },
            "to": data.to,
            // "android": {
            //     "ttl":"30s"
            // },
            // "webpush":{
            //   "headers":{
            //     "TTL":"30"
            //   }
            // }
        }
      }
}