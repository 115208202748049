import React from 'react';
import Duration from '../Player/Duration';
import PlaylistSelector from './PlaylistSelector';
import Icon from '../Icon/Icon';
// import SoundCloudImporter from './SoundCloudImporter';

class StationControl extends React.PureComponent{

    state={
        showAddSelector: false,
        showAddModal: false,
        showAddPlaylistModal: false,
        showPlaylistSelector: false,
        showSoundCloudModal: false,
        hideVideo: true
    }

    showSearchModal = ()=> {
        this.props.showAddSongModal();
        this.setState({showAddSelector: false});
    }

    showLibraryModal = ()=> {
        this.props.showLibraryModal();
        this.setState({showAddSelector: false});
    }

    hideVideo = () => {
        this.props.hideVideo(!this.state.hideVideo);
        this.setState({hideVideo: !this.state.hideVideo});
        
    }

    showPlaylistModal = ()=>  this.setState({showPlaylistSelector: true, showAddSelector: false});
    showSoundCloudModal = ()=>  this.setState({showSoundCloudModal: true});

    render(){
        return(
            <div className="station__bottom">
                <div className="station__bottom__wrap">


                    <div className="station__bottom__inner">

                        {this.props.mobile && 
                            <div className={`player__video__button ${this.state.hideVideo ? '':'player__video__button-active'}`} onClick={this.hideVideo}>
                                <Icon icon="ios-videocam" fontSize="18px" color="#3f3576"/>
                            </div>
                        }

                        <div className="addToStation">
                            <button className="button__addToStation" onClick={()=> this.setState({showAddSelector: !this.state.showAddSelector})}><Icon icon="md-add" fontSize="18px" color="#ffffff"/></button>
                        </div>

                        {this.props.isOwner &&
                            <div className={`stationRepeat ${this.props.repeat ? 'stationRepeat-active': ''}`}>
                                <button className="button__stationRepeat" onClick={()=>this.props.setStationRepeat(this.props.repeat)}><Icon icon="md-refresh" fontSize="18px" color="#ffffff"/></button>
                            </div>
                        }

                        {this.props.isOwner &&
                            <div id="StationPlayBtn" className="player__play" onClick={this.props.playPause}>
                                <i className="play_outline"></i>
                                <span>
                                    {!this.props.playing ?
                                        <svg className="play-button" width="100%" height="100%" viewBox="0 0 40 40" alt="Play">
                                            <defs>
                                                <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                                                    <stop offset="0%" style={{stopColor:"rgb(247,247,247)",stopOpacity:"1"}} />
                                                    <stop offset="100%" style={{stopColor:"rgb(167,167,167)",stopOpacity:"1"}} />
                                                </linearGradient>
                                            </defs>
                                            <path id="play-icon" fill="url(#grad1)" d="M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28" />
                                        </svg>
                                    :
                                    <svg className="play-button" width="100%" height="100%" viewBox="0 0 40 40" alt="Play">
                                        <defs>
                                            <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                                                <stop offset="0%" style={{stopColor:"rgb(247,247,247)",stopOpacity:"1"}} />
                                                <stop offset="100%" style={{stopColor:"rgb(167,167,167)",stopOpacity:"1"}} />
                                            </linearGradient>
                                        </defs>
                                        <path id="pause-icon" fill="url(#grad1)" d="M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26" />
                                    </svg>
                                    }
                                </span>
                            </div>
                        }
                        
                        <div className="player__volume" onClick={()=> this.props.setMuted()}>
                            <button>{this.props.muted && <span><Icon icon="md-close" fontSize="16px" color="#c16565"/></span>} {this.props.muted ? <Icon icon="ios-volume-mute" fontSize="20px" color="#3f3576"/> : <Icon icon="ios-volume-up" fontSize="20px" color="#3f3576"/> }</button>
                        </div>
                        <div className="player__volume__control">
                            <input type='range' aria-label="Change Volume" min={0} max={1} step='any' value={this.props.volume} onChange={this.props.setVolume} />
                        </div>


                    </div>



                    <div className="player__duration">
                            <Duration seconds={this.props.played} /> / <Duration seconds={this.props.currentSong && this.props.currentSong.duration ? this.props.currentSong.duration : 0} />
                    </div> 
                    
                    <div className="player_glow"></div>
                </div>

                {this.state.showAddSelector &&
                    <div className="station__bottom__addModal">
                        <button onClick={this.showSearchModal}><Icon icon="logo-youtube" fontSize="14px" color="#ffffff"/> Add Songs from Youtube</button>
                        {/* <button onClick={this.showSoundCloudModal}><Icon icon="ios-cloud-circle" fontSize="14px" color="#ffffff"/> Add from SoundCloud</button> */}
                        <button onClick={this.showLibraryModal}><Icon icon="md-appstore" fontSize="14px" color="#ffffff"/> Add Songs from Library</button>
                        {this.props.isOwner && <button onClick={this.showPlaylistModal}><Icon icon="md-list" fontSize="14px" color="#ffffff"/> Add a Playlist</button>}
                    </div>
                }

                {this.props.isOwner && this.state.showPlaylistSelector && <PlaylistSelector hidePlaylistSelector={()=> this.setState({showPlaylistSelector: false})} playlists={this.props.playlists} importPlaylist={this.props.importPlaylist} />}
                {/* {this.state.showSoundCloudModal && <SoundCloudImporter addSationSong={this.props.addSationSong} closeImporter={()=> this.setState({showSoundCloudModal: false})} />} */}
            </div>
        );
    }
}

export default StationControl;