import { Transition } from 'react-transition-group';
import { useRef } from 'react';

const duration = {enter: 0, exit: 200};

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
   entering: { opacity: 0 },
   entered:  { opacity: 1 },
   exiting:  { opacity: 0 }
};

function Fade({children, in: inProp }) {
  const nodeRef = useRef(null);
  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={duration}>
      {state => (
        <div ref={nodeRef} style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}>
         {children}
        </div>
      )}
    </Transition>
  );
}

export default Fade;