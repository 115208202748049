import React from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UserMalePic from '../../assets/user_m.png';
import UserFemalePic from '../../assets/user_m.png';
import { leadZero } from '../../helpers';
import { sendFriendRequest, cancelFreindShip, acceptFreindShip } from '../../actions/friends';
import { ContentSkeleton, TitleSkeleton } from '../Loader/basicSkeletons';
import Icon from '../Icon/Icon';

class Profile extends React.PureComponent{

    state={
        //active: localStorage.getItem("showProfile") ? JSON.parse(localStorage.getItem("showProfile")) : true,
        active: true,
        showCancelDropdown:false,
        friendship:'',
        friendsCount:null
    }



    componentWillReceiveProps(nextProps){
        // if(nextProps.auth.uid && nextProps.authorID && !this.state.friendship){
        //     if(nextProps.auth.uid !== nextProps.authorID){
        //         this.fetchFriendsCount(nextProps.authorID);
        //         this.fetchFriendShip(nextProps.auth.uid, nextProps.authorID);
        //     }
        // }
        // //Set friends Count to current user's profile
        // if((nextProps.auth.uid && nextProps.authorID && (nextProps.auth.uid === nextProps.authorID)  )){
        //     if(!this.state.friendsCount){
        //         this.fetchFriendsCount(nextProps.authorID);
        //     }
        // }
    }

    componentDidMount(){
        //console.log('Comp Mounted!!', this.props);
        // if(this.props.uid && this.props.authorID && (this.props.uid === this.props.authorID)) {
        //     if(!this.state.friendsCount){
        //         this.fetchFriendsCount(this.props.authorID);
        //     }
        // }
        // if(this.props.uid && this.props.authorID && (this.props.uid !== this.props.authorID)) {
        //     if(!this.state.friendsCount){
        //         this.fetchFriendsCount(this.props.authorID);
        //     }
        //     if(!this.state.friendship){
        //         this.fetchFriendShip(this.props.uid, this.props.authorID);
        //     }

        // }
    }

    fetchFriendShip = ( uid, friendID) => {
        const db = firebase.firestore();
        const friendsRootRef = db.collection('Friends');
        friendsRootRef.doc(uid).get().then((docSnapshot )=> {
            if (docSnapshot.exists) {
                const allFriends = docSnapshot.data();
                const thisFriend = allFriends[friendID]
                if(thisFriend){  this.setState({friendship: thisFriend.status })  }
              }
        }).catch((err)=> {
            return console.log(err);
        })
    }

    fetchFriendsCount = (friendID) => {
        const db = firebase.firestore();
        const friendsRootRef = db.collection('Friends');
        friendsRootRef.doc(friendID).get().then((docSnapshot )=> {
            if (docSnapshot.exists) {
                const allFriends = docSnapshot.data();
                const actualFriends = Object.keys(allFriends).filter((key) => allFriends[key].status === 'friend' )
                const friendsCount = actualFriends.length;
                return this.setState({friendsCount: friendsCount });
              }
        }).catch((err)=> {
            return console.log(err);
        })
    }


    friendShipAction = (type='send')=> {
        if(this.props.loggedInUser){
            const setMeasFriend = {name:this.props.loggedInUser.fullname, username:this.props.loggedInUser.username }
            const friendObject = {name: this.props.user.fullname, username: this.props.user.username};
            if(type ==='send'){
                this.props.sendFriendRequest(this.props.uid, this.props.authorID, friendObject, setMeasFriend);
                return this.setState({friendship: 'requested', showCancelDropdown:false});
            }else if(type ==='accept'){
                this.props.acceptFreindShip(this.props.uid, this.props.authorID, friendObject, setMeasFriend);
                return this.setState({friendship: 'friend', showCancelDropdown:false});
            }else if(type ==='cancel'){
                this.props.cancelFreindShip(this.props.uid, this.props.authorID);
                return this.setState({friendship: '', showCancelDropdown:false});
            }
        }
    }

    renderFriendActionDropdown = () => {
        return(
            <div className="renderFriendActionDropdown">
                <span onClick={()=> this.setState({showCancelDropdown: !this.state.showCancelDropdown})}>
                    {this.state.showCancelDropdown ? <Icon icon="ios-arrow-up" fontSize="12px" color="#fff"/> :  <Icon icon="ios-arrow-down" fontSize="12px" color="#fff"/>}
                </span>
                {this.state.showCancelDropdown && <div onClick={()=> this.friendShipAction('cancel')} ><Icon icon="ios-close" fontSize="18px" color="#fff"/>{this.state.friendship === 'friend'? 'Unfriend' : 'Cancel Request'}</div>}
            </div>
        );
    }

    renderFriendAction = () => {
        if(this.state.friendship === 'friend'){
            return(
                <div className="friend_action_wrap">
                    <div className="friend_action"><Icon icon="md-person" fontSize="12px" color="#fff"/> Friend </div>
                    {this.renderFriendActionDropdown()}
                </div>
            );
        }else if(this.state.friendship === 'pending'){
            return(
                <div className="friend_action_wrap">
                    <div className="friend_action actionable" onClick={()=> this.friendShipAction('accept')} ><Icon icon="md-checkmark" fontSize="14px" color="#fff"/> Accept Request</div>
                    {this.renderFriendActionDropdown()}
                </div>
            );
        }else if(this.state.friendship === 'requested'){
            return(
                <div className="friend_action_wrap">
                    <div className="friend_action"><Icon icon="md-sync" fontSize="14px" color="#fff"/> Requested</div>
                    {this.renderFriendActionDropdown()}
                </div>
            );
        }else{
            return(
                <div className="friend_action_wrap">
                    <div className="friend_action actionable" onClick={()=> this.friendShipAction('send')} ><Icon icon="md-add" fontSize="14px" color="#fff"/> Add Friend</div>
                </div>
            );
        }
    }

    render(){

        const {avatarUrl, fullname='', gender, bio='', username} = this.props.user ? this.props.user : {}; 
        const userPic = gender & gender === 'female' ? UserFemalePic : UserMalePic;

        return(
            <div id="profile_box" className={`${this.state.active ? 'active' : ''}`}>
                <div className="profile_box__inner">
                    <div className="profile_box__left">
                        <div className="profile_image">
                            <img src={avatarUrl ? avatarUrl : userPic} alt={username} />
                        </div>
                        <div className="profile_content">
                            <div className="profile_content__title">
                                {this.props.loading ? <TitleSkeleton /> : <h3>{fullname} <span className="profile_station_button"><Link to={`/station/${username}/`}>{username}'s Station</Link></span></h3> }
                                
                            </div>
                            <div className="profile_content__bio">
                                {this.props.loading ? <ContentSkeleton /> : <p>{bio}</p>  }
                                {(this.props.uid && this.props.authorID) && (this.props.uid === this.props.authorID) && !bio &&
                                    <p><i>No bio. Write a short bio.</i></p>
                                }
                                {!this.props.uid && this.props.authorID && !bio &&
                                    <p><i>{fullname} does not have a bio.</i></p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="profile_box__right">
                        <div className="profile_stats">
                            <div className="profile_stats__playlists">
                                <span>{this.props.playlistsCount ? leadZero(this.props.playlistsCount) : 0 }</span>
                                <span>Playlists</span>
                            </div>
                            <div className="profile_stats__songs">
                                <span>{this.props.songCount ? leadZero(this.props.songCount) : 0 }</span>
                                <span>Songs</span>
                            </div>
                            {/* <div className="profile_stats__friends">
                                <span>{this.state.friendsCount ? leadZero(this.state.friendsCount) : 0}</span>
                                <span>Friends</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connect(null, { sendFriendRequest, cancelFreindShip, acceptFreindShip })(Profile);