const genres =  [
    {id: 'mixed', name: 'Mixed' },
    {id: 'pop', name: 'Pop' },
    {id: 'hiphop', name: 'Hip hop' },
    {id: 'rnb', name: 'R&B and soul' },
    {id: 'rock', name: 'Rock' },
    {id: 'metal', name: 'Metal' },
    {id: 'instrumental', name: 'Instrumental' },
    {id: 'country', name: 'Country' },
    {id: 'indie', name: 'Indie' },
    {id: 'christian', name: 'Christian' },
    {id: 'asian', name: 'Asian' },
    {id: 'blues', name: 'Blues' },
    {id: 'electronic', name: 'Electronic' },
    {id: 'dance', name: 'Dance' },
    {id: 'folk', name: 'Folk' },
    {id: 'jazz', name: 'Jazz' },
    {id: 'latin', name: 'Latin' },
    {id: 'international', name: 'International' },
]

export default genres;