import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import TimeAgo from 'react-timeago';
import isEqual from 'lodash.isequal';
import { Link } from 'react-router-dom';
import { getStorage, ref ,getDownloadURL } from "firebase/storage";
// import { Emoji } from 'emoji-mart';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart'
import Picker from '@emoji-mart/react';
import striptags from 'striptags';
//import  NimblePicker from 'emoji-mart/dist-es/components/picker/nimble-picker';
//import data from 'emoji-mart/data/messenger.json';
// import 'emoji-mart/css/emoji-mart.css' <<==============RESOLVE!!!!
import Icon from '../Icon/Icon';
import OverlayScreen from '../PRO/OverlayScreen';
import { youtubeKey } from '../../config';
import { uniqByProp, filterChatMessage, youtubeVideoDuration } from '../../helpers';
import UserMalePic from '../../assets/user_m.png';
import AddChannelSong from './AddChannelSong';
init({ data });

const ChatBox = (props) => {
   const messagesRef = useRef(null);
   const {playlistsMin, queue, channel, channelName, mobile, onlineUsers, postChatMessage, uid, addSong, messages:messagesProps, newChatMessage, loadPrevMessages, station} = props;
   const [message, setMessage] =  useState('');
   const [messages, setMessages] = useState([]);
   const [users, setUsers] =  useState([]);
   const [emojiipicker, setEmojiipicker] =  useState(false);
   const [error, setError] = useState('');
   const [wordcount, setWordcount] = useState(0);
   const [videoPopup, setVideoPopup] = useState(null);
   const [mobileChannelSelector, setMobileChannelSelector] = useState(false);
   const [showAddModal, setShowAddModal] =  useState(false);


    //TODO:
    //Fix Firebase Rules
    //Mobile Compatibility 
    //Show number of ONLINE USERS: https://firebase.google.com/docs/database/web/offline-capabilities#section-sample

    const fetchAvatars = useCallback((usrs) => {
      //Fetch Images
      usrs.filter(usr=> !usr.avatarLoaded).forEach( (user)=> {
         if(user.id === 'bot'){ return }
         const storage = getStorage();
         const storageRef = ref(storage, `gs://yhimsical.appspot.com/avatars/${user.id}_small.jpg`);
         // var pathReference = storage.refFromURL(`gs://yhimsical.appspot.com/avatars/${key}_small.jpg`);
         getDownloadURL(storageRef).then((data)=> {
            user['avatar'] = data;
            
            return setUsers((currentUsers)=> uniqByProp([ ...currentUsers, user], 'id'))
         }).catch((error) => {
            user['avatarLoaded'] = true;
            setUsers((currentUsers)=> uniqByProp([ ...currentUsers, user], 'id'))
            return console.log('Error Fetching Image: ', error);
         })
      })
   }, [])

   
   const parseEmojii = useCallback((string, stringID) => {
      // console.log('############# CALLING PARSE EMOJIIII FUNCTION');
      let matchArr;
      let matchArrYTB;
      let lastOffset = 0;
      const regex = new RegExp(/:[a-zA-Z0-9-_+]+:(:skin-tone-[2-6]:)?/g);
      const regexShortcode = new RegExp(/\[+([^\][[]+)]]+/g);
      const partsOfTheMessageText = [];

      while ((matchArr = regex.exec(string)) !== null) {
          const previousText = string.substring(lastOffset, matchArr.index);
          if (previousText.length) partsOfTheMessageText.push(previousText);
          
          lastOffset = matchArr.index + matchArr[0].length;
         //  console.log(previousText, lastOffset, matchArr);
         const emoji = <em-emoji shortcodes={matchArr[0]} set="messenger" size={16}></em-emoji>
          if (emoji) {
            partsOfTheMessageText.push(emoji);
          } else {
            partsOfTheMessageText.push(matchArr[0]);
          }
      }

      while ((matchArrYTB = regexShortcode.exec(string)) !== null) {
          const previousText = string.substring(lastOffset, matchArrYTB.index);
          if (previousText.length) partsOfTheMessageText.push(previousText);
          
          lastOffset = matchArrYTB.index + matchArrYTB[0].length;
        
          //console.log(previousText, lastOffset, matchArrYTB);
          let videoID = matchArrYTB[0].replace('[[', '');
          videoID = videoID.replace(']]', '');
          const youtubeVid = ( <span className="chat_youtubeLink" onClick={()=> onVideoClick(videoID)}>{videoID}</span> );
        
          if (youtubeVid) {
            partsOfTheMessageText.push(youtubeVid);
          } else {
            partsOfTheMessageText.push(matchArrYTB[0]);
          }
      }

      const finalPartOfTheText = string.substring(lastOffset, string.length);
      if (finalPartOfTheText.length) partsOfTheMessageText.push(finalPartOfTheText);


      return partsOfTheMessageText.map((p, idx) => <span key={`${stringID}_${idx}`}>{p}</span>);

  }, [])

    useEffect(()=> {
      if(!messagesProps){ return }
      const messagePropsIDS = [];
      const theMessages = messagesProps && Object.keys(messagesProps).map((msgID)=> {
         messagePropsIDS.push(msgID);
         return {
             id: msgID, 
             content: messagesProps[msgID].content, 
             created: messagesProps[msgID].created, 
             senderID: messagesProps[msgID].senderID, 
             senderName: messagesProps[msgID].senderName,
             bot: messagesProps[msgID].bot ? messagesProps[msgID].bot : false 
         }
      });

      if(!messagesRef.current || (messagesRef.current && !isEqual(messagesRef.current,messagePropsIDS)) ){
         messagesRef.current = messagePropsIDS;
         setMessages((currentMessages=[]) => {
            let newmsgs = [];
            const currentMessageIds = currentMessages.map(item => item.id);
            if(theMessages && theMessages.length > 0 && !isEqual(currentMessageIds, messagePropsIDS) ){
               // console.warn('Message Changed!!', currentMessages, theMessages);
               newmsgs = theMessages.map((msg)=> {
                  const itemExist = currentMessages.filter(item => item.id === msg.id);
   
                  if(itemExist && itemExist[0]){
                     return {...msg, content: itemExist[0].content}
                  }else{
                     if(msg && !msg.bot && newChatMessage){
                           console.log('New Message Added!');
                           newChatMessage();
                     }
                     return {...msg, content: parseEmojii(msg.content, msg.id) }
                  }
               });
   
               newmsgs = newmsgs.sort((a, b)=> a.created - b.created );
               // console.log(newmsgs);
               const users = theMessages && theMessages.map((msg)=> ({id: msg.senderID, username: msg.senderName, active: false, avatar:''}));
               fetchAvatars(users);
               
               setTimeout(() => {
                     const objDiv = document.getElementById("chatbox__inner");
                     objDiv.scrollTop = objDiv.scrollHeight;
               }, 1000);
   
               return newmsgs;
            }
         });
      }

    }, [messagesProps, newChatMessage, fetchAvatars, parseEmojii])

   //  componentWillReceiveProps(nextProps){

   //      if( isEqual(this.props, nextProps) ){
   //          return;
   //      }

   //      if(this.props.channel !== nextProps.channel){
   //          if(!messagesProps){
   //              this.setState({messages: [], users:[]});
   //          }
   //      }

   //      if(!messagesProps){ return }
   //      //console.log(this.props, nextProps);
   //      //if( !isEqual(this.props.messages, messagesProps) ){
   //          console.log('###props dont match!');

            
   //      //}
   //  }


   const processMsg = (event) => {
        setMessage(event.target.value);
        setWordcount(event.target.value.length);
    }

   const onEnterPress = (event) => {
        
        if(event.keyCode === 13){
            event.preventDefault();
            sendMsg();
        }
    }

   const sendMsg = () => {
        if(!message){
            setError('Please type something first to send!');
            return setTimeout(() => { setError(''); }, 3000);
        }
        if(message && message.length > 300){
            setError('Cannot Post more than 300 words in one Message');
            return setTimeout(() => { setError(''); }, 3000);
        }
        const youtubeFiltermsg = processYoutubeLink(message);
        let cleanMsg = youtubeFiltermsg && filterChatMessage(youtubeFiltermsg);
        cleanMsg = striptags(cleanMsg);
        console.log(message, cleanMsg, youtubeFiltermsg);
        postChatMessage(channel,cleanMsg);
        setMessage('');
        setWordcount(0);
        console.log(cleanMsg);
    }

   const insertEmojii = (emoji) => {
      setEmojiipicker(false);
      setMessage(`${message} ${emoji.shortcodes }`);
      console.log(emoji, `${message} ${emoji.shortcodes }`);
   }

   const processYoutubeLink = (string) => {
        if(!string){ return }
        let cleanstring = string;
        const youtube_regex = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\\&vi?=)([^#\\&\\?]*).*/
        const parsed = cleanstring.match(youtube_regex);
        
        if(parsed){
            var stringsArray = cleanstring.split(' ');
            var newString = [];
            stringsArray.forEach((word)=> {
                var parsedWord = word.match(youtube_regex);
                var videoID = parsedWord && parsedWord[2] ? parsedWord[2]:'';
                console.log(word, parsedWord, videoID);
                if(videoID){
                    newString.push(`[[${videoID}]]`);
                }else{
                    newString.push(word);
                }
                
            })
    
            cleanstring = newString.join(" ");
        }

        return cleanstring;

    }

   const onVideoClick = (videoID) => {
        axios.get(`https://noembed.com/embed?url=https://www.youtube.com/watch?v=${videoID}`).then((data)=> {
            console.log(data);
            if(data.data){
               setVideoPopup({title: data.data.title, author: data.data.author_name, id:videoID, thumb: data.data.thumbnail_url})
            }
        })
    }


   const addChannelSong = (playlist) => {
        axios.get(`https://www.googleapis.com/youtube/v3/videos?id=${videoPopup.id}&part=contentDetails,snippet&key=${youtubeKey}`)
        .then((data)=> {
            if(data.data.items.length > 0  && data.data.items[0].contentDetails.duration){
                //console.log(data.data.items);
                let songs = []
                songs = data.data.items.map((song)=> {
                    var dt = song.snippet.publishedAt.replace("T"," ").replace(/\..+/g,"")
                    dt = new Date( dt ); dt = dt.getTime();
                    return {
                        title: song.snippet.title,
                        id: song.id,
                        youtube: song.id,
                        release_date: dt,
                        duration:youtubeVideoDuration(song.contentDetails.duration)
                    }
                })
                console.log(songs);

                addSong(playlist, songs[0])
            }else{
                console.log('Video Not Found');
            }
        }).catch((err)=> {
            console.log('Error Fetching Video Data!', err);
        });
    }


   const renderMsgs = () => {
        return messages && messages.map((msg, index)=> {
            const sender = users.filter((usr)=> usr.id === msg.senderID);
            return(
                <div className={`chatMessage ${(msg.senderID === 'bot'|| msg.bot)? 'botMessage':''}`} key={msg.id}>
                    <div className="chatMessage__avatar">
                        <img src={sender.length> 0 && sender[0] && sender[0].avatar ? sender[0].avatar: UserMalePic } alt={msg.senderName} />
                    </div>
                    <div className="chatMessage__content">
                        <div className="chatMessage__header">
                            {(msg.senderID !== 'bot' && !msg.bot) &&<div className="chatMessage__name"><a target="_blank" href={`/user/${msg.senderName}`} rel='noreferrer'>{msg.senderName}</a></div>}

                            <div className="chatMessage__time">
                                <TimeAgo date={new Date(msg.created * 1000)} /> 
                            </div>
                            {msg.bot && msg.senderID !== 'bot' && <div className="chatMessage__name"><img src={sender.length> 0 && sender[0] && sender[0].avatar ? sender[0].avatar: UserMalePic } alt={msg.senderName} /></div>}
                        </div>
                        <p>{msg.content && msg.content}</p>
                    </div>

                </div>
            )
        })
    }

   const mobileChannelSelectorMenu = () => {
        return <button onClick={()=> setMobileChannelSelector(!mobileChannelSelector)}>Channel - {channelName} {mobileChannelSelector ? <Icon icon="ios-arrow-up" fontSize="18px" color="#ffffff"/> : <Icon icon="ios-arrow-down" fontSize="18px" color="#ffffff"/>}</button>;
    }


   return(
      <div id="chatbox" className={`chatbox`}>
            <h4>{mobile ? mobileChannelSelectorMenu() : 'Chat'} <span>{onlineUsers ? Object.keys(onlineUsers).length : 0} Online</span></h4>
            
            {mobileChannelSelector && <div className="mobileChannelSelector">
               <ul>
                  <li><Link to={`/channel/pop/`} onClick={()=> setMobileChannelSelector(false)}>Pop</Link></li>
                  <li><Link to={`/channel/hiphop/`} onClick={()=> setMobileChannelSelector(false)}>Hiphop</Link></li>
                  <li><Link to={`/channel/rnb/`} onClick={()=> setMobileChannelSelector(false)}>Rnb</Link></li>
                  <li><Link to={`/channel/rock/`} onClick={()=> setMobileChannelSelector(false)}>Rock</Link></li>

                  <li><Link to={`/channel/metal/`} onClick={()=> setMobileChannelSelector(false)}>Metal</Link></li>
                  <li><Link to={`/channel/indie/`} onClick={()=> setMobileChannelSelector(false)}>Indie</Link></li>
                  <li><Link to={`/channel/electronic/`} onClick={()=> setMobileChannelSelector(false)}>Electronic</Link></li>
                  <li><Link to={`/channel/dance/`} onClick={()=> setMobileChannelSelector(false)}>Dance</Link></li>

                  <li><Link to={`/channel/instrumental/`} onClick={()=> setMobileChannelSelector(false)}>Instrumental</Link></li>
                  <li><Link to={`/channel/country/`} onClick={()=> setMobileChannelSelector(false)}>Country</Link></li>
                  <li><Link to={`/channel/blues/`} onClick={()=> setMobileChannelSelector(false)}>Blues</Link></li>
                  <li><Link to={`/channel/folk/`} onClick={()=> setMobileChannelSelector(false)}>Folk</Link></li>
               </ul>
            </div>}
            <div className="channels__chat__msgs customScrollbar">
               <div id="chatbox__inner">
                  {(messages && messages.length < 22 && !station) && <button className='channels__chat__prevBtn' onClick={()=> loadPrevMessages()}>Load Previous Messages</button>}
                  {messages && messages.length > 0 && renderMsgs()}
               </div>
            </div>

            {(!uid) ?
                           <OverlayScreen 
                           className="chat_block" 
                           title="" 
                           message="Please Login to Join the Chat."
                           action="login"
                           button="Login"
                           />
            :
            <div className={`channels__chat__form ${message.length >= 300 ? 'chatmsg_overlimit':''}`}>
               <textarea name="" id="" cols="30" rows="10" placeholder="Type Here..." onChange={processMsg} value={message} onKeyDown={onEnterPress}></textarea>
               <span className={`emojiiPickerBtn ${emojiipicker ? 'pickerActive':''}`} onClick={()=> setEmojiipicker(!emojiipicker)}>&#9786;</span>
               <span className="wordCout">{wordcount}/300</span>
               
               <button onClick={sendMsg}><Icon icon="ios-send" fontSize="28px" color="#ffffff"/></button>
               {error && <p>{error}</p>}
               {emojiipicker && <div style={{  position: 'absolute', bottom: '25px', right: '20px' }}><Picker onEmojiSelect={insertEmojii} set='messenger' theme="dark" /></div>}
            </div>
            }

            {videoPopup && 
            <div className="chatVideoPopup">
               <span className="chatVideoPopup__close" onClick={()=> setVideoPopup(false)}>x</span>
               <div className="chatVideoItem">
                  <div className="chatVideoItem__cover"><img src={videoPopup.thumb} alt={videoPopup.title} /></div>
                  <div className="chatVideoItem__content">
                        <div className="chatVideoItem__content__title">{videoPopup.title}</div>
                        <div className="chatVideoItem__content__author">{videoPopup.author}</div>
                  </div>
                  <button className="chatVideoItem__add" onClick={()=> setShowAddModal(!showAddModal)}><Icon icon="md-add" fontSize="14px" color="#ffffff"/></button>
               </div>

               {showAddModal && 
                  <AddChannelSong 
                  playlistsMin={playlistsMin} 
                  queue={queue} 
                  addSong={addChannelSong} 
                  hideAddModal={()=> setShowAddModal(false)} />
               }
            </div>
            }
      </div>
   );

}

export default ChatBox;