import React from 'react';
import { useDragLayer } from 'react-dnd';
import { formatDuration } from '../../helpers';
import albumCover from '../../assets/cover.png';

const SongDragLayer = (props) => {
   const { isDragging, initialCursorOffset, initialFileOffset, currentFileOffset, item, itemType } = useDragLayer((monitor) => ({
     item: monitor.getItem(),
     itemType: monitor.getItemType(),
     initialCursorOffset: monitor.getInitialClientOffset(),
     initialFileOffset: monitor.getInitialSourceClientOffset(),
     currentFileOffset: monitor.getSourceClientOffset(),
     isDragging: monitor.isDragging(),
   }));
 
   if (!isDragging) {
     return null;
   }

   
   const songItem = (item) => {
      const {title='', artist='', duration='', cover=''} = item.song;

      return(
        <div>
            <div className='song song-drag'>
              <div className="song_inner">
                    {/* {!this.props.queue &&  */}
                      <div className="song__album">
                        <img src={cover ? cover : albumCover}  alt={title ? title : 'Album Cover'} />
                      </div>
                    {/* } */}
                    <div className='song__details'>
                        <span className="song__title">{title}</span>
                        {artist && <span className="song__artist">{artist}</span>}
                    </div>
                    
              </div>
              {duration && <div className="song__duration">{formatDuration(duration)}</div>}
          </div>
              {item.songs.length === 2 && <div className="songStack"><div></div></div>}
              {item.songs.length > 2 && <div className="songStack"><div></div><div></div></div>}
        </div>
          
      );
  }

   const renderItem = (type, item) => {
      switch (type) {
        case 'song':
          return songItem(item);
        case 'stationSong':
          return songItem(item);
        default:
          return;
      }
      
   }

   const layerStyles = {
      position: "fixed",
      pointerEvents: "none",
      zIndex: 100,
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
   };

   return (
     <div className='songDragLayer' style={layerStyles}>
       <div style={getItemStyles( initialCursorOffset, initialFileOffset, currentFileOffset )}>
         {renderItem(itemType, item)}
       </div>
     </div>
   );
 };
 

 
 function getItemStyles( initialCursorOffset, initialOffset, currentOffset ) {
   if (!initialOffset || !currentOffset || !initialCursorOffset) {
     return { display: "none" };
   }
   // const x = initialCursorOffset?.x + (currentOffset.x - initialOffset.x);
   // const y = initialCursorOffset?.y + (currentOffset.y - initialOffset.y);
   const {x, y} = currentOffset;
   const transform = `translate(${x}px, ${y}px)`;
 
   return { transform, WebkitTransform: transform };
 }

export default SongDragLayer;