import React from 'react';
import isequal from 'lodash.isequal';
import { getDatabase, ref, onValue, onDisconnect, set, remove, off } from "firebase/database";
import UserMalePic from '../../assets/user_m.png';
import { objectToArray } from '../../helpers';
import ChatBox from '../channels/ChatBox';
import StationSongWrapper from './StationSongWrapper';
//import StationControl from './StationControl';
import StationUsers from './StationUsers';
import StationPlayer from './StationPlayer';
import StationMobileActions from './StationMobileActions';
import Icon from '../Icon/Icon';

class StationOwner extends React.PureComponent{
    constructor(props){
        super(props);
          this.state = {
            playing: false,
            station:{},
            stationOnline: null,
            stationActive:false,
            stationMessage:'',
            activeUsers:{},
            pendingUsers:[],
            channelSongs:[],
            currentSong:{},
            showAddModal: false,
            playlistSelect:'queueOne',
            mobileTab:'queue',
            newChatMessage:0,
            mobileSelectedSong:null,
            stationRef:{},
            showStationMenu: false,
        }
    
      }

    componentWillReceiveProps(nextProps){
        //console.log('nextProps: ', this.props, nextProps);

        if(this.props.station !== nextProps.station){
            //this.makeUserOffline();
        }

    }

    componentDidMount = () => {

        if(!this.props.userSettings.mobile) {
            document.addEventListener('visibilitychange', () =>{
                if(this.state.newChatMessage > 0){
                    if(document.hidden){
                    }else{
                        setTimeout(() => {
                            this.setState({newChatMessage: 0  });
                            document.title = `${this.props.stationName}'s Station - Yhimsical`;
                        }, 600);

                    }
                }
            })
        }

        if(this.props.stationID){
            const db = getDatabase();
            const theStationRef = ref(db, `stations/${this.props.stationID}`);
            this.setState({stationRef: theStationRef })
        }

    }

    componentWillUnmount = () => {
        //console.log('@@@@componentWillUnmount');
        if(this.state.stationRef){
         const db = getDatabase();
         const theStationRef = ref(db, `stations/${this.props.stationID}`);
         set(theStationRef, null);
         remove(theStationRef);
            // this.state.stationRef.remove();
        }
        this.props.setStation('');
    }

    listenToDB = (isActive) => {
        if(!this.props.stationID) { return this.setState({error: 'Station ID not Found!'})}
        if(isActive){ return off(this.state.stationRef); }
        
        //this.state.stationRef.on('value', (snapshot) => {
         onValue(this.state.stationRef,  (snapshot) => {

            //console.log(snapshot.val());
            let station = snapshot.val();

            if(station && this.props.auth.uid && station.users && station.users[this.props.auth.uid] && (station.users[this.props.auth.uid].status ==='allowed' || station.users[this.props.auth.uid].status ==='owner')){
                //console.log('USER ALLOWED!');
                this.setState({ stationActive: true, sentRequest: false});
                if(station && station.songs){
                    this.props.setSationSongs(station.songs);
                    localStorage.setItem('station_songs', JSON.stringify(station.songs));
                }

    
                if(!isequal(this.state.station.users, station.users)){
                    let pendingUsers = []; let activeUsers = {}; 
                    Object.keys(station.users).forEach(userID=> {
                        if(station.users[userID].status === 'pending'){
                            pendingUsers.push(station.users[userID]);
                            if(this.props.userFCMToken && this.props.stationName){
                              //   this.props.sendPushNotification({message: `${station.users[userID].fullname? station.users[userID].fullname: station.users[userID].username} wants to Join your Station`, userToken: this.props.userFCMToken, path:`station/${this.props.stationName}`} );
                            }
                        }
                        if(station.users[userID].status === 'allowed' || station.users[userID].status === 'owner' ){
                            activeUsers[userID] = station.users[userID];
                        }
                    });
                    if(pendingUsers.length > 0){
                        document.title = `(${pendingUsers.length})${this.props.stationName}'s Station - Yhimsical`;
                    }else{
                        document.title = `${this.props.stationName}'s Station - Yhimsical`;
                    }
                    this.setState({pendingUsers: pendingUsers, activeUsers: activeUsers});
                }
                
                this.setState({station: station ? station : {}});
            } 

            //OWNERS ACTIVITIES
                //IF owner gets disconnected or leaves, remove the station
                // this.state.stationRef.onDisconnect().remove();
                onDisconnect(this.state.stationRef).set(null)
                //Remove Users from the station if they left
                if(station && station.userLeft){
                    Object.keys(station.userLeft).forEach((userID)=>{
                        this.props.completeUserLeft( userID, this.props.stationID, station.users[userID] ? station.users[userID] : null);
                    })
                }


        });


    }

    turnOnStation = (isActive) => {
        if(!this.props.auth.uid){ return }

        if(isActive){
            console.log('TURN OFF STATION!!!!');
            this.setState({station:{}});
            this.props.setStation('');
            this.props.stopStation(this.props.stationID);
            this.listenToDB(true);
            this.setState({ stationOnline: false });
        }else{
            console.log('TURN ON STATION!!!!');
            this.props.setStation(this.props.stationID);
            this.props.startStation(this.props.stationID);
            this.listenToDB();
            this.setState({ stationOnline: true });
        }
    }

    removeSong = (songID, song) => {
        this.props.removeSationSong(songID, song);
    }

    newChatMessage = () => {
        if(this.props.userSettings.mobile && this.props.mobileTab === 'queue'){
            this.setState({newChatMessage: this.state.newChatMessage + 1})
        }
        //console.log('@@@@@document.hasFocus', document.hasFocus());
        if(!this.props.userSettings.mobile && !document.hasFocus()){
           
            this.setState({newChatMessage: this.state.newChatMessage + 1}, ()=> {
                if(this.state.newChatMessage > 0){
                    document.title = `(${this.state.newChatMessage})${this.props.stationName}'s Station - Yhimsical`;
                }
            })
            
        }

    }
    
    renderPlaylist = () => {
        return <StationSongWrapper 
                //selectedSong={this.props.userSettings.selectedSong}
                //selectSong={this.props.selectSong}
                // mobileSelectedSong={this.state.mobileSelectedSong}
                //uid={this.props.auth  && this.props.auth.uid }
                reorderSongs={this.props.reorderStaionSongs}
                songs={objectToArray(this.state.station.songs, true)} 
                isMobile={this.props.userSettings.mobile}
                queue={this.props.queue}
                playlistsMin={this.props.userplaylistsMin}
                draggingSong={this.props.draggingSong}
                currentSong={this.state.station.currentSong && this.state.station.currentSong.id}
                songPlaying={this.state.station.currentSong && this.state.station.currentSong.playing}
                userActions={this.props.userSettings.actions}
                addSong={this.props.copySong}
                removeSationSong={this.removeSong}
                likeStationSong={this.props.likeStationSong}
                isOwner={true}
                trueOwner={true}
                currentUser={this.props.profile.username}
                playSong={this.props.setCurrentSong}
                pauseSong={this.props.pauseStationSong}
                mobileSelectedSong={this.state.mobileSelectedSong}
                mobileSelectSong={(sng)=> this.setState({mobileSelectedSong: sng}) }
                />
    }

    renderPendingUser = () => {
        return this.state.pendingUsers.map((user)=> {
            return (
                <div className="pendingUser" key={user.uid}>
                    <img src={user.avatar ? user.avatar : UserMalePic} alt={user.username} /> {user.fullname ? user.fullname: user.username} Wants to Join your Station.
                        {this.state.station.users && Object.keys(this.state.station.users).length <= 5 ?
                        <p>
                            <button className="pendingUser__approveJoin" onClick={()=> this.props.acceptUser(user.uid, this.props.stationID, user.username)}>Accept</button>
                            <button className="pendingUser__rejectJoin" onClick={()=> this.props.rejectUser(user.uid, this.props.stationID)}>Reject</button>
                        </p>
                        :
                        <p className="pendingUser__error">
                            Station reached 5/5 Users. Please Upgrade to add more users to your Station. <button className="pendingUser__rejectJoin" onClick={()=> this.props.rejectUser(user.uid, this.props.stationID)}>Reject</button>
                        </p>
                        } 
                </div>
            );
        })
    }


    stationMessage = () => {
        let stationMessage = "";
        if(!this.props.profile.username){
            stationMessage = this.state.stationOnline ? <p>Station is Online. Please login to join the Station <button className="stationLoginbtn" onClick={ ()=>this.props.showUserArea()}>Login</button></p>: <p>This Station is not Online! Please try again later.. </p>;
        }
        if(!this.state.stationOnline && this.props.profile.username && (this.props.profile.username === this.props.stationName)){
            stationMessage = <p>Turn on Your Station and enjoy music together with your friends!</p>;
        }

        if(this.state.stationOnline && this.state.stationActive && this.props.auth.uid && this.state.activeUsers[this.props.auth.uid] && this.state.activeUsers[this.props.auth.uid].status === 'allowed'){
            stationMessage = '';
        }
        //console.log(stationMessage);
        return stationMessage;
    }
    

    render(){
        let stationMessage = this.stationMessage();
        return(
            <div className="station__owner">
               <div className={`station__inner station__inner--mobile__${this.state.mobileTab}`}>

                    <div className="station__left">
                        <div className='playlist__header'>
                           <h4>
                              <span><img alt={this.props.stationName} src={this.props.owner && this.props.owner.avatarUrl ? this.props.owner.avatarUrl :UserMalePic} /></span>
                              {`${this.props.stationName}'s Station`}
                              {this.state.stationOnline ? <i className="stationActiveIcon" /> : <i className="stationInactiveIcon" /> }
                           </h4>
                           <div className='playlist__header_right'>
                              {this.props.profile && (this.props.profile.username === this.props.stationName) &&
                                    <div className="stationActiveCheck custom_checkbox">
                                       <input 
                                          type="checkbox" 
                                          id="stationActive_switch"
                                          name="stationActive"
                                          value={this.state.stationActive} 
                                          onChange={()=> { this.setState({stationActive: !this.state.stationActive}, ()=> this.state.stationActive ? this.turnOnStation(false) : this.turnOnStation(true))}}
                                          checked={this.state.stationActive }
                                       />
                                       <label htmlFor="stationActive_switch">Toggle</label>
                                       <span>{this.state.stationActive ? 'Station is On' :'Turn On Station'}</span>
                                    </div>  
                              }
                                 {this.state.stationActive && 
                                    <div className={`playlist__edit ${this.state.showStationMenu ? 'active': ''}`} onClick={()=> this.setState({showStationMenu: !this.state.showStationMenu})}>
                                       <div className="playlist__edit__icon"><Icon icon="ios-more" fontSize="22px" color="#ffffff"/></div>
                                       <ul>
                                          <li onClick={()=> { window.stationSongs = objectToArray(this.state.station.songs, true); this.props.createPlaylist(); }}>Save as Playlist</li>
                                          <li onClick={()=> this.props.showInviteModal()}>Invite Friends</li>
                                          <li onClick={()=> this.props.clearStation()}>Clear Songs</li>
                                       </ul>
                                    </div>
                                 }
                           </div>
                        </div>
                        {stationMessage && <div className="stationMessage">{stationMessage}</div>}

                        {this.state.stationActive &&
                           <div className="station__playlist">
                              {this.state.station.songs && <div className="station_playlist_wrap customScrollbar"><div>{this.renderPlaylist()}</div></div>}
                              {!this.state.station.songs && <div className="stationMessage"><p>No Songs added yet! Add Songs to get Started.</p></div>}
                           </div>
                        }
                    </div>


                    <div className="station__right">

                        {/* {!this.props.userFCMToken && this.state.stationActive && <div className="stationNotif">Get Notified when someone joins. <button onClick={this.props.askFCmPermission}>Enable Notifications</button></div>} */}
                        
                        <div className="station__chat">
                            {this.state.stationActive && this.state.station && this.state.station.users &&
                                <StationUsers 
                                isOwner={true} 
                                stationID={this.props.stationID} 
                                users={this.state.station.users} 
                                kickUser={this.props.kickUser} 
                                giveFullAccess={this.props.giveFullAccess}
                                acceptUser={this.props.acceptUser} 
                                rejectUser={this.props.rejectUser}
                                mobile={this.props.userSettings.mobile}
                                />
                            }
                            {this.state.stationActive ? 
                                <ChatBox 
                                uid={this.props.auth && this.props.auth.uid}
                                messages={this.state.station && this.state.station.messages} 
                                onlineUsers={this.state.activeUsers} 
                                channel={this.props.stationID}
                                station={this.props.stationID}
                                channelName={this.props.stationName}
                                postChatMessage={this.props.postChatMessage}
                                firebase={this.props.firebase}
                                addSong={this.props.copySong}
                                playlistsMin={this.props.userplaylistsMin} 
                                queue={this.props.queue} 
                                mobile={this.props.userSettings.mobile}
                                newChatMessage={this.newChatMessage}
                                />
                                :
                                <div id="chatbox" className={`chatbox`}>
                                    <h4>Chat</h4>
                                </div>
                            }
                        </div>

                    </div>

                    {this.props.userSettings.mobile && this.state.mobileSelectedSong &&
                        <StationMobileActions 
                        mobileSelectSong={(sng)=> this.setState({mobileSelectedSong: sng}) }
                        mobileSelectSongClear={()=> this.setState({mobileSelectedSong: null}) }
                        addSong={this.props.copySong}
                        removeSationSong={this.props.removeSationSong}
                        playlistsMin={this.props.playlistsMin}
                        playSong={this.props.setCurrentSong}
                        reorderSongs={this.props.reorderStaionSongs}
                        queue={this.props.queue}
                        mobileSelectedSong={this.state.mobileSelectedSong}
                        songs={this.state.station.songs && objectToArray(this.state.station.songs, true).length > 0 && objectToArray(this.state.station.songs, true).sort(( a, b )=>  a.order - b.order)}
                        />
                    }
                
                </div>

                {this.props.userSettings && this.props.userSettings.mobile && this.state.stationActive &&
                    <div className={`station__mobile__tabs station__mobile__tabs__${this.state.mobileTab}`}>
                        <button className="station__mobile__tabs__queue" onClick={()=> this.setState({mobileTab: 'queue'})}>Queue</button>
                <button className="station__mobile__tabs__chat" onClick={()=> this.setState({mobileTab: 'chat', newChatMessage:0})}>Chat {this.state.newChatMessage > 0 && <span>{this.state.newChatMessage}</span>}</button>
                    </div>
                }

                {this.state.stationActive && 
                    <StationPlayer 
                    showLibraryModal={this.props.showLibraryModal} 
                    showAddSongModal={this.props.showAddSongModal} 
                    playlists={this.props.playlists} 
                    isOwner={true} 
                    trueOwner={true}
                    mobile={this.props.userSettings && this.props.userSettings.mobile}
                    importPlaylist={this.props.importPlaylist}
                    stationSongs={this.state.station.songs && objectToArray(this.state.station.songs, true).length > 0 && objectToArray(this.state.station.songs, true).sort(( a, b )=>  a.order - b.order)}
                    currentSong={this.state.station.currentSong}
                    playerVolume={this.props.playerVolume}
                    setCurrentSong={this.props.setCurrentSong}
                    playSong={this.props.playStationSong}
                    pauseSong={this.props.pauseStationSong}
                    setPlayedSeconds={this.props.setPlayedSeconds}
                    volume={this.props.player.volume}
                    getCurrentTime={()=> this.state.station.playedSeconds ? this.state.station.playedSeconds : 0}
                    stationInfo={this.state.station.stationInfo}
                    setStationRepeat={this.props.setStationRepeat}
                    currentUser={this.props.profile.username}
                    likeStationSong={this.props.likeStationSong}
                    addSationSong={this.props.addSationSong}
                    />
                }
                    
                {this.state.pendingUsers.length > 0 && <div className="pendingUsers">{this.renderPendingUser()}</div>}
            </div>
        );
    }
}

export default StationOwner;