import React, { useState, useEffect }  from 'react';
import { Link, useNavigate, useParams  } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Tracks from '../../components/Discover/Tracks';
import { CLOUD_FUNC_URL, getSongs } from '../../helpers';
import { addSong } from '../../redux/songSlice';
import { setDiscoverListView } from '../../redux/userSlice';
// import { selectedSong } from '../../redux/playlistSlice';
import genres from '../../utils/genres';
import TrackSkeleton from '../Loader/TrackSkeleton';
import DiscoverHeader from '../Discover/DiscoverHeader';
import { useRef } from 'react';
import Icon from '../Icon/Icon';



const AllTracks  = () =>{
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const countsLoded = useRef(null);
   const { genre: genreParam='all', sortParam='release_date', page: pageParam=0 } = useParams();
   const queue = useSelector((state)=> state.queue );
   const userSettings = useSelector((state)=> state.user );
   const userplaylists = useSelector((state) => state.userplaylists);
   const userplaylistsMin =  useSelector((state) => state.userplaylistsMin);
   const songs = getSongs(userplaylists, 'temp' );
   const uid = userSettings && userSettings.user && userSettings.user.id;
   const listView = userSettings.discoverListView;

   const [loading, setLoading] = useState(false);
   const [tracksCounts, setTracksCounts] = useState({});
   const [genre, setGenre] = useState('');
   const [sort, setSort] = useState('');
   const [page, setPage] = useState(null);
   const [tracks, setTracks] = useState([]);
   // const [showSortChoices, setShowSortChoices] = useState(false);
   const [showGenreChoices, setShowGenreChoices] = useState(false);


   useEffect(()=> {
      if(!countsLoded.current){
         countsLoded.current = true;
         firebase.firestore().collection("Counts").doc('songs').get().then((resp)=> {
            console.log('resp: ', resp.exists, resp.data() );
            if(resp.exists){
               setTracksCounts(resp.data());
            }
         }).catch((err)=> {
            console.log('getUser ERROR: ', err);
         })
      }
   }, [])


   useEffect(()=> {
      console.log('genreParam: ', genreParam, genre, ((genreParam !== genre) || (sortParam !== sort) || (pageParam !== page)) );
      if(((genreParam !== genre) || (sortParam !== sort) || (pageParam !== page)) || genre === '' ){
         setGenre(genreParam);
         setSort(sortParam);
         setPage(parseInt(pageParam, 10));
         getTracks(genreParam, sortParam, pageParam);
      }
   }, [genreParam, sortParam, pageParam, genre, sort, page]);

   const getTracks = (genre, sort, page) => {
        console.log(`getTracks being called!`);
        setLoading(true)
        setLoading(true);
        axios.get(`${CLOUD_FUNC_URL}/app/`, {
            params: { type:'tracks', genre: genre, sort: sort, page: page }, 
            headers:{'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }}
        )
        .then((results)=> {
            console.log(results);
            if(results.data){
               setTracks(results.data);
            }
        })
        .catch(err => console.log(err))
        .finally(()=> setLoading(false))

    }

    const playSong = (song) => {
        const newSong = { 
            artist: song.artist, 
            duration:song.duration, 
            title: song.title, 
            youtube: song.youtube, 
            order: songs[songs.length - 1] ? Number(songs[songs.length - 1].order) + 1 : 0,
            added: Number((new Date().getTime() / 1000).toFixed(0))
        };
        const queueHasSong = queue[queue.selected].filter(sng => sng.id === newSong.id).length > 0 ? true: false;
        dispatch(addSong(uid, queue.selected, newSong, true, queueHasSong));
    }

    const addToPlaylist = (song, playlistID) => {
        const selectedPlaylist = userplaylistsMin.length > 0 ? userplaylistsMin.filter(plist => plist.id === playlistID) : [];
        const newSong = { 
            artist: song.artist, 
            duration:song.duration, 
            title: song.title, 
            youtube: song.youtube, 
            order: selectedPlaylist.length > 0 ?  selectedPlaylist[0].count + 1: 0,
            added: Number((new Date().getTime() / 1000).toFixed(0)) 
        };

        return dispatch(addSong(uid, playlistID, newSong));
    }

    const handlePageClick = (data) => {
        let page = data.selected;
        setPage(Number(page));
        console.log(sort, page);
        if(sort){
            return navigate(`/music/${genre}/${sort}/page/${page}/`);
        }else{
            return navigate(`/music/${genre}/page/${page}/`);
        }
        
        //return this.getTracks(genre, sort, page);
    }

    const renderHeader = () => {
      let copiedGenres = [...genres]; copiedGenres[0] = {id:'all', name:'All', count: tracksCounts ? tracksCounts.all : 0 };
      copiedGenres.forEach((item)=> item.count = tracksCounts && tracksCounts[item.id] ? tracksCounts[item.id] : 0 );

      // let sort = [{id:'', name:'Release Date'},{id:'views', name:'Popular'} ];
      // const allSort = sort.map((srt)=> <Link key={srt.id} className={sort === srt.id ? `active`:``} to={`/music/${genre}/${srt.id}`} onClick={()=> this.setState({showSortChoices: false, sort:srt.id, page:0})}>{srt.name}</Link>);
      // const sortArrow = showSortChoices ? <Icon icon="md-arrow-dropup" fontSize="14px" color="#ffffff"/> :  <Icon icon="md-arrow-dropdown" fontSize="14px" color="#ffffff"/>; 

      const genArrow = showGenreChoices ? <Icon icon="md-arrow-dropup" fontSize="14px" color="#ffffff"/> :  <Icon icon="md-arrow-dropdown" fontSize="14px" color="#ffffff"/>; 
      const songCount = tracksCounts && tracksCounts[genre] ? `${(page + 1) * 20}/${tracksCounts[genre]} Tracks` : `No Tracks Found`;
      
      return(
            <div className="tracks_header">
                <div className="tracks_header__inner">
                <div className="tracks_header__left">
                    <h2>All Tracks</h2> 
                    <div className="tracks_genre_count">{songCount}</div>
                </div> 
                <div className="tracks_header__right">
                  <div className={` genChoices ${showGenreChoices ? 'showGenreChoices' : ''}`}>
                        <button onClick={()=> setShowGenreChoices(!showGenreChoices)}>{genre} {genArrow}</button>
                        <ul>
                        {copiedGenres.map((genr)=> <li key={genr.id}>
                                                      <Link className={genre === genr.id ? `active`:``} to={`/music/${genr.id}`} onClick={()=> { setShowGenreChoices(false); }}>
                                                         {genr.name} <span>{genr.count}</span>
                                                      </Link></li>
                        )}
                     </ul>
                  </div>
                </div> 
                    

                    {/* {userSettings.mobile ? 'Sort' :'Tracks by '}  */}
                    {/* <div className={showSortChoices ? 'showSortChoices' : ''}>
                        <button onClick={()=> { setShowGenreChoices(false); setShowSortChoices(!showSortChoices); }}>{sort === 'views' ? 'Popular' : userSettings.mobile ? 'Date' :'Release Date' } {sortArrow}</button>
                        <ul>{allSort}</ul>
                    </div> */}

                </div>
            </div>
      );
   }


   const pageCount = tracksCounts && genre && tracksCounts[genre] ?  Math.ceil(tracksCounts[genre]/20)  : 1;
   return(
      <div id="discover" className="discover--allTracks">
            {userSettings.mobile && <DiscoverHeader showListUI={true}  /> }
            {!userSettings.mobile && <button className={`listViewBtn ${listView ? 'listViewBtn--active':'' }`} data-tip="List View" onClick={()=> dispatch(setDiscoverListView(!listView))}><Icon icon="md-list" fontSize="16px" color="#ffffff"/></button>}

            <div id="discover_content">
               <div className="discover_content__wrap discover_content__wrap-all">
                  <div className="tracks_wrap">
                        {renderHeader()}
                        <div className="trackScroll_wrap trackScroll_wrap--pagination customScrollbar">
                           <div>
                              {!loading && (tracks.length > 0) ?
                                    <Tracks 
                                    listView={listView}
                                    songs={tracks} 
                                    addToPlaylist={addToPlaylist} 
                                    playSong={playSong} 
                                    allPlaylists={userplaylistsMin} 
                                    slider={false}
                                    guest={!uid} 
                                    />
                              :
                                    <TrackSkeleton />
                              }
                           </div>
                        </div>
                  </div>
                  <div className="tracks__pagination">
                        <ReactPaginate previousLabel={<Icon icon="ios-arrow-back" fontSize="20px" color="#ffffff"/>}
                        nextLabel={<Icon icon="ios-arrow-forward" fontSize="20px" color="#ffffff"/>}
                        breakLabel={'...'}
                        forcePage={page}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={userSettings.mobile ? 1 : 5 }
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                  </div>
            </div>
      </div>
      </div>
   );
};

export default AllTracks;