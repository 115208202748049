import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Profile from './Profile';
import PlayList from '../PlayList/PlayList';
import { selectPlaylist, likePlaylist, removeLikedPlaylist } from '../../redux/playlistSlice';
import { playAll } from '../../redux/queueSlice';
import playlistCategories from '../../utils/genres';
import PlaylistLike from '../PlayList/PlaylistLike';
import PlayListLikers from '../PlayList/PlaylistLikers';
import Modal from '../Modal/Modal';
import { getSongs } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../Icon/Icon';



const PublicProfile = () => {
   const profileLoaded = useRef(null);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { pathname } = useLocation();
   const { playlistSlug: playlistSlugParam } = useParams();

   const queue = useSelector((state) => state.queue);
   const userProfile = useSelector((state) => state.user.userData || {});
   const uid = userProfile?.uid;

   // const [active, setActive] =  useState(true); 
   const [playlists, setPlaylists] = useState([]);
   const [privatePlaylists, setPrivatePlaylists] = useState([]);
   // const [playlistsCount, setPlaylistsCount] =  useState(0); 
   // const [songCount, setSongCount] =  useState(0);
   const [defaultPlaylist, setDefaultPlaylist] =  useState('');
   // const [profile, setProfile] =  useState(null);
   const [currentProfile, setCurrentProfile] = useState(null);
   const [currentProfileUid, setCurrentProfileUid] = useState('');
   const [currentPlaylists, setCurrentPlaylists] = useState({});
   // const [playlistLikes, setPlaylistLikes] = useState(null);
   const [showPlaylistModal, setShowPlaylistModal] =  useState(false);
   const [showLikers, setShowLikers] =  useState(false);
   const [playlistError, setPlaylistError] =  useState('');
   const [loadingProfile, setLoadingProfile] =  useState(false);
   const [loading, setLoading] =  useState(true);
   const [privacy, setPrivacy] =  useState('public');

   

   const fetchUserPlaylists = useCallback(( initialPlaylist, currentProfile) => {
        
      //console.log('@@@ 2 fetchUserPlaylists props.currentProfile Passed!', currentProfileUid, currentProfile.id);
      const db = firebase.firestore();
      if(!currentProfileUid || (currentProfileUid && (currentProfileUid !== currentProfile.id ))){
          
          let playlistsArray = [];
          let playlistsPrivate = [];
          
          if(currentProfile){
              
              Object.keys(currentProfile.playlists).forEach((pid)=> { 
                  if(currentProfile.playlists[pid].status === 'public'){
                      playlistsArray.push(currentProfile.playlists[pid]);
                  }else if(currentProfile.playlists[pid].status === 'private'){
                      playlistsPrivate.push(currentProfile.playlists[pid]);
                  }
              });
              playlistsArray.sort((a,b) => a.order - b.order);
  
              let defaultPlaylist = playlistsArray.length > 0 ? playlistsArray[0].id : '';
              let initialplst = [];

              let allPlaylists = [...playlistsArray, ...playlistsPrivate] //merge private and public playlists
              if(initialPlaylist && allPlaylists.length > 0){
                  initialplst = allPlaylists.filter(plst => plst.slug === initialPlaylist);
                  if(initialplst[0]){
                      defaultPlaylist = initialplst[0].id;
                  }
              }

              //console.log('fetchUserPlaylists Called!!', playlistsArray, currentProfile, initialPlaylist);
              setCurrentProfileUid(currentProfile.id);
              setCurrentProfile(currentProfile);
              setDefaultPlaylist(defaultPlaylist);
              setPlaylists(playlistsArray);
              setPrivatePlaylists(playlistsPrivate);
              setCurrentPlaylists({});
              setLoading(false);
              if(allPlaylists.length > 0 && privacy !== 'public-private'){
                   //If Playlist is Private, return immedietly, so that the playlists songs are not loaded
                   if(initialPlaylist && initialplst[0] && initialplst[0].status && initialplst[0].status === 'private'){
                   console.log('@@@ Private Playlist');
                   setShowPlaylistModal(true);
                   setPlaylistError('Sorry. This Playlist is Private.');
                }

                //console.log(initialPlaylist, initialplst, allPlaylists);
                //If Playlist is deleted, return immedietly, so that the playlists songs are not loaded
                if(initialPlaylist && initialplst.length === 0){
                   console.log('@@@ Playlist Not Found!! ');
                   setShowPlaylistModal(true);
                   setPlaylistError('Sorry. This Playlist has been removed by the User.');
                }

                //Load the initial Playlist Songs fronm the Playlists Collection
                if(defaultPlaylist && currentProfile.playlists && Object.keys(currentProfile.playlists).length > 0){
                   if(!currentPlaylists[defaultPlaylist]){

                         //console.log('RUN ONCE!!!! ');
                         const PlaylistRef = db.collection('Playlists').doc(defaultPlaylist);
                         PlaylistRef.get().then((doc)=> {
                            //console.log(defaultPlaylist, currentPlaylists[defaultPlaylist], doc);
                            console.log('@@ Playlist Query Complete!');
                            if(doc.exists){
                               dispatch(selectPlaylist({playlistID: defaultPlaylist}));
                               setCurrentPlaylists({...currentPlaylists, [doc.id] : doc.data()})
                            }
                         });

                   }
                }
             }

          }

      }
      
  }, [currentPlaylists, currentProfileUid, dispatch, privacy])

   const fetchUser = useCallback((currentUsername, initialPlaylistSlug=null) => {
        
      if(!currentUsername || loadingProfile ){ return }

      if(!currentProfile || (currentProfile.username && (currentProfile.username !== currentUsername )) ){
          const db = firebase.firestore();
          const UserRootRef = db.collection('Users').where("username", "==", currentUsername).limit(1);
          
 
          setLoadingProfile(true);
          

          UserRootRef.get().then((docSnap)=> {
              //console.log('### New CurrentUsername: ', docSnap);

              if(docSnap.size === 0){ return setLoading(false)}

              docSnap.forEach((doc)=> {
                  const userObj = doc.data();
                  delete userObj.email;
                  let {avatarUrl='', bio='', country='', username='', fullname='', gender='', photoURL='', playlists={}, settings={}} = userObj;
                  let filteredProfile = { avatarUrl, bio, country, username, fullname, gender, photoURL, playlists, settings, id: doc.id }
                  console.log('$$$$$$ Profile Query Complete!');

                  setLoadingProfile(false)
                  setCurrentProfile(filteredProfile);
                  //if()
                  if( filteredProfile && filteredProfile.settings && uid && (filteredProfile.id === uid) && (filteredProfile.settings.private === 'true')){
                      setPrivacy('author-private');
                  }else if( filteredProfile && filteredProfile.settings && filteredProfile.settings.private === 'true'){
                      setPrivacy('public-private');
                  }else{
                      setPrivacy('public');
                  }

                  if(filteredProfile){
                      fetchUserPlaylists(initialPlaylistSlug, filteredProfile);
                  }else{
                      setLoading(false)
                  }

                  
              });
          }).catch(err => setLoading(false) )

      }
   }, [currentProfile, loadingProfile, uid, fetchUserPlaylists])

   useEffect(()=> {
      if(!profileLoaded.current){
         const userMatch = matchPath('/user/:username', pathname);
         const userMatchWithPlaylist = matchPath('/user/:username/:playlistSlug', pathname);
 
         let currentUsername = '';
         let initialPlaylistSlug = ''; 
         if(userMatch && userMatch.params.username){ currentUsername = userMatch.params.username }
         if(userMatchWithPlaylist && userMatchWithPlaylist.params.username){ currentUsername = userMatchWithPlaylist.params.username }
         if(userMatchWithPlaylist && userMatchWithPlaylist.params.playlistSlug){ initialPlaylistSlug = userMatchWithPlaylist.params.playlistSlug}
         console.log('currentUsername, initialPlaylistSlug: ', currentUsername, initialPlaylistSlug);
         fetchUser(currentUsername, initialPlaylistSlug);
         profileLoaded.current = true;
      }
   }, [pathname, fetchUser])

   //  componentWillReceiveProps(nextProps){
   //      const userMatch = matchPath(pathname, {  path: '/user/:username',  exact: true, strict: false });
   //      const userMatchWithPlaylist = matchPath(pathname, {  path: '/user/:username/:playlistSlug',  exact: true, strict: false });
       
   //      let currentUsername = '';
   //      let initialPlaylistSlug = ''; 
   //      if(userMatch && userMatch.params.username){ currentUsername = userMatch.params.username }
   //      if(userMatchWithPlaylist && userMatchWithPlaylist.params.username){ currentUsername = userMatchWithPlaylist.params.username }
   //      if(userMatchWithPlaylist && userMatchWithPlaylist.params.playlistSlug){ initialPlaylistSlug = userMatchWithPlaylist.params.playlistSlug}
   //      //console.log('currentUsername, initialPlaylistSlug: ', currentUsername, initialPlaylistSlug);
   //      fetchUser(currentUsername, initialPlaylistSlug);
   //  }


   


   const switchPlaylist = (plist) => {
      if(plist){
         dispatch(selectPlaylist({playlistID: plist.id}));
         const newDefaultPlaylist = plist.id;
         setDefaultPlaylist(newDefaultPlaylist);
         if(newDefaultPlaylist && playlists && playlists.length > 0){
            if(!currentPlaylists[newDefaultPlaylist]){
                  const db = firebase.firestore();
                  const PlaylistRef = db.collection('Playlists').doc(newDefaultPlaylist);
                  PlaylistRef.get().then((doc)=> {
                     //console.log(newDefaultPlaylist, doc);
                     if(doc.exists){
                        setCurrentPlaylists({...currentPlaylists, [doc.id] : doc.data() });
                     }
                  });
            }
         }
      }
      
      navigate(`/user/${currentProfile.username}/${plist.slug}/`);
    }

   const likeThePlaylist = (plist, userAlreadyLiked, likeCount)=> {
        
        const likedBy = {
            [uid]: {  username:userProfile.username, liked: Number((new Date().getTime() / 1000).toFixed(0))  } 
        }
        const notificationSender = {username:userProfile.username, name: userProfile.fullname }

        const playlistDetail = { title: plist.title, slug: plist.slug, author: currentProfile.username }
        dispatch(likePlaylist(currentProfileUid, plist.id, playlistDetail, likedBy, likeCount, notificationSender));


        let newCount = userAlreadyLiked ? likeCount - 1 : likeCount + 1;
        newCount = newCount < 0 ? 0 : newCount;

        const updatedPlaylists = playlists.map((playlist)=> {
            if(playlist.id === plist.id){
                return {...playlist, likes: newCount} 
            }else{
                return playlist;
            }
        })

        console.log(likeCount, newCount, updatedPlaylists);

        setTimeout(() => {
            setPlaylists(updatedPlaylists);
        }, 500);
    }

   const closeModal = () => {
        setShowPlaylistModal(false);
        setPlaylistError('');
        if( playlists[0] && playlists[0].id ){
            switchPlaylist(playlists[0]);
            return navigate(`/user/${currentProfile.username}/`);
        }

    }

   const removeSavedPlaylist = (targetPlsitID) => {
        if(!targetPlsitID) return;
        setShowPlaylistModal(false);
        setPlaylistError('');
        dispatch(removeLikedPlaylist(targetPlsitID));
        if( playlists[0] && playlists[0].id ){
            switchPlaylist(playlists[0]);
            return navigate(`/user/${currentProfile.username}/`);
        }
    }

   const renderRemovePlaylistBtn = () => {
        const userMatchWithPlaylist = matchPath('/user/:username/:playlistSlug', pathname);
        const usersLikedPlaylists = userProfile && userProfile.liked;
        let targetPlsitID = '';
        if(usersLikedPlaylists && Object.keys(usersLikedPlaylists).length > 0 && userMatchWithPlaylist){
            const likedPlaylistsArray = Object.keys(usersLikedPlaylists).map((plistID)=> {
                return {...usersLikedPlaylists[plistID], id: [plistID]};
            });
            const targetPlsit = likedPlaylistsArray.filter(plist => plist.slug === userMatchWithPlaylist.params.playlistSlug && plist.author === userMatchWithPlaylist.params.username)
            targetPlsitID = targetPlsit[0] && targetPlsit[0].id;
        } 

        return targetPlsitID ? <button className="removeLikedPlaylist" onClick={()=> removeSavedPlaylist(targetPlsitID)}>Remove from Saved Playlists</button> : '';

    }



   let totalSongsCount = 0;
   playlists.map(plst => totalSongsCount = totalSongsCount + plst.count);

   return(
      <div id="publicProfile" className="routeHasPlaylist">
            <div>

                  <Profile 
                  uid={uid}
                  authorID={currentProfileUid}
                  loading={loading}
                  user={currentProfile ? currentProfile : {}} 
                  loggedInUser={userProfile}
                  playlistsCount={playlists.length}
                  songCount={totalSongsCount}
                  />


               {currentProfile === null && <p className="profile_not_found"><Icon icon="md-warning" fontSize="14px" color="#ffffff"/> Profile Not Found!</p>}
               {privacy === 'author-private'&& 
                  <p className="profile_private_msg"><Icon icon="md-lock" fontSize="16px" color="#ffffff"/> Your Profile is set to Private.</p>
               }
               {privacy === 'public-private'&& 
                  <p className="profile_private_msg public_private"><Icon icon="md-lock" fontSize="16px" color="#ffffff"/> This Profile is set to Private.</p>
               }

               {privacy !== 'public-private' &&
               <div className="publicPlaylis customScrollbar">
                  <div>
                        {currentProfile && playlists.length === 0 && privatePlaylists.length > 0 && currentProfileUid && uid && (currentProfileUid === uid) &&
                           <p className="profile_private_msg"><Icon icon="md-warning" fontSize="16px" color="#ffffff"/> You do not have any Public Playlists. Add public playlists to display them on your Profile (this) page.</p>
                        }

                        {currentProfile && !playlistSlugParam &&
                           playlists.map((plist)=> {

                              const selectedCat = plist.category ? playlistCategories.filter((pl)=>  pl['id'] === plist.category  ) :[{id:'mixed',name:'Mixed'}];
                              let userAlreadyLiked = false;
                              const currentUsersLikedPlaylists = userProfile.id && userProfile.liked && userProfile.liked
                              if(currentUsersLikedPlaylists){
                              Object.keys(currentUsersLikedPlaylists).forEach((key)=> {
                                    if(key === plist.id){ userAlreadyLiked = true }
                              });
                              }

                              return (
                                    <div className={`publicPlaylist ${defaultPlaylist === plist.id ? 'active': ''}`} key={plist.id}>
                                       <div className="playlist__header" onClick={()=> switchPlaylist(plist)}>
                                          <div className="playlist__header__left">
                                                <div className="playlist__title">
                                                   {plist.title} <span>{plist.count}</span>
                                                </div>
                                                <div className="playlist__category">
                                                   {selectedCat[0].name}
                                                </div>
                                                {uid && 
                                                   <PlaylistLike 
                                                   likes={plist.likes ? plist.likes : 0 }
                                                   userIsAuthor={uid === currentProfile.id ? true : false} 
                                                   showLikers={()=> setShowLikers(true)} 
                                                   likePlaylist={()=> likeThePlaylist(plist, userAlreadyLiked, plist.likes ? plist.likes : 0)}
                                                   userAlreadyLiked={userAlreadyLiked}
                                                   />
                                                }
                                          </div>
                                          <div className="playlist__header__right">
                                                {defaultPlaylist === plist.id  &&
                                                   <div className="playAll" data-tip="Play All">
                                                      <button aria-label="Play All" onClick={() => dispatch(playAll({songs: getSongs(currentPlaylists, defaultPlaylist), queue: queue.selected}))}>
                                                            <Icon icon="md-play" fontSize="14px" color="#ffffff"/>
                                                      </button>
                                                   </div>
                                                }
                                          </div>

                                       </div>
                                       {defaultPlaylist === plist.id &&
                                          <PlayList
                                          playlists={{[defaultPlaylist] : currentPlaylists[defaultPlaylist]}}
                                          publicPlaylist={true}
                                          authorID={currentProfileUid} 
                                          auth={userProfile}
                                          author={currentProfile && currentProfile.username}
                                          />
                                       }
                                    </div>
                              )
                           })
                        }
                        {currentProfile && playlistSlugParam && 
                           <Link to={`/user/${currentProfile.username }/`} className="allPlistBttn"><Icon icon="ios-arrow-back" fontSize="16px" color="#ffffff"/> All Playlists by {currentProfile.fullname ? currentProfile.fullname : currentProfile.username }</Link> 
                        }
                        {currentProfile && playlistSlugParam &&
                           playlists.map((plist)=> {
                              if(plist.slug !== playlistSlugParam)
                              {
                                    return null;
                              }

                                    const selectedCat = plist.category ? playlistCategories.filter((pl)=>  pl['id'] === plist.category  ) :[{id:'mixed',name:'Mixed'}];
                                    let userAlreadyLiked = false;
                                    const currentUsersLikedPlaylists = userProfile.id && userProfile.liked && userProfile.liked
                                    if(currentUsersLikedPlaylists){
                                    Object.keys(currentUsersLikedPlaylists).forEach((key)=> {
                                       if(key === plist.id){ userAlreadyLiked = true }
                                    });
                                    }

                                    return (
                                       <div className={`publicPlaylist ${defaultPlaylist === plist.id ? 'active': ''}`} key={plist.id}>
                                          <div className="playlist__header" onClick={()=> switchPlaylist(plist)}>
                                                <div className="playlist__header__left">
                                                   <div className="playlist__title">
                                                      {plist.title} <span>{plist.count}</span>
                                                   </div>
                                                   <div className="playlist__category">
                                                      {selectedCat[0].name}
                                                   </div>
                                                   {uid && 
                                                      <PlaylistLike 
                                                      likes={plist.likes ? plist.likes : 0 }
                                                      userIsAuthor={uid === currentProfile.id ? true : false} 
                                                      showLikers={()=> setShowLikers(true)} 
                                                      likePlaylist={()=> likeThePlaylist(plist, userAlreadyLiked, plist.likes ? plist.likes : 0)}
                                                      userAlreadyLiked={userAlreadyLiked}
                                                      />
                                                   }
                                                </div>
                                                <div className="playlist__header__right">
                                                   {defaultPlaylist === plist.id  &&
                                                      <div className="playAll" data-tip="Play All">
                                                            <button aria-label="Play All" onClick={() => dispatch(playAll({songs: getSongs(currentPlaylists, defaultPlaylist), queue: queue.selected}))}>
                                                               <Icon icon="md-play" fontSize="14px" color="#ffffff"/>
                                                            </button>
                                                      </div>
                                                   }
                                                </div>

                                          </div>
                                          {defaultPlaylist === plist.id &&
                                                <PlayList
                                                playlists={{[defaultPlaylist] : currentPlaylists[defaultPlaylist]}}
                                                publicPlaylist={true}
                                                authorID={currentProfileUid} 
                                                auth={userProfile}
                                                author={currentProfile && currentProfile.username}
                                                />
                                          }
                                       </div>
                                    )
                              
                           })
                        }
                  </div>
               </div>
               }
               {uid && currentProfile && (currentProfile.id === uid) && showLikers &&
                  <PlayListLikers 
                  playlistID={defaultPlaylist} 
                  uid={uid} 
                  show={showLikers}
                  onClose={()=> setShowLikers(false)}
                  />
               }

               <Modal title={`Error Loading The Playlist`} show={showPlaylistModal} maxWidth={400} closeModal={closeModal}>
                  <div className="publicPlaylistError_Modal">
                        <p><Icon icon="md-warning" fontSize="14px" color="#ffffff"/> {playlistError}</p>
                        {renderRemovePlaylistBtn()}
                  </div>
               </Modal>
            </div>
      </div>
   );


}

// export default compose(
//     withRouter,
//     connect(
//       (state, props) => {
//           return {
//             auth: state.firebase.auth,
//             //currentProfile: filteredProfile,
//             loggedInUser: state.firebase.profile,
//             queue: state.queue
//           }
//       },
//       {selectedPlaylist, likePlaylist, removeLikedPlaylist, playAll}
//     ),
//   )(PublicProfile);
export default PublicProfile;
