import React, { useEffect, useState } from 'react';
import axios from 'axios'
import striptags from 'striptags';
import Icon from '../Icon/Icon';
import Tracks from './Tracks';
import { objectToArray, getSongs } from '../../helpers';
import { addSong } from '../../redux/songSlice';
import { guestAdd } from '../../redux/guestPlaylistSlice';
import fakePic from '../../assets/user_m.png';
import TrackSkeleton from '../Loader/TrackSkeleton';
import { TitleSkeleton } from '../Loader/basicSkeletons';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

const Artist = () => {

   const { slug: slugParam } = useParams();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   
   const guestPlaylists = useSelector((state) => state.playlistGuest);
   const queue = useSelector((state)=> state.queue );
   const userSettings = useSelector((state)=> state.user );
   const userplaylists = useSelector((state) => state.userplaylists);
   const userplaylistsMin =  useSelector((state) => state.userplaylistsMin);
   const userSongs = getSongs(userplaylists, 'temp' );
   const uid = userSettings && userSettings.user && userSettings.user.id;
 
   // const  [loading, setLoading] =  useState(false);
   const  [artist, setArtist] =  useState(null);
   const  [songs, setSongs] =  useState(null);
   const  [showFullBio, setShowFullBio] =  useState(false);


   const  [bioLoading, setBioLoding] =  useState(false);
   const fetchBio = useCallback((artist) => {
      if(artist.bio){ return; }
      setBioLoding(true);
      axios.get(`https://api.yhimsical.com/getartistbio?mbid=${artist.mbid}&name=${artist.name}`)
      .then((response) => {
         console.log(response.data);
          if(response?.data?.artist){
               console.log(response.data.artist);
               const fetchedArtist = response.data.artist;
               const bio = fetchedArtist.bio ? striptags(fetchedArtist.bio).trim() : '';
               const summary = fetchedArtist?.summary || '';
               // const image = fetchedArtist.image ? fetchedArtist.image : '';
               setArtist({...artist, summary, bio });
          }
      })
      .catch(err => console.log('ERROR Loading From API: ', err)).finally(()=> setBioLoding(false) )
    }, [])

    useEffect(()=> {
      if(!artist && slugParam){
         axios.get(`https://yhimsical.b-cdn.net/artistsongs/${slugParam}.json`).then((res)=> {
            console.clear();
            console.log('Artist CDN Data: ', res);
            if(res.data.songs){
               const artistSongs = objectToArray(res.data.songs, true);
               setSongs(artistSongs);
               const artistInfo = res.data;
               delete artistInfo.songs;
               console.warn('artistInfo: ', artistInfo);
               setArtist(artistInfo);
               if(!artistInfo.summary){
                  fetchBio(artistInfo);
               }
            }
         }).catch((err)=> {
            console.warn('ERROR Loading From CDN', err);
         })
      }
    }, [slugParam, artist, fetchBio]);


   const playSong = (song) => {
        const newSong = { 
            artist: song.artist, 
            duration:song.duration, 
            title: song.title, 
            youtube: song.youtube, 
            order: userSongs[userSongs.length - 1] ? Number(userSongs[userSongs.length - 1].order) + 1 : 0,
            added: Number((new Date().getTime() / 1000).toFixed(0))
        };
        const queueHasSong = queue[queue.selected].filter(sng => sng.id === newSong.id).length > 0 ? true: false;
        dispatch(addSong(uid, queue.selected, newSong, true, queueHasSong));

    }

   const addToPlaylist = (song, playlistID) => {
        const selectedPlaylist = userplaylistsMin.length > 0 ? userplaylistsMin.filter(plist => plist.id === playlistID) : [];
        const newSong = { 
            artist: song.artist, 
            duration:song.duration, 
            title: song.title, 
            youtube: song.youtube, 
            order: selectedPlaylist.length > 0 ?  selectedPlaylist[0].count + 1: 0,
            added: Number((new Date().getTime() / 1000).toFixed(0)) 
        };

        return dispatch(addSong(uid, playlistID, newSong));
    }

   const goBack = () => {
      if (window.history.state && window.history.state.idx > 0) {
         navigate(-1);
      } else {
            navigate('/', { replace: true });
      }
   }

   const renderBio = () => {
        return <div>
            {showFullBio && artist.bio && !bioLoading ? artist.bio.split('\n').map((t, idx) => <p key={`artistP-${idx}`}>{t}</p>)  : artist.summary } 
            <button onClick={()=> { fetchBio(artist); setShowFullBio(!showFullBio)}}>{showFullBio && !bioLoading ? `Read Less..` : `Read More..`}</button>
        </div>
    }


   const sortedSongs = songs && songs.length > 0 ? songs.sort((a,b) => {
      let dateB = b.release_date.toString().length < 13 ? parseInt(b.release_date, 10) * 1000 : b.release_date;
      let dateA = a.release_date.toString().length < 13 ? parseInt(a.release_date, 10) * 1000 : a.release_date;
      return dateB - dateA;
   }) : songs;

   return(
      <div id="artist">
            <div className="artist_inner">
               <div className="artist_back_button">
                  <button onClick={goBack}><Icon icon="md-arrow-back" fontSize="18px" color="#ffffff"/></button>
               </div>

               <div className="trackScroll_wrap trackScroll_wrap--pagination customScrollbar">
                  <div>
                        <div className="artist_header">
                           <div className="artist_header__wrap">
                                    <div className="artist_left">
                                       <div className="artist_left__img">
                                          <img src={artist && artist.image? artist.image : fakePic} alt={artist ? artist : 'artist-photo'} />
                                       </div>
                                    </div>
                                    <div className="artist_right">
                                       {artist && artist.name ? <h3>{artist.name}</h3> : <TitleSkeleton /> }
                                       <span className="artist__tracks__count">{songs ? songs.length : 0} Tracks</span>
                                       <span className="artist__genres">{artist && artist.categories && Object.keys(artist.categories).toString()}</span>
                                    </div>


                           </div>
                        </div>

                        <div className="artist_tracks">

                           <div className="artist_content">
                              <div className="artist_bio">
                                    {artist && !artist.summary ? 
                                       <p>No Bio..</p>
                                    :
                                       artist && artist.summary && renderBio()
                                       
                                    }
                              </div>
                              <h2>Music Videos ({songs ? songs.length : 0})</h2>
                              {sortedSongs ? 
                                    <Tracks 
                                    songs={sortedSongs} 
                                    addToPlaylist={addToPlaylist} 
                                    playSong={playSong} 
                                    allPlaylists={userplaylistsMin}
                                    slider={false}
                                    guest={!uid} 
                                    guestAdd={guestAdd}
                                    guestPlaylists={guestPlaylists}
                                    />
                              :
                                    <TrackSkeleton />
                              }
                           </div>
                        </div>
                  </div>
               </div>

            </div>
      </div>
   );

}

export default Artist;