import React, {useState, useEffect} from 'react';
import SignUp from './SignUp/SignUp';
import CompleteSignup from './SignUp/CompleteSignup';
import Login from './Login/Login';
import ResetPassword from './Login/ResetPassword';
import UserSettings from './UserSettings';
import { useLocation } from 'react-router-dom';
import { hideUserArea } from '../../redux/userSlice';
import { useDispatch } from 'react-redux';
import Icon from '../Icon/Icon';

const User = ({profile, loadingUser, auth, history, firebase, visible, userSettings, showCompleteSignup=false}) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const [tab, setTab] = useState('login');
    const noProfile =  profile && profile.id ? false : true;

   //  console.log('loadingUser: ', loadingUser, noProfile, profile);

    useEffect(()=> {
      if(noProfile && tab === 'login'){  setTab('login') }
      if(noProfile && tab === 'signup'){  setTab(  'signup' )  }
      if(pathname.includes("resetpassword")){  setTab( 'resetpassword' )  }
      if(profile && profile.id){ setTab( 'myaccount' ); }
    }, [noProfile, setTab , tab, pathname, profile])
   
    const onLogout = ()=> {
        setTab('login')
        firebase.auth().signOut().then(()=> {
            setTab('login')
            document.body.classList.remove('complete-registration');
            window.location.reload();
        });
        
    }

    const closeSidebar = () => {
      console.log('closeSidebar');
         dispatch(hideUserArea());
        if(pathname === '/resetpassword/'|| pathname === '/resetpassword'){
            document.body.classList.remove('complete-registration');
            setTab( noProfile ? 'login' : 'myaccount' );
            history.push('/');
        }
        if(tab === 'resetpassword'){
            setTab( noProfile ? 'login' : 'myaccount');
            document.body.classList.remove('complete-registration');
        }
    }

    const push = (url) => {
      console.log('PUSH', url);
    }
    

      return(
         <div id="User" className={visible ? 'showUserArea' : 'hideUserArea'}>
            <div className="User__topbar">
               {!showCompleteSignup  && 
                  <span className="close_sidebar" onClick={closeSidebar}>
                     <Icon icon="md-arrow-forward" fontSize="24px" color="#ffffff"/>
                  </span>
               }
               {noProfile === false && 
                  <button className="logout_button" onClick={onLogout }><Icon icon="md-log-out" fontSize="18px" color="#ffffff"/> Logout</button>
               }
            </div>                    
            
            <div className="UserInner">
               {!showCompleteSignup && noProfile && tab === 'login' && 
                  <Login user={profile} push={push} setTab={setTab} firebase={firebase} userSettings={userSettings} />
               }
               {!showCompleteSignup && noProfile && tab === 'signup' && 
                  <SignUp user={profile} firebase={firebase} push={push} setTab={setTab} />
               }
               {(!loadingUser && showCompleteSignup) && 
                  <CompleteSignup user={profile} auth={auth} setTab={setTab} authData={userSettings.authData} />
               }
               {!showCompleteSignup && tab === 'resetpassword' && 
                  <ResetPassword user={profile} setTab={setTab} history={history} />
               }
               {!showCompleteSignup && tab === 'myaccount'  && profile && profile.username && 
                  <UserSettings user={profile} push={push} setTab={setTab} firebase={firebase} userSettings={userSettings} />
               }
            </div>
         </div>
      );

}


export default User;
