import { current, createSlice } from "@reduxjs/toolkit";
import { loadState } from "../localStorage";

const persistenGuestPlaylist = loadState('playlistGuest');

export const slice = createSlice({
   name: "playlistGuest",
   initialState:  {
      playlists: persistenGuestPlaylist.playlists || {
          temp: {
              created: 1530450918,
              category:"mixed",
              slug:"untitled-1233423",
              title: "Untitled Playlist",
              status:"public",
              songs:{
                  JGwWNGJdvx8:{
                      added: 1530450918,
                      artist: "Ed Sheeran",
                      title: "Shape of You",
                      duration: 264,
                      order:0,
                      id:"JGwWNGJdvx8",
                      youtube:"JGwWNGJdvx8",
                      cover: "https://i.ytimg.com/vi/JGwWNGJdvx8/default.jpg"
                  },
                  xpVfcZ0ZcFM:{
                      added: 1530458770,
                      order:1,
                      album:"Scary Hours",
                      artist:"Drake",
                      duration:357,
                      id:"xpVfcZ0ZcFM",
                      title:"God's Plan",
                      youtube:"xpVfcZ0ZcFM",
                      cover: "https://i.ytimg.com/vi/xpVfcZ0ZcFM/default.jpg"
                  },
                  M4ZoCHID9GI:{
                      added: 1530459503,
                      album: "My Dear Melancholy,",
                      order:2,
                      artist:"The Weeknd",
                      duration:239,
                      id:"M4ZoCHID9GI",
                      title:"Call Out My Name",
                      youtube:"M4ZoCHID9GI",
                      cover: "https://i.ytimg.com/vi/M4ZoCHID9GI/default.jpg"
                  }
              }
          }
      },
    },
   reducers: {
      guestAdd: (state, {payload}) => {
         const song = payload
         const tempPl = current(state.playlists.temp);
         const newSongs = {...tempPl.songs, [song.id]: {...song, order: Object.keys(tempPl.songs).length }}
         const newPlaylist =  { temp: { ...tempPl, songs:newSongs} };
         state.playlists = {...current(state['playlists']), ...newPlaylist}
      },
      guestRemove: (state, {payload}) => {
         
         let updatedSongs = {...current(state.playlists.temp.songs)};
         delete updatedSongs[payload];
         const updatedPlaylist =  { temp: { ...state.playlists.temp, songs: updatedSongs}  };
         state.playlists = {...current(state['playlists']), ...updatedPlaylist}
      },
      guestReorder: (state, {payload}) => {
         let reSongs={}
         const songs = payload;
         songs.map((song)=> {
             return reSongs = {...reSongs, [song.id]: song}}
         )
         const reOrderedPlaylist =  { temp: { ...state.playlists.temp, songs: reSongs} };
         state.playlists = {...current(state['playlists']), ...reOrderedPlaylist}
      },
   }
});

export const { guestAdd, guestRemove, guestReorder } = slice.actions;

export default slice.reducer;