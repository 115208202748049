import React, { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import Track from './Track';
import { draggingSong } from '../../redux/userSlice';
import { addToQueue } from '../../redux/queueSlice';
import { useDispatch } from 'react-redux';


const Tracks = (props) => {

   const {allPlaylists, listView, searchTrack, guest,  songs, addToPlaylist, station, addToStation, playSong, mobile } = props;

   const [showPlaylists, setShowPlaylists] = useState( '' );
   const [addedToPlaylist, setAddedToPlaylist] = useState( '' );
   const [selectedPlaylist, setSelectedPlaylist] = useState('queueOne' );
   const [selectedSong, setSelectedSong] = useState('');

   const dispatch = useDispatch();

    let thumbWaitTime = null;
    let thumbWaitTimeOne = null;
    let thumbWaitTimeTwo = null;
    let thumbWaitTimeThree = null;
    let thumbWaitTimeFour = null;

    useEffect(()=> {
      window.dispatchEvent(new Event('resize'));
    }, [])

   const animateThumbnail = (youtubeID) => {
        if(mobile) { return }
        thumbWaitTime = setTimeout(() => {
            thumbWaitTimeOne = setTimeout(() => {  if(document.getElementById(`song-${youtubeID}`)) document.getElementById(`song-${youtubeID}`).src = `https://i.ytimg.com/vi/${youtubeID}/1.jpg`;  }, 500);
            thumbWaitTimeTwo = setTimeout(() => {  if(document.getElementById(`song-${youtubeID}`)) document.getElementById(`song-${youtubeID}`).src = `https://i.ytimg.com/vi/${youtubeID}/2.jpg`;  }, 900);
            thumbWaitTimeThree = setTimeout(() => {  if(document.getElementById(`song-${youtubeID}`)) document.getElementById(`song-${youtubeID}`).src = `https://i.ytimg.com/vi/${youtubeID}/3.jpg`;  }, 1300);
            thumbWaitTimeFour = setTimeout(() => {  if(document.getElementById(`song-${youtubeID}`)) document.getElementById(`song-${youtubeID}`).src = `https://i.ytimg.com/vi/${youtubeID}/hqdefault.jpg`;  }, 1700);
        }, 500);
    }

   const resetThumbnail = (youtubeID) =>{
        if(mobile) { return }
        clearTimeout(thumbWaitTime); clearTimeout(thumbWaitTimeOne); clearTimeout(thumbWaitTimeTwo);
        clearTimeout(thumbWaitTimeThree); clearTimeout(thumbWaitTimeFour);
        document.getElementById(`song-${youtubeID}`).src = `https://i.ytimg.com/vi/${youtubeID}/hqdefault.jpg`;
        setSelectedSong('');
    }

   const handleOptionChange = (changeEvent) => {
        setSelectedPlaylist(changeEvent.target.value);
    }

   const addTrackToPlaylist = (song, playlistID) => {
        setAddedToPlaylist(song.youtube);

        addToPlaylist(song, playlistID);
        
        setTimeout(() => {
            setShowPlaylists('');
            setAddedToPlaylist('');
        }, 1000);

    }

   const playlistSelector = (song) => {
         let currentPlaylists = [{id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
         if(guest){
            currentPlaylists.push({id:'temp', title:'Untitled Playlist'});
         }
         //   console.log(currentPlaylists, allPlaylists);
         if(allPlaylists && allPlaylists.length > 0){ 
            currentPlaylists = [...currentPlaylists, ...allPlaylists];
         }


        return(
            <div className="track__addToPlaylist">
                <div className="trackScroll_wrap customScrollbar">
                    <div>
                        <ul>
                            {addedToPlaylist === song.youtube && 
                                <p className="track__addToPlaylist__success"><Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/> Song Added!</p>
                            }
                            <p>Add <span>{song.title}</span> to: </p>
                            
                            <div className="customRadio">
                                {currentPlaylists && 
                                    currentPlaylists.map((playlist)=> {
                                        return <label className="control control--radio" key={`${playlist.id}${song.youtube}`}>{playlist.title}
                                            <input type="radio"
                                            value={playlist.id} 
                                            checked={selectedPlaylist === playlist.id} 
                                            onChange={handleOptionChange}  /> 
                                            <div className="control__indicator"></div>
                                        </label>
                                    }) 
                                }
                            </div>
                        </ul>
                    </div>
                </div>
                <button onClick={()=> addTrackToPlaylist(song, selectedPlaylist)}>+ Add to Playlist</button>
                <button className="track__addToPlaylist__cancel" onClick={()=> setShowPlaylists('')}>Cancel</button>
            </div>
        );
    }
        
   return(
      <div className={`tracks ${listView ? 'tracks--listView': ''}`}>

         <div>
               {songs && songs.length > 0 &&
                  songs.map((song)=>{
                        return <Track
                        listView={listView}
                        searchTrack={searchTrack}
                        key={song.id ? song.id : song.youtube}
                        playSong={playSong} 
                        song={song} 
                        selectedSong={selectedSong}
                        animateThumbnail={animateThumbnail}
                        resetThumbnail={resetThumbnail}
                        selectSong={(songID)=> setSelectedSong(songID)}
                        playlistSelector={playlistSelector}
                        activePlaylist={showPlaylists}
                        draggingSong={(value)=> dispatch(draggingSong(value))}
                        showPlaylists={(songYTID)=> showPlaylists ? setShowPlaylists('') : setShowPlaylists(songYTID)}
                        addToQueue={addToQueue}
                        station={station}
                        addToStation={addToStation}
                        />
                  })
               }
            </div>
            
      </div>
   );

}

export default Tracks;