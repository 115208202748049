import React, { useEffect, useMemo, useRef, useState } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { minifyPlaylist, getSongs } from '../../helpers';
import { showAddSongModal, selectSong, draggingSong, showAddtoPlaylist } from '../../redux/userSlice';
import {  likePlaylist, selectPlaylist } from '../../redux/playlistSlice';
import { addToQueue, playAll } from '../../redux/queueSlice';
import { guestReorder } from '../../redux/guestPlaylistSlice';
import SongWrapper from '../Song/SongWrapper';
import PlaylistLike from './PlaylistLike';
import PlayListLikers from './PlaylistLikers';
import PlaylistEdit from './PlaylistEdit';
import PlaylistDelete from './PlaylistDelete';
import PlaylistShare from './PlaylistShare';
import playlistCategories from '../../utils/genres';
// import PlaylistSkeleton from '../Loader/PlaylistSkeleton';
import isEqual from 'lodash.isequal';
import PlaylistMobileActions from './PlaylistMobileActions';
import Icon from '../Icon/Icon';


const PlayList  = ({ publicPlaylist=false, authorID, author, playlists }) => {
   const dispatch = useDispatch();
   const playlistElement = useRef(null);
   const uid = !publicPlaylist && authorID;
   const userIsAuthor = uid && authorID && uid === authorID;
   const queue = useSelector((state) => state.queue);
   const userplaylists = useSelector((state)=> state.userplaylists );
   const playlistsMin = useMemo(()=> playlists ? minifyPlaylist(playlists) : [], [playlists]);
   const userSettings = useSelector((state)=> state?.user );
   const userProfile = userSettings?.userData;
   const userPlaylistsMin = useMemo(()=> minifyPlaylist(userProfile?.playlists || {}) , [userProfile]);
   const guestPlaylists =  useSelector((state)=> state.playlistGuest );
   const songsGuest = !userProfile && getSongs( guestPlaylists.playlists, 'temp' );
   const playlistSongs = useMemo(() => {
      return playlists && Object.keys(playlists).length > 0 ? getSongs( playlists, userplaylists.selectedPlaylist ? userplaylists.selectedPlaylist  : Object.keys(playlists)[0] ) : [];
   }, [userplaylists, playlists])

   const [songs, setSongs] = useState(null);
   const [currentPlaylist, setCurrentPlaylist] = useState('temp');
   // const [playlistHeight, setPlaylistHeight] = useState( 500);
   const [showEditMenu, setShowEditMenu] = useState( false);
   const [showEditModal, setShowEditModal] = useState( false);
   const [showShareModal, setShowShareModal] = useState( false);
   const [showRemoveModal, setShowRemoveModal] = useState( false);
   const [showPlaylistSelector, setShowPlaylistSelector] = useState( false);
   const [showLikers, setShowLikers] = useState(false);
   const [reorderingSongs, setReorderingSongs] = useState([]);
   const [mobileSelectedSong, setMobileSelectedSong] = useState( null);
   const [reordering, setReordering] = useState( false);


   useEffect(()=> {
      //Reset State on selcting another playlist
      const currentPlaylistID = userplaylists.selectedPlaylist ? userplaylists.selectedPlaylist : playlistsMin && playlistsMin.length > 0 && playlistsMin[0].id;
      const newCurrentPlaylist = uid ? currentPlaylistID : 'temp';
      let newSongs = {}
      if( uid && playlistSongs){
         newSongs = playlistSongs ? playlistSongs  : []
      }else if(!uid){
         newSongs = songsGuest ? songsGuest  : []
      }

      if(!isEqual(newSongs, songs) && !publicPlaylist){
         setSongs(newSongs);
      }
      if(newCurrentPlaylist !== currentPlaylist){
         setCurrentPlaylist(uid ? currentPlaylistID : 'temp');
      }
   }, [songs, currentPlaylist, userplaylists, uid, playlistSongs, playlistsMin, songsGuest, publicPlaylist])


  useEffect(()=> {
   //  if(publicPlaylist !== true && userProfile){
   //    setPlaylistHeight(playlistElement.clientHeight);
   //  }
  
    //Public Profile Songs Fix
    if(playlistSongs && playlistSongs.length > 0 && publicPlaylist){
      const currentPlaylistID = playlistsMin && playlistsMin.length > 0 ? playlistsMin[0].id : 'temp';
      console.log(publicPlaylist, currentPlaylistID, playlistSongs);
      setSongs(playlistSongs);
      setCurrentPlaylist(currentPlaylistID);
    }
  }, [publicPlaylist, playlistSongs, playlistsMin])


  const playSong = (songID) => {
    // const songsplaylist = playlistsMin && playlistsMin.filter((plist)=>  plist.id === currentPlaylist  ); 
    // const playlistDetail = songsplaylist && songsplaylist.length > 0 && {...songsplaylist[0], author: author ? author : '', userIsAuthor: userIsAuthor };
    // dispatch(selectPlaylistSong(songID, currentPlaylist, playlistDetail , songs));
    const song = songs.filter((sng)=> sng.id === songID)
    if(song.length > 0 ){
      const queueHasSong = queue[queue.playing.queue].filter(song => song.id === songID).length > 0 ? true: false;
      dispatch(addToQueue({song:song[0], queue:queue.selected, play:true, playAgain:queueHasSong}));
    }
  }

   const playAllSongs = () => {
      dispatch(playAll({songs: uid ? playlistSongs : songs, queue: queue.selected}));
   }



   const mobileSelectSong = (song) => {
      setMobileSelectedSong(song);
   }


   const renderPlaylist = () =>{
    const allPlaylists = playlists;
    const currentPlaylistID = currentPlaylist;
    const lodedPlaylist = allPlaylists && currentPlaylistID ? allPlaylists[currentPlaylistID] : {};
    //console.log('FROM PLAYLIST ---------->',lodedPlaylist, allPlaylists, currentPlaylistID);
    const {title='Untitled Playlist', category='', status=''} =  lodedPlaylist ? lodedPlaylist : {};
    const selectedCat = category ? playlistCategories.filter((plist)=>  plist['id'] === category  ) :[{id:'mixed',name:'Mixed'}];

   //  let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
   //  if(playlistsMin && !publicPlaylist && playlistsMin.length > 0){ queuePlaylists = [...queuePlaylists, ...playlistsMin]  }

    const currentPlaylistWithLike = userPlaylistsMin.filter(plst => plst.id === currentPlaylistID);
    const currentPlaylistLikes = currentPlaylistWithLike && currentPlaylistWithLike[0] && currentPlaylistWithLike[0].likes ? currentPlaylistWithLike[0].likes : 0;
    const currentUsersLikedPlaylists = userProfile?.liked;
    let userAlreadyLiked = false;
    if(currentUsersLikedPlaylists){
      Object.keys(currentUsersLikedPlaylists).forEach((key)=> {
        if(key === currentPlaylist){ userAlreadyLiked = true }
      });
    }
    

    return(
      <div className="Playlist" ref={playlistElement}>

         {userSettings.mobile && mobileSelectedSong && 
            <PlaylistMobileActions 
            uid={uid}
            queue={queue}
            playlistsMin={playlistsMin}
            publicPlaylist={publicPlaylist}
            authorID={authorID}
            songs={songs}
            reorderingSongs={reorderingSongs}
            reordering={reordering}
            mobileSelectedSong={mobileSelectedSong}
            currentPlaylist={currentPlaylist}
            setMobileSelectedSong={setMobileSelectedSong}
            setReorderingSongs={setReorderingSongs}
            setReordering={setReordering}
            />
        }

        {!publicPlaylist && userSettings.mobile && uid && <div className="playlists__Button" onClick={()=> dispatch(showAddtoPlaylist())}><button><span>+</span></button></div>}

        {publicPlaylist !== true &&
            <div className="playlist__header">
              <div className="playlist__header__left">
                {userSettings.mobile && uid && 
                  <button className={'playlistSelectorButton'} onClick={()=> setShowPlaylistSelector(!showPlaylistSelector)}>
                    {showPlaylistSelector ? <Icon icon="ios-arrow-up" fontSize="18px" color="#ffffff"/> : <Icon icon="ios-arrow-down" fontSize="18px" color="#ffffff"/>}
                  </button>
                }
                <div className="playlist__title" onClick={()=> userSettings.mobile && setShowPlaylistSelector(!showPlaylistSelector)}>
                  {status === 'public' ? <Icon icon="md-globe" fontSize="18px" color="#ffffff"/> : <Icon icon="md-lock" fontSize="18px" color="#ffffff"/> }
                  {title} <span>{songs ? songs.length : 0}</span>
                </div>
                
                {currentPlaylistID !== 'temp' && !userIsAuthor &&
                  <div className="playlist__category">
                    {selectedCat[0].name}
                  </div>
                }

                {currentPlaylistID !== 'temp' && !userIsAuthor &&
                  <PlaylistLike 
                  likes={currentPlaylistLikes} 
                  userIsAuthor={userIsAuthor} 
                  showLikers={()=> setShowLikers(true)} 
                  likePlaylist={()=> likePlaylist(currentPlaylistLikes)}
                  userAlreadyLiked={userAlreadyLiked}
                  />
                }
                <div id="playlist_playAll_button" className="playAll" data-tooltip-content="Play All">
                  <button aria-label="Play All" onClick={playAllSongs}>
                    <Icon icon="md-play" fontSize="14px" color="#ffffff"/>
                  </button>
                </div>

              </div>


              { userIsAuthor &&
                <div className="playlist__header__right">
                  <div id="playlist_add_button" className="playlist__add" data-tooltip-content="Add Song">
                    <button onClick={()=> dispatch(showAddSongModal())}><Icon icon="md-add" fontSize="24px" color="#ffffff"/></button>
                  </div>
                    {currentPlaylistID !== 'temp' &&
                      <PlaylistLike 
                      likes={currentPlaylistLikes} 
                      userIsAuthor={userIsAuthor} 
                      showLikers={()=> setShowLikers(true)} 
                      likePlaylist={()=> likePlaylist(currentPlaylistLikes)}
                      userAlreadyLiked={userAlreadyLiked}
                      />
                    }
                    {
                      <div className={`playlist__edit ${showEditMenu ? 'active': ''}`} onClick={()=> setShowEditMenu(!showEditMenu)}>
                        <div className="playlist__edit__icon"><Icon icon="ios-more" fontSize="22px" color="#ffffff"/></div>
                        <ul>
                          <li onClick={()=> setShowEditModal(true)}>Edit Playlist</li>
                          <li onClick={()=> setShowShareModal(true)}>Share Playlist</li>
                          <li onClick={()=> setShowRemoveModal(true)}>Delete Playlist</li>
                        </ul>
                      </div>
                    }

                </div>
              }
              {userIsAuthor &&
                <div className="playlist__category playlist__category--owner">
                  {selectedCat[0].name}
                </div>
              }
            </div>
        }

        {userSettings.mobile && uid && showPlaylistSelector &&
            <ul className="playlistSelector">
              {
                playlistsMin && playlistsMin.map((playlist, index) => {
                return (
                    <li 
                    key={playlist.id}
                    className={currentPlaylistID === playlist.id ? 'active-playlist-item' : ''}
                    onClick={ ()=>{ dispatch(selectPlaylist({playlistID: playlist.id})); setShowPlaylistSelector(false); }}>
                        <>{playlist.title}<span>{playlist.count? playlist.count : 0}</span></>
                    </li>
                    )
                })
              }
            </ul>
          }

        <div className="playlist_songs">

              
              {userIsAuthor && songs && songs.length > 0 ?
                <div>
                  <SongWrapper 
                  selectedSong={userSettings.selectedSong}
                  selectSong={selectSong}
                  isMobile={userSettings.mobile}
                  mobileSelectedSong={mobileSelectedSong}
                  mobileSelectSong={mobileSelectSong}
                  pressDelay={150} 
                  songs={reordering ? reorderingSongs : songs} 
                  userIsAuthor={userIsAuthor && userIsAuthor}
                  playlistsMin={userPlaylistsMin}
                  playSong={playSong}
                  draggingSong={draggingSong}
                  playlistID={currentPlaylist}
                  activeSong={null}
                  uid={uid}
                  userActions={userSettings.actions}
                  />
                
                </div>
              :
                userIsAuthor && uid && <p className="nosongs">Please add a song to this playlist by clicking the " + " icon above.</p>
              }

              { !userIsAuthor && uid && songs && songs.length > 0 ?
                  <div>
                  <SongWrapper 
                  selectedSong={userSettings.selectedSong}
                  selectSong={selectSong}
                  isMobile={userSettings.mobile}
                  mobileSelectedSong={mobileSelectedSong}
                  mobileSelectSong={mobileSelectSong}
                  pressDelay={150} 
                  songs={reordering ? reorderingSongs : songs} 
                  userIsAuthor={userIsAuthor && userIsAuthor}
                  playlistsMin={userPlaylistsMin}
                  playSong={playSong}
                  draggingSong={draggingSong}
                  playlistID={currentPlaylist}
                  activeSong={null}
                  uid={uid}
                  userActions={userSettings.actions}
                  />
                
                </div>
              :
                !userIsAuthor && uid && <p className="nosongs">No songs in this playlist.</p>
              }

              {/* PUBLIC PLAYLIST */}
              { !uid && !userIsAuthor && songs && songs.length > 0 &&
                  <div>
                  <SongWrapper 
                  selectedSong={userSettings.selectedSong}
                  selectSong={selectSong}
                  isMobile={userSettings.mobile}
                  mobileSelectedSong={mobileSelectedSong}
                  mobileSelectSong={mobileSelectSong}
                  pressDelay={150} 
                  songs={reordering ? reorderingSongs :songs}
                  userIsAuthor={userIsAuthor && userIsAuthor}
                  playlistsMin={userPlaylistsMin}
                  playSong={playSong}
                  draggingSong={draggingSong}
                  playlistID={currentPlaylist}
                  activeSong={null}
                  uid={uid}
                  guestReorder={guestReorder}
                  />
                
                </div>
              }

        </div>

        { userIsAuthor &&
          <div className="playlistActionModals">
            <PlaylistEdit 
            width={userSettings.mobile ? 290 : 380}
            show={showEditModal} 
            onClose={()=> setShowEditModal(false)}
            playlist={playlists ? playlists[currentPlaylist] : {}} 
            playlistsMin={playlistsMin}
            playlistID={currentPlaylist}
            />
            <PlaylistDelete
            show={showRemoveModal} 
            onClose={()=> setShowRemoveModal(false)}
            playlist={playlists ? playlists[currentPlaylist] : {}} 
            playlistID={currentPlaylist}
            playlistsMin={playlistsMin}
            />
            <PlaylistShare
            show={showShareModal} 
            onClose={()=> setShowShareModal(false)}
            playlist={playlists ? playlists[currentPlaylist] : {}} 
            uid={uid}
            author={author}
            playlistID={currentPlaylist}
            />
          </div>
        }

        {userIsAuthor && showLikers &&
          <PlayListLikers 
          playlistID={currentPlaylist} 
          uid={uid} 
          show={showLikers}
          onClose={()=> setShowLikers(false)}
           />
        }

        <Tooltip anchorId="playlist_playAll_button" />
        <Tooltip anchorId="playlist_add_button" />
      </div>
    );
  }


      // return !userProfile ? <PlaylistSkeleton header={publicPlaylist ? false:true} /> : renderPlaylist();
      return renderPlaylist();
      //return <PlaylistSkeleton header={true} />

}


export default PlayList;