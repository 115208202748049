import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { findDOMNode } from 'react-dom';
import axios from 'axios';
import isEqual from 'lodash.isequal';
import ReactPlayer from 'react-player';
// import screenfull from 'screenfull';
import { useDispatch, useSelector } from 'react-redux';
import Duration from './Duration';
import { base64toBlob, youtube_regex, YT_SEARCH_URL } from '../../helpers';
// import { selectSong } from '../../redux/playlistSlice';
import { addToQueue } from '../../redux/queueSlice';
import { playerVolume, repeatPlayer } from '../../redux/playerSlice';
import { changeVideoMode } from '../../redux/userSlice';
// import { lastfmKey } from '../../config';
import albumCover from '../../assets/cover.png';
import usePrevious from '../../hooks/usePrevious';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Icon from '../Icon/Icon';


const Player = () => {
   const player = useSelector((state) => state.player);
   const userSettings = useSelector((state) => state.user);
   const playingQueueSongId = useSelector((state) => state.queue?.playing?.songId || '');
   const playingQueue = useSelector((state) => state.queue?.playing?.queue || 'queueOne');
   const currentQSongs = useSelector((state) => state.queue[playingQueue] || []);
   const previousSongs = usePrevious(currentQSongs);
   const previousSongId = usePrevious(playingQueueSongId);
   const isMobilePrev = usePrevious(userSettings.mobile);

   const dispatch = useDispatch();
   const { pathname } = useLocation();
   const playerRef = useRef(null);

   const [url, setUrl] =  useState(null);
   const [songs, setSongs] = useState([]);
   const [songsYoutube, setSongsYoutube] = useState([]); //For localsongs that do not have youtube value
   const [currentSong, setCurrentSong] = useState(null);
   // const [playlist, setPlaylist] =  useState('');
   const [playing, setPlaying] =  useState(false);
   const [volume, setVolume] =  useState(player && player.volume ? player.volume :  0.8);
   const [muted, setMuted] =  useState(false);
   const [seeking, setSeeking] = useState(false);
   const [channel, setChannel] = useState(false);
   const [played, setPlayed] =  useState(0);
   // const [playedSeconds, setPlayedSeconds] =  useState(0);
   const [loaded, setLoaded] =  useState(0);
   // const [loadedSeconds, setLoadedSeconds] =  useState(0);
   const [duration, setDuration] =  useState(0);
   const [loop, setLoop] =  useState(player && player.loop ? player.loop : false);
   const [index, setIndex] = useState(0);
   const [start, setStart] = useState(0);
   const [end, setEnd] = useState(0);
   const [hideVideo, setHideVideo] =  useState(false);
   const [shuffle, setShuffle] =  useState(false);
   // const [playDirect, setPlayDirect] =   useState(player && player.playDirect ? player.playDirect : false);

   const searchYoutube = useCallback((local=true) => {
      const searchString = `${currentSong.artist ? currentSong.artist.replace('/', ' ')+' - ' : '' } ${currentSong.title} `;
      axios.get(YT_SEARCH_URL+searchString)
      .then((response) => {
          //console.log(response.data.results);
          if(response.data.results.length > 0){
               const newSong = response.data.results[0];
               const parsed = newSong.video.url.match(youtube_regex);
               const videoID = parsed && parsed[2] ? parsed[2]:null;
               //var duration = durationRaw.split(':').reduce((acc,time) => (60 * acc) + +time);
               setUrl('https://www.youtube.com/watch?v='+videoID);
               setCurrentSong({...currentSong, youtube: videoID, alternateSrc: local ? null : true });
               setPlaying(true);
               setSongsYoutube(songsYoutube);
          }

      })
      .catch(function (error) {
        setPlaying(false);
      });
   }, [currentSong, songsYoutube])

   const onError = useCallback((e)=> {
      console.log('onError', e);
      searchYoutube(false);
   }, [searchYoutube])

   const onPlay = useCallback(() => {
      console.log('onPlay');      
      //IF ELECTRON
      if(window.isElectron){
            //IF PRO USER && SET PLAYER TO AUDIO ONLY
            // if(playDirect){
            //   if(currentSong && currentSong.youtube && !currentSong.local && !currentSong.youtubeDirect) {
            //     setPlaying(false);
            //     window.sendToElectron('fetchURL', currentSong.youtube);
            //     window.ipcRenderer.once('sendURL', (event, audioData)=> {
            //       console.log('######FETECHED URL', audioData);
            //       if(audioData.url && audioData.url !== 'error'){
            //         const url = audioData.url;
            //         setUrl(url);
            //         setPlaying(true);
            //         setCurrentSong({...currentSong, youtubeDirect: true});
            //       }else{
            //       //   setPlaying(false);
            //       //   setCurrentSong({...currentSong, error: true} );
            //         onError();
            //       }
        
            //     });
            //   }
            // }else 
            if(currentSong.youtube && !currentSong.local){
              setUrl('https://www.youtube.com/watch?v='+currentSong.youtube);
              setPlaying(true);
            }

            //IF IS LOCAL SONG
            if(currentSong.local && !currentSong.localDirect){
              window.sendToElectron('fetchSongURL', currentSong.local);
              window.ipcRenderer.once('sendSongURL', (event, audioData)=> {
                console.log('######FETECHED SONG URL', audioData);
                const url = audioData.url;
                const blob = base64toBlob(url) ;
                const objectURL = URL.createObjectURL(blob);
                console.log(objectURL);
                setUrl(objectURL);
                setPlaying(true);
                setCurrentSong({...currentSong, localDirect: true});
              });
            }

      }else{
        //console.log('Playing on non Electron Device', url);
        //IF IS NOT ELECTRON AND IS LOCAL SONG
        if(currentSong && currentSong.local){

          //IF song Already has the youtube link play that
          if(currentSong.youtube) {
            setUrl('https://www.youtube.com/watch?v='+currentSong.youtube);
            setPlaying(true);
            return document.title =`${currentSong && currentSong.artist ? currentSong.artist+' - ' : ''}${currentSong && currentSong.title && currentSong.title}`
          }

            //IF Already fetched the youtube link , play that
            const hasYoutube = songsYoutube.find( x => x.id === currentSong.id);
            
            if(hasYoutube){
              setUrl('https://www.youtube.com/watch?v='+hasYoutube.youtube);
              setPlaying(true);
              return document.title =`${currentSong && currentSong.artist ? currentSong.artist+' - ' : ''}${currentSong && currentSong.title && currentSong.title}`
            }
          
          //ELSE, fetch the youtube link
          searchYoutube();

        }else{
         //  console.log('Playing on non Electron Device nonLocal Song', currentSong);
          setPlaying(true);
          document.title =`${currentSong && currentSong.artist ? currentSong.artist+' - ' : ''}${currentSong && currentSong.title && currentSong.title}`
        }

      }
      
   }, [currentSong, searchYoutube, songsYoutube])

   const setSong = useCallback((filteredSong, play) => {
      console.log('############setSong', filteredSong, play);
      let url = filteredSong.youtube && 'https://www.youtube.com/watch?v='+filteredSong.youtube;
      setCurrentSong({...filteredSong });
      setUrl(url);
      setDuration(filteredSong.duration ? filteredSong.duration : 200);
      setStart(filteredSong.start ? filteredSong.start : 0);
      setEnd(filteredSong.end ? filteredSong.end : filteredSong.duration);
      setIndex(filteredSong.initial ? 0 : songs.findIndex(x => x.id && x.id === filteredSong.id));
      onPlay();
   }, [onPlay, songs]);

   useEffect(()=> {
      if((currentQSongs && playingQueueSongId && !isEqual(currentQSongs , previousSongs)) || !isEqual(playingQueueSongId, previousSongId)){
        console.log('Song Changed!', currentQSongs);

        //const newSong = currentQSongs.filter((song)=> currentSong && currentSong.id !== playingQueueSongId ? song : '');
        const newSong = previousSongs && currentQSongs ? currentQSongs.filter((obj) => previousSongs.indexOf(obj) === -1 ) : [];
        let play = player && player.playing && (playingQueueSongId !== newSong.id) ?  true : false;
        
         if(currentSong){
            //If Song is Reordered in Queue, change the current songs index as its used to mesaure the next track
            setIndex(currentQSongs.findIndex(x => x.id === currentSong.id));
            //If Current playing songs inline Repeat Button is clicked from the Queue  and changed the current Songs Repeat State
               if(newSong[0] && newSong[0].id && newSong[0].id === currentSong.id ){
                  const repeat = newSong[0].repeat ? newSong[0].repeat : 0;
                  console.log('SET REPEAT!!', repeat);
                  setLoop(repeat > 0 ? true : false);
                  setCurrentSong({...currentSong, repeat: repeat });
               }
         }
         // console.log(songs, currentQSongs, !isEqual(songs, currentQSongs) || (previousSongId !== playingQueueSongId));
         if(!isEqual(songs, currentQSongs) || (previousSongId !== playingQueueSongId)){
            setSongs(currentQSongs);
            const theSong = currentQSongs.filter((song)=> song.id === playingQueueSongId ? 'https://www.youtube.com/watch?v='+song.youtube:'');
            console.log(currentQSongs, playingQueueSongId, theSong);
            if(theSong.length > 0){
              if(theSong[0].youtube || theSong[0].local){
                if(!currentSong || (currentSong && theSong[0].id !== currentSong.id)){
                  setSong(theSong[0], play );
                }
              }
            }
         }
      }
   }, [pathname, player, songs, setSong, currentQSongs, playingQueueSongId, currentSong, previousSongs, previousSongId, userSettings]);

   useEffect(()=> {
      if((isMobilePrev !== userSettings.mobile) && userSettings.mobile){
         setHideVideo(true);
      }
   }, [isMobilePrev, userSettings.mobile]);

   useEffect(()=> {
      const resetPlayer = () => {
         setPlaying(false);
         setChannel(true);
         setPlayed(0);
      }
      if(pathname && (pathname.includes("/channel/") || pathname.includes("/station/"))){
         console.log('######Channel or Station Detedted!!!! Pausing Player');
         resetPlayer();
         document.title =`Yhimsical`; 
       }else{
         setChannel(false);
       }
      //  console.log('pathname:', pathname);
   }, [pathname]);

   useEffect(()=> {
      const onSpacebarPress = (event)=> {
         if(document.activeElement && (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA')){ return; }
         const key = event.keyCode || event.charCode || 0;
         console.log('Key Pressed: ', key);
         if(key === 32){
            setPlaying((currentPlayingStatus)=> !currentPlayingStatus );
         }
      }
      document.addEventListener('keydown', onSpacebarPress);
      return ()=> document.removeEventListener('keydown', onSpacebarPress );
   }, [])

   const playPause = () => {
      setPlaying(!playing);
   }

   const toggleLoop = () => {
         setLoop(!loop);
         dispatch(repeatPlayer( loop ));
   }
   const setPlayerVolume = e => {
        const vol = parseFloat(e.target.value);
        setVolume(vol);
        dispatch(playerVolume(vol));
   }

   const onPause = () => {
        //console.log('onPause', playing)
        //IF IN MOBILE, When the screen is locked, the music stops. This plays the audio after a second
          if(playing &&userSettings.mobile){
            setPlaying(false);
            setTimeout(() => {
               setPlaying(true);
            }, 200);
          }
          if(!userSettings.mobile){
             setPlaying(false);
          }

   }

   const onSeekMouseDown = e => {
         setSeeking(true);
   }
      
   const onSeekChange = e => {
        //console.log('onSeekChange: ', e.target.value);
        const seekVal = parseFloat(e.target.value);
        if(userSettings.mobile){
            setSeeking(true);
            setPlayed(seekVal);
            setTimeout(() => {
               setSeeking(false);
               playerRef.current.seekTo(seekVal);
            }, 1000);
        }else{
            setPlayed(seekVal);
        }
    }

   const onSeekMouseUp = e => {
        setSeeking(false);
        playerRef.current.seekTo(parseFloat(e.target.value))
      }

   const onEnded = () => {
        console.log('onEnded')
        setPlaying(loop);
   }

   const onDuration = (duration) => {
        setDuration(duration);
   }

   //  const onClickFullscreen = () => {
   //      screenfull.request(findDOMNode(playerRef))
   // }

    const onProgress = state => {
         // console.log('onProgress', state);
         //IF Time is edited to start from a certain time, apply the time at the start of the song play
         if(!seeking  && start > state.playedSeconds){
            playerRef.current.seekTo(start);
         }
        
         if (!seeking) {
            // console.log(end - 2 , state.playedSeconds, end - 2 <= state.playedSeconds );
            setPlayed(state.played); 
            setLoaded(state.loaded);
            if(end - 2 <= state.playedSeconds  || (currentSong && currentSong.alternateSrc && (duration -2 <= state.playedSeconds )) ){
               if(currentSong && currentSong.repeat && parseInt(currentSong.repeat, 10) > 0){
                     //Track Has Repeat, keep repeating the song until the reapt count is 0 
                     setCurrentSong({...currentSong, repeat: parseInt(currentSong.repeat, 10) });
                     playerRef.current.seekTo(start);
               }else{
                  //Doesnt Have Repeat, Move on to the Next Track
                  console.log('nextIndex Follow');
                  
                  if((index === songs.length -1) && loop === true){
                     console.log('Player Has Repeat On, repeat the Queue from start');
                     dispatch(addToQueue({song: songs[0], queue: playingQueue}));
                  }else{
                     console.log('Song Ended!');
                     const nextIndex = shuffle? Math.floor(Math.random() * Math.floor(songs.length -1)) : index + 1;
                     if(songs[nextIndex]){
                     console.log('Play Next Track');
                     dispatch(addToQueue({song: songs[nextIndex], queue: playingQueue, play: true}));
                     }
                  }

               }
            }
         }
      }
    const nextTrack = () => {
        if(index < songs.length -1){
          dispatch(addToQueue({song: songs[index + 1], queue: playingQueue, play: true}));
        }else if((index === songs.length -1) && loop === true){
          dispatch(addToQueue({song: songs[0], queue: playingQueue}));
        }
      }

    const prevTrack = () => {
        if(index !== 0){
          dispatch(addToQueue({song: songs[index - 1], queue: playingQueue, play: true}));
        }else if((index === 0) && loop === true){
          dispatch(addToQueue({song: songs[0], queue: playingQueue, play: true}));
        }
      }

    const changeTheVideoMode = () => {
         const mode = userSettings.videoMode;
         let newMode = mode === 'left' ? 'full' : 'left';
         dispatch(changeVideoMode(newMode));
      }

   //  const { url, playing, muted, loop, volume, played, loaded, duration } = this.state;
    const { title, artist, album } = currentSong ? currentSong : {};

    const songCover = () => {
      let coverImage = currentSong.youtube ? `https://i.ytimg.com/vi/${currentSong.youtube}/default.jpg` : '';
      if(currentSong.cover){
         coverImage = currentSong.cover.includes('http') ? currentSong.cover : 'https://lastfm.freetls.fastly.net/i/u/300x300/'+currentSong.cover;
      }
      return coverImage;
    }

    return(
      <div id="player">
        <div className={`player__video__wrap player__video__wrap-${userSettings.videoMode}  ${(hideVideo || channel) ? 'player__video-hide':''}`}>

          <div className={`player__video`}>
              <div className="player__mode_switch" onClick={changeTheVideoMode}>
                  {userSettings && userSettings.videoMode === 'left' ?  
                     <Icon icon="md-resize" fontSize="16px" color="#ffffff"/> : <Icon icon="ios-arrow-round-down" fontSize="20px" color="#ffffff"/> 
                  }
              </div>
              <ReactPlayer 
                  ref={playerRef}
                  url={url}
                  playing={playing}
                  loop={loop}
                  className='react-player'
                  config={{ youtube: { playerVars: { rel: 0 } },  file: {  attributes: { poster: currentSong && currentSong.youtube && `https://i.ytimg.com/vi/${currentSong.youtube}/hqdefault.jpg`  } }  }}
                  volume={volume}
                  muted={muted}
                  onReady={()=> console.log('ReactPlayer Ready!')}
                  onStart={() => console.log('onStart')}
                  onPlay={onPlay}
                  onPause={onPause}
                  onBuffer={() => console.log('onBuffer')}
                  onSeek={e => console.log('onSeek', e)}
                  onEnded={onEnded}
                  onError={onError}
                  onProgress={onProgress}
                  onDuration={onDuration}
                  width='100%'
                  height='100%'
                  rel={0}
              />
              {currentSong && currentSong.alternateSrc && <span className="player__songInfo__alternate"><i>i</i> Alternative Source</span>}
          </div>
          <div className="player__nowplaying">
              {title && !channel &&
                  <div className="player__nowplaying__songInfo">
                      {albumCover && <div className="player__nowplaying__songInfo__album"><img src={albumCover} alt={album && album} /></div>}
                      <div className="player__nowplaying__songInfo__details">
                          <span className="player__songInfo__count">{index + 1}/{songs.length}</span>
                          <span className="player__songInfo__title">
                            {title} 
                            {currentSong.repeat && currentSong.repeat > 0 ? <dl><Icon icon="md-refresh" fontSize="14px" color="#ffffff"/></dl> :''}
                          </span>
                          <span className="player__songInfo__artist">{artist ? artist : 'Unknow Artist'}</span>
                          {album && <span className="player__songInfo__album">{album}</span>}
                          
                      </div>
                  </div>
              }
          </div>
        </div>
        
        <div className="player__wrapper">
            <div className="player_seek">
                <input
                  type='range' min={0} max={1} step='any'
                  aria-label="Seek"
                  value={played}
                  onMouseDown={onSeekMouseDown}
                  onChange={onSeekChange}
                  onMouseUp={onSeekMouseUp}
                /> 
                <progress max={1} value={played} />
                <progress max={1} value={loaded} />
            </div>

            <div className="player_glow"></div>
            <div className="player_line"><span /><span /><span /></div>

            <div className="player__controls">
                {channel && pathname.includes("/channel/") && <div className="player__controls__blocker"><span>Player Controls do not work in Channels</span></div> }
                <div className="player__controls_inner">
                    <div className="player__controls__small">
                        <div id="videoModeButton" className={`player__video__button ${hideVideo ? '':'player__video__button-active'}`} onClick={()=> setHideVideo(!hideVideo)} data-tooltip-content={`${hideVideo ? 'Show Video' :'Hide Video'}`}>
                          <Icon icon="ios-videocam" fontSize="18px" color="#3f3576"/>
                        </div>
                        <div id="shuffleModeButton" className={`player__shuffle ${shuffle ? 'player__shuffle-active':''}`} onClick={()=> setShuffle(!shuffle)} data-tooltip-content="Shuffle">
                        <Icon icon="md-repeat" fontSize="18px" color="#3f3576"/>
                        </div>
                    </div>
                    <div className={`player__repeat ${loop ? 'player__repeat-active': ''}`} onClick={toggleLoop}>
                      <span><Icon icon="md-refresh" fontSize="18px" color="#3f3576"/></span>
                    </div>

                    <div className={`player__prev ${(index !== 0) || loop === true ? 'player__prev-enabled' :'player__prev-disable'}`} onClick={prevTrack}>
                      <span><Icon icon="ios-skip-backward" fontSize="18px" color="rgb(63, 53, 118)"/></span>
                    </div>
                    
                    <div className="player__play" onClick={playPause}>
                        <i className="play_outline"></i>
                        <span>
                            {/* <Icon icon="md-play" fontSize="36px" color="rgb(63, 53, 118)"/> */}
                              <svg className="play-button" width="100%" height="100%" viewBox="0 0 40 40" alt="Play">
                                 <defs>
                                       <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                                          <stop offset="0%" style={{stopColor:"rgb(247,247,247)",stopOpacity:"1"}} />
                                          <stop offset="100%" style={{stopColor:"rgb(167,167,167)",stopOpacity:"1"}} />
                                       </linearGradient>
                                 </defs>
                                 {!playing ?
                                       <path id="play-icon" fill="url(#grad1)" d="M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28" />
                                    :
                                       <path id="pause-icon" fill="url(#grad1)" d="M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26" />
                                 }
                              </svg>

                        </span>
                    </div>
                    <div className={`player__next ${(index < songs.length -1) || loop === true ? 'player__next-enabled' :'player__next-disable'}`} onClick={nextTrack}>
                      <span><Icon icon="ios-skip-forward" fontSize="18px" color="rgb(63, 53, 118)"/></span>
                    </div>
                    <div className="player__volume" onClick={()=> setMuted(!muted)}>
                        <span>{muted ? <Icon icon="ios-volume-mute" fontSize="20px" color="#3f3576"/> : <Icon icon="ios-volume-up" fontSize="20px" color="#3f3576"/> }</span>
                    </div>
                    <div className="player__volume__control">
                        <input type='range' aria-label="Change Volume" min={0} max={1} step='any' value={volume} onChange={setPlayerVolume} />
                    </div>

                </div> 

                <div className="player__duration">
                        <Duration seconds={duration * played} /> / <Duration seconds={duration} />
                </div> 
                {userSettings.mobile && currentSong &&
                    <div className="player__nowplaying__songInfo__mobile">
                      <span className="player__songInfo__count">{index + 1}/{songs.length}</span>
                      <span className="player__songInfo__title">
                        {title} 
                        {currentSong.repeat && currentSong.repeat > 0 ? <dl><Icon icon="md-refresh" fontSize="14px" color="#ffffff"/></dl> :''}
                      </span>
                      <span className="player__songInfo__artist">{artist ? artist : 'Unknow Artist'}</span>
                    </div>
                }

            </div>

            <div className="player__seek">
                <span></span>
            </div>
            <div className="playerBG">{currentSong && <img alt="playerBG" src={songCover()} />}</div>
        </div>
        <Tooltip anchorId="videoModeButton" />
        <Tooltip anchorId="shuffleModeButton" />
      </div>
    );

}

export default Player;