import React from 'react';
import Modal from '../Modal/Modal';
import {publicURL} from '../../config';

class PlaylistShare extends React.PureComponent {

    //PROPS = paylist, uid, playlistID, show, updatePlaylist

    state={
        title:'',
        slug:'',
        author:'',
        show: false 
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.playlist && (nextProps.playlist.title !== (this.props.playlist && this.props.playlist.title))) {
            this.setState({ 
                title: nextProps.playlist.title,
                slug: nextProps.playlist.slug,
            })
        }

        this.setState({ author: nextProps.author })
        
        if (nextProps.show!== this.props.show) {
            this.setState({ show: nextProps.show })
        }

    }

    onClose = () => {
        console.log('Close Clicked');
        this.setState({show: false});
        this.props.onClose();
    }


    render(){
        const itemsWidth = 380;

        return(
            <Modal title={`Share Playlist - ${this.state.title}`} show={this.state.show} maxWidth={itemsWidth} closeModal={this.onClose}>
                <div className="shareBox">
                    <label>Share URL: </label>
                    <input type="text" defaultValue={`${publicURL}user/${this.state.author}/${this.state.slug}/`} readOnly  />   
                </div>
            </Modal>
        );
        
    }
}

export default PlaylistShare;
