import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { sendNotification } from './notifications';

export const acceptFreindShip = (uid, friendID, friendObj, currentUser) =>
  (dispatch, getState, getFirebase) => {
      console.log('FROM ACTIOn -->>', uid, friendID, friendObj);
      const db = firebase.firestore();
      const friendsRootRef = db.collection('Friends');
      delete friendObj.image; delete friendObj.id;

      friendsRootRef.doc(uid).update({[friendID]:{...friendObj, status:'friend'}}).then(()=> {
          console.log('Friend Request Accepted!');
        friendsRootRef.doc(friendID).set({[uid]:{...currentUser, status:'friend'}}).then(()=> {
          console.log('Updated Friends DB!');
        });

        if(uid && friendID && currentUser){
          console.log('friend request sending....');
          dispatch(sendNotification('friend-accepted', uid, friendID, currentUser));
        }

           //SEND NOTIFICATION Letting Requester know that their friend request has been accepted
      });
};

export const cancelFreindShip = (uid, friendID) =>
  (dispatch, getState, getFirebase) => {
      console.log('FROM ACTIOn -->>', uid, friendID);
      const db = firebase.firestore();
      const friendsRootRef = db.collection('Friends');

      friendsRootRef.doc(uid).update({[friendID]: firebase.firestore.FieldValue.delete() }).then(()=> {
        friendsRootRef.doc(friendID).update({[uid]: firebase.firestore.FieldValue.delete() })
      });
};

export const sendFriendRequest = (uid, friendID, friendObj, currentUser) =>
  (dispatch, getState, getFirebase) => {
      console.log('FROM ACTIOn -->>', uid, friendID);
      const db = firebase.firestore();
      const friendsRootRef = db.collection('Friends');

      friendsRootRef.doc(uid).update({[friendID]: {...friendObj, status:'requested'} })
      .then(() => {
          friendsRootRef.doc(friendID).update({[uid]: {...currentUser, status:'pending'} }).then(()=> {})
          .catch((error) => {
            friendsRootRef.doc(friendID).set({[uid]: {...currentUser, status:'pending'} })
          });

          if(uid && friendID && currentUser){
            console.log('friend request sending....');
            dispatch(sendNotification('friend-request', uid, friendID, currentUser));
          }
        
      })
      .catch((error) => {
        friendsRootRef.doc(uid).set({[friendID]: {...friendObj, status:'requested'} })
      });

};

