import React from 'react';
import Icon from '../components/Icon/Icon';

const tourSteps = [
   // {
   //   selector: '.playlists_wrapper',
   //   content: ({ goTo, inDOM }) => (
   //     <div className="tour_content">
   //       <h4>Menu Panel</h4>
   //       <p>You can access your Playlists, Library and Discover new Music from here. This panel will always be visible.</p>
   //     </div>
   //   ),
   // },
   {
       selector: '.playlists__menu__myPlaylist',
       content: ({ goTo, inDOM }) => (
         <div className="tour_content">
           <h4>Your Playlists</h4>
           <p>You can create new Playlist and access your Playlists from this section anytime you want.</p>
         </div>
       ),
   },
   {
       selector: '.playlists__menu__discover',
       content: ({ goTo, inDOM }) => (
         <div className="tour_content">
           <h4>Discover New Songs</h4>
           <p>You can access the Latest and Popular Songs from this section.</p>
         </div>
       ),
   },
   {
       selector: '.playlists__menu__savedPlaylist',
       content: ({ goTo, inDOM }) => (
         <div className="tour_content">
           <h4>Saved Playlist</h4>
           <p>When you like other's playlists, they will saved here for Quick access.</p>
         </div>
       ),
   },
   {
       selector: '.playlists__menu__library',
       content: ({ goTo, inDOM }) => (
         <div className="tour_content">
           <h4>You Library</h4>
           <p>All the Songs in your playlists can be accessed here. You can also add and accesse your Local Songs from here.</p>
         </div>
       ),
   },
   {
       selector: '#searchBar',
       content: ({ goTo, inDOM }) => (
         <div className="tour_content">
           <h4>Searching Songs</h4>
           <p>You can search millions of youtube songs from here. You can search for Songs and Artists. Lets search for "Rihanna Umbrella".</p>
           <p><small>Forward to automatically search for "Rihanna Umbrella"</small></p>
         </div>
       ),
       action: node => {
         console.log('searchBar node: ', node);
         if(document.getElementById('addSong')){ document.getElementById('addSong').style.visibility = "hidden"; }
         node.focus();
       },
   },
   {
       selector: '.searchresults .tracks .track:nth-child(1)',
       content: ({ goTo, inDOM }) => (
         <div className="tour_content">
           <h4>Adding New Song</h4>
           <p>When the songs appear, you can hover over them and click the <Icon icon="ios-play" fontSize="14px" color="#ffffff"/> button to play the song instantly. Or click the + button and then add it to your Queue or a Playlist.</p>
           <p>You can also drag the song to the Queue Panel (on Right) to add it to your Current Queue.</p>
         </div>
       ),
       action: node => {
         if(document.getElementById('addSong')){ document.getElementById('addSong').style.visibility = "visible"; }
         setTimeout(() => {
            if(node)
            node.focus();
            const parentElm = document.getElementById('addSong');
            const firstTrack = parentElm.querySelectorAll('.track')[0];
            firstTrack.classList.add('activeSong');
            if(document.querySelector('.addSong__header')){ 
               let addSongHead = document.querySelector('.addSong__header');
               addSongHead.classList.remove('tour_addSong_methods');
            }
         }, 500);
         
       },
   },
   {
     selector: '.addSong__header ul',
     content: ({ goTo, inDOM }) => (
       <div className="tour_content">
         <h4>More Methods of adding Songs</h4>
         <p><b>Artists: </b>You can also search Artists and all their albums and songs from this Tab.</p>
         <p><b>Url: </b>You can paste youtbe video url to add them to your Queue or Playlists.</p>
         {/* <p><b>Import: </b>Its possible to import public playlists from youtube from this section.</p> */}
       </div>
     ),
     action: node => {
       if(document.querySelector('.addSong__header')){ 
         let addSongHead = document.querySelector('.addSong__header');
         addSongHead.classList.add('tour_addSong_methods');
       }
       node && node.focus();
     },
     style: {
       marginLeft: '150px',
     },
   },
   {
     selector: '.searchButton',
     content: ({ goTo, inDOM }) => (
       <div className="tour_content">
         <h4>The Add Song Screen</h4>
         <p>To open the Add Song screen whenever you want, just click the Search Button icon.</p>
       </div>
     ),
     action: node => {
       if(document.getElementById('addSong')){ document.getElementById('addSong').style.visibility = "hidden"; }
       node.focus();
     }
   },
   {
     selector: '#playlist_mini',
     content: ({ goTo, inDOM }) => (
       <div className="tour_content">
         <h4>The Queue</h4>
         <p>This is where your currently playing songs will be displayed. You can drag and drop songs here from your playlists and the Discover section.</p>
         <p>There are total 3 Queues in Yhimsical, you can add songs to any one of them without messing up the other one.</p>
       </div>
     )
   },
   {
     selector: '.playlist_songs-mini .song:nth-child(1)',
     content: ({ goTo, inDOM }) => (
       <div className="tour_content">
         <p>Each song has Individial repeat <Icon icon="md-refresh" fontSize="14px" color="#fff" /> button, when clicked, the song will keep repeating unless its clicked again.</p>
         <p>You can drag and move the song up/down to change their position in the Queue.</p>
       </div>
     )
   },
   {
     selector: '.player__controls_inner',
     content: ({ goTo, inDOM }) => (
       <div className="tour_content">
         <h4>The Player</h4>
         <p><Icon icon="ios-videocam" fontSize="14px" color="#fff" /> <b>Hide Video:</b> Hides the currently playing video by playing it in background</p>
         <p><Icon icon="md-repeat" fontSize="14px" color="#fff" /> <b>Shuffle:</b> When enabled, the songs in the queue will be played in random order.</p>
         <p><Icon icon="md-refresh" fontSize="14px" color="#fff" /> <b>Repeat Queue:</b> Keeps repeating the current Queue.</p>
         <p><Icon icon="ios-skip-backward" fontSize="14px" color="#fff" /> <b>Previous Song:</b> Plays the previous song.</p>
         <p><Icon icon="ios-play" fontSize="14px" color="#fff" /> <b>Play/Pause:</b> Play/pause button.</p>
         <p><Icon icon="ios-skip-forward" fontSize="14px" color="#fff" /> <b>Next Song:</b> skip to the next song.</p>
         <p><Icon icon="ios-volume-mute" fontSize="14px" color="#fff" /> <b>Volume Bar:</b> Control the volume from here. You can also click the volume icon to mute the player</p>
         {/* <p><Icon icon="ios-flash" fontSize="14px" color="#fff" /> <b>Audio Only:</b> Audio Only mode plays only the audio of youtube videos to save huge amount of Bandwidth.</p> */}
       </div>
     ),
     style: {
       maxwidth: '761px',
       marginleft: '-150px'
     },
   },
   {
     selector: 'body',
     content: ({ goTo, inDOM }) => (
       <div className="tour_content">
         <h4>Thats It!</h4>
         <p>Hope you enjoy Yhimsical.</p>
       </div>
     )
   }
]

export default tourSteps;