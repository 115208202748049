import React from 'react';

const SoundCloudCover = (props) => {
    return <div className={`soundCloudCover ${props.playing ? '' : 'soundCloudCover--off'} ${props.blur ? 'soundCloudCover--blur' : '' }`}>
        <div className="soundCloudCover__inner">
            <div className='soundCloudCover__wave soundCloudCover__wave-one' />
            <div className='soundCloudCover__wave soundCloudCover__wave-two' />
            <div className='soundCloudCover__wave soundCloudCover__wave-three' />
            <div className='soundCloudCover__title'>{props.title}</div>
        </div>
    </div>
}
export default SoundCloudCover;