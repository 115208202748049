import React, { useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import update from 'immutability-helper';
// import { Motion, spring } from 'react-motion';
import { useDispatch } from 'react-redux';
import { reorderPlaylists } from '../../redux/playlistSlice';
import PlayListGroupItem from './PlayListGroupItem';
import { hideAddSongModal } from '../../redux/userSlice';
import { addSong } from '../../redux/songSlice';

const PlayListGroupWrapper = ({playlists:palylistProps=[], dragging, draggingFrom, uid, currentlyPlaying, selectedPlaylist, currentPlaylist }) => {
    const dispatch = useDispatch();
    const [hoveredItem, setHoveredItem] = useState('');
    const [thePlaylists, setThePlaylists] = useState([]);

    useEffect(()=> {
        if(!dragging){ setHoveredItem(''); }
    }, [dragging]);

    useEffect(()=> {
      const sortedPlaylists = [...palylistProps].sort(( a, b )=>  a.order - b.order);
      setThePlaylists(sortedPlaylists);
    }, [palylistProps]);

	const movePlaylist = (dragId, hoverId) => {
        //const dragPlaylist = playlists[dragIndex];
        const dragIndex = thePlaylists.findIndex((el) => el.id === dragId);
        const hoverIndex = thePlaylists.findIndex((el) => el.id === hoverId);
      //   console.log(dragIndex, hoverIndex, dragId, hoverId);

      if( dragIndex === -1 ){  return  }

      const clonedPlaylists = update(thePlaylists, {
         $splice: [
           [dragIndex, 1],
           [hoverIndex, 0, thePlaylists[dragIndex]],
         ],
       })

      //   console.log(thePlaylists, clonedPlaylists);
        const orderdPlaylists = [...clonedPlaylists].map((itm, indx)=> ({...itm, order: indx})).sort(( a, b )=>  a.order - b.order);
        setThePlaylists(orderdPlaylists);
        window.newOrderedPlaylists = orderdPlaylists;

    }
    
   const reorder = () => {
      const newPlaylists = window.newOrderedPlaylists;

      if(newPlaylists && newPlaylists.length > 0 && !isEqual(palylistProps, newPlaylists)){
         dispatch(reorderPlaylists(newPlaylists));
         window.newOrderedPlaylists = {}
      }
   };

   const addTheSong = (playlistID, song) => {
        //const songID = song.id;
        console.log(playlistID, song);
        dispatch(addSong(uid, playlistID, song));
   }

   return(
      <ul>
            {thePlaylists && thePlaylists.length > 0 && thePlaylists.map((playlist, index)=>  
                  //   <Motion key={playlist.id} style={{ y: spring(index * itemHeight, { stiffness: 500, damping: 32 }) }}>
                  //   {({ y }) =>
                        <PlayListGroupItem
                           key={playlist.id}
                           playlist={playlist}
                           currentPlaylist={currentPlaylist}
                           currentlyPlaying={currentlyPlaying}
                           selectedPlaylist={selectedPlaylist}
                           playlistId={playlist.id}
                           playlistOrder={index}
                           className={`playlistsItem ${currentPlaylist === playlist.id ? 'active': ''}`}
                           movePlaylist={movePlaylist}
                           reorder={reorder}
                           style={{ position: 'absolute', transform: 'translate3d(0, ' + (index * 30) + 'px, 0)', }}
                           hovered={hoveredItem === playlist.id}
                           setHoveredItem={(ID)=> setHoveredItem(ID)}
                           resetHoveredItem={()=> setHoveredItem('')}
                           hideActivePlaylist={draggingFrom === 'playlist'}
                           hideAddSongModal={hideAddSongModal}
                           addSong={addTheSong}
                        />
                  //   }
                  // </Motion>
                  )}
      </ul>

   );
}

export default PlayListGroupWrapper;