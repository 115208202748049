import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { getAllSongs, bodyClass, minifyPlaylist } from '../../helpers';
import User from '../User/User';
import { history } from '../../store';
import { useEffect } from 'react';
import Header from '../Header/Header';
import { FirestoreProvider, useFirebaseApp } from 'reactfire';
import { getFirestore } from 'firebase/firestore';
import { useTour } from '@reactour/tour';
import { setAuthListener, setDiscoverListView, setMobileMode, showAddSongModal } from '../../redux/userSlice';
import RightSidebar from '../RightSidebar/RightSidebar';
import Player from '../Player/Player';
import PlayListGroup from '../PlayListGroup/PlayListGroup';
import CreatePlayList from '../PlayList/CreatePlayList';
import AppRoutes from './AppRoutes';
import Fade from '../../utils/animations';
import AddSong from '../AddSong/AddSong';
import SongDragLayer from '../Song/SongDragLayer';
import Library from '../Library/Library';
import WelcomePopup from './WelcomePopup';


const AppNew = () => {
   // const count = useSelector(selectCount);
   const dispatch = useDispatch();
   const { setIsOpen } = useTour();
   const firestoreInstance = getFirestore(useFirebaseApp());
   // const [defaultSongSet, setdefaultSongSet] = useState(false);
   const [createPlayListfromQ, setCreatePlayListfromQ] = useState(false);
   // const [electron, setElectron] = useState(false);
   const [showWelcome, setShowWelcome] = useState(false);
   const [showWelcomeDisabled, setShowWelcomeDisabled] = useState(false);
   const navigate = useNavigate(); 
   const { pathname } = useLocation();
   const queue = useSelector((state) => state.queue);
   const playlistGuest = useSelector((state) => state.playlistGuest);
   const isLoadingUser = useSelector((state) => state.user.loadingUser);
   const userProfile = useSelector((state) => state.user.userData);
   const userSettings = useSelector((state) => state.user);
   const dragging = useSelector((state) => state.user.actions.dragging ? `dragging ${state.user.actions.draggingFrom ? 'dragging_from-'+state.user.actions.draggingFrom : ''}`: '');
   const draggingYhimbo = useSelector((state) => state.user.actions.draggingYhimbo ? 'body--draggingYhimbo': '');
   const electronClass = useSelector((state) => state.user.electron ? ' electron': '');
   const isMobile = useSelector((state) => state.user.mobile ? ' mobile': '');
   const activeMobiletab = userSettings.mobile ? 'mobile-tab-'+userSettings.mobileTab: '';
   const stationActive = useSelector((state) => state.user.station ? 'station-active' : '');
   const userplaylists = useSelector((state) => state.userplaylists);

   useEffect(()=> {
      console.log('AppNew Started!!!!');
      dispatch(setAuthListener());
      if(window.screen.width <990){
         dispatch(setMobileMode());
      }
   }, [dispatch]);

   useEffect(()=> {
      if(userSettings.showUsernameSetup && document?.location?.pathname !== "/complete-signup"){
         console.log('Navigate to Complete Signup Process!!');
         navigate('/complete-signup');
      }
   }, [userSettings.showUsernameSetup, dispatch, navigate])

   useEffect(()=> {
      const listView = localStorage.getItem('discoverListView');
      if(listView === 'true'){ dispatch(setDiscoverListView(true)); }
      if(window.screen.width <990){
         if(listView !== 'false'){
            dispatch(setDiscoverListView(true));
         }
      }
   }, [dispatch]);

   useEffect(()=> {
      if(!userProfile && userSettings.loadingUser === false && !showWelcomeDisabled ){
         const welcomeTourDone = JSON.parse(localStorage.getItem('welcomeTourDone'))
         if(!welcomeTourDone){
            setShowWelcome(true);
         }
      }
   }, [userProfile, userSettings.loadingUser, showWelcomeDisabled])


   return(
      <FirestoreProvider sdk={firestoreInstance}>
         <div className={`${bodyClass(pathname)} ${dragging} ${electronClass} ${stationActive} ${isMobile} ${activeMobiletab} ${draggingYhimbo}`} >
            <Header 
            userSettings={userSettings} 
            profile={userProfile || {}}
            loadingUser={isLoadingUser}
            playlists={userplaylists} 
            />
            <PlayListGroup 
            playlistsMin={userplaylists?.userPlaylistsMin || []}
            userplaylists={userplaylists}
            playlistGuestMin={!userProfile && minifyPlaylist( playlistGuest.playlists )}
            currentUserID={userProfile && userProfile.id} 
            authorID={userProfile && userProfile.id}
            savedPlaylists={userProfile && userProfile.liked ? Object.keys(userProfile.liked).map((key)=> userProfile.liked[key]) : []}
            userSettings={userSettings}
            />
            <main>
               <AppRoutes />
               <User 
               profile={userProfile || {}} 
               showCompleteSignup={userSettings.showUsernameSetup}
               loadingUser={isLoadingUser}
               firebase={firebase} 
               history={history} 
               visible={userSettings.showUserArea || userSettings.showUsernameSetup } 
               auth={firebase.auth()} 
               userSettings={userSettings} 
               />
               <Player 
               auth={firebase.auth()} 
               playlist={userSettings.currentPlaylist} 
               push={history.push} 
               profile={userProfile} 
               /> 
               <RightSidebar
               onCreatePlaylist={()=> setCreatePlayListfromQ(true)}
               history={history}
               userSettings={userSettings}
               queue={queue}
               playlists={userplaylists}
               profile={userProfile}
               authorID={userProfile && userProfile.id}
               author={userProfile && userProfile.username ? userProfile.username : ''}
               />
               <Fade in={userSettings.showAddModal}>
                  {userSettings.showAddModal && 
                     <AddSong uid={userProfile && userProfile.id} playlists={userplaylists} /> 
                  }
               </Fade>
               <CreatePlayList 
               uid={userProfile && userProfile.id} 
               playlists={userplaylists?.userPlaylists} 
               userSettings={userSettings}
               currentQueue={queue[queue.selected]}
               currentQueueName={queue.selected}
               createPlayListfromQ={createPlayListfromQ} 
               clearPlayListfromQ={()=> setCreatePlayListfromQ(false)} 
               />
               <Fade in={userSettings.showLibModal}>
                  {userSettings.showLibModal && 
                     <Library 
                     queue={queue}
                     userplaylistsMin={userplaylists?.userPlaylistsMin || []}
                     playlists={userplaylists?.userPlaylists || {}}
                     guestPlaylists={playlistGuest.playlists}
                     currentPlaylist={userplaylists.selectedPlaylist && userplaylists.selectedPlaylist}
                     uid={userProfile && userProfile.id}
                     pro={false}
                     // addSong={addSong}
                     // guestAdd={guestAdd}
                     // updateSong={updateSong}
                     // hideLibraryModal={hideLibraryModal}
                     // addSationSong={addSationSong}
                     songs={getAllSongs(userplaylists?.userPlaylists || {})}
                     search={userSettings.addSongModalVars}
                     clear={userSettings.actions.songAdded ? false : true}
                     userSettings={userSettings}
                     station={userSettings.station}
                     />
                  }
               </Fade>
            </main>

            {showWelcome && 
               <WelcomePopup 
               isMobile={userSettings.mobile} 
               userLoggedIn={userProfile?.id} 
               closePopup={()=> { setShowWelcome(false); setShowWelcomeDisabled(true); }} 
               showTour={()=> { 
                  dispatch(showAddSongModal({tab: 'search', string: 'Rihanna Umbrella' }))
                  setTimeout(() => {
                    if(document.getElementById('addSong')){ document.getElementById('addSong').style.visibility = "hidden"; }
                  }, 100);
                  setIsOpen(true);
               }} 
               />
            }

            <div className="siteBG">
               <div className="siteBG_color"/>
               <div className="siteBG_fade"/>
            </div>
            <ReactTooltip effect="solid"/>
            {!userSettings.mobile && <SongDragLayer />}
         </div>
      </FirestoreProvider>
   );
}

export default AppNew;