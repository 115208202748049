import React from 'react';
import Icon from '../Icon/Icon';

class Modal extends React.Component {

    state={
      show:false,
    }

    render(){

        const { title, backdrop=true, show=false, children, size="medium", maxWidth=600, headless=false, customClass=''} = this.props;

        return(
            <div className={`Modal ${customClass} ${show ? 'Modal-show' : ''} ${backdrop ? '' : 'Modal-nobackdrop'}  Modal-${size}`}>
                {children && show &&
                    <div className="Modal__inner" style={{maxWidth:maxWidth}}>
                        <div className={`Modal__header ${headless ? 'Modal__header--none': ''}`}>
                            <div className="Modal__header__title">
                                {title}
                            </div>
                            <div className="Modal__heade__close" onClick={this.props.closeModal}>
                                <Icon icon="md-close" fontSize="24px" color="#ffffff"/>
                            </div>
                        </div>
                        <div className="Modal__content">
                            {children}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Modal;