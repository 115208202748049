import React, { useEffect, useRef, useState } from 'react';
// import isEqual from 'lodash.isequal';
import update from 'immutability-helper';
import StationSong from './StationSong';

const getNodeClientBounds = node => {
    const el = node.nodeType === 1 ? node : node.parentElement;
    if (!el) {
      return null;
    }
    return el.getBoundingClientRect();
};

const StationSongWrapper = (props) => {
   const { isMobile,  mobileSelectedSong, mobileSelectSong, queue,  songs=[], playlistsMin, playSong, draggingSong} = props;
      const {  pauseSong, currentSong,  addSong, removeSationSong, likeStationSong, isOwner, currentUser, songPlaying, reorderSongs} = props;
    const container = useRef(null);
    const reorederdSongs = useRef([]);
    const [selectedSongs, setSelectedSongs] = useState([]);
    const [theSongs, setTheSongs] = useState(songs);
   //  const [height, setHeight] = useState(400);


    useEffect(()=> {
      const sortedSongs = [...songs].sort(( a, b )=>  a.order - b.order);
      setTheSongs(sortedSongs);
    }, [songs]);

    
   const onSongDragStart = (dragItem) => {
      if(isMobile){ return }
      if(!isOwner){ return }
      console.log(dragItem);
      const bounds = {};
      Array.from(container.current.childNodes).forEach((child, i) => {
          bounds[i] = getNodeClientBounds(child);
      });
      //   const updatedSongs = [...theSongs].map((song, index)=> ({...song, bounds: bounds[index] || {} }));
      //   console.warn('updatedSongs: ', theSongs, updatedSongs, songs);
      // setTheSongs(updatedSongs);
      setSelectedSongs(dragItem.songs);
      // setSelectedSongsIds(dragItem.songs.map(c => c.id));
 }


   const moveSong = (dragId, hoverId, item) => {
       if(isMobile){ return }
       if(!isOwner){ return }
       const dragIndex = theSongs.findIndex((el) => el.id === dragId);
       const hoverIndex = theSongs.findIndex((el) => el.id === hoverId);
     
       if( dragIndex === -1 ){  return console.log(hoverId)  }

       const updatedSongs = update(theSongs, {
          $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, theSongs[dragIndex]],
          ],
       });

       setTheSongs(updatedSongs);
       reorederdSongs.current = updatedSongs;
   }

   const reorder = (queue) => {
      if(isMobile){ return }
      if(!isOwner){ return }
      // console.log(queue, reorederdSongs.current);
      if(reorederdSongs.current && reorederdSongs.current.length > 0){
         const orderedSongs = [...reorederdSongs.current].map((song, index)=> { console.log(song); return {...song, order: index} });
         setTheSongs(orderedSongs);
         reorederdSongs.current = [];

         let updateSongs = {};
         const clonedSongs = [...orderedSongs];
         clonedSongs.map( (song)=> {
               const songID = song.id; 
            //   delete song.id; 
               delete song.bounds; 
               return updateSongs[songID] = song;
         });
         reorderSongs(updateSongs);
      }
   }

   return(
      <div className="songWrapper customScrollbar" ref={container}>
               {theSongs && theSongs.length > 0 && theSongs.map((song, index)=> {
                  return <StationSong 
                           key={theSongs[index].id}
                           index={index}
                           order={index}
                           songID={theSongs[index].id} 
                           song={theSongs[index]} 
                           songPlaying={songPlaying}
                           playlistsMin={playlistsMin}
                           queue={queue}
                           playSong={playSong}
                           pauseSong={pauseSong}
                           activeSong={currentSong === theSongs[index].id} 
                           draggingSong={draggingSong}
                           moveSong={moveSong}
                           addSong={addSong}
                           removeSationSong={removeSationSong}
                           likeStationSong={likeStationSong}
                           isOwner={isOwner}
                           mobileSelectSong={mobileSelectSong}
                           mobileSelectedSong={mobileSelectedSong}
                           currentUser={currentUser}
                           selectedSongs={selectedSongs}
                           onDragStart={onSongDragStart}
                           userIsAuthor={currentUser === theSongs[index].addedBy}
                           reorder={reorder}
                           style={{position: 'absolute', transform: 'translate3d(0, ' + (index * 65) + 'px, 0)', }}
                           />

               })
               }
      </div>

   );
}

export default StationSongWrapper;