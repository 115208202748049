import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Tracks from '../../components/Discover/Tracks';
// import genres from '../../utils/genres';
import { getSongs, getLastSongOrder } from '../../helpers';
import { playAll } from '../../redux/queueSlice';
import { addSong } from '../../redux/songSlice';
import TrackSkeleton from '../Loader/TrackSkeleton';
import Icon from '../Icon/Icon';


const TracksHome = () => {

   const dispatch = useDispatch();

   const queue = useSelector((state)=> state.queue );
   const userSettings = useSelector((state)=> state.user );
   const userplaylists = useSelector((state) => state.userplaylists);
   const userplaylistsMin =  useSelector((state) => state.userplaylistsMin);
   const songs = getSongs(userplaylists, 'temp' );
   const uid = userSettings && userSettings.user && userSettings.user.id;
   const listView = userSettings.discoverListView;
   const selectedCat = 'all';
   const filtered = [];
   // const [selectedCat, setSelectedCat] =  useState('all');
   // const [filtered, setFiltered] =  useState([]);
   // const [showGenreChoices, setShowGenreChoices] =  useState(false);
   const [latestSongs, setLatestSongs] = useState([]);

   useEffect(()=> {
      if(latestSongs.length === 0){
         axios.get('https://yhimsical.b-cdn.net/latest.json').then((res)=> {
            console.log(res.data);
            if(res.data && Array.isArray(res.data) && res.data.length > 0){
               setLatestSongs(res.data);
            }else{
               console.warn('ERROR Fetching Latest Tracks!',);
            }
         }).catch((err)=> {
            console.warn('ERROR Fetching Latest Tracks', err);
         })
      }

   }, [latestSongs])


   const playSong = (song) => {
        const newSong = { 
            artist: song.artist, 
            duration:song.duration, 
            title: song.title, 
            youtube: song.youtube, 
            order: songs[songs.length - 1] ? Number(songs[songs.length - 1].order) + 1 : 0,
            added: Number((new Date().getTime() / 1000).toFixed(0)) 
        };
        const queueHasSong = queue[queue.selected].filter(sng => sng.id === newSong.id).length > 0 ? true: false;
        dispatch(addSong(uid, queue.selected, newSong, true, queueHasSong));
  
   }

   const addToPlaylist = (song, playlistID) => {
        const orderValue = getLastSongOrder(userplaylistsMin, playlistID);
        const newSong = { 
            artist: song.artist, 
            duration:song.duration, 
            title: song.title, 
            youtube: song.youtube, 
            order: orderValue,
            added: Number((new Date().getTime() / 1000).toFixed(0)) 
        };

        return dispatch(addSong(uid, playlistID, newSong));
   }

   // const onCatChange = (selectedOption) => { 
   //    setSelectedCat(selectedOption);
   //    if(selectedOption === 'all') {
   //       setFiltered([]);
   //    }else{
   //       const filteredSongs = latestSongs.filter((sng) =>  sng.genres && sng.genres[selectedOption] === true );
   //       setFiltered(filteredSongs);
   //    }
   //    setShowGenreChoices(false);
   // }

   const playAllTracks = () => {
      dispatch(playAll({songs: filtered.length > 0 ? filtered : latestSongs, queue: queue.selected}));
   }


   // Since Most of the Latest Songs does not have genres, no point in letting users sort songs by genres 
   // const genresModified = genres.map(gen => gen.id === 'mixed' ? {id: 'all', name: 'All' } : gen );
   // const allGeners = genresModified.map((genr)=> <li key={genr.id} className={selectedCat === genr.id ? `active`:``} onClick={()=> onCatChange(genr.id)}>{genr.name}</li>);

   // const genArrow = showGenreChoices ? <Icon icon="md-arrow-dropup" fontSize="14px" color="#ffffff"/> :  <Icon icon="md-arrow-dropdown" fontSize="14px" color="#ffffff"/>; 

   return(
      <div className="discover_content__wrap discover_content__wrap-all">
            <div className="tracks_header">
               <div className="tracks_header__inner">
                  <div className="tracks_header__left">
                        <h2>Latest 100 Tracks</h2>
                        <div className="playAll" data-tip="Play All">
                           <button aria-label="Play All" onClick={playAllTracks}>
                              <Icon icon="md-play" fontSize="14px" color="#ffffff"/>
                           </button>
                        </div>
                  </div>

                  <div className="tracks_header__right">
                        <div className="discover_latest_header__genres">

                           {/* <div className={`genChoices ${showGenreChoices ? 'showGenreChoices' : ''}`}>
                              <button onClick={()=> setShowGenreChoices(!showGenreChoices)}>{selectedCat} {genArrow}</button>
                              <ul>{allGeners}</ul>
                           </div> */}
                           {/* <Select style={{ width: 140 }} value={selectedCat} onChange={onCatChange} placeholder="Genre" optionLabelProp="children" showSearch={userSettings.mobile ? false: true} >
                              {genresModified.length > 0 && genresModified.map((cat)=> <Option key={cat.id} value={cat.id}>{cat.name}</Option> )}
                           </Select> */}
                        </div>
                  </div> 
               
               </div>
                  

            </div>
            { (selectedCat === 'all' && latestSongs && latestSongs.length > 0) || (selectedCat !== 'all' && filtered.length > 0) ?
               <div className="trackScroll_wrap customScrollbar">
                  <div>
                        <Tracks 
                        listView={listView}
                        mobile={userSettings.mobile}
                        songs={filtered.length > 0 && selectedCat !== 'all' ? filtered : latestSongs} 
                        addToPlaylist={addToPlaylist} 
                        playSong={playSong} 
                        allPlaylists={userplaylistsMin} 
                        guest={!uid} 
                        slider={false}
                        />
                  </div>
               </div>
               :
               <div className="trackScroll_wrap customScrollbar"><div><TrackSkeleton /></div></div>
            }

      </div>
   );

}

export default TracksHome;
