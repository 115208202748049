import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { addToQueue, playAll } from '../../redux/queueSlice';

const QueueDropZone = (props) => {
   const {songs, queue=''}  = props;
   let queuID = 1;
   if(queue === 'queueTwo') {queuID = 2 } 
   if(queue === 'queueThree') {queuID = 3 }

   const dragzoneRef = useRef(null);
   const dispatch = useDispatch();

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: ['song', 'track'],
      drop: (_item, monitor) => {
         const item = monitor.getItem();
         console.log(item, props);
         if(item.song && queue && songs.findIndex((el) => el.id === item.id) === -1){
            //const autoplay = songs.length > 0 ? false : true;
            if(item.songs.length > 1){
               dispatch(playAll({songs:item.songs, queue}));
            }
            if(item.songs.length === 1){
               dispatch(addToQueue({ song: item.songs[0], queue, play: false }));
            }
         }
         return { moved: true };
      },
      collect: (monitor) => ({
         isOver: monitor.isOver(),
         isOverCurrent: monitor.isOver({ shallow: true }),
         canDrop: monitor.canDrop(),
         itemType: monitor.getItemType()
      }),
   }))

   const isActive = canDrop && isOver;

   drop(dragzoneRef);

   return <div ref={dragzoneRef} className="queueDropzone" style={isActive ? {backgroundColor:'#58347ec7'}: null}><div>Drop Song here to <h3>Add to Queue {queuID}</h3></div></div>
    
}

export default QueueDropZone;