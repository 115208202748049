import React from 'react';

export const TitleSkeleton = (props) => {
    return <div className="titleSkeleton" style={{width: props.width ? props.width:'50%', height: props.height ? props.height:'14px', margin: props.margin ? props.margin : '20px 0'}}></div>
}

export const ContentSkeleton = (props) => {
    const lines = props.lines ? props.lines: 5;
    const linesArray = [];

    for (var i=0; i < lines; i++){
        linesArray.push(<div key={`contentLine-${i}`} style={{width: props.width ? props.width:'100%', height: props.height ? props.height:'10px'}}></div>);
    } 

    return <div className="contentSekeleton">{linesArray}</div>
}