import React from 'react';
import { useDispatch } from 'react-redux';
import { showUserArea, showHidePRO } from '../../redux/userSlice';
import Icon from '../Icon/Icon';

const OverlayScreen = (props) => {
   const dispatch = useDispatch();
   const {className, title, message, action, button}  = props;

   const submitButton = ()=> {
        if(action === 'pro'){
            return dispatch(showHidePRO());
        }
        if(action === 'login'){
            return dispatch(showUserArea());
        }
    }


   return(
      <div className={`overlay_msg ${className? className : ''}`}>
            <div className="overlay_msg_inner">
               <div className="overlay_msg_content">
                  <h3>{title}</h3>
                  <p>{message}</p>
               </div>
               {button && 
                  <div className="overlay_msg_action">
                     <button onClick={submitButton}>
                        {action === 'pro' && <Icon icon="ios-flash" fontSize="24px" color="#3f3576"/>}{button}
                     </button>
                  </div>
               }
            </div>
      </div>
   );


}

export default OverlayScreen;
