

export const sendNotification = (type='', uid, friendID, currentUser, playlist) => 
    (dispatch, getState, getFirebase) => {
        console.log('FROM ACTIOn -->>', uid, type, friendID);
        const firebase = getFirebase();
        let notification = null
        let notificationID = ''
        if(type === 'like'){
            console.log('Like Invocked');
            notification = { 
                type:'like', 
                sender:{ name:currentUser.name, username: currentUser.username, uid:uid }, 
                playlist:{ title: playlist.title, slug: playlist.slug }, 
                sent: parseInt((new Date().getTime() / 1000).toFixed(0), 10) 
            } 
            notificationID = currentUser.username+playlist.slug;
        }

        if(type === 'friend-request'){
            console.log('friend-request Invocked');
                notification = { 
                    type:'friend-request', 
                    sender:{ name:currentUser.name, username: currentUser.username, uid:uid }, 
                    sent: parseInt((new Date().getTime() / 1000).toFixed(0), 10) 
                } 
                notificationID = currentUser.username+'request';
        }

        if(type === 'friend-accepted'){
            console.log('friend-accepted Invocked');
                notification = { 
                    type:'friend-accepted', 
                    sender:{ name:currentUser.name, username: currentUser.username, uid:uid }, 
                    sent: parseInt((new Date().getTime() / 1000).toFixed(0), 10) 
                } 
                notificationID = currentUser.username+'accepted';
        }

        if(notification && notificationID){
            firebase.set(`notifications/${friendID}/${notificationID}`, notification)
            .then((snapshot) => {
                //console.log('Sent Notification',snapshot.val());
              //dispatch({type: 'CREATE_PLAYLIST', payload: playlist})
            })
    }

}



export const clearOldNotifications = (uid, notifications, lastseenDate) => 
    //Should be ran when the notification is checked and before notificationChecked action.
    (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        const lastseen = parseInt((new Date().getTime() / 1000).toFixed(0), 10) + 259200;
        Object.keys(notifications).forEach((key)=> {
            if(notifications[key].sent >= lastseen){
                firebase.remove(`notifications/${uid}/${key}`).then((data) => {
                    console.log('Notification Removed!',data);
                })
            }
        })
    }

export const notificationChecked = (uid) => 
    (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        const lastseen = parseInt((new Date().getTime() / 1000).toFixed(0), 10)
        firebase.set(`notifications/${uid}/lastseen`, lastseen )
        .then((data) => {
            console.log('Last Seen date Updated!',data);
          //dispatch({type: 'CREATE_PLAYLIST', payload: playlist})
        })
    
}

