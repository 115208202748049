export const config = {
    apiKey: `${process.env.REACT_APP_FB_API}`,
    authDomain: `${process.env.REACT_APP_FB_AUTH}`,
    databaseURL: `${process.env.REACT_APP_FB_DB}`,
    projectId: `${process.env.REACT_APP_FB_ID}`,
    storageBucket: `${process.env.REACT_APP_FB_STORAGE}`,
    messagingSenderId: `${process.env.REACT_APP_FB_MSG}`
};
export const lastfmKey = `${process.env.REACT_APP_LAST_FM_KEY}`;
export const youtubeKey = `${process.env.REACT_APP_YT_KEY}`;
export const publicURL = `${process.env.REACT_APP_PUBLIC_URL}`;
export const soundcloudApis = `${process.env.REACT_APP_SOUNDCLOUD}`;
export const FBServerkey = `${process.env.REACT_APP_FBSERVERKEY}`;