import React from 'react';

const ToastBox = ({children, type, className='default'}) => {

    return(
        <div className={`ToastBox ToastBox-${className}`}>
            {type==="success" && children && <div className="success message">{children}</div> }
            {type==="error" && children && <div className="error message">{children}</div>}
        </div>
    );
}

export default ToastBox;