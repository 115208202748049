import React from 'react';
import { Link  } from 'react-router-dom';
import fakePic from '../../assets/user_m.png';

const Artists = (props) => {
    return(
        <div className="artists">
            {props.artists.length > 0 &&
                props.artists.map((artist)=> {
                    return(
                        <Link to={`/artist/${artist.slug}/`} key={artist.id}>
                            <div className="artist">
                                
                                    <div className="artist_left">
                                        <img src={artist.image? artist.image : fakePic} alt={artist.name ? artist.name: "Artist photo"} />
                                    </div>
                                    <div className="artist_right">
                                        <h3>{artist.name}</h3>
                                        <span className="artist__tracks__count">{artist.songs? artist.songs: 0 } Tracks</span>
                                        <span className="artist__genres">{Object.keys(artist.categories).toString()}</span>
                                    </div>
                                
                            </div>
                        </Link>
                    );
                })
            }
        </div>
    );
}

export default Artists;