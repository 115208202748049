import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
// import isequal from 'lodash.isequal';
import firebase from "firebase/compat/app";
import Icon from '../Icon/Icon';
import { getDatabase, ref, onValue, query, limitToLast, serverTimestamp, remove, set, onDisconnect, push } from "firebase/database";
// import { getDatabase, ref, child, get } from "firebase/database";
import { youtube_regex, processChannelSongs, getCurrentUserTime, YT_SEARCH_URL } from '../../helpers';
import { playerVolume } from '../../redux/playerSlice';
import { addSong } from '../../redux/songSlice';
import { addToQueue } from '../../redux/queueSlice';
import ChannelSong from './ChannelSong';
import Duration from '../Player/Duration';
import ChatBox from './ChatBox';
import { useParams } from 'react-router-dom';
import SelectField from '../SelectField/SelectField';


const Channels  = (props) => {

   const channelSongsLoaded = useRef(false);
   const channelPlayer = useRef(null);
   const dispatch = useDispatch();

   const playlistsMin = useSelector((state) => state.userplaylists.userPlaylistsMin);
   const player = useSelector((state)=> state.player );
   const queue = useSelector((state)=> state.queue );
   const userSettings = useSelector((state)=> state.user )
   const profile = userSettings?.userData;
   const uid = profile?.id;


   const { channel } = useParams();

   // const [current, setCurrent] =  'pop';
   const [url, setUrl] =  useState('');
   const [played, setPlayed] = useState(0);
   const [playing, setPlaying] =  useState(false);
   const [volume, setVolume] =  useState(player?.volume || 0.8);
   const [muted, setMuted] =  useState(false);
   const [channelSongs, setChannelSongs] = useState([]);
   const [currentSong, setCurrentSong] = useState({});
   const [showAddModal, setShowAddModal] =  useState(false);
   const [playlistSelect, setPlaylistSelect] = useState('queueOne');
   const [mobilePlayerFull, setMobilePlayerFull] =  useState(false);
   const [channelMessages, setChannelMessages] = useState({});
   const [channelOnlineUsers, setChannelOnlineUsers] = useState({});

   const playSong = (channelSongs) => {
        
      if(!channelSongs || channelSongs.length === 0){
          return
      }
      // console.log('PLAY THE SONG!');
      setPlayed(0);

      const currentUserTime = getCurrentUserTime();
      //Then find the closest song to users time
      let foundSong = {};
      
      channelSongs.forEach((song, index)=> {
         if(song.playAt <= currentUserTime && song.playAt + song.duration  >= currentUserTime ){
            foundSong = song;
         }
      });

      
      //console.log(foundSong, currentUserTime);

      if(foundSong && foundSong.youtube){
          setCurrentSong(foundSong);
          setUrl('https://www.youtube.com/watch?v='+foundSong.youtube);
          setPlaying(true);
      }

      //after the current song is set Check if its currently playing
      //This fixes the bug where the song doesnt change
      // setTimeout(() => {
          
      //     if(played === 0){
      //        playSong(channelSongs);
      //     } 
      // }, 7000);

  }

   useEffect(()=> {
      const validChannels =['pop', 'hiphop', 'rock', 'metal', 'rnb', 'indie', 'country', 'instrumental', 'electronic', 'dance', 'blues', 'folk']
      if(channel && validChannels.includes(channel)){
         firebase.firestore().collection("Channels").doc(channel).get().then((resp)=> {
            console.log('resp: ', resp.exists, resp.data() );
            if(resp.exists){
               const chnlSongs =  resp.data();
               const theChanlSongs = processChannelSongs({songs: chnlSongs});
               // console.warn('theChanlSongs: ', theChanlSongs);
               setChannelSongs(theChanlSongs);
               channelSongsLoaded.current = true;
               setTimeout(() => {
                    playSong(theChanlSongs);
               }, 1600);
            }
         }).catch((err)=> {
            console.log('Channels ERROR: ', err);
         })
      }

   }, [channel])
   
   // Connect to Firebase Realtime Database to get the Chat Messages
   useEffect(()=> {
      const validChannels =['pop', 'hiphop', 'rock', 'metal', 'rnb', 'indie', 'country', 'instrumental', 'electronic', 'dance', 'blues', 'folk']
      if(channel && validChannels.includes(channel)){
         // Load the Last 20 Chat Messages
         const db = getDatabase();
         const channelMessageRef = query(ref(db, `channels/${channel}/messages`), limitToLast(20));
         onValue(channelMessageRef, (snapshot) => {
            const data = snapshot.val();
            // console.log(data);
            setChannelMessages(data);
         });

         // Update Users Online Count
         // https://cloud.google.com/firestore/docs/solutions/presence
         const userID = firebase.auth()?.currentUser?.uid;
         if(userID){
            const db = getDatabase();
            const myConnectionsRef = ref(db, `channels/${channel}/connections/${userID}`);
            const userData = { photoURL: profile.photoURL, fullname: profile.fullname, username: profile.username }
            const isOnlineForDatabase = { joined: serverTimestamp(), user: userData };
            const connectedRef = ref(db, '.info/connected');
            onValue(connectedRef,  (snap) => {
               // If we're not currently connected, don't do anything.
               console.log('connectedRef Snap!!! ', snap.val());
               if (snap.val() === false) {  return; };
               //console.log(connectedRef, myConnectionsRef, snap, snap.val());
               if (snap.val()) {
                  set(myConnectionsRef, isOnlineForDatabase);
                  onDisconnect(myConnectionsRef).set(null);
               }
            })
            // Listen to the Total Connected Users
            const connectionsRef = ref(db, `channels/${channel}/connections`);
            onValue(connectionsRef,  (snap) => {
               const connectionsObj = snap.val();
               if (snap.val() === false) {  return; };
               if (snap.val()) {
                  setChannelOnlineUsers(connectionsObj);
                  // Update Connections Ref Here!!!
               }
            })
         }

      }

   }, [profile, channel]);

   useLayoutEffect(() => {
      return () => {
         const userID = firebase.auth()?.currentUser?.uid;
         // console.warn('Component Will Unmount!', userID);

         if(userID){
            const db = getDatabase();
            const myConnectionsRef = ref(db,`channels/${channel}/connections/${userID}`);
            set(myConnectionsRef, null);
            remove(myConnectionsRef);
         }
      }
  }, [channel])


    const loadPrevMessages = () => {
      if(channel){
         const db = getDatabase();
         const channelMessageRef = query(ref(db, `channels/${channel}/messages`), limitToLast(200));
         console.log(channelMessages);
         onValue(channelMessageRef, (snapshot) => {
            const prevMessages = {};
            snapshot.forEach((childSnapshot) => {
               const childKey = childSnapshot.key;
               const data = childSnapshot.val();
               prevMessages[childKey] = data;
            });
            setChannelMessages({...prevMessages, ...channelMessages});
         }, {
            onlyOnce: true
         });
      }
   }

   const onStart = () => {
        console.log('onStart');
        //Seek the song to where the song should start
        const currentUserTime = getCurrentUserTime();
        //const currentUserTime = 1547675817;
        var songTimeDiff = currentUserTime - currentSong.playAt;
        console.log('songTimeDiff:', songTimeDiff);
        channelPlayer.current.seekTo(songTimeDiff);
    }

   const onPause = () => {
        console.log('onPause');
        setPlaying(false);
        setTimeout(() => {
            setPlaying(true);
        }, 200);
      }

   const onProgress = (state) => {
        //console.log(state.playedSeconds);
        setPlayed(state.playedSeconds);
        if(state.playedSeconds >= currentSong.duration -1){
            setTimeout(() => {
               playSong(channelSongs);
            }, 1200);
            
        }

    }

   const onEnded = () => {
        console.log('onEnded');
        playSong(channelSongs);
    }
    
   const onError = (e)=> {
        console.log('onError', e);
        searchYoutube();
    }

   const changeVolume = e => {
        const vol = parseFloat(e.target.value);
        if(vol === 0){
            setMuted(true);
            setVolume(vol);
            dispatch(playerVolume(vol));
        }else{
            setMuted(false);
            setVolume(vol);
            dispatch(playerVolume(vol));
        }
    }

   const searchYoutube = () => {
        const searchString = `${currentSong.artist ? currentSong.artist.replace('/', ' ')+' - ' : '' } ${currentSong.title} `;
        axios.get(YT_SEARCH_URL+searchString)
        .then((response) => {
            //console.log(response.data.results);
            if(response.data.results.length > 0){
                const newSong = response.data.results[2];
                const parsed = newSong.video.url.match(youtube_regex);
                const videoID = parsed && parsed[2] ? parsed[2]:null;
                console.log(newSong);
                //var duration = durationRaw.split(':').reduce((acc,time) => (60 * acc) + +time);
                console.log('Searched Song: ', {...currentSong, youtube: videoID });
                //const songsYoutube = [...this.state.songsYoutube, {id: currentSong.id, youtube: videoID}];
               setCurrentSong({...currentSong, youtube: videoID });
               setUrl('https://www.youtube.com/watch?v='+videoID);
               setPlaying(true);
            }
  
        })
        .catch(function (error) {
          setPlaying(false);
        });
    }

   const onPlaylistSelectorChange = (value, option) => {
        setPlaylistSelect(value);
    }
    

   const copySong = (playlistSelect, song) => {
        let queue = '';
        if(playlistSelect === 'queueOne' || playlistSelect === 'queueTwo' || playlistSelect === 'queueThree'){
            queue = playlistSelect;
        }

        const copySelectedPlaylist = playlistsMin && playlistsMin.filter((plist)=>  plist['id'] === playlistSelect  ); 
        console.log(copySelectedPlaylist, copySelectedPlaylist[0] ? copySelectedPlaylist[0].count : 0);
        if(!queue){
            const newSong = {...song , order: copySelectedPlaylist[0] ? copySelectedPlaylist[0].count : 0}
            dispatch(addSong(uid, playlistSelect, newSong));
        }else{
            dispatch(addToQueue({song, queue, play: false}));
        }

        setTimeout(()=> {
            setShowAddModal(false);
        }, 500);
        
    }
    

   const onClickFullscreen = () => {
      if(userSettings.mobile){
         return setMobilePlayerFull(!mobilePlayerFull);
      }else{
         const channelPlayer = document.getElementById('react-player__channel');
         channelPlayer.requestFullscreen()
      }
    }


   const renderPlaylist = () => {
        if(userSettings.mobile){ return }
        const lastTenSongs = channelSongs.filter((song, index)=> song.playAt < getCurrentUserTime() );
        return lastTenSongs.slice(0, 10).map((song, index)=> {
                return <ChannelSong 
                active={currentSong.id === song.id}
                pastSong={song.playAt < getCurrentUserTime()}
                key={song.id}
                songID={song.youtube} 
                song={song} 
                playlistsMin={playlistsMin}
                queue={queue}
                addSong={copySong}
                />
        })
    }

   const postChatMessage = (channel,msg) => {
        if(!uid || !profile.username){
            return console.log('Auth Id or username Missing!');
        }
        const theMsg = {
            "content": msg,
            "created": Math.round((new Date()).getTime() / 1000),
            "senderID": uid,
            "senderName": profile.username,
        }
        console.log(channel, msg, `channels/${channel}/messages`);
        const db = getDatabase();
      const postsRef = ref(db, `channels/${channel}/messages`);
      const newPostRef = push(postsRef, theMsg);
      console.log(newPostRef);
   }


   const confirmIcon = <Icon icon="md-checkmark" fontSize="18px" color="#ffffff"/>;
   const cancelIcon = <Icon icon="md-close" fontSize="18px" color="#ffffff"/>;
   let queuePlaylists = [ {id:'queueOne', title:'Queue 1'}, {id:'queueTwo', title:'Queue 2'}, {id:'queueThree', title:'Queue 3'}];
   if(playlistsMin && playlistsMin.length > 0){ queuePlaylists = [...queuePlaylists, ...playlistsMin]  }
   // const copySelectedPlaylist = queuePlaylists.filter((plist)=>  plist['id'] === playlistSelect  );

   return(
      <div className="channels">
            <div className="channels__left">
                  <h4>Channel - {channel}</h4>
                  <div className={`channels__player ${mobilePlayerFull ? 'channels__player--mobileFull' : ''}`}>

                        <div className="channels__player__overlay">
                           <div className="channels__player__inner">
                              
                              <button className="channelFullScreen__btn" onClick={onClickFullscreen}><Icon icon="md-resize" fontSize="16px" color="#ffffff"/></button>


                              <div className="channels__player__songInfo">
                                    <span className="channels__player__songInfo__nowPlaying">Now Playing</span>
                                    <h3>
                                       {currentSong.artist ? `${currentSong.artist} -  ${currentSong.title}` : currentSong.title}
                                       {currentSong.release_date && <span> ({new Date(currentSong.release_date).getFullYear()})</span>}
                                       <button className="channelSong__add" onClick={()=> setShowAddModal(true)}><Icon icon="md-add" fontSize="14px" color="#ffffff"/></button>
                                       {showAddModal && 
                                       <div className="song__actions__confirm  song__actions__confirm-copy">
                                          <span>Add Song to</span>
                                          <br />
                                          <SelectField 
                                          style={{ width: 200 }} 
                                          options={queuePlaylists.map((category)=> ({ label: category.title, value: category.id }) )}
                                          value={playlistSelect}
                                          onChange={onPlaylistSelectorChange}
                                          placeholder={'Select Category'}
                                          showSearch={false}
                                          />
                                          <button className="confirm_yes" onClick={()=> copySong(playlistSelect, currentSong) }>{confirmIcon}</button>
                                          <button className="confirm_no" onClick={()=>  setShowAddModal(false)}>{cancelIcon}</button>
                                       </div>
                                       }
                                    </h3>
                                    <div className="channelPlayer__volume__control">
                                       <div className="player__volume" onClick={()=> { setMuted(!muted); setVolume(0); }}>
                                          <span>{muted ? <Icon icon="ios-volume-mute" fontSize="20px" color="#3f3576"/> : <Icon icon="ios-volume-up" fontSize="20px" color="#3f3576"/> }</span>
                                       </div>
                                       <input type='range' aria-label="Change Volume" min={0} max={1} step='any' value={volume} onChange={changeVolume} />
                                    </div>
                                    <div className="player__duration">
                                          <Duration seconds={played} /> / <Duration seconds={currentSong.duration ? currentSong.duration : 0} />
                                    </div> 
                              </div>
                              {userSettings.mobile && !mobilePlayerFull && <div className="playerBG">{currentSong && currentSong.youtube && <img alt="playerBG" src={`https://i.ytimg.com/vi/${currentSong.youtube}/hqdefault.jpg`} />}</div>}

                           </div>

                        </div>

                        <ReactPlayer 
                           ref={channelPlayer}
                           url={url}
                           playing={playing}
                           className='react-player'
                           id="react-player__channel"
                           config={{ youtube: { preload: true, playerVars: { rel: 0 } },  file: {  attributes: { poster: currentSong && currentSong.youtube && `https://i.ytimg.com/vi/${currentSong.youtube}/hqdefault.jpg`  } }  }}
                           volume={volume}
                           muted={muted}
                           //onStateChange={(state) => console.log('State: ',state)}
                           //onReady={this.onReady}
                           onStart={onStart}
                           // onPlay={this.onPlay}
                           onPause={onPause}
                           onBuffer={() => console.log('onBuffer')}
                           onSeek={e => console.log('onSeek', e)}
                           onEnded={onEnded}
                           onError={onError}
                           onProgress={onProgress}
                           // onDuration={this.onDuration}
                           width='100%'
                           height='100%'
                           rel={0}
                        />
                  </div>
                  <div className="channels__playlist">
                        <h5>Last 10 Tracks</h5>
                        {channelSongs && <div className="channel_playlist_wrap customScrollbar"><div>{renderPlaylist()}</div></div>}
                  </div>
            </div>
            <div className="channels__right">
               <div className="channels__chat">
                  <ChatBox 
                  uid={uid}
                  messages={channelMessages} 
                  onlineUsers={channelOnlineUsers} 
                  channel={channel} 
                  channelName={channel} 
                  postChatMessage={postChatMessage}
                  firebase={firebase}
                  addSong={copySong}
                  playlistsMin={playlistsMin} 
                  queue={queue} 
                  mobile={userSettings.mobile}
                  loadPrevMessages={loadPrevMessages}
                  />
               </div>
            </div>

      </div>
   );

}

// export default compose(
//     withRouter,
//     firebaseConnect((props) => {
//         return [
//         { path: `/channels/${props.match && props.match.params && props.match.params.channel ? props.match.params.channel : 'pop' }`} , // create todo listener
//           // `todos/${props.params.todoId}` // equivalent string notation
//         ]
//       }),
//     connect(
//       (state, props) => {
//         return ({
//         channel: props.match && props.match.params && props.match.params.channel ? props.match.params.channel : '', 
//         channelSongs: getVal(state.firebase, `data/channels/${props.match && props.match.params && props.match.params.channel ? props.match.params.channel : 'pop' }`),
//         //channelSongsSorted: processChannelSongs(getVal(state.firebase, `data/channels/${props.match && props.match.params && props.match.params.channel ? props.match.params.channel : 'pop' }`)),
//         queue: state.queue,
//         player: state.player,
//         playlistsMin: props.playlists ? minifyPlaylist( props.playlists ) : [],
//         userSettings: state.user,
//         profile: state.firebase.profile 
//       })},
//       {addSong, addToQueue, playerVolume}
//     )
//   )(Channels);

export default Channels;

